import React, {useState} from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Row } from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom';
import FormInput from '../components/FormInput';
import FormButton from '../components/FormButton';
import { useAuth } from '../../../providers/AuthProvider';
import authimg from './imgs/authimg.png'
import logo from './imgs/id_login.png'
import css from './auth.module.css'

const intialSigninValues ={
    username: "",
    password: ""
}

const SigninSchema = Yup.object().shape({
    username: Yup.string().required(),
    password: Yup.string().required()
});

function Signin(props) {
    const [errorForm, setErrorForm] = useState({error: false, message: ""});
    const { signIn } = useAuth();

    function handleSubmit(values, formikBag) {
        setErrorForm({error: false, message: ""});
        signIn(values)
            .then(({ updatePassword }) => updatePassword && props.history.push('/update-password')) 
            .catch((err) => {
                console.log(err);
                if (err.message === "User is disabled.") return props.history.push("/user-disabled")
                setErrorForm({error: true, message: err.message});
                formikBag.setSubmitting(false);
            })
    }

    return (
        <div className={css.authContainer}>
            <section className={css.form}>
                <Link to="/"><img src={logo} alt="CEO360" /></Link>
                <Formik
                    initialValues={intialSigninValues}
                    validationSchema={SigninSchema}
                    onSubmit={handleSubmit}>
                    {({errors, touched, isSubmitting })=>(
                        <Form>
                            <h4>Entrar</h4>
                            <FormInput name="username"  type="text" errors={errors} touched={touched} holder="E-mail"/>
                            <FormInput name="password"  type="password" errors={errors} touched={touched} holder="Senha" />

                            <Row className="p-0 m-0">
                                {errorForm.error && 
                                    <span style={{color:'red'}}>{errorForm.message}</span>
                                }
                            </Row>
                            <Link className={css.forgotLink} to="/forgot">
                                Esqueceu a senha?
                            </Link>
                            <Row className="p-0 m-0 justify-content-center">
                                <FormButton disabled={isSubmitting} type="submit" text="Login" />
                            </Row>
                            <Link className={css.authLink} to="/signup">
                                Ainda não tem uma conta? Teste gratuitamente
                            </Link>
                        </Form>
                    )}
                </Formik>
            </section>
            <img className={css.authImg} src={authimg} alt="Business"/>
        </div>
    )
}

export default withRouter(Signin);