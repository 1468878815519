import React, {useEffect, useCallback} from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../../companyoverview.module.css';
import CurrencyInput from '../../../../../CurrencyInput';

function MonthTotalizers(props){
    const {
        values: { companyValueChainBudget },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    const loadData = useCallback(
        () => {
            if(companyValueChainBudget[props.index]["companyValueChainBudget"].length){
                let entries = Object.entries(companyValueChainBudget[props.index]["companyValueChainBudget"][props.subindex]["companyValueChainBudget"]);
                entries.pop(); //Retirando a ultima posição do array
                entries = entries.map((el)=>(el[1].total));
                const total = entries.reduce((acc, el)=>acc + el, 0);
                setFieldValue(props.name, total);
            }
        },
        [companyValueChainBudget[props.index]["companyValueChainBudget"][props.subindex]["companyValueChainBudget"]],
    );


    useEffect(() => {
        loadData();
    }, [loadData]);

    return (
        <>
            <CurrencyInput disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default MonthTotalizers