import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../../companyoverview.module.css';

function UnitTotalizers(props) {
    const {
        values: { companyValueChainBudget },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    React.useEffect(() => {

        if(companyValueChainBudget[props.index]["companyValueChainBudget"].length){
            const entries = Object.entries(companyValueChainBudget[props.index]["companyValueChainBudget"][props.subindex]["companyValueChainBudget"]);
            const total = entries.reduce((acc, el)=>acc + (el[1][props.calculate] ? parseInt(el[1][props.calculate]) : 0), 0);
            setFieldValue(props.name, total);
        }

    }, [props.calculate !== "total" ? companyValueChainBudget[props.index]["companyValueChainBudget"][props.subindex]["companyValueChainBudget"] : companyValueChainBudget[props.index]["companyValueChainBudget"][props.subindex]["companyValueChainBudget"]['total'].totalUnit]);

    return (
        <>
            <input disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default UnitTotalizers