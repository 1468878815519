import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../companyoverview.module.css';
import CurrencyInput from '../../../../CurrencyInput';

function Totalizers({item, original, index, subIndex,...props}){
    const {
        values: { companyValueChainCostExpenses },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    React.useEffect(() => {
        let totals = Object.values(item[original]);
        totals = totals.reduce((acc, el)=>acc + Number(el), 0);
        setFieldValue(props.name, totals);
    }, [companyValueChainCostExpenses[index][original][original][subIndex][original]]);
    return (
        <>
            <label htmlFor="">Total</label>
            <CurrencyInput disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default Totalizers