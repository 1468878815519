import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Table } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { getBudgetData } from "../../../services/formData";
import useSpinner from "../../spinner/useSpinner";
import BudgetTable from "./components/BudgetTable";
import BudgetTableProducts from "./components/BudgetTableProducts";
import BudgetTableMargemBruta from "./components/BudgetTableMargemBruta";
import BudgetTableDesp from "./components/BudgetTableDesp";
import BudgetTableResultOp from "./components/BudgetTableResultOp";
import BudgetTableAmortDesp from "./components/BudgetTableAmortDesp";
import BudgetTableLucroLiq from "./components/BudgetTableLucroLiq";

import css from "./budget.module.css";
import { useThemeContext } from "../../../providers/ThemeProvider";
import { useAuth } from "../../../providers/AuthProvider";

function Budget({ forms, ...props }) {
    const [totDesp, setTotDesp] = useState({});
    const [produtos, setProdutos] = useState({});
    const [costs, setCosts] = useState([]);
    const [margin, setMargin] = useState({});
    const [amortDep, setAmortDep] = useState({});
    const [prodTotal, setProdTotal] = useState({});
    const [departamentos, setDepartamentos] = useState([]);
    const [spinner, showSpinner, hideSpinner] = useSpinner();
    const { getContentWidth, getContentHeight } = useThemeContext();
    const { getAccessToken } = useAuth();

    useEffect(() => {
        let isSubscribe = true;
        if (isSubscribe) {
            const loadData = async () => {
                if (forms && Array.isArray(forms) && isSubscribe) {
                    showSpinner();
                    const mainMenu = forms.find(
                        (hit) => hit.FormType.menuOrder === 1
                    ); //find do menu principal - strategic position
                    if (mainMenu && Object.keys(mainMenu).length) {
                        //Verificando a existencia de chaves
                        const secMenu = mainMenu.FormType.MainGroups.find(
                            (hit) => hit.menuOrder === 4
                        ); //find do menu secundario - value chain
                        if (secMenu && Object.keys(secMenu).length) {
                            //Verificando a existencia de chaves
                            let operations = secMenu.SubGroups.find(
                                (hit) => hit.menuOrder === 4
                            ); //operations
                            let serviceSales = secMenu.SubGroups.find(
                                (hit) => hit.menuOrder === 3
                            ); // Service and sales
                            const accessToken = await getAccessToken();
                            const budgetData = await getBudgetData(
                                mainMenu.id,
                                secMenu.id,
                                accessToken
                            );
                            if (
                                !Array.isArray(budgetData) ||
                                budgetData.error
                            ) {
                                props.history.push("/");
                            } else {
                                if (isSubscribe) {
                                    //Services and sales

                                    let budgetServiceData = budgetData?.filter(
                                        (hit) =>
                                            hit.sGroupId === serviceSales.id
                                    )[0];
                                    if (budgetServiceData) {
                                        budgetServiceData =
                                            budgetServiceData.questions?.companyValueChainBudget?.filter(
                                                (item) =>
                                                    parseInt(item.year) ===
                                                    new Date().getFullYear()
                                            );
                                        if (budgetServiceData.length) {
                                            //Carregando o dados de serviços e produtos
                                            budgetServiceData =
                                                budgetServiceData[0]
                                                    .companyValueChainBudget;
                                            setProdutos(budgetServiceData);

                                            let totalProdutos =
                                                calcTotalProduto(
                                                    budgetServiceData
                                                );
                                            setProdTotal(totalProdutos);
                                        }
                                    }

                                    //Carregando os dados de custo fixo e custo variável de produtos
                                    let budgetCostData = budgetData.filter(
                                        (hit) => hit.sGroupId === operations.id
                                    );
                                    if (budgetCostData.length) {
                                        budgetCostData = budgetCostData[0];

                                        //Recuperando o ano corrente
                                        let budgetCostDataCurrentYear =
                                            budgetCostData.questions.companyValueChainBudget?.filter(
                                                (item) =>
                                                    parseInt(item.year) ===
                                                    new Date().getFullYear()
                                            )[0];
                                        if (budgetCostDataCurrentYear) {
                                            let costs =
                                                budgetCostDataCurrentYear.companyValueChainBudget;
                                            setCosts(costs);

                                            //Margem Bruta Total
                                            let totalMargin =
                                                budgetCostDataCurrentYear?.total;
                                            totalMargin.total = 0;
                                            Object.values(totalMargin).forEach(
                                                (hit) => {
                                                    if (hit.totalMargin)
                                                        totalMargin.total +=
                                                            parseFloat(
                                                                hit.totalMargin
                                                            );
                                                }
                                            );
                                            setMargin(totalMargin);

                                            //Amortização e depreciação
                                            let amortDepData =
                                                budgetCostData.questions.companyValueChainAmortizationDepreciation.find(
                                                    (item) =>
                                                        item.year ===
                                                        new Date().getFullYear()
                                                );
                                            setAmortDep(amortDepData);
                                        }
                                    }

                                    secMenu.SubGroups.forEach((item) => {
                                        let dataDepart = budgetData.find(
                                            (hit) => hit.sGroupId === item.id
                                        );
                                        if (dataDepart) {
                                            //Departamento encontrado
                                            //Inserindo despesas dos departamentos separadamente
                                            item.expenses =
                                                dataDepart.questions.companyValueChainCostExpenses.find(
                                                    (data) =>
                                                        data.year ===
                                                        new Date().getFullYear()
                                                );
                                        }
                                    });

                                    secMenu.SubGroups = calcTotalAnualDespesas(
                                        secMenu.SubGroups
                                    );
                                    setDepartamentos(secMenu.SubGroups);

                                    let totalDasDespesas = calcTotalDespesas(
                                        secMenu.SubGroups
                                    );
                                    setTotDesp(totalDasDespesas);
                                }
                            }
                            hideSpinner();
                        }
                    }
                }
            };
            loadData();
        }
        return () => (isSubscribe = false);
    }, [forms]);

    //Funcão que calcula o total normal
    const handleTotal = (months, key) => {
        let total = 0;
        for (let i in months) {
            if (key) {
                if (months[i][key]) {
                    total += parseFloat(months[i][key]);
                }
            } else {
                total += parseFloat(months[i]);
            }
        }
        return total;
    };

    const calcTotalAnualDespesas = (departamentos) => {
        departamentos.forEach((hit) => {
            if (hit?.expenses?.companyValueChainExpenses.monthsTotal) {
                let obj = hit?.expenses?.companyValueChainExpenses.monthsTotal;
                let totalAnual = Object.values(obj).reduce(
                    (acc, value) => parseFloat(acc) + parseFloat(value.total),
                    0
                );
                hit.expenses.companyValueChainExpenses.monthsTotal.totalAnual =
                    totalAnual;
            }
        });
        return departamentos;
    };

    const calcTotalDespesas = (departamentos) => {
        let total = {
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            totalAnual: 0,
        };
        departamentos.forEach((hit) => {
            if (hit?.expenses?.companyValueChainExpenses.monthsTotal) {
                let obj = hit?.expenses?.companyValueChainExpenses.monthsTotal;
                total.jan += parseFloat(obj.jan.total);
                total.fev += parseFloat(obj.fev.total);
                total.mar += parseFloat(obj.mar.total);
                total.abr += parseFloat(obj.abr.total);
                total.mai += parseFloat(obj.mai.total);
                total.jun += parseFloat(obj.jun.total);
                total.jul += parseFloat(obj.jul.total);
                total.ago += parseFloat(obj.ago.total);
                total.set += parseFloat(obj.set.total);
                total.out += parseFloat(obj.out.total);
                total.nov += parseFloat(obj.nov.total);
                total.dez += parseFloat(obj.dez.total);
                total.totalAnual += parseFloat(obj.totalAnual);
            }
        });
        return total;
    };

    const calcTotalProduto = (produtos) => {
        let total = {
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            totalAnual: 0,
        };
        produtos.forEach((hit) => {
            if (hit?.companyValueChainBudget) {
                let obj = hit?.companyValueChainBudget;
                total.jan += parseFloat(obj.jan.total);
                total.fev += parseFloat(obj.fev.total);
                total.mar += parseFloat(obj.mar.total);
                total.abr += parseFloat(obj.abr.total);
                total.mai += parseFloat(obj.mai.total);
                total.jun += parseFloat(obj.jun.total);
                total.jul += parseFloat(obj.jul.total);
                total.ago += parseFloat(obj.ago.total);
                total.set += parseFloat(obj.set.total);
                total.out += parseFloat(obj.out.total);
                total.nov += parseFloat(obj.nov.total);
                total.dez += parseFloat(obj.dez.total);
                total.totalAnual += parseFloat(obj.total.totalMain);
            }
        });
        return total;
    };

    return (
        <>
            {spinner}
            <ToastContainer />
            <div
                className="p-4"
                style={{
                    overflow: "auto",
                    width: getContentWidth() + "px",
                    height: getContentHeight() + "px",
                }}
            >
                <Table className={css.budgetTable}>
                    <thead>
                        <tr>
                            <th align="center">Orçamento</th>
                            <th>Produto / Setor</th>
                            <th>Jan</th>
                            <th>Fev</th>
                            <th>Mar</th>
                            <th>Abr</th>
                            <th>Mai</th>
                            <th>Jun</th>
                            <th>Jul</th>
                            <th>Ago</th>
                            <th>Set</th>
                            <th>Out</th>
                            <th>Nov</th>
                            <th>Dez</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <BudgetTableProducts
                            produtos={
                                Array.isArray(produtos) && produtos.length
                                    ? produtos
                                    : []
                            }
                            totalMes="total"
                            totalAno="totalMain"
                            title="Receitas"
                            prodTotal={prodTotal}
                        />

                        <BudgetTable
                            produtos={costs}
                            totalMes="fixCost"
                            totalAno="fixCostTotal"
                            title="Custos Fixos dos Produtos"
                        />

                        <BudgetTable
                            produtos={costs}
                            totalMes="varCost"
                            totalAno="varCostTotal"
                            title="Custos Variáveis dos Produtos"
                        />

                        <BudgetTableMargemBruta
                            costs={costs}
                            margin={margin}
                            title="Margem Bruta"
                        />

                        <BudgetTableDesp
                            departamentos={departamentos}
                            totDesp={totDesp}
                            title="Despesas"
                        />

                        <BudgetTableResultOp
                            margin={margin}
                            totDesp={totDesp}
                            title="Resultado Operacional"
                        />

                        <BudgetTableAmortDesp
                            amortDep={amortDep}
                            handleTotal={handleTotal}
                            title="Depreciação & Amortização"
                        />

                        <BudgetTableLucroLiq
                            margin={margin}
                            totDesp={totDesp}
                            amortDep={amortDep}
                            handleTotal={handleTotal}
                            title="Lucro Líquido"
                        />
                    </tbody>
                </Table>
            </div>
        </>
    );
}

export default withRouter(Budget);
