import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import useSpinner from "../../spinner/useSpinner";
import { ToastContainer } from "react-toastify";
import { Container } from "react-bootstrap";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Budget from "../budget/Budget";
import Swot from "./components/Swot";
import Page from "../../Page";
import BudgetAssumption from "./components/BudgetAssumption";
import MarketOverview from "./components/MarketOverview";
import PrivateTab from "../../PrivateTab";
import permissionEnum from "../../../enums/permission";
import PrivateComponent from "../../PrivateComponent";

function BusinessPlan(props) {
    const [tabIndex, setTabIndex] = useState(0);
    const [spinner, showSpinner, hideSpinner] = useSpinner();
    const [menu, setMenu] = useState([]);
    const [changeTab, setChangeTab] = useState(true);

    useEffect(() => {
        if (!props.forms || !props.formSelected) {
            return;
        }
        let isSubscribe = true;
        showSpinner();

        if (isSubscribe) {
            const businessPlanForms = props.forms.length
                ? props.forms.filter((hit) => hit.id === props.formSelected)[0]
                : [];
            let groupId = 0;
            if (businessPlanForms) {
                businessPlanForms.FormType?.MainGroups?.sort(
                    (a, b) => a.menuOrder - b.menuOrder
                );
                groupId = businessPlanForms?.FormType?.MainGroups[0].id; //Recuperando o primeiro formulário
                if (groupId) setMenu(businessPlanForms.FormType.MainGroups);
            }

            hideSpinner();
        } else {
            hideSpinner();
        }
        return () => {
            isSubscribe = false;
        };
    }, [props.forms, props.formSelected, hideSpinner, showSpinner, changeTab]);

    function handleSelectQuestion(group) {
        setChangeTab(!changeTab);
    }

    return (
        <Page>
            {spinner}
            <ToastContainer />

            <Container className="p-0" fluid>
                <Tabs
                    selectedIndex={tabIndex}
                    onSelect={(index) => setTabIndex(index)}
                >
                    <TabList>
                        <PrivateTab
                            permission={permissionEnum.MAIN_GROUP.BUDGET}
                        >
                            <Tab
                                onClick={() => handleSelectQuestion(menu[0].id)}
                            >
                                {menu.length > 0
                                    ? menu[0]?.title
                                    : "Loading..."}
                            </Tab>
                        </PrivateTab>
                        <PrivateTab
                            permission={
                                permissionEnum.MAIN_GROUP.BUDGET_ASSUMPTIONS
                            }
                        >
                            <Tab
                                onClick={() => handleSelectQuestion(menu[1].id)}
                            >
                                {menu.length > 0
                                    ? menu[1]?.title
                                    : "Loading..."}
                            </Tab>
                        </PrivateTab>
                        <PrivateTab
                            permission={
                                permissionEnum.MAIN_GROUP.MARKET_OVERVIEW
                            }
                        >
                            <Tab
                                onClick={() => handleSelectQuestion(menu[2].id)}
                            >
                                {menu.length > 0
                                    ? menu[2]?.title
                                    : "Loading..."}
                            </Tab>
                        </PrivateTab>
                        <PrivateTab permission={permissionEnum.MAIN_GROUP.SWOT}>
                            <Tab
                                onClick={() => handleSelectQuestion(menu[3].id)}
                            >
                                {menu.length > 0
                                    ? menu[3]?.title
                                    : "Loading..."}
                            </Tab>
                        </PrivateTab>
                    </TabList>

                    <TabPanel>
                        <PrivateComponent
                            permission={permissionEnum.MAIN_GROUP.BUDGET}
                        >
                            <Budget
                                formSelected={props.formSelected}
                                forms={props.forms}
                            />
                        </PrivateComponent>
                    </TabPanel>
                    <TabPanel>
                        <PrivateComponent
                            permission={
                                permissionEnum.MAIN_GROUP.BUDGET_ASSUMPTIONS
                            }
                        >
                            <BudgetAssumption forms={props.forms} />
                        </PrivateComponent>
                    </TabPanel>
                    <TabPanel>
                        <PrivateComponent
                            permission={
                                permissionEnum.MAIN_GROUP.MARKET_OVERVIEW
                            }
                        >
                            <MarketOverview forms={props.forms} />
                        </PrivateComponent>
                    </TabPanel>
                    <TabPanel>
                        <PrivateComponent
                            permission={permissionEnum.MAIN_GROUP.SWOT}
                        >
                            <Swot forms={props.forms} />
                        </PrivateComponent>
                    </TabPanel>
                </Tabs>
            </Container>
        </Page>
    );
}

export default withRouter(BusinessPlan);
