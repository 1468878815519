import * as Yup from "yup";

export const intialCompetitionDynamic = {
    marketDynamicDirectCompetitors: [
        {
            marketDynamicSegment: "",
            marketDynamicCompetitors: [
                {
                    marketDynamicCompetitors: "",
                    marketDynamicTotalMarket: 0,
                    marketDynamicShare: 0,
                },
            ],
            marketDynamicSegmentTotal: 0,
        },
    ],
    marketDynamicIndirectCompetitors: [
        {
            marketDynamicSegment: "",
            marketDynamicCompetitors: [
                {
                    marketDynamicCompetitors: "",
                    marketDynamicTotalMarket: 0,
                    marketDynamicShare: 0,
                },
            ],
            marketDynamicSegmentTotal: 0,
        },
    ],
    marketDynamicStrengths: [{ marketDynamicStrengths: "" }],
    marketDynamicOpportunities: [{ marketDynamicOpportunities: "" }],
    marketDynamicThreats: [{ marketDynamicThreats: "" }],
    marketDynamicWeaknesses: [{ marketDynamicWeaknesses: "" }],
    marketDynamicAttack: [{ marketDynamicAttack: "" }],
    marketDynamicGrowth: [{ marketDynamicGrowth: "" }],
    marketDynamicMaint: [{ marketDynamicMaint: "" }],
    marketDynamicSurvival: [{ marketDynamicSurvival: "" }],
    marketDynamicSalesForecast: [
        {
            marketDynamicSegment: "",
            marketDynamicYear1: 0,
            marketDynamicYear2: 0,
            marketDynamicYear3: 0,
        },
    ],
    marketDynamicGrowthForecast: [
        {
            marketDynamicSegment: "",
            marketDynamicYear1: 0,
            marketDynamicYear2: 0,
            marketDynamicYear3: 0,
        },
    ],
    marketDynamicRivalryLevel: [
        {
            marketDynamicSegment: "",
            marketDynamicLevel: "",
        },
    ],
    marketDynamicBenckmark: [
        {
            marketDynamicPlayer: "",
            marketDynamicBenckmark: [],
        },
    ],
};

export const CompetitionDynamicSchema = Yup.object().shape({
    marketDynamicDirectCompetitors: Yup.array().of(
        Yup.object().shape({
            marketDynamicSegment: Yup.string()
                .min(2, "Muito curto!")
                .max(40, "Muito Longo"),

            marketDynamicCompetitors: Yup.array().of(
                Yup.object().shape({
                    marketDynamicCompetitors: Yup.string()
                        .min(2, "Muito curto!")
                        .max(40, "Muito Longo"),

                    marketDynamicTotalMarket: Yup.number(),

                    marketDynamicShare: Yup.number(),
                })
            ),
            marketDynamicSegmentTotal: Yup.number(),
        })
    ),
    marketDynamicIndirectCompetitors: Yup.array().of(
        Yup.object().shape({
            marketDynamicSegment: Yup.string()
                .min(2, "Muito curto!")
                .max(40, "Muito Longo"),

            marketDynamicCompetitors: Yup.array().of(
                Yup.object().shape({
                    marketDynamicCompetitors: Yup.string()
                        .min(2, "Muito curto!")
                        .max(40, "Muito Longo"),

                    marketDynamicTotalMarket: Yup.number(),

                    marketDynamicShare: Yup.number(),
                })
            ),
            marketDynamicSegmentTotal: Yup.number(),
        })
    ),
    marketDynamicStrengths: Yup.array().of(
        Yup.object().shape({
            marketDynamicStrengths: Yup.string()
                .min(2, "Muito curto!")
                .max(100, "Muito Longo"),
        })
    ),
    marketDynamicOpportunities: Yup.array().of(
        Yup.object().shape({
            marketDynamicOpportunities: Yup.string()
                .min(2, "Muito curto!")
                .max(100, "Muito Longo"),
        })
    ),
    marketDynamicThreats: Yup.array().of(
        Yup.object().shape({
            marketDynamicThreats: Yup.string()
                .min(2, "Muito curto!")
                .max(100, "Muito Longo"),
        })
    ),
    marketDynamicWeaknesses: Yup.array().of(
        Yup.object().shape({
            marketDynamicWeaknesses: Yup.string()
                .min(2, "Muito curto!")
                .max(100, "Muito Longo"),
        })
    ),
    marketDynamicAttack: Yup.array().of(
        Yup.object().shape({
            marketDynamicAttack: Yup.string()
                .min(2, "Muito curto!")
                .max(100, "Muito Longo"),
        })
    ),
    marketDynamicGrowth: Yup.array().of(
        Yup.object().shape({
            marketDynamicGrowth: Yup.string()
                .min(2, "Muito curto!")
                .max(100, "Muito Longo"),
        })
    ),
    marketDynamicMaint: Yup.array().of(
        Yup.object().shape({
            marketDynamicMaint: Yup.string()
                .min(2, "Muito curto!")
                .max(100, "Muito Longo"),
        })
    ),
    marketDynamicSurvival: Yup.array().of(
        Yup.object().shape({
            marketDynamicSurvival: Yup.string()
                .min(2, "Muito curto!")
                .max(100, "Muito Longo"),
        })
    ),
    marketDynamicSalesForecast: Yup.array().of(
        Yup.object().shape({
            marketDynamicSegment: Yup.string()
                .min(2, "Muito curto!")
                .max(40, "Muito Longo"),
            marketDynamicYear1: Yup.number(),
            marketDynamicYear2: Yup.number(),
            marketDynamicYear3: Yup.number(),
        })
    ),
    marketDynamicGrowthForecast: Yup.array().of(
        Yup.object().shape({
            marketDynamicSegment: Yup.string()
                .min(2, "Muito curto!")
                .max(40, "Muito Longo"),
            marketDynamicYear1: Yup.number(),
            marketDynamicYear2: Yup.number(),
            marketDynamicYear3: Yup.number(),
        })
    ),
    marketDynamicRivalryLevel: Yup.array().of(
        Yup.object().shape({
            marketDynamicSegment: Yup.string()
                .min(2, "Muito curto!")
                .max(40, "Muito Longo"),
        })
    ),
    marketDynamicBenckmark: Yup.array().of(
        Yup.object().shape({
            marketDynamicPlayer: Yup.string()
                .min(3, "Muito curto!")
                .max(40, "Muito Longo!"),
            marketDynamicBenckmark: Yup.array(),
        })
    ),
});
