import React from 'react';
import FormInput from '../../../../components/FormInput';
import FormInputDebounce from "../../../../components/FormInputDebounce";
import css from '../../../companyoverview.module.css';
import Margin from './Margin';

function Month({month, values, name, index, subindex}) {
    return (
        <>
            <div className={`${css.input_label}`}><label htmlFor={name}>{month}</label></div>
            <FormInput
                name={`${name}[${month.toLowerCase()}].total`}
                type="currency"
                original="total"
                errors={false}
                touched={false}
                disabled={true}
                holder="R$" >
                Total
            </FormInput>
            <FormInputDebounce
                name={`${name}[${month.toLowerCase()}].varCost`}
                type="currency"
                values={values[month.toLowerCase()].varCost}
            >
                Custo Variável
            </FormInputDebounce>
            <FormInputDebounce
                name={`${name}[${month.toLowerCase()}].fixCost`}
                type="currency"
                values={values[month.toLowerCase()].fixCost}
            >
                Custo Fixo
            </FormInputDebounce>
            <label>Margin</label>
            <Margin
                name={`${name}[${month.toLowerCase()}].margin`}
                index={index}
                subindex={subindex}
                month={month.toLowerCase()}
            >
            </Margin>
        </>
    )
}

export default Month;
