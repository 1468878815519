import React from "react";
import {FieldArray, Field} from "formik";
import {Col, Row} from "react-bootstrap";
import Totalizers from "./Totalizers";
import FormInputDebounce from "../../../components/FormInputDebounce";
import MainTotalizers from "./MainTotalizers";
import Button from "../../../../Button";
import { MdAdd } from "react-icons/md";
const indexName = "companyValueChainCostExpenses";

function CostsExpensesProjection({values, item, index, name, label, months, title, button}){
    return (
        <>
            <label className="mt-3">{title}</label>
            <hr/>
            <FieldArray name={`[${indexName}][${index}][${name}][${name}]`}>
                {({remove, push})=>(
                    <div className="mt-4 mb-4">
                        {item[name]?.[name]?.length > 0 &&
                        item[name]?.[name]?.map((subItem, subIndex)=>(
                            <div key={subIndex}>
                                {
                                    `[${indexName}][${index}][${name}]` === `[companyValueChainCostExpenses][${index}][companyValueChainExpenses]` &&
                                        <Row className="p-0 m-0 justify-content-end">
                                            <label className="d-flex align-items-center">
                                                <Field className="mr-1" type="checkbox" name={`[${indexName}][${index}][${name}][${name}][${subIndex}].companyValueChainInvestiment`} />
                                                Investimento
                                            </label>
                                        </Row>
                                }                     
                                <Row className="p-0 m-0">
                                    <Col className="p-0 m-0" lg={1}>
                                        <FormInputDebounce
                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}].companyValueChainDescription`}
                                            values={values[indexName][index][name][name][subIndex]['companyValueChainDescription']}
                                            type="text"
                                        >
                                            {label}
                                        </FormInputDebounce>
                                    </Col>
                                    <Col className="p-0 m-0" lg={9}>
                                        <Row className="p-0 m-0">
                                            <Col className="p-0 m-0 pr-1" lg={12}>
                                                <Row className="p-0 m-0">
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].jan`}
                                                            values={values[indexName][index][name][name][subIndex][name]['jan']}
                                                            type="currency"
                                                        >
                                                            Jan
                                                        </FormInputDebounce>
                                                        
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].fev`}
                                                            values={values[indexName][index][name][name][subIndex][name]['fev']}
                                                            type="currency"
                                                        >
                                                            Fev
                                                        </FormInputDebounce>
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].mar`}
                                                            values={values[indexName][index][name][name][subIndex][name]['mar']}
                                                            type="currency"
                                                        >
                                                            Mar
                                                        </FormInputDebounce>
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].abr`}
                                                            values={values[indexName][index][name][name][subIndex][name]['abr']}
                                                            type="currency"
                                                        >
                                                            Abr
                                                        </FormInputDebounce>
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].mai`}
                                                            values={values[indexName][index][name][name][subIndex][name]['mai']}
                                                            type="currency"
                                                        >
                                                            Mai
                                                        </FormInputDebounce>
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].jun`}
                                                            values={values[indexName][index][name][name][subIndex][name]['jun']}
                                                            type="currency"
                                                        >
                                                            Jun
                                                        </FormInputDebounce>
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].jul`}
                                                            values={values[indexName][index][name][name][subIndex][name]['jul']}
                                                            type="currency"
                                                        >
                                                            Jul
                                                        </FormInputDebounce>
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].ago`}
                                                            values={values[indexName][index][name][name][subIndex][name]['ago']}
                                                            type="currency"
                                                        >
                                                            Ago
                                                        </FormInputDebounce>
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].set`}
                                                            values={values[indexName][index][name][name][subIndex][name]['set']}
                                                            type="currency"
                                                        >
                                                            Set
                                                        </FormInputDebounce>
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].out`}
                                                            values={values[indexName][index][name][name][subIndex][name]['out']}
                                                            type="currency"
                                                        >
                                                            Out
                                                        </FormInputDebounce>
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].nov`}
                                                            values={values[indexName][index][name][name][subIndex][name]['nov']}
                                                            type="currency"
                                                        >
                                                            Nov
                                                        </FormInputDebounce>
                                                    </Col>
                                                    <Col className="p-0 m-0 pr-1" lg={1}>
                                                        <FormInputDebounce
                                                            name={`[${indexName}][${index}][${name}][${name}][${subIndex}][${name}].dez`}
                                                            values={values[indexName][index][name][name][subIndex][name]['dez']}
                                                            type="currency"
                                                        >
                                                            Dez
                                                        </FormInputDebounce>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="p-0 m-0 h-100">
                                        <Row className="p-0 m-0 h-100 justify-content-center align-items-center">
                                            <Col className="p-0 m-0">
                                                <Totalizers
                                                    name={`[${indexName}][${index}][${name}][${name}][${subIndex}].companyValueChainTotal`}
                                                    original={name}
                                                    item={subItem}
                                                    index={index}
                                                    subIndex={subIndex}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="p-2 m-0" lg={1}>
                                        <Row className="p-0 m-0 h-100 justify-content-center align-items-center">
                                            <Button
                                                variant="outline-danger"
                                                type="button"
                                                onClick={()=>{
                                                    if(item[name][name].length > 1){
                                                        remove(subIndex);
                                                    }
                                                }}
                                                className="mt-2"
                                            ><i className="fas fa-trash-alt"/></Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        ))
                        }
                        <Button
                            type="button"
                            onClick={()=>{
                                let objectToPush = {companyValueChainDescription: "", companyValueChainTotal: 0};
                                objectToPush[name] = months;
                                push(objectToPush);
                            }}
                            variant="outline-success"
                            className={`mt-2 w-100`}
                        ><MdAdd size="20" className="mr-1" />{button}</Button>
                    </div>
                )}
            </FieldArray>
            <Col className="p-0 m-0">
                <Row className="p-0 m-0">
                    <Col className="p-0 m-0">
                        <MainTotalizers
                            index={index}
                            name={name}
                        />
                    </Col>
                </Row>
            </Col>
            
        </>
    )
}

export default CostsExpensesProjection;