import * as Yup from 'yup';

export const initialCostumerDynamicValues = {
    marketDynamicPowerNegociation:[
        {
            marketDynamicSegment: "",
            marketDynamicCustomer: [
                {
                    marketDynamicCustomer: "",
                    marketDynamicTotal: 0,
                    marketDynamicShare: 0
                }
            ],
            marketDynamicSegmentTotal: 0
        }
    ],
    marketDynamicSalesForecast:[
        {
            marketDynamicSegment: "",
            marketDynamicYear1: 0,
            marketDynamicYear2: 0,
            marketDynamicYear3: 0
        }
    ],
    marketDynamicElementsValue: "",
    marketDynamicRiskVerticalization: ""

};

export const CostumerDynamicSchema = Yup.object().shape({
    marketDynamicPowerNegociation: Yup.array()
    .of(
        Yup.object().shape({
            marketDynamicSegment: Yup.string()
            .min(3, 'Muito curto!')
            .max(40, 'Muito Longo!'),
            marketDynamicCustomer: Yup.array()
            .of(
                Yup.object().shape({
                    marketDynamicCustomer: Yup.string()
                    .min(2, 'Muito curto!')
                    .max(40, 'Muito Longo'),
                    marketDynamicShare: Yup.number(),                    
                    marketDynamicTotal: Yup.number()
                })
            ),
            marketDynamicSegmentTotal: Yup.number(),
        })
    ),
    marketDynamicSalesForecast: Yup.array()
    .of(
        Yup.object().shape({
            marketDynamicSegment: Yup.string()
            .min(3, 'Muito curto!')
            .max(40, 'Muito Longo!'),            
            marketDynamicYear1: Yup.number(),
            marketDynamicYear2: Yup.number(),            
            marketDynamicYear3: Yup.number()
        })
    ),
    marketDynamicElementsValue: Yup.string(),    
    marketDynamicRiskVerticalization: Yup.string()
});