import React from 'react';
import { useUserContext } from '../../providers/UserProvider';

// import { Container } from './styles';


function PrivateTab({ children, permission }) {

  const { data } = useUserContext();

  if (permission && data?.permissions) {
    if (data.permissions.includes(permission)) {
      return children
    }
  }

  return React.Children.map(children, (child) => React.cloneElement(child, {
    style: { opacity: 0.5, cursor: 'not-allowed' }
  }))
}

export default PrivateTab;