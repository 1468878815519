import React, {useState} from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {Row} from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom';
import FormInput from '../components/FormInput';
import FormButton from '../components/FormButton';
import { useAuth } from '../../../providers/AuthProvider';
import authimg from './imgs/authimg.png'
import logo from './imgs/id_login.png'
import css from './auth.module.css'

const intialSignupValues ={
    username: "",
    email: "",
    password: ""
}

const SignupSchema = Yup.object().shape({
    username: Yup.string().required(),
    email: Yup.string()
    .required()
    .email(),
    password: Yup.string().required()
});

function Signup(props) {
    const [errorForm, setErrorForm] = useState({error: false, message: ""});

    const { signUp } = useAuth();

    function handleSubmit(values, formikBag) {
        setErrorForm({error: false, message: ""});
        signUp(values)
            .then(() => props.history.push("/confirmation"))
            .catch((err) => {
                console.log(err);
                setErrorForm({error: true, message: err.message});
                formikBag.setSubmitting(false);
            })
    }

    return (
            <div className={css.authContainer}>
                <section className={css.form}>
                    <Link to="/"><img src={logo} alt="CEO360" /></Link>
                    <Formik
                        initialValues={intialSignupValues}
                        validationSchema={SignupSchema}
                        onSubmit={handleSubmit}>
                        {({errors, touched, isSubmitting})=>(
                            <Form>
                                <h4>Cadastre-se</h4>
                                <FormInput name="username"  type="text" errors={errors} touched={touched} holder="Usuário"/>
                                <FormInput name="email"  type="text" errors={errors} touched={touched} holder="E-mail"/>
                                <FormInput name="password"  type="password" errors={errors} touched={touched} holder="Senha"/>
                                <Row className="p-0 m-0">
                                    {errorForm.error && 
                                        <span style={{color:'red'}}>{errorForm.message}</span>
                                    }
                                </Row>
                                <Row className="p-0 m-0 justify-content-end">
                                    <FormButton disabled={isSubmitting} type="submit" text="Login" />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                    <Link to="/confirmation" className={css.authLink}>
                        Confirmação de usuário
                    </Link>
                    <Link className={css.authLink} to="/signin">
                        Já tenho usuário
                    </Link>
                </section>
                <img className={css.authImg} src={authimg} alt="Business"/>
            </div>
    )
}

export default withRouter(Signup);