import React from "react";
import { Switch, useRouteMatch, Route } from "react-router-dom";

import MarketDynamicOverview from "../../../components/forms/market-dynamic/MarketDynamicOverview";
import MacroEnv from "../../../components/forms/macro-env/MacroEnv";
import CompanyOverview from "../../../components/forms/strategic-position/CompanyOverview";
import BusinessPlan from "../../../components/forms/business-plan/BusinessPlan";

import { useQuestionForms } from "../../../providers/QuestionFormsProvider";
import { useEffect } from "react";

function BusinessPlanRouter(props) {
    const { path } = useRouteMatch();
    const { types, forms, formSelected, setFormSelected } = useQuestionForms();

    useEffect(() => {
        if (!forms || !types) {
            return;
        }

        const sortedTypes = types?.sort((a, b) => a.id - b.id);

        const temp = props.location.pathname.split("/");
        const resource = temp[temp.length - 1];

        switch (resource) {
            case "strategic":
                setFormSelected(
                    forms.find((form) => form.formTypeId === sortedTypes[0].id)
                        .id
                );
                break;

            case "dynamic":
                setFormSelected(
                    forms.find((form) => form.formTypeId === sortedTypes[1].id)
                        .id
                );
                break;

            case "macro":
                setFormSelected(
                    forms.find((form) => form.formTypeId === sortedTypes[2].id)
                        .id
                );
                break;

            case "businessplan":
                setFormSelected(
                    forms.find((form) => form.formTypeId === sortedTypes[3].id)
                        .id
                );
                break;

            default:
                props.history.replace("/");
                break;
        }
    }, [props.location.pathname, props.history, setFormSelected, forms, types]);

    return (
        <Switch>
            <Route
                exact
                path={`${path}/strategic`}
                render={(props) => (
                    <CompanyOverview
                        forms={forms}
                        formSelected={formSelected}
                        {...props}
                    />
                )}
            />
            <Route
                exact
                path={`${path}/dynamic`}
                render={(props) => (
                    <MarketDynamicOverview
                        forms={forms}
                        formSelected={formSelected}
                        {...props}
                    />
                )}
            />
            <Route
                exact
                path={`${path}/macro`}
                render={(props) => (
                    <MacroEnv
                        forms={forms}
                        formSelected={formSelected}
                        {...props}
                    />
                )}
            />
            <Route
                exact
                path={`${path}/businessplan`}
                render={(props) => (
                    <BusinessPlan
                        forms={forms}
                        formSelected={formSelected}
                        {...props}
                    />
                )}
            />
        </Switch>
    );
}

export default BusinessPlanRouter;
