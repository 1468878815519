import React, {useEffect, useState} from 'react';
import useSpinner from "../../../../spinner/useSpinner";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {getQuestions} from "../../../../../services/formData";
import SupplierSharedGraphic from "./SupplierSharedGraphic";
import SupplierPowerNegotiationGraphic from "./SupplierPowerNegotiationGraphic";
import TotalSupplierShared from "./TotalSupplierShared";

function SupplierDynamic(props) {
    const [spinner, showSpinner, hideSpinner] = useSpinner();
    const [tabIndex, setTabIndex] = useState(0);
    const [costs, setCosts] = useState([]);
    const [power, setPower] = useState([]);

    useEffect(()=>{
        let isSubscribe = true;
        showSpinner();
        const formularios = props.forms;
        const dynamicMenu = formularios?.find(hit=>hit.FormType.menuOrder === 2); //find do menu de dinâmica de mercado
        if(dynamicMenu && Object.keys(dynamicMenu).length) {
            const dynamicSecMenu = dynamicMenu.FormType.MainGroups.find(hit=>hit.menuOrder === 3);
            if(dynamicSecMenu && Object.keys(dynamicSecMenu).length && isSubscribe){
                getQuestions(dynamicMenu.id, dynamicSecMenu.id, 0, (err, data)=>{
                    if(err){
                        props.history.push("/");
                        hideSpinner();
                    }else{
                        if(isSubscribe){
                            const costsSupplier = data.marketDynamicSupplierSharedYourCost;
                            const powerNegotiation = data.marketDynamicSupplierNegotiationPower;
                            setCosts(costsSupplier);
                            setPower(powerNegotiation);
                            hideSpinner();
                            console.log(costsSupplier)
                        }
                    }
                });
            }
        }
    }, [props.forms])

    return (
        <>
            {spinner}
            <Container fluid>
                <Row className="p-2">
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Card className="p-4 h-100">
                            <TotalSupplierShared data={costs} />
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Card>
                            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                                <TabList>
                                    <Tab>Comparativo de mercado</Tab>
                                    <Tab>Poder de negociação</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="p-4">
                                        <SupplierSharedGraphic
                                            title="Fornecedores x Produtos"
                                            data={costs}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="p-4">
                                        <SupplierPowerNegotiationGraphic
                                            title="Poder de negociação"
                                            data={power}
                                        />
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SupplierDynamic;