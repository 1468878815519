import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../../companyoverview.module.css';
import labelcss from '../../../companyoverview.module.css';
import CurrencyInput from '../../../../../CurrencyInput';

function Totalizers({subIndex, values, original, label, index, ...props}){
    const {
        values: { companyValueChainBudget },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);
    React.useEffect(() => {
        let totals = Object.values(values);
        totals = totals.map(el=>(el[original]))
        totals = totals.reduce((acc, el)=>acc + parseInt(el), 0);
        setFieldValue(props.name, totals);
    }, [companyValueChainBudget[index].companyValueChainBudget[subIndex]]);
    return (
        <>
            <div className={`${labelcss.input_label}`}>
                <label htmlFor="">{label}</label>
            </div>
            <CurrencyInput disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default Totalizers