import React from 'react';
import {Col, Row} from "react-bootstrap";

function NoDataPresent(){
    return (
        <Row className="p-0 m-0">
            <Col className="text-center">
                <span>NO DATA TO SHOW</span>
            </Col>
        </Row>
    );
};

export default NoDataPresent;
