import axios from 'axios';
import { getAuthUser } from '../auth/auth';

let url="http://localhost:3000";
if(process.env.NODE_ENV === 'development'){
    url="http://localhost:3000";
}else{
    url="https://ae360.herokuapp.com";
}

const server = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT || "http://localhost:3000",
    headers: {
        "Content-type": "application/json",
    }
})

export function getUserData(accessToken) {
    return server.get('/users/data', {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
}

export const getUser = (username, callback)=>{
    getAuthUser()?.getSession((err, session)=>{
        if(err){
            callback(err);
        }else{
            const headers = {
                "Content-type": "application/json",
                "Authorization": `Bearer ${session.getIdToken().getJwtToken()}`
            }

            fetch(`${url}/users/${username}`, {method: 'GET', headers})
            .then(res=>res.json())
            .then(json=>callback(null,json))
            .catch(error=>callback(error))
        }
    });
}

//Post user can not have validation
export const postUser = (data, callback)=>{
    const headers = {
        "Content-type": "application/json",
    }

    fetch(`${url}/users`, {method: 'POST', headers, body: JSON.stringify(data)})
        .then(res=>res.json())
        .then(json=>callback(null,json))
        .catch(error=>callback(error))
}

export const postCompany = (data, callback)=>{
    getAuthUser()?.getSession((err, session)=>{
        if(err){
            callback(err);
        }else{
            const headers = {
                "Content-type": "application/json",
                "Authorization": `Bearer ${session.getIdToken().getJwtToken()}`
            }

            fetch(`${url}/users/company/`, {method: 'POST', headers, body: JSON.stringify(data)})
                .then(res=>res.json())
                .then(json=>callback(null,json))
                .catch(error=>callback(error))
        }
    });
}