import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../../companyoverview.module.css';
import CurrencyInput from '../../../../../CurrencyInput';

function Margin({month, index, subindex, ...props}) {
    const {
        values: { companyValueChainBudget },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);
    React.useEffect(() => {
        //Calculo de margem para cada produto
        //preço de venda - custos
        if(companyValueChainBudget[index]["companyValueChainBudget"]?.length){
            let price = parseInt(companyValueChainBudget[index]["companyValueChainBudget"][subindex]["companyValueChainBudget"][month]?.price);
            let fixCost = parseInt(companyValueChainBudget[index]["companyValueChainBudget"][subindex]["companyValueChainBudget"][month]?.fixCost);
            let varCost = parseInt(companyValueChainBudget[index]["companyValueChainBudget"][subindex]["companyValueChainBudget"][month]?.varCost);
            let margin = price >= 0 && fixCost >= 0 && varCost >= 0 ? price - (fixCost + varCost) : 0;
            setFieldValue(props.name, margin ? margin : 0);
        }
    }, [companyValueChainBudget[index]["companyValueChainBudget"][subindex]["companyValueChainBudget"][month]?.fixCost, companyValueChainBudget[index]["companyValueChainBudget"][subindex]["companyValueChainBudget"][month]?.varCost]);

    return (
        <>
            <CurrencyInput disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default Margin