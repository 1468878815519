import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../marketdynamicoverview.module.css';

function MarketDynamicTotal(props) {
    const {values, setFieldValue} = useFormikContext();
      const [field, meta] = useField(props);
    
      React.useEffect(() => {
        if (values[props.values][props.index].marketDynamicProduct.length) {
            let total = values[props.values][props.index].marketDynamicProduct.reduce((acc, current)=>acc + current.marketDynamicTotal, 0);
            setFieldValue(props.name, total);
        }
  
      }, [props.index, values, props.values, setFieldValue, props.name]);
    
      return (
        <>
          <input className={`p-1 rounded border`} {...props} {...field} />
          {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
      );
}

export default MarketDynamicTotal;