import axios from 'axios';

const server = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || "http://localhost:3000",
  headers: {
    "Content-type": "application/json",
  }
})

export function getPermissionList(accessToken) {
  return server.get('/permissions', {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
}