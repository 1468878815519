import React from 'react';
import { Button, Form } from 'react-bootstrap';

import { Formik, Field, ErrorMessage } from 'formik';

import UserGroupSchema from '../../validation/UserGroupForm';

function GroupForm({ onSubmit, values, edit }) {

  const initialValues = {
    name: values?.name || '',
    description: values?.description || '',
  }
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserGroupSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Nome</Form.Label>
            <Field name="name">
              {({ field }) => (
                <Form.Control {...field} type="text" placeholder="Ex: RH" />
              )}
            </Field>
            <ErrorMessage name="name" component="div" className="error-message"/>
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>Descrição</Form.Label>
            <Field name="description">
              {({ field }) => (
                <Form.Control {...field} as="textarea" rows={3} />
              )}
            </Field>
            <ErrorMessage name="description" component="div" className="error-message"/>
          </Form.Group>

          { edit ? (
            <Button variant="warning" type="submit" disabled={isSubmitting}>
              Salvar alterações
            </Button>
          ) : (
            <Button variant="success" type="submit" disabled={isSubmitting}>
              Cadastrar
            </Button>
          ) }

          

        </Form>
      )}
    </Formik>
  );
}

export default GroupForm;