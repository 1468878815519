import React from 'react';
import {Table} from "react-bootstrap";
import { formatCurrency } from '../../../../utils/formatCurrency';
import HeaderTable from "./HeadTable";

function BudgetTable({costs, margin, title}){
    return (
        <>
            {costs?.length > 0 &&
                costs.map((prod, index)=>{
                return (
                    <tr key={index}>
                        {index === 0 && <td valign="middle" rowSpan={costs.length + 1}>{title}</td>}
                        <td>{prod.companyValueChainProduct}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.jan.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.fev.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.mar.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.abr.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.mai.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.jun.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.jul.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.ago.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.set.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.out.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.nov.margin)}</td>
                        <td align="right">{formatCurrency(prod.companyValueChainBudget.dez.margin)}</td>
                        <td align="right">{formatCurrency(prod.marginTotal)}</td>
                    </tr>
                )
            })}
                <tr>
                    {costs?.length <= 0 && <td valign="middle">{title}</td>}
                    <td />
                    <td align="right">{formatCurrency(margin?.jan?.totalMargin ? margin.jan.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.fev?.totalMargin ? margin.fev.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.mar?.totalMargin ? margin.mar.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.abr?.totalMargin ? margin.abr.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.mai?.totalMargin ? margin.mai.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.jun?.totalMargin ? margin.jun.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.jul?.totalMargin ? margin.jul.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.ago?.totalMargin ? margin.ago.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.set?.totalMargin ? margin.set.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.out?.totalMargin ? margin.out.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.nov?.totalMargin ? margin.nov.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.dez?.totalMargin ? margin.dez.totalMargin : 0)}</td>
                    <td align="right">{formatCurrency(margin?.total ? margin.total : 0)}</td>
                </tr>
        </>
    )
}

export default BudgetTable;