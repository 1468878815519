import React from "react";
import { BrowserRouter } from "react-router-dom";
// Providers
import AuthProvider from "./providers/AuthProvider";

import Routes from "./routes";

function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
