import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    mainView: {
        marginLeft: 28,
    },
    titleView: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 8,
        borderRadius: 8,
        maxWidth: "84%",
    },
    titleText: {
        backgroundColor: "#6C757D",
        padding: 8,
        color: "#FFF",
        fontSize: 8,
        minWidth: 88,
        borderBottomLeftRadius: 8,
        borderTopLeftRadius: 8,
    },
    answerText: {
        fontSize: 10,
        paddingLeft: 3,
        backgroundColor: "#FFF",
    },
});

const StrategicPositioning = ({ strategicPosition }) => (
    <View style={styles.mainView}>
        <View style={styles.titleView}>
            <Text style={styles.titleText}> PARA </Text>
            <Text style={styles.answerText}>
                {strategicPosition.companyLiders}
            </Text>
        </View>
        <View style={styles.titleView}>
            <Text style={styles.titleText}> QUE PRECISA </Text>
            <Text style={styles.answerText}>
                {strategicPosition.companyProfileTeam}
            </Text>
        </View>
        <View style={styles.titleView}>
            <Text style={styles.titleText}> E QUEREM </Text>
            <Text style={styles.answerText}>
                {strategicPosition.companyKnowledgeRequired}
            </Text>
        </View>
        <View style={styles.titleView}>
            <Text style={styles.titleText}> A </Text>
            <Text style={styles.answerText}>
                {strategicPosition.companyMethod}
            </Text>
        </View>
        <View style={styles.titleView}>
            <Text style={styles.titleText}> É </Text>
            <Text style={styles.answerText}>
                {strategicPosition.companyStructure}
            </Text>
        </View>
        <View style={styles.titleView}>
            <Text style={styles.titleText}> QUE </Text>
            <Text style={styles.answerText}>{strategicPosition.what}</Text>
        </View>
        <View style={styles.titleView}>
            <Text style={styles.titleText}> AO INVÉS </Text>
            <Text style={styles.answerText}>{strategicPosition.instead}</Text>
        </View>
        <View style={styles.titleView}>
            <Text style={styles.titleText}> NOSSO SERVIÇO </Text>
            <Text style={styles.answerText}>
                {strategicPosition.ourService}
            </Text>
        </View>
    </View>
);

export default StrategicPositioning;
