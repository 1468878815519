import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Departments from "./graphics/Departments";
import SalesAnlytics from "./graphics/SalesAnlytics";
import PersonalData from "./graphics/PersonalData";
import { useQuestionForms } from "../providers/QuestionFormsProvider";
import Page from "./Page";
import {
    getBudgetData,
    getDepartmentPercent,
    getQuestionsForm,
} from "../services/formData";
import { getFormatData } from "helpermejs";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../providers/AuthProvider";

function Portal() {
    const [serviceData, setServiceData] = useState([]);
    const [operationsData, setOperationsData] = useState([]);
    const { forms, showLoader, hideLoader } = useQuestionForms();
    const [departments, setDeparments] = useState([]);
    const [lastUpdate, setLastUpdate] = useState("00/00/00 00:00");
    const [generalData, setGeneralData] = useState({});
    const { getAccessToken } = useAuth();

    useEffect(() => {
        let isSubscribe = true;
        if (isSubscribe) {
            const loadData = async () => {
                showLoader();
                const mainMenu = forms?.find(
                    (hit) => hit.FormType.menuOrder === 1
                ); //find do menu principal - strategic position

                if (mainMenu && Object.keys(mainMenu).length) {
                    //Verificando a existência de chaves
                    const valueChainMenu = mainMenu.FormType.MainGroups.find(
                        (hit) => hit.menuOrder === 4
                    ); //find do menu secundário - value chain
                    const generalMenu = mainMenu.FormType.MainGroups.find(
                        (hit) => hit.menuOrder === 1
                    );
                    if (
                        valueChainMenu &&
                        Object.keys(valueChainMenu).length &&
                        generalMenu &&
                        Object.keys(generalMenu).length
                    ) {
                        //Verificando a existência de chaves
                        let serviceSales = valueChainMenu.SubGroups.find(
                            (hit) => hit.menuOrder === 3
                        ); // Service and sales
                        let operations = valueChainMenu.SubGroups.find(
                            (hit) => hit.menuOrder === 4
                        ); // Operations

                        const accessToken = await getAccessToken();
                        const [budgetData, departData, genData] =
                            await Promise.all([
                                getBudgetData(
                                    mainMenu.id,
                                    valueChainMenu.id,
                                    accessToken
                                ),
                                getDepartmentPercent(accessToken),
                                getQuestionsForm(
                                    mainMenu.id,
                                    generalMenu.id,
                                    0,
                                    accessToken
                                ),
                            ]);

                        if (isSubscribe) {
                            console.log(genData);
                            if (
                                !genData ||
                                Object.keys(genData).length === 0 ||
                                genData.error
                            ) {
                                console.log("Não há dados a serem mostrados");
                            } else {
                                setGeneralData(genData);
                            }

                            if (
                                !Array.isArray(budgetData) ||
                                budgetData.error
                            ) {
                                console.log("Não há dados a serem mostrados");
                            } else {
                                //Services and sales
                                let budgetServiceData = budgetData?.filter(
                                    (hit) => hit.sGroupId === serviceSales.id
                                )[0];
                                if (budgetServiceData) {
                                    //Separando o ano de 2021
                                    budgetServiceData =
                                        budgetServiceData.questions?.companyValueChainBudget?.filter(
                                            (item) =>
                                                parseInt(item.year) ===
                                                new Date().getFullYear()
                                        );
                                    setServiceData(budgetServiceData);
                                }

                                let budgetOperationsData = budgetData?.filter(
                                    (hit) => hit.sGroupId === operations.id
                                )[0];
                                if (budgetOperationsData) {
                                    //Separando o ano de 2021
                                    budgetOperationsData =
                                        budgetOperationsData.questions?.companyValueChainBudget?.filter(
                                            (item) =>
                                                parseInt(item.year) ===
                                                new Date().getFullYear()
                                        );
                                    setOperationsData(budgetOperationsData);
                                }
                            }

                            if (
                                !Array.isArray(departData?.departments) ||
                                departData.error
                            ) {
                                console.log("Não há dados a serem mostrados");
                            } else {
                                //Departaments
                                const dateTemp = getFormatData();
                                setLastUpdate(
                                    `${dateTemp.date} ${dateTemp.time}`
                                );
                                departData.departments.sort(
                                    (a, b) => a.menuOrder - b.menuOrder
                                );
                                setDeparments(departData.departments);
                            }
                        }
                        hideLoader();
                    }
                }
            };
            loadData();
        }
        return () => {
            isSubscribe = false;
        };
    }, [forms]);

    const handleLoadData = async () => {
        const accessToken = await getAccessToken();
        const departData = await getDepartmentPercent(accessToken);
        if (!Array.isArray(departData?.departments) || departData.error) {
            console.log("Não há dados a serem mostrados no momento");
        } else {
            const dateTemp = getFormatData();
            setLastUpdate(`${dateTemp.date} ${dateTemp.time}`);
            departData.departments.sort((a, b) => a.menuOrder - b.menuOrder);
            setDeparments(departData.departments);
        }
    };

    return (
        <Page>
            <ToastContainer />
            <Row className="p-4">
                <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                    <PersonalData data={generalData} />
                    <Departments
                        data={departments}
                        handleLoadData={handleLoadData}
                        lastUpdate={lastUpdate}
                    />
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <SalesAnlytics
                        title="Análise de previsão de vendas por produto"
                        data={serviceData}
                        arrayKey="total"
                    />
                    <SalesAnlytics
                        title="Análise de margin por produto"
                        data={operationsData}
                        arrayKey="margin"
                    />
                </Col>
            </Row>
        </Page>
    );
}

export default Portal;
