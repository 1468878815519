import axios from 'axios';

import { trimKeys } from '../utils/formatObject';

const server = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || "http://localhost:3000",
  headers: {
    "Content-type": "application/json",
  }
})

export function getUserList(accessToken) {
  return server.get('/users', {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
}

export function getGroups(accessToken) {
  return server.get('/users-groups', {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
}

export function createGroup(data, accessToken) {
  return server.post('/users-groups', trimKeys(data), {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
}

export function editGroup(data, id, accessToken) {
  return server.put(`/users-groups/${id}`, trimKeys(data), {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  }); 
}

export function deleteGroup(id, accessToken) {
  return server.delete(`/users-groups/${id}`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
}

export function addUsersToGroup(data, id, accessToken) {
  return server.post(`/users-groups/${id}/users`, data, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
}

export function editGroupPermissions(data, id, accessToken) {
  return server.put(`/users-groups/${id}/permissions`, data, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
}

export function deleteUsersInGroup(data, id, accessToken) {
  return server.delete(`/users-groups/${id}/users`, {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    },
    data: data
  });
}

export function inviteUser(data, accessToken) {
  return server.post('/companies/invite', trimKeys(data), {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
}