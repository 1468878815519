import React, {useState, useEffect} from 'react';
import {Bar} from "react-chartjs-2";
import {allColors} from '../css/colors';

const months = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];

const datasetItem = {
    label: 'Total of 0 ',
    data: [0,0,0,0,0,0,0,0,0,0,0,0],
    backgroundColor: [
        'black',
        'white',
    ],
    borderColor: "white",
    borderWidth: 1,
}

const body = {
    labels: months,
    datasets: [
        datasetItem
    ],
}

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
            align: "start"
        }
    },
    scales: {
        yAxes: [
            {
                stacked: true,
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
        xAxes: [
            {
                stacked: true,
            }
        ]
    },
}

function ProductPredictionGraphic(props) {
    const [dataBody, setDataBody] = useState(body);

    useEffect(()=>{
        if(props.serviceSales.length > 0){
            const produtos = props.serviceSales[0].companyValueChainBudget.map(hit=>{
                return {
                    prod: hit.companyValueChainProduct,
                    values: hit.companyValueChainBudget
                }
            });

            //Grafico por produto
            let dataAssembly = []

            produtos.forEach((item, index)=>{
                let data = {
                    label: item.prod,
                    data: [
                        item.values.jan.total,
                        item.values.fev.total,
                        item.values.mar.total,
                        item.values.abr.total,
                        item.values.mai.total,
                        item.values.jun.total,
                        item.values.jul.total,
                        item.values.ago.total,
                        item.values.set.total,
                        item.values.out.total,
                        item.values.nov.total,
                        item.values.dez.total
                    ],
                    backgroundColor: [
                        allColors[index],
                        allColors[index]
                    ],
                    borderColor: "white",
                    borderWidth: 1,
                    stack: "stack"
                }
                dataAssembly.push(data);
            });


            let dataGraph = {
                datasets: dataAssembly
            }

            setDataBody({...dataBody, ...dataGraph});
        }
    }, [props.serviceSales])

    return (
        <div>
            <Bar
                data={dataBody}
                options={options}
                height={300}
            />
        </div>
    );
}

export default ProductPredictionGraphic;