import React, {useEffect, useState} from 'react';
import useSpinner from "../../../../spinner/useSpinner";
import {getQuestions} from "../../../../../services/formData";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import PowerNegotiationGraphic from "./PowerNegotiationGraphic";
import SalesForecastGraphic from "./SalesForecastGraphic";
import TotalPowerSegmentGraphic from "./TotalPowerSegmentGraphic";

function CustomerDynamic(props) {
    const [spinner, showSpinner, hideSpinner] = useSpinner();
    const [tabIndex, setTabIndex] = useState(0);
    const [power, setPower] = useState([]);
    const [forecast, setForecast] = useState([]);

    useEffect(()=>{
        let isSubscribe = true;
        showSpinner();
        const formularios = props.forms;
        const dynamicMenu = formularios?.find(hit=>hit.FormType.menuOrder === 2); //find do menu de dinâmica de mercado
        if(dynamicMenu && Object.keys(dynamicMenu).length) {
            const dynamicSecMenu = dynamicMenu.FormType.MainGroups.find(hit=>hit.menuOrder === 2);
            if(dynamicSecMenu && Object.keys(dynamicSecMenu).length && isSubscribe){
                getQuestions(dynamicMenu.id, dynamicSecMenu.id, 0, (err, data)=>{
                    if(err){
                        props.history.push("/");
                        hideSpinner();
                    }else{
                        if(isSubscribe){
                            console.log(data.marketDynamicPowerNegociation);
                            const powerNegotiation = data.marketDynamicPowerNegociation;
                            const salesForecast = data.marketDynamicSalesForecast;
                            if(powerNegotiation)setPower(powerNegotiation);
                            if(salesForecast?.length)setForecast(salesForecast);
                            hideSpinner();
                        }
                    }
                });
            }
        }

        return ()=>{
            isSubscribe = false;
        }
    }, [props.forms])

    return (
        <>
            {spinner}
            <Container fluid>
                <Row className="p-2">
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Card className="p-4 h-100">
                            <TotalPowerSegmentGraphic data={power} />
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Card>
                            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                                <TabList>
                                    <Tab>Poder de negociação</Tab>
                                    <Tab>Previsão de crescimento</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="p-4">
                                        <PowerNegotiationGraphic
                                            title="Poder de negociação"
                                            data={power}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="p-4">
                                        <SalesForecastGraphic
                                            title="Previsão de crescimento por segmento"
                                            data={forecast}
                                        />
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default CustomerDynamic;