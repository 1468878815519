import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserAttribute,
    CognitoUserPool,
} from "amazon-cognito-identity-js";
import { useEffect } from "react";
import { useContext, createContext, useState, useCallback } from "react";

import POOL_DATA from "../configs/userPool";

const userPool = new CognitoUserPool(POOL_DATA);

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

function AuthProvider({ children }) {
    const [session, setSession] = useState(null);
    const [updatePasswordState, setUpdatePasswordState] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const signIn = useCallback((data) => {
        return new Promise((resolve, reject) => {
            const { username, password } = data;

            const authDetails = new AuthenticationDetails({
                Username: username,
                Password: password,
            });
            const cognitoUser = new CognitoUser({
                Username: username,
                Pool: userPool,
            });
            cognitoUser.authenticateUser(authDetails, {
                onSuccess: (result) => {
                    //Devolve os tokens refresh, access e id
                    const accessToken = result.getAccessToken().getJwtToken();
                    const idToken = result.getIdToken().getJwtToken();
                    const refreshToken = result.getRefreshToken().getToken();
                    const signInSession = {
                        access: accessToken,
                        id: idToken,
                        refresh: refreshToken,
                    };
                    setSession(signInSession);
                    setIsLoading(false);
                    resolve({
                        success: true,
                        updatePassword: false,
                        session: signInSession,
                    });
                },
                onFailure: (err) => {
                    reject(err);
                },
                newPasswordRequired: function () {
                    setUpdatePasswordState({ username, password });
                    resolve({ success: false, updatePassword: true });
                },
            });
        });
    }, []);

    const signUp = useCallback((data) => {
        return new Promise((resolve, reject) => {
            const { username, email, password } = data;

            const emailAttribute = {
                Name: "email",
                Value: email,
            };

            const attributeEmail = new CognitoUserAttribute(emailAttribute);
            const attributeList = [];

            attributeList.push(attributeEmail);

            userPool.signUp(
                username,
                password,
                attributeList,
                null,
                (err, result) => {
                    if (err) {
                        reject(err);
                    } else {
                        let user = result.user;
                        resolve(user);
                    }
                }
            );
        });
    }, []);

    const signOut = useCallback(() => {
        return new Promise((resolve, reject) => {
            localStorage.removeItem("user");
            let user = userPool.getCurrentUser();
            if (user) {
                user.signOut();
            }
            setSession(null);
            resolve();
        });
    }, []);

    const resendCode = useCallback((data) => {
        return new Promise((resolve, reject) => {
            const { username } = data;

            const cognitoUser = new CognitoUser({
                Username: username,
                Pool: userPool,
            });
            cognitoUser.resendConfirmationCode((err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            });
        });
    }, []);

    const changePassword = useCallback((data) => {
        return new Promise((resolve, reject) => {
            const { user, oldPassword, newPassword } = data;
            //O metodo changePassword é interno do aws sdk
            user.changePassword(
                oldPassword,
                newPassword,
                function (err, result) {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }, []);

    const updatePassword = useCallback(
        ({ newPassword }) => {
            if (!updatePasswordState) {
                return;
            }

            return new Promise((resolve, reject) => {
                const { username, password } = updatePasswordState;
                const authDetails = new AuthenticationDetails({
                    Username: username,
                    Password: password,
                });
                const cognitoUser = new CognitoUser({
                    Username: username,
                    Pool: userPool,
                });

                cognitoUser.authenticateUser(authDetails, {
                    onSuccess: (result) => {
                        const accessToken = result
                            .getAccessToken()
                            .getJwtToken();
                        const idToken = result.getIdToken().getJwtToken();
                        const refreshToken = result
                            .getRefreshToken()
                            .getToken();
                        const signInSession = {
                            access: accessToken,
                            id: idToken,
                            refresh: refreshToken,
                        };
                        setSession(signInSession);
                        setIsLoading(false);
                        resolve({
                            success: true,
                            updatePassword: false,
                            session: signInSession,
                        });
                    },
                    onFailure: (err) => {
                        reject(err);
                    },
                    newPasswordRequired: function (
                        userAttributes,
                        requiredAttributes
                    ) {
                        delete userAttributes.email_verified;
                        cognitoUser.completeNewPasswordChallenge(
                            newPassword,
                            userAttributes,
                            {
                                onSuccess: (result) => {
                                    const accessToken = result
                                        .getAccessToken()
                                        .getJwtToken();
                                    const idToken = result
                                        .getIdToken()
                                        .getJwtToken();
                                    const refreshToken = result
                                        .getRefreshToken()
                                        .getToken();
                                    const signInSession = {
                                        access: accessToken,
                                        id: idToken,
                                        refresh: refreshToken,
                                    };
                                    setSession(signInSession);
                                    setIsLoading(false);
                                    resolve({
                                        success: true,
                                        updatePassword: false,
                                        session: signInSession,
                                    });
                                },
                                onFailure: (err) => {
                                    reject(err);
                                },
                            }
                        );
                    },
                });
            });
        },
        [updatePasswordState]
    );

    const confirmUser = useCallback((data) => {
        return new Promise((resolve, reject) => {
            const { username, code } = data;

            const cognitoUser = new CognitoUser({
                Username: username,
                Pool: userPool,
            });
            cognitoUser.confirmRegistration(code, true, (err, result) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(result);
                }
            });
        });
    }, []);

    const sendEmailForgot = useCallback((data) => {
        return new Promise((resolve, reject) => {
            let { email } = data;

            const cognitoUser = new CognitoUser({
                Username: email,
                Pool: userPool,
            });
            cognitoUser.forgotPassword({
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    reject(err);
                },
                inputVerificationCode: (data) => {
                    resolve(data);
                },
            });
        });
    }, []);

    const setPasswordForgot = useCallback((data) => {
        return new Promise((resolve, reject) => {
            let { email, code, password } = data;
            const cognitoUser = new CognitoUser({
                Username: email,
                Pool: userPool,
            });
            cognitoUser.confirmPassword(code, password, {
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    }, []);

    const updateSession = useCallback(() => {
        return new Promise((resolve, reject) => {
            const user = userPool.getCurrentUser();

            if (user) {
                user.getSession((err, result) => {
                    if (!err) {
                        const accessToken = result
                            .getAccessToken()
                            .getJwtToken();
                        const idToken = result.getIdToken().getJwtToken();
                        const refreshToken = result
                            .getRefreshToken()
                            .getToken();
                        const signInSession = {
                            access: accessToken,
                            id: idToken,
                            refresh: refreshToken,
                        };
                        setSession(signInSession);
                        resolve(signInSession);
                    } else {
                        setSession(null);
                        reject({
                            error: true,
                            message: "Nenhuma seção",
                        });
                    }
                });
            } else {
                setSession(null);
                reject({
                    error: true,
                    message: "Nenhuma seção",
                });
            }
        });
    }, []);

    const getAccessToken = useCallback(() => {
        return updateSession()
            .then((session) => session.id)
            .catch((err) => console.log(err));
    }, [updateSession]);

    useEffect(() => {
        updateSession()
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    }, [updateSession]);

    return (
        <AuthContext.Provider
            value={{
                session,
                signIn,
                signUp,
                signOut,
                resendCode,
                changePassword,
                confirmUser,
                sendEmailForgot,
                setPasswordForgot,
                getAccessToken,
                updatePassword,
                updatePasswordState,
                isLoading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthProvider;
