import React from "react";
import Select from "react-select";

export const CustomSelect = ({
    className,
    placeholder,
    field,
    form,
    options,
    isMulti = false,
}) => {
    const onChange = (option) => {
        const newOptions = option.map((item) => {
            if (item) {
                return item.value;
            }
        });

        form.setFieldValue(
            field.name,
            isMulti ? newOptions.toString() : option.value
        );
    };

    const getValue = () => {
        if (options) {
            return isMulti
                ? options.filter(
                      (option) => field.value.indexOf(option.value) >= 0
                  )
                : options.find((option) => option.value === field.value);
        } else {
            return isMulti ? [] : "";
        }
    };

    return (
        <Select
            className={className}
            name={field.name}
            value={getValue()}
            onChange={onChange}
            placeholder={placeholder}
            options={options}
            isMulti={isMulti}
        />
    );
};

export default CustomSelect;
