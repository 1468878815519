import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import styles from './styles.module.css';
import classNames from 'classnames';

const modalRoot = document.getElementById('sideModal');

function SideModal ({ onClose, children, title, show }) {
  const container = document.createElement('div');

  useEffect(() => {
    modalRoot.appendChild(container);

    return () => { 
      modalRoot.removeChild(container) 
    };
  }, [container, onClose]); 

  if (show) {
    return ReactDOM.createPortal(
      <>
          <div className={styles.overlay} onClick={onClose}/>
          <div className={classNames(styles.content, 'py-3', 'px-4')} onClick={(e) => e.stopPropagation()}>
            <div className="d-flex justify-content-between">
              <h3>{title}</h3>
              <button onClick={onClose} className={styles.close}>
                &times;
              </button>
            </div>
            <hr className="mx-0" />
  
            {children}
          </div>
      </>,
      container
    )
  } else {
    return null
  }
}

export default SideModal;