import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';

function PermissionList({ groupPermissions = [], permissionsTree, permissionsList, onSubmit }) {
  const [selectedPermissionsId, setSelectedPermissionsId] = useState([]);

  useEffect(() => {
    setSelectedPermissionsId(groupPermissions.map(permission => permission.id))
  }, [groupPermissions])

  function handleSubmit() {
    const selected = Array.from(new Set(selectedPermissionsId));
    onSubmit(
      selected.map(
        selectedPermissionId => permissionsList
          .find(permission => permission.id === selectedPermissionId)
      )
    )
  } 
  
  return (
    <>
    <div style={{ overflow: "auto", height: '100px' }} className="flex-fill pr-3">
      { permissionsTree && permissionsTree.map((formType) => (
        <FormPermission 
          key={formType.permissionId} 
          name={formType.permissionName} 
          mainGroups={formType.mainGroups} 
          formTypeId={formType.id} 
          useSelectedPermissionsId={[selectedPermissionsId, setSelectedPermissionsId]}
          id={formType.permissionId}
          permissionsTree={permissionsTree}
        />
      ))}
    </div>
    <div className="px-3 pt-3 bg-light">
      <Button 
          variant="success" 
          onClick={handleSubmit}
        >
        Salvar alterações
      </Button>
    </div>
    </>
  );
}

function FormPermission({ name, mainGroups, useSelectedPermissionsId, id, formTypeId, permissionsTree }) {
  const [selectedPermissionsId, setSelectedPermissionsId] = useSelectedPermissionsId;

  const handlePermissionChange = (e) => {
    const target = parseInt(e.target.value, 10);
    const relatedIds = [target];
    const formType = permissionsTree.find(formType => formType.id === formTypeId);
    
    formType.mainGroups.forEach((mainGroup) => {
      relatedIds.push(mainGroup.permissionId);

      mainGroup.subGroups.forEach((subGroup) => {
        relatedIds.push(subGroup.permissionId);
      })
    })

    if (e.target.checked) setSelectedPermissionsId((prev) => [...prev, ...relatedIds]);
    else setSelectedPermissionsId((prev) => prev.filter(permissionId => !relatedIds.includes(permissionId)));
  };

  return (
    <Card className="mb-3">
      <Card.Header>
        <Row className="justify-content-between px-2">
          <h6>
            <Form.Label 
              htmlFor={`switch-${id}`} 
              className="mb-0"
              style={{ cursor: 'pointer'}}
            >
              {name}
            </Form.Label>
          </h6>
          <Form.Check 
            type="switch"
            checked={selectedPermissionsId.includes(id)}
            onChange={handlePermissionChange}
            id={`switch-${id}`}
            value={id}
          />
        </Row>
      </Card.Header>
      <Card.Body>
        { mainGroups.map((mainGroup, index) => (
          <React.Fragment key={mainGroup.id}>
            <Row className="align-items-center" style={{ flexWrap: 'nowrap', overflow: 'auto' }} >
                <MainGroupPermission 
                  name={mainGroup.permissionName} 
                  subGroups={mainGroup.subGroups}
                  useSelectedPermissionsId={useSelectedPermissionsId}
                  id={mainGroup.permissionId}
                  formTypeId={formTypeId}
                  mainGroupId={mainGroup.id}
                  permissionsTree={permissionsTree}
                />
            </Row>
            { index !== mainGroups.length - 1 && <hr />}
          </React.Fragment>
        ))}
      </Card.Body>
    </Card>
  )
}

function MainGroupPermission({ name, subGroups, id, useSelectedPermissionsId, onChange, mainGroupId, formTypeId, permissionsTree }) {
  const [selectedPermissionsId, setSelectedPermissionsId] = useSelectedPermissionsId;

  const handlePermissionChange = (e) => {
    const target = parseInt(e.target.value, 10);
    const formType = permissionsTree.find(formType => formType.id === formTypeId);
    const mainGroupIndex = formType.mainGroups.findIndex(mainGroup => mainGroup.id === mainGroupId);
    const relatedIds = [target];
    const checkedSiblings = formType.mainGroups
      .map((mainGroup) => mainGroup.permissionId)
      .filter((id) => id !== target && selectedPermissionsId.includes(id));
    
    formType.mainGroups[mainGroupIndex].subGroups.forEach((subGroup) => {
      relatedIds.push(subGroup.permissionId);
    })

    if (checkedSiblings.length === 0) relatedIds.push(formType.permissionId)

    if (e.target.checked) setSelectedPermissionsId((prev) => [...prev, ...relatedIds]);
    else setSelectedPermissionsId((prev) => prev.filter(permissionId => !relatedIds.includes(permissionId)));
  };

  return (
    <>
      <Col xs={2} className="d-flex ">
        <Form.Check 
          type="switch"
          checked={selectedPermissionsId.includes(id)}
          onChange={handlePermissionChange}
          id={`switch-${id}`}
          value={id}
        />
        <Form.Label 
          htmlFor={`switch-${id}`} 
          style={{ cursor: 'pointer'}}
        >
          {name}
        </Form.Label>
      </Col>
      {subGroups.map((subGroup) => (
        <Col key={subGroup.id}>
            <SubGroupPermission 
              id={subGroup.permissionId}
              name={subGroup.permissionName} 
              useSelectedPermissionsId={useSelectedPermissionsId}
              formTypeId={formTypeId}
              mainGroupId={mainGroupId}
              permissionsTree={permissionsTree}
            />
        </Col>
      ))}
    </>
  )
}

function SubGroupPermission({ name, useSelectedPermissionsId, id, formTypeId, mainGroupId, permissionsTree }) {
  const [selectedPermissionsId, setSelectedPermissionsId] = useSelectedPermissionsId;

  const handlePermissionChange = (e) => {
    const target = parseInt(e.target.value, 10);
    const formType = permissionsTree.find(formType => formType.id === formTypeId);
    const mainGroupIndex = formType.mainGroups.findIndex(mainGroup => mainGroup.id === mainGroupId);
    const relatedIds = [target];
    const checkedSiblings = formType.mainGroups[mainGroupIndex].subGroups
      .map((subGroups) => subGroups.permissionId)
      .filter((id) => id !== target && selectedPermissionsId.includes(id));

    const checkedMainGroupSiblings = formType.mainGroups
      .map((mainGroup) => mainGroup.permissionId)
      .filter((id) => id !== formType.mainGroups[mainGroupIndex].permissionId && selectedPermissionsId.includes(id));

    
    if (checkedSiblings.length === 0) { 
      relatedIds.push(formType.mainGroups[mainGroupIndex].permissionId);
    }

    if (checkedMainGroupSiblings.length === 0 && checkedSiblings.length === 0) {
      relatedIds.push(formType.permissionId);
    }

    debugger;

    if (e.target.checked) setSelectedPermissionsId((prev) => [...prev, ...relatedIds]);
    else setSelectedPermissionsId((prev) => prev.filter(permissionId => !relatedIds.includes(permissionId)));
  };

  return(
    <div className="d-flex flex-column align-items-center text-center">
      <Form.Check 
        type="checkbox"
        checked={selectedPermissionsId.includes(id)}
        onChange={handlePermissionChange}
        id={`switch-${id}`}
        value={id}
      />
      <Form.Label 
        htmlFor={`switch-${id}`} 
        style={{ cursor: 'pointer'}}
      >
        {name}
      </Form.Label>
    </div>
  )
}

export default PermissionList;