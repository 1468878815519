import React from 'react';
import {Row, Col, FormGroup, Card} from 'react-bootstrap';
import css from '../../marketdynamicoverview.module.css';
import { FieldArray} from 'formik';
import FormInput from '../../../components/FormInput';
import CompetitionDynamicTotal from './CompetitionDynamicTotal';
import CompetitionDynamicShare from './CompetitionDynamicShare';
import Button from '../../../../Button';
import { MdAdd } from 'react-icons/md';

function DirectCompetitors({values, errors, touched}) {
    return (
        <FieldArray name="marketDynamicDirectCompetitors">
            {({insert, remove, push, form})=>(
                <>
                    {values.marketDynamicDirectCompetitors?.length > 0 &&
                        values.marketDynamicDirectCompetitors.map((md, index)=>(
                        <React.Fragment key={index}>
                            <Row className="align-items-start">
                                <Col lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name={`marketDynamicDirectCompetitors[${index}].marketDynamicSegment`}
                                            type="text"
                                            original="marketDynamicSegment"
                                            errors={form.errors?.marketDynamicDirectCompetitors && form.errors?.marketDynamicDirectCompetitors.length && form.errors?.marketDynamicDirectCompetitors[index] ? form.errors.marketDynamicDirectCompetitors[index] : ""}
                                            touched={form.touched?.marketDynamicDirectCompetitors && form.touched?.marketDynamicDirectCompetitors.length && form.touched?.marketDynamicDirectCompetitors[index] ? form.touched.marketDynamicDirectCompetitors[index] : ""}
                                            holder="Entre com o segmento" >
                                            Segmento
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                                <Button
                                    type="button"
                                    onClick={()=>{
                                        if(values.marketDynamicDirectCompetitors.length > 1){
                                            remove(index)
                                        }
                                    }}
                                    style={{ marginTop: '34px'}}
                                    variant="outline-danger"
                                ><i className="fas fa-trash-alt mr-2" />Remover Segmento</Button>
                            </Row>
                            
                                <FieldArray
                                    name={`marketDynamicDirectCompetitors[${index}].marketDynamicCompetitors`}
                                    render={({remove, unshift})=>(
                                        <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                                            <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                                                <Button
                                                    type="button"
                                                    variant="outline-success"
                                                    className={`w-100 h-100`}
                                                    onClick={() => unshift({marketDynamicCompetitors: "", marketDynamicTotalMarket: 0, marketDynamicShare: 0})}
                                                >
                                                    <MdAdd fontSize={24} />
                                                </Button>
                                            </Col>
                                            {md.marketDynamicCompetitors?.map((mdc, indexMdc) => (
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3} key={indexMdc}>
                                                    <Card className="p-3">
                                                        <FormGroup>
                                                            <FormInput
                                                                name={`marketDynamicDirectCompetitors[${index}].marketDynamicCompetitors[${indexMdc}].marketDynamicCompetitors`}
                                                                type="text"
                                                                original="marketDynamicCompetitors"
                                                                errors={
                                                                    form.errors?.marketDynamicDirectCompetitors &&
                                                                    form.errors?.marketDynamicDirectCompetitors?.length &&
                                                                    form.errors?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors &&
                                                                    form.errors?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors.length &&
                                                                    form.errors?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] ?
                                                                    form.errors?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors[indexMdc]  : ""}
                                                                touched={
                                                                    form.touched?.marketDynamicDirectCompetitors &&
                                                                    form.touched?.marketDynamicDirectCompetitors?.length &&
                                                                    form.touched?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors &&
                                                                    form.touched?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors.length &&
                                                                    form.touched?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] ?
                                                                    form.touched?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] : ""}
                                                                holder="Entre com o competidor" >
                                                                Competidor
                                                            </FormInput>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <FormInput
                                                                name={`marketDynamicDirectCompetitors[${index}].marketDynamicCompetitors[${indexMdc}].marketDynamicTotalMarket`}
                                                                type="number"
                                                                original="marketDynamicTotalMarket"
                                                                errors={
                                                                    form.errors?.marketDynamicDirectCompetitors &&
                                                                    form.errors?.marketDynamicDirectCompetitors?.length &&
                                                                    form.errors?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors &&
                                                                    form.errors?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors.length &&
                                                                    form.errors?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] ?
                                                                    form.errors?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors[indexMdc]  : ""}
                                                                touched={
                                                                    form.touched?.marketDynamicDirectCompetitors &&
                                                                    form.touched?.marketDynamicDirectCompetitors?.length &&
                                                                    form.touched?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors &&
                                                                    form.touched?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors.length &&
                                                                    form.touched?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] ?
                                                                    form.touched?.marketDynamicDirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] : ""}
                                                                holder="Entre com o total" >
                                                                Total
                                                            </FormInput>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <div className={`${css.input_label}`}><label htmlFor={`marketDynamicDirectCompetitors[${index}].marketDynamicCompetitors[${indexMdc}].marketDynamicShare`}>Participação(%)</label></div>
                                                            <CompetitionDynamicShare values="marketDynamicDirectCompetitors" index={index} indexmdc={indexMdc} disabled={true} name={`marketDynamicDirectCompetitors[${index}].marketDynamicCompetitors[${indexMdc}].marketDynamicShare`} />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Button variant="outline-danger" className={`mt-2 w-100`} type="button" onClick={() =>{
                                                                if(values.marketDynamicDirectCompetitors[index].marketDynamicCompetitors.length > 1){
                                                                    remove(indexMdc);
                                                                }
                                                            }}>
                                                                <i className="fas fa-trash-alt" />
                                                            </Button>
                                                        </FormGroup>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    )}
                                />

                                <Row className="mt-4">
                                    <Col className="d-flex align-items-center">
                                        <div className="mr-3 font-weight-bold"><span>Total deste segmento:</span></div>
                                        <CompetitionDynamicTotal values="marketDynamicDirectCompetitors" index={index} disabled={true} name={`marketDynamicDirectCompetitors[${index}].marketDynamicSegmentTotal`} />
                                    </Col>
                                </Row>

                                <hr />
                            </React.Fragment>
                        ))
                    }
                    
                    <FormGroup>
                        <Button
                            type="button"
                            variant="outline-success"
                            onClick={()=> push({marketDynamicSegment: "", marketDynamicCompetitors: [{marketDynamicCompetitors: "", marketDynamicTotalMarket: 0, marketDynamicShare: 0}], marketDynamicSegmentTotal:0})}
                            className={``}
                        >
                            <MdAdd fontSize={20} className="mr-1" />
                            Adicionar Segmento
                        </Button>
                    </FormGroup>
                </>
            )}
        </FieldArray>
    )
}

export default DirectCompetitors;
