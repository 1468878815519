import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    indexContainer: {
        flexDirection: "row",
        alignItems: "baseline",
        marginBottom: 6,
    },
    indexText: {
        fontSize: 12,
    },
    underline: {
        flex: 1,
        borderBottomWidth: 1,
        marginLeft: 4,
    },
    indexNumber: {
        fontSize: 12,
        color: "#1e0f70",
        marginLeft: 2,
    },
    pageNumber: {
        fontSize: 14,
        color: "#1e0f70",
        marginLeft: 2,
    },
});

const IndexPdf = () => (
    <>
        <View style={styles.indexContainer}>
            <Text style={styles.indexNumber}>1. </Text>
            <Text style={styles.indexText}>
                Posição estratégica - Visão Geral
            </Text>
            <View style={styles.underline} />
            <Text style={styles.pageNumber}>3</Text>
        </View>
        <View style={styles.indexContainer}>
            <Text style={styles.indexNumber}>2.</Text>
            <Text style={styles.indexText}>Posição estratégica - ESG</Text>
            <View style={styles.underline} />
            <Text style={styles.pageNumber}>4</Text>
        </View>
        <View style={styles.indexContainer}>
            <Text style={styles.indexNumber}>3.</Text>
            <Text style={styles.indexText}>
                Posição estratégica - Diretrizes Estratégicas
            </Text>
            <View style={styles.underline} />
            <Text style={styles.pageNumber}>6</Text>
        </View>
        <View style={styles.indexContainer}>
            <Text style={styles.indexNumber}>4.</Text>
            <Text style={styles.indexText}>
                Posição estratégica - Posicionamento Estratégico
            </Text>
            <View style={styles.underline} />
            <Text style={styles.pageNumber}>7</Text>
        </View>
        <View style={styles.indexContainer}>
            <Text style={styles.indexNumber}>5.</Text>
            <Text style={styles.indexText}>Plano de negócio - SWOT</Text>
            <View style={styles.underline} />
            <Text style={styles.pageNumber}>8</Text>
        </View>
        <View style={styles.indexContainer}>
            <Text style={styles.indexNumber}>6.</Text>
            <Text style={styles.indexText}>Forças Macro Ambientais</Text>
            <View style={styles.underline} />
            <Text style={styles.pageNumber}>9</Text>
        </View>
        <View style={styles.indexContainer}>
            <Text style={styles.indexNumber}>7.</Text>
            <Text style={styles.indexText}>
                Plano de Negócio - Premissas do orçamento
            </Text>
            <View style={styles.underline} />
            <Text style={styles.pageNumber}>10</Text>
        </View>
        <View style={styles.indexContainer}>
            <Text style={styles.indexNumber}>8.</Text>
            <Text style={styles.indexText}>Estratégia e Performance</Text>
            <View style={styles.underline} />
            <Text style={styles.pageNumber}>11</Text>
        </View>
        <View style={styles.indexContainer}>
            <Text style={styles.indexNumber}>9.</Text>
            <Text style={styles.indexText}>Cadeia de Valor - ESG</Text>
            <View style={styles.underline} />
            <Text style={styles.pageNumber}>12</Text>
        </View>
    </>
);

export default IndexPdf;
