import React, { useEffect, useState, useCallback } from "react";
import { Row, Col, FormGroup, Card } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import {
    initialValueChainValues,
    CompanyValueChainSchema,
} from "./helpers/formValidation";
import { getQuestions } from "../../../../../services/formData";
import FormInput from "../../../components/FormInput";
import CostsExpenses from "../components/CostsExpenses";
import BudgetCost from "./components/BudgetCost";
import AmortizationDepreciation from "./components/AmortizationDepreciation";
import { totalizers } from "./helpers/formValidation";
import { withRouter } from "react-router-dom";
import useSpinner from "../../../../spinner/useSpinner";
import Button from "../../../../Button";
import { MdAdd } from "react-icons/md";
import FormFieldArray from "../../../components/FormFieldArray";
import FormValidationError from "../../../components/FormValidationError";

function Operations(props) {
    const [formValues, setFormValues] = useState(initialValueChainValues);
    const [spinner, showSpinner, hideSpinner] = useSpinner();

    const loadData = useCallback(() => {
        let questionsTemp = { ...props.questions }; // Criando uma cópia das questões
        getQuestions(
            props.formSelected,
            props.group,
            props.sales,
            (err, salesQuestions) => {
                if ((salesQuestions && salesQuestions.error) || err) {
                    console.log(err);
                    hideSpinner();
                    props.history.push("/warning");
                } else {
                    try {
                        if (salesQuestions?.companyValueChainBudget) {
                            let budget =
                                salesQuestions.companyValueChainBudget.filter(
                                    (questions) =>
                                        questions.year ===
                                        new Date().getFullYear()
                                )[0];
                            if (props.questions.companyValueChainBudget) {
                                const ano =
                                    props.questions.companyValueChainBudget.find(
                                        (questions) =>
                                            questions.year ===
                                            new Date().getFullYear()
                                    );
                                if (budget && ano && Object.keys(ano).length) {
                                    //Montar os dados que farão o match
                                    budget.companyValueChainBudget.forEach(
                                        (proj) => {
                                            delete proj.companyValueChainBudget
                                                .total;
                                            for (let pj in proj.companyValueChainBudget) {
                                                const item =
                                                    ano.companyValueChainBudget.find(
                                                        (item) =>
                                                            item.companyValueChainProduct ===
                                                            proj.companyValueChainProduct
                                                    ); //Achando o produto dentro do props.questions
                                                //carregar os dados do props.questions
                                                proj.companyValueChainBudget[
                                                    pj
                                                ].varCost = item
                                                    ? item
                                                          .companyValueChainBudget[
                                                          pj
                                                      ].varCost
                                                    : 0;
                                                proj.companyValueChainBudget[
                                                    pj
                                                ].fixCost = item
                                                    ? item
                                                          .companyValueChainBudget[
                                                          pj
                                                      ].fixCost
                                                    : 0;
                                                proj.companyValueChainBudget[
                                                    pj
                                                ].margin =
                                                    proj
                                                        .companyValueChainBudget[
                                                        pj
                                                    ].price -
                                                    (proj
                                                        .companyValueChainBudget[
                                                        pj
                                                    ].varCost +
                                                        proj
                                                            .companyValueChainBudget[
                                                            pj
                                                        ].fixCost);
                                                proj.varCostTotal =
                                                    item?.varCostTotal
                                                        ? item.varCostTotal
                                                        : 0;
                                                proj.marginTotal =
                                                    item?.marginTotal
                                                        ? item.marginTotal
                                                        : 0;
                                                proj.fixCostTotal =
                                                    item?.fixCostTotal
                                                        ? item.fixCostTotal
                                                        : 0;
                                                proj.priceTotal =
                                                    item?.priceTotal
                                                        ? item.priceTotal
                                                        : 0;
                                            }
                                        }
                                    );
                                    budget.total = totalizers;
                                    questionsTemp.companyValueChainBudget = [];
                                    questionsTemp.companyValueChainBudget.push(
                                        budget
                                    );
                                    let initialValues = {
                                        ...initialValueChainValues,
                                        ...questionsTemp,
                                    };
                                    if (
                                        !initialValues.companyValueChainEnvironmental
                                    ) {
                                        initialValues.companyValueChainEnvironmental =
                                            [
                                                {
                                                    companyValueChainEnvironmental:
                                                        "",
                                                },
                                            ];
                                        initialValues.companyValueChainGovernance =
                                            [
                                                {
                                                    companyValueChainGovernance:
                                                        "",
                                                },
                                            ];
                                        initialValues.companyValueChainSocial =
                                            [
                                                {
                                                    companyValueChainSocial: "",
                                                },
                                            ];
                                    }
                                    initialValues.companyValueChainESG = [
                                        {
                                            companyValueChainEnvironmental: [
                                                {
                                                    companyValueChainEnvironmental:
                                                        "",
                                                },
                                            ],
                                            companyValueChainSocial: [
                                                {
                                                    companyValueChainSocial: "",
                                                },
                                            ],
                                            companyValueChainGovernance: [
                                                {
                                                    companyValueChainGovernance:
                                                        "",
                                                },
                                            ],
                                        },
                                    ];
                                    setFormValues(initialValues);
                                }
                            } else {
                                //Caso o registro no mongo esteja vazio
                                let questions = { ...initialValueChainValues }; //Criar uma cópia

                                budget.companyValueChainBudget.forEach(
                                    (proj) => {
                                        delete proj.companyValueChainBudget
                                            .total;
                                        for (let pj in proj.companyValueChainBudget) {
                                            //carregar os dados do props.questions
                                            proj.companyValueChainBudget[
                                                pj
                                            ].varCost = 0;
                                            proj.companyValueChainBudget[
                                                pj
                                            ].fixCost = 0;
                                            proj.companyValueChainBudget[
                                                pj
                                            ].margin =
                                                proj.companyValueChainBudget[pj]
                                                    .price -
                                                (proj.companyValueChainBudget[
                                                    pj
                                                ].varCost +
                                                    proj
                                                        .companyValueChainBudget[
                                                        pj
                                                    ].fixCost);
                                            proj.varCostTotal = 0;
                                            proj.marginTotal = 0;
                                            proj.fixCostTotal = 0;
                                            proj.priceTotal = 0;
                                        }
                                    }
                                );
                                budget.total = totalizers;
                                let questionsProps = {
                                    ...questions.companyValueChainBudget[0],
                                    ...budget,
                                };
                                questions.companyValueChainBudget[0].companyValueChainBudget =
                                    questionsProps.companyValueChainBudget;

                                setFormValues(questions);
                            }
                            hideSpinner();
                        } else {
                            hideSpinner();
                        }
                    } catch (e) {
                        console.log(e);
                        hideSpinner();
                        props.history.push("/warning");
                    }
                }
            }
        );
    }, [showSpinner, hideSpinner, props.questions]);

    useEffect(() => {
        let isSubscribe = true;
        if (isSubscribe) {
            loadData();
        }
        return () => {
            isSubscribe = false;
        };
    }, [loadData]);

    return (
        <>
            {spinner}
            <Formik
                initialValues={formValues}
                validationSchema={CompanyValueChainSchema}
                validate={(values) => {
                    //Como os valores dos produtos sofrem diferenças por virem da aba sales & services
                    //Foram retirados desta comparação
                    let valuesTemp = { ...values };
                    let formsTemp = { ...formValues };
                    delete valuesTemp.companyValueChainBudget;
                    delete formsTemp.companyValueChainBudget;
                    props.setChanged(
                        JSON.stringify(valuesTemp) !== JSON.stringify(formsTemp)
                    );
                }}
                onSubmit={(values) => {
                    props.setSubmitting(true);
                    props.setChanged(false);
                    props.handleSubmit(values, props.group, props.subGroup);
                }}
                enableReinitialize
            >
                {({ values, errors, touched }) => (
                    <Form className="p-4">
                        <Row className="py-4">
                            <Col xs={12} lg={4} className="border-right">
                                <h4 className="text-muted font-weight-light text-right mr-3">
                                    Sobre os objetivos da área
                                </h4>
                            </Col>
                            <Col>
                                <Row>
                                    <Col xs={12}>
                                        <FormGroup>
                                            <FieldArray name="companyValueChainObjectives">
                                                {({
                                                    insert,
                                                    remove,
                                                    push,
                                                    unshift,
                                                    form,
                                                }) => (
                                                    <Row
                                                        style={{
                                                            flexWrap: "nowrap",
                                                            overflow: "auto",
                                                        }}
                                                        className="py-1"
                                                    >
                                                        <Col xs={1}>
                                                            <Button
                                                                type="button"
                                                                variant="outline-success"
                                                                onClick={() =>
                                                                    unshift({
                                                                        companyValueChainObjectives:
                                                                            "",
                                                                        companyValueChainGoals:
                                                                            "",
                                                                        companyValueChainActionPlan:
                                                                            "",
                                                                    })
                                                                }
                                                                className={`w-100 h-100`}
                                                            >
                                                                <MdAdd fontSize="24" />
                                                            </Button>
                                                        </Col>
                                                        {values
                                                            .companyValueChainObjectives
                                                            ?.length > 0 &&
                                                            values.companyValueChainObjectives.map(
                                                                (cv, index) => (
                                                                    <Col
                                                                        className=""
                                                                        xs={4}
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        <Card className="p-3">
                                                                            <FormGroup>
                                                                                <FormInput
                                                                                    name={`companyValueChainObjectives[${index}].companyValueChainObjectives`}
                                                                                    type="text"
                                                                                    original="companyValueChainObjectives"
                                                                                    errors={
                                                                                        form
                                                                                            .errors
                                                                                            ?.companyValueChainObjectives &&
                                                                                        form
                                                                                            .errors
                                                                                            ?.companyValueChainObjectives
                                                                                            .length &&
                                                                                        form
                                                                                            .errors
                                                                                            ?.companyValueChainObjectives[
                                                                                            index
                                                                                        ]
                                                                                            ? form
                                                                                                  .errors
                                                                                                  .companyValueChainObjectives[
                                                                                                  index
                                                                                              ]
                                                                                            : ""
                                                                                    }
                                                                                    touched={
                                                                                        form
                                                                                            .touched
                                                                                            ?.companyValueChainObjectives &&
                                                                                        form
                                                                                            .touched
                                                                                            ?.companyValueChainObjectives
                                                                                            .length &&
                                                                                        form
                                                                                            .touched
                                                                                            ?.companyValueChainObjectives[
                                                                                            index
                                                                                        ]
                                                                                            ? form
                                                                                                  .touched
                                                                                                  .companyValueChainObjectives[
                                                                                                  index
                                                                                              ]
                                                                                            : ""
                                                                                    }
                                                                                    holder="Entre com o objetivo"
                                                                                >
                                                                                    Objetivo
                                                                                </FormInput>
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                <FormInput
                                                                                    name={`companyValueChainObjectives[${index}].companyValueChainGoals`}
                                                                                    type="text"
                                                                                    original="companyValueChainGoals"
                                                                                    errors={
                                                                                        form
                                                                                            .errors
                                                                                            ?.companyValueChainObjectives &&
                                                                                        form
                                                                                            .errors
                                                                                            ?.companyValueChainObjectives
                                                                                            .length &&
                                                                                        form
                                                                                            .errors
                                                                                            ?.companyValueChainObjectives[
                                                                                            index
                                                                                        ]
                                                                                            ? form
                                                                                                  .errors
                                                                                                  .companyValueChainObjectives[
                                                                                                  index
                                                                                              ]
                                                                                            : ""
                                                                                    }
                                                                                    touched={
                                                                                        form
                                                                                            .touched
                                                                                            ?.companyValueChainObjectives &&
                                                                                        form
                                                                                            .touched
                                                                                            ?.companyValueChainObjectives
                                                                                            .length &&
                                                                                        form
                                                                                            .touched
                                                                                            ?.companyValueChainObjectives[
                                                                                            index
                                                                                        ]
                                                                                            ? form
                                                                                                  .touched
                                                                                                  .companyValueChainObjectives[
                                                                                                  index
                                                                                              ]
                                                                                            : ""
                                                                                    }
                                                                                    holder="Entre com a meta"
                                                                                >
                                                                                    Meta
                                                                                </FormInput>
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                <FormInput
                                                                                    name={`companyValueChainObjectives[${index}].companyValueChainActionPlan`}
                                                                                    as="textarea"
                                                                                    rows={
                                                                                        3
                                                                                    }
                                                                                    original="companyValueChainActionPlan"
                                                                                    errors={
                                                                                        form
                                                                                            .errors
                                                                                            ?.companyValueChainObjectives &&
                                                                                        form
                                                                                            .errors
                                                                                            ?.companyValueChainObjectives
                                                                                            .length &&
                                                                                        form
                                                                                            .errors
                                                                                            ?.companyValueChainObjectives[
                                                                                            index
                                                                                        ]
                                                                                            ? form
                                                                                                  .errors
                                                                                                  .companyValueChainObjectives[
                                                                                                  index
                                                                                              ]
                                                                                            : ""
                                                                                    }
                                                                                    touched={
                                                                                        form
                                                                                            .touched
                                                                                            ?.companyValueChainObjectives &&
                                                                                        form
                                                                                            .touched
                                                                                            ?.companyValueChainObjectives
                                                                                            .length &&
                                                                                        form
                                                                                            .touched
                                                                                            ?.companyValueChainObjectives[
                                                                                            index
                                                                                        ]
                                                                                            ? form
                                                                                                  .touched
                                                                                                  .companyValueChainObjectives[
                                                                                                  index
                                                                                              ]
                                                                                            : ""
                                                                                    }
                                                                                    holder="Entre com p plano de ação"
                                                                                >
                                                                                    Plano
                                                                                    de
                                                                                    ação
                                                                                    (5W2H)
                                                                                </FormInput>
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                <Button
                                                                                    type="button"
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            values
                                                                                                .companyValueChainObjectives
                                                                                                .length >
                                                                                            1
                                                                                        ) {
                                                                                            remove(
                                                                                                index
                                                                                            );
                                                                                        }
                                                                                    }}
                                                                                    className="mt-2 w-100"
                                                                                    variant="outline-danger"
                                                                                >
                                                                                    <i className="fas fa-trash-alt" />
                                                                                </Button>
                                                                            </FormGroup>
                                                                        </Card>
                                                                    </Col>
                                                                )
                                                            )}
                                                    </Row>
                                                )}
                                            </FieldArray>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <hr />

                                <Row>
                                    <Col xs={12} lg={6}>
                                        <FormGroup>
                                            <FormInput
                                                name="companyValueChainStrategy"
                                                type="text"
                                                errors={errors}
                                                touched={touched}
                                                holder="Quais é a sua estratégia?"
                                            >
                                                Qual a sua estratégia?
                                            </FormInput>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12} lg={6}>
                                        <FormFieldArray
                                            name="companyValueChainPerformanceIndicators"
                                            values={values}
                                            title="Indicadores de performance"
                                            field="companyValueChainPerformanceIndicators"
                                            errors={errors}
                                            touched={touched}
                                            holder="Quais são seus indicadores de performance?"
                                            type="text"
                                            lessThenOne={false}
                                            butonAddText="Adicionar Indicador de Performance"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <hr />

                        <Row className="py-4">
                            <Col xs={12} lg={4} className="border-right">
                                <h4 className="text-muted font-weight-light text-center ">
                                    ESG
                                </h4>
                                <h4 className="text-muted font-weight-light text-center mr-3">
                                    Environmental-Social-Governance
                                </h4>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <FieldArray name="companyValueChainESG">
                                        {({ remove, unshift, form, push }) => (
                                            <Row
                                                style={{
                                                    flexWrap: "nowrap",
                                                    overflow: "auto",
                                                }}
                                                className="py-1"
                                            >
                                                {values.companyValueChainESG
                                                    ?.length > 0 &&
                                                    values.companyValueChainESG.map(
                                                        (cv, index) => (
                                                            <Col
                                                                className=""
                                                                lg={6}
                                                                key={index}
                                                            >
                                                                <Card className="p-3">
                                                                    <FormFieldArray
                                                                        name="companyValueChainEnvironmental"
                                                                        values={
                                                                            values
                                                                        }
                                                                        title="Ambiental"
                                                                        field="companyValueChainEnvironmental"
                                                                        errors={
                                                                            errors
                                                                        }
                                                                        touched={
                                                                            touched
                                                                        }
                                                                        holder="Entre com Ambiental"
                                                                        type="text"
                                                                        lessThenOne={
                                                                            false
                                                                        }
                                                                        butonAddText="Adicionar"
                                                                    />
                                                                    <FormFieldArray
                                                                        name="companyValueChainSocial"
                                                                        values={
                                                                            values
                                                                        }
                                                                        title="Social"
                                                                        field="companyValueChainSocial"
                                                                        errors={
                                                                            errors
                                                                        }
                                                                        touched={
                                                                            touched
                                                                        }
                                                                        holder="Entre com Social"
                                                                        type="text"
                                                                        lessThenOne={
                                                                            false
                                                                        }
                                                                        butonAddText="Adicionar"
                                                                    />

                                                                    <FormFieldArray
                                                                        name="companyValueChainGovernance"
                                                                        values={
                                                                            values
                                                                        }
                                                                        title="Governança"
                                                                        field="companyValueChainGovernance"
                                                                        errors={
                                                                            errors
                                                                        }
                                                                        touched={
                                                                            touched
                                                                        }
                                                                        holder="Entre com Governança"
                                                                        type="text"
                                                                        lessThenOne={
                                                                            false
                                                                        }
                                                                        butonAddText="Adicionar"
                                                                    />
                                                                </Card>
                                                            </Col>
                                                        )
                                                    )}
                                            </Row>
                                        )}
                                    </FieldArray>
                                </FormGroup>
                            </Col>
                        </Row>

                        <hr />

                        <Row className="pt-4">
                            <Col xs={12} lg={4} className="border-right">
                                <h4 className="text-muted font-weight-light text-right mr-3">
                                    Despesas e custos
                                </h4>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <Row>
                                    <Col>
                                        <CostsExpenses
                                            values={values}
                                            showCosts={true}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <hr />

                        <Row className="pt-4">
                            <Col xs={12} lg={4} className="border-right">
                                <h4 className="text-muted font-weight-light text-right mr-3">
                                    Depreciação & Amortização
                                </h4>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <Row>
                                    <Col>
                                        <AmortizationDepreciation
                                            values={values}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <hr />

                        <Row className="pt-4">
                            <Col xs={12} lg={4} className="border-right">
                                <h4 className="text-muted font-weight-light text-right mr-3">
                                    Orçamento Operações
                                </h4>
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <Row>
                                    <Col>
                                        <BudgetCost values={values} />

                                        <Row className="p-0 m-0 mt-3 justify-content-end">
                                            {Object.keys(errors).length > 0 && (
                                                <FormValidationError
                                                    errors={errors}
                                                />
                                            )}
                                        </Row>

                                        <Button
                                            variant="success"
                                            disabled={props.submitting}
                                            type="submit"
                                        >
                                            Salvar informações
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default withRouter(Operations);
