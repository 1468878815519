import React, {useState} from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Row } from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom';
import FormInput from '../components/FormInput';
import FormButton from '../components/FormButton';
import { useAuth } from '../../../providers/AuthProvider';

import authimg from './imgs/authimg.png'
import logo from './imgs/id_login.png'
import css from './auth.module.css'

const intialSigninValues ={
    newPassword: ""
}

const SigninSchema = Yup.object().shape({
    newPassword: Yup.string().required()
});

function UpdatePassword(props) {
    const [errorForm, setErrorForm] = useState({error: false, message: ""});
    const { updatePassword, updatePasswordState } = useAuth();

    function handleSubmit(values, formikBag) {
        setErrorForm({error: false, message: ""});
        updatePassword(values)
            .catch((err) => {
                console.log(err);
                setErrorForm({error: true, message: err.message});
                formikBag.setSubmitting(false);
            })
    }

    if (!updatePasswordState) {
      props.history.push('/');
    }

    return (
        <div className={css.authContainer}>
            <section className={css.form}>
                <Link to="/"><img src={logo} alt="CEO360" /></Link>
                <Formik
                    initialValues={intialSigninValues}
                    validationSchema={SigninSchema}
                    onSubmit={handleSubmit}>
                    {({errors, touched, isSubmitting })=>(
                        <Form>
                            <h4>Sua senha expirou</h4>
                            <p className="text-center">Defina sua nova senha para acessar a plataforma</p>
                            <FormInput name="newPassword" type="password" errors={errors} touched={touched} holder="Nova senha" />

                            <Row className="p-0 m-0">
                                {errorForm.error && 
                                    <span style={{color:'red'}}>{errorForm.message}</span>
                                }
                            </Row>
                            <Row className={`${css.button} p-0 m-0 justify-content-end`}>
                                <FormButton disabled={isSubmitting} type="submit" text="LOGIN" />
                            </Row>
                            <Link className={css.authLink} to="/signup">
                                Ainda não tem uma conta? Teste gratuitamente
                            </Link>
                        </Form>
                    )}
                </Formik>
            </section>
            <img className={css.authImg} src={authimg} alt="Business"/>
        </div>
    )
}

export default withRouter(UpdatePassword);