import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AdmFundamentals from "./administration-fundamentals/AdmFundamentals";
import StrategicGuidelines from "./strategic-guidelines/StrategicGuidelines";
import ValueChain from "./value-chain/ValueChain";
import General from "./general-view/General";
import useSpinner from "../../spinner/useSpinner";
import { Prompt, withRouter } from "react-router-dom";
import {
    getQuestions,
    postAnswers,
    postSubAnswers,
} from "../../../services/formData";
import { ToastContainer, toast } from "react-toastify";
import { messages } from "../../../services/system-messages/systemMessages";
import Page from "../../Page";
import PrivateComponent from "../../PrivateComponent";
import PrivateTab from "../../PrivateTab";
import permissionEnum from "../../../enums/permission";
import { useAuth } from "../../../providers/AuthProvider";

function CompanyOverview(props) {
    const [questions, setQuestions] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [menu, setMenu] = useState([]);
    const [spinner, showSpinner, hideSpinner] = useSpinner();
    const [submitting, setSubmitting] = useState(false);
    const [changed, setChanged] = useState(false);
    const { getAccessToken } = useAuth();

    useEffect(() => {
        let isSubscribe = true;

        showSpinner();
        const loadData = async () => {
            if (
                isSubscribe &&
                props.formSelected !== null &&
                props.forms !== null
            ) {
                // console.log(props.formSelected);
                const strategicForms = props.forms.length
                    ? props.forms.filter(
                          (hit) => hit.id === props.formSelected
                      )[0]
                    : null;
                if (strategicForms) {
                    strategicForms.FormType.MainGroups.sort(
                        (a, b) => a.menuOrder - b.menuOrder
                    ); //Ordenando o array de acordo com a ordem do menu
                    const groupId = strategicForms?.FormType?.MainGroups[0].id; //Recupera o groupId do primeiro menu
                    if (groupId) setMenu(strategicForms.FormType.MainGroups);
                    if (groupId) {
                        const accessToken = await getAccessToken();
                        getQuestions(
                            props.formSelected,
                            groupId,
                            0,
                            (err, questions) => {
                                if ((questions && questions.error) || err) {
                                    console.log(err);
                                    hideSpinner(); //Escondendo o spinner
                                    props.history.push("/warning");
                                } else {
                                    if (isSubscribe) {
                                        setQuestions(questions);
                                        setTabIndex(0);
                                        hideSpinner();
                                    }
                                }
                            }
                        );
                    }
                }
            }
        };
        loadData();

        return () => {
            isSubscribe = false;
        };
    }, [props.formSelected]);

    async function componentSubmit(values, groupId) {
        const accessToken = await getAccessToken();
        postAnswers(
            props.formSelected,
            groupId,
            values,
            (err, response) => {
                if ((response && response.error) || err) {
                    console.log(err);
                    toast.error(messages.danger.forms.error);
                } else {
                    console.log(response);
                    toast.success(messages.success.forms.saved);
                }
                setSubmitting(false);
                setChanged(false);
            },
            accessToken
        );
    }

    async function componentSubSubmit(values, groupId, subGroupId) {
        const accessToken = await getAccessToken();
        postSubAnswers(
            props.formSelected,
            groupId,
            subGroupId,
            values,
            (err, response) => {
                if ((response && response.error) || err) {
                    console.log(err);
                    toast.error(messages.danger.forms.error);
                } else {
                    console.log(response);
                    toast.success(messages.success.forms.saved);
                }
                setSubmitting(false);
            },
            accessToken
        );
    }

    function handleSelectQuestion(group, index) {
        if (changed) {
            const response = window.confirm(
                "Voce alterou o formulário. Seus dados serão perdidos. :( Gostaria de continuar sem salvar ?"
            );
            if (!response) {
                setTabIndex(tabIndex);
            } else {
                setChanged(false);
                setTabIndex(index);
                loadingTabQuestions(group !== menu[3].id, group);
            }
        } else {
            setTabIndex(index);
            loadingTabQuestions(group !== menu[3].id, group);
        }
    }

    function loadingTabQuestions(status, group) {
        if (status) {
            showSpinner();
            getQuestions(props.formSelected, group, 0, (err, questions) => {
                if ((questions && questions.error) || err) {
                    console.log(err);
                    hideSpinner();
                    props.history.push("/warning");
                } else {
                    setQuestions(questions);
                    hideSpinner();
                }
            });
        }
    }

    return (
        <Page>
            {spinner}
            <Prompt
                when={changed}
                message="Voce alterou o formulário. Seus dados serão perdidos. :( Gostaria de continuar sem salvar ?"
            />
            <ToastContainer />
            <Container fluid className="p-0">
                <Tabs selectedIndex={tabIndex} onSelect={() => {}}>
                    <TabList>
                        <PrivateTab
                            permission={permissionEnum.MAIN_GROUP.GENERAL}
                        >
                            <Tab
                                onClick={() =>
                                    handleSelectQuestion(menu[0].id, 0)
                                }
                            >
                                {menu.length > 0 && menu[0]
                                    ? menu[0].title
                                    : "Loading..."}
                            </Tab>
                        </PrivateTab>
                        <PrivateTab
                            permission={
                                permissionEnum.MAIN_GROUP.STRATEGIC_GUIDELINES
                            }
                        >
                            <Tab
                                onClick={() =>
                                    handleSelectQuestion(menu[1].id, 1)
                                }
                            >
                                {menu.length > 0 && menu[1]
                                    ? menu[1].title
                                    : "Loading..."}
                            </Tab>
                        </PrivateTab>
                        <PrivateTab
                            permission={
                                permissionEnum.MAIN_GROUP
                                    .ADMINISTRATION_FUNDAMENTALS
                            }
                        >
                            <Tab
                                onClick={() =>
                                    handleSelectQuestion(menu[2].id, 2)
                                }
                            >
                                {menu.length > 0 && menu[2]
                                    ? menu[2].title
                                    : "Loading..."}
                            </Tab>
                        </PrivateTab>
                        <PrivateTab
                            permission={permissionEnum.MAIN_GROUP.VALUE_CHAIN}
                        >
                            <Tab
                                onClick={() =>
                                    handleSelectQuestion(menu[3].id, 3)
                                }
                            >
                                {menu.length > 0 && menu[3]
                                    ? menu[3].title
                                    : "Loading..."}
                            </Tab>
                        </PrivateTab>
                    </TabList>

                    <TabPanel>
                        <PrivateComponent
                            permission={permissionEnum.MAIN_GROUP.GENERAL}
                        >
                            <General
                                submitting={submitting}
                                setSubmitting={setSubmitting}
                                group={menu[0]?.id}
                                questions={questions}
                                overviewSubmit={componentSubmit}
                                setChanged={setChanged}
                            />
                        </PrivateComponent>
                    </TabPanel>
                    <TabPanel>
                        <PrivateComponent
                            permission={
                                permissionEnum.MAIN_GROUP.STRATEGIC_GUIDELINES
                            }
                        >
                            <StrategicGuidelines
                                submitting={submitting}
                                setSubmitting={setSubmitting}
                                group={menu[1]?.id}
                                questions={questions}
                                strategicSubmit={componentSubmit}
                                setChanged={setChanged}
                            />
                        </PrivateComponent>
                    </TabPanel>
                    <TabPanel>
                        <PrivateComponent
                            permission={
                                permissionEnum.MAIN_GROUP
                                    .ADMINISTRATION_FUNDAMENTALS
                            }
                        >
                            <AdmFundamentals
                                submitting={submitting}
                                setSubmitting={setSubmitting}
                                group={menu[2]?.id}
                                questions={questions}
                                fundamentalsSubmit={componentSubmit}
                                setChanged={setChanged}
                            />
                        </PrivateComponent>
                    </TabPanel>
                    <TabPanel>
                        <PrivateComponent
                            permission={permissionEnum.MAIN_GROUP.VALUE_CHAIN}
                        >
                            <ValueChain
                                submitting={submitting}
                                setSubmitting={setSubmitting}
                                group={menu[3]?.id}
                                questions={questions}
                                componentSubmit={componentSubSubmit}
                                formSelected={props.formSelected}
                                forms={props.forms}
                                setChanged={setChanged}
                            />
                        </PrivateComponent>
                    </TabPanel>
                </Tabs>
            </Container>
        </Page>
    );
}

export default withRouter(CompanyOverview);
