import { useUserContext } from '../../providers/UserProvider';

function PrivateComponent({ permission, children, isAdmin = false }) {
  const { data } = useUserContext();

  if (isAdmin && data?.isAdmin) {
    return children
  }
  
  if (permission && data?.permissions) {
    if (data.permissions.includes(permission)) {
      return children
    }
  }

  return null;
}

export default PrivateComponent;