import React, { createContext, useCallback, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import useSpinner from "../components/spinner/useSpinner";
import { getForm, getMenuTypes } from "../services/formData";
import { useAuth } from "./AuthProvider";

const QuestionFormsContext = createContext({});

export function useQuestionForms() {
    return useContext(QuestionFormsContext);
}

function QuestionFormsProvider({ children }) {
    const [types, setTypes] = useState(null);
    const [forms, setForms] = useState(null);
    const [formSelected, setFormSelected] = useState(null);
    const { getAccessToken } = useAuth();
    const [loader, showLoader, hideLoader] = useSpinner();

    const init = useCallback(async () => {
        showLoader();
        const accessToken = await getAccessToken();
        getMenuTypes(accessToken, (err, types) => {
            if (err) {
                console.log(err);
            } else {
                setTypes(types);
                getForm(accessToken, (err, form) => {
                    // if (err) {
                    //     return console.log(err);
                    // } else {
                    if (form.error) {
                        console.log("erro getForm", err);
                    } else {
                        console.log("This is the Form", form);
                        setForms(form);
                    }
                });
            }
        });
        hideLoader();
    }, [showLoader, hideLoader]);

    useEffect(() => {
        let isSubscribe = true;
        if (isSubscribe) {
            init();
        }

        return () => {
            isSubscribe = false;
        };
    }, [init]);

    return (
        <QuestionFormsContext.Provider
            value={{
                types,
                forms,
                formSelected,
                setFormSelected,
                showLoader,
                hideLoader,
            }}
        >
            {loader}
            {children}
        </QuestionFormsContext.Provider>
    );
}

export default QuestionFormsProvider;
