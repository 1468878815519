exports.genElementsValue = () => [
    { label: "Valor de selo", value: "valor_selo" },
    { label: "Organiza", value: "organiza" },
    { label: "Reduz o custo", value: "reduz_custo" },
    { label: "Economiza tempo", value: "economiza_tempo" },
    { label: "Qualidade", value: "qualidade" },
    { label: "Simplifica", value: "simplifica" },
    { label: "Evita aborrecimentos", value: "evita_aborrecimentos" },
    { label: "Integra", value: "integra" },
    { label: "Me recompensa", value: "me_recompensa" },
    { label: "Fornece acesso", value: "fornece_acesso" },
    { label: "Reduz o esforço", value: "reduz_esforço" },
    { label: "Auto atualização", value: "auto_atualizacao" },
    { label: "Diversão entretenimento", value: "diversao_entretenimento" },
    { label: "Valor terapêutico", value: "valor_terapeutico" },
    { label: "Reduz o risco", value: "reduz_risco" },
    { label: "Design / Estética", value: "design_estetica" },
    { label: "Informa", value: "informa" },
    { label: "Herança", value: "herança" },
    { label: "Ganha dinheiro", value: "ganha_dinheiro" },
    { label: "Motivação", value: "motivacao" },
    { label: "Atratividade", value: "atratividade" },
    { label: "Fornece esperança", value: "fornece_esperança" },
    { label: "Bem estar", value: "bem_estar" },
    { label: "Nostalgia", value: "nostalgia" },
    { label: "Veriedade", value: "variedade" },
    { label: "Auto-transcendência", value: "auto_transcendencia" },
    { label: "Conecta", value: "conecta" },
    { label: "Reduz a ansiedade", value: "reduz_ansiedade" },
    { label: "Apelo sensorial", value: "apelo_sensorial" },
    { label: "Afiliação e pertencimento", value: "afiliacao_pertencimento" },
];
