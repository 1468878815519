import React, { useEffect, useState } from 'react';
import { Badge, Table } from 'react-bootstrap';
import { MdClose, MdDelete, MdPlaylistAdd, MdSearch } from 'react-icons/md';
import { formatDayMonthYear } from '../../utils/formatDate';
import UserAvatar from '../UserAvatar';
import Button from '../Button';

import styles from './styles.module.css';

function UserList({ users = [], onAddToGroup, onRemoveFromGroup}) {
  const [selected, setSelected] = useState([]);
  const [displayUsers, setDisplayUsers] = useState(users);
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (!users) {
      return;
    }

    const regex = new RegExp(search.trim(), 'i');
    const filteredUsers = users.filter((user) => regex.test(JSON.stringify([user.email, user.name ])));
    const filterdUsersId = filteredUsers.map((user) => user.id);

    setDisplayUsers(filteredUsers);
    setSelected(prev => prev.filter(userId => filterdUsersId.includes(userId)))

  }, [search, users])

  function handleSelectAll() {
    if (!displayUsers) return;
    
    if (selected.length === displayUsers.length) {
      setSelected([]);
      return;
    }

    setSelected(displayUsers.map((user) => user.id));
  }

  function handleSelect(e) {
    const target = parseInt(e.target.value, 10);

    if (!e.target.checked) {
      setSelected(prev => prev.filter(userId => userId !== target));
      return;
    }

    setSelected(prev => [ ...prev, target])
  }

  return (
    <>
      <div className="d-flex justify-content-between bg-light py-3">
        <div className="d-flex align-items-center w-100">

          { onAddToGroup && (
            <Button variant="outline-success" onClick={() => onAddToGroup(selected.map((selectedId) => users.find(user => selectedId === user.id)))}>
              <MdPlaylistAdd className="mr-2" />
              Adicionar usuários selecionados a um grupo
            </Button>
          )}

          { onRemoveFromGroup && (
            <Button variant="outline-danger" onClick={() => onRemoveFromGroup(selected.map((selectedId) => users.find(user => selectedId === user.id)))}>
              <MdDelete className="mr-2" />
              Remover usuários selecionados do grupo
            </Button>
          )}

          <div className="d-flex ml-auto justify-content-center align-items-stretch">
            <input 
              type="text" 
              placeholder="Buscar" 
              className="p-2 px-4 rounded border border-right-0"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="border border-left-0 rounded-right d-flex justify-content-center align-items-center p-2 bg-white">
              { 
                search.trim() ? <MdClose title="Limpar" onClick={() => setSearch('') } style={{ cursor: 'pointer' }}/> : <MdSearch />
              }
            </div>
          </div>
        </div>
      </div>

      <div style={{ overflow: "auto", height: '100px' }} className="flex-fill">
        <Table striped hover className={styles.table}>
          <thead>
            <tr>
              <th>
                <input 
                  type="checkbox" 
                  onChange={handleSelectAll} 
                  checked={displayUsers?.length === selected?.length && displayUsers.length !== 0}
                />
              </th>
              <th>
                #
              </th>
              <th>Nome</th>
              <th>Usuário</th>
              <th>E-mail</th>
              <th>Membro desde</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            { displayUsers && displayUsers.map((user) => (
              <tr key={user.id}>
                <td>
                  <input 
                    type="checkbox" 
                    checked={selected.includes(user.id)} 
                    value={user.id} 
                    onChange={handleSelect}
                  />
                </td>
                <td>
                  <UserAvatar username={user.firstName} />
                </td>
                <td>{user.firstName + ' ' + user.lastName}</td>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{formatDayMonthYear(user.createdAt)}</td>
                <td>{user.active ? <Badge variant="success">Ativo</Badge> : <Badge variant="secondary">Inativo</Badge>}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default UserList;