import React from 'react';
import classNames from 'classnames';
import { Button as Btn } from 'react-bootstrap';

import styles from './styles.module.css';

function Button({ variant, children, className, ...rest }) {
  return <Btn variant={variant} className={classNames(styles.btn, styles[variant], className)} {...rest}>{children}</Btn>;
}

export default Button;
