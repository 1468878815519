import React, {useState, useEffect} from 'react';
import {Bar} from "react-chartjs-2";
import {someColors} from '../css/colors';

const months = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];

const datasetItem = {
    label: 'Total of 0',
    data: [0,0,0,0,0,0,0,0,0,0,0,0],
    backgroundColor: someColors,
    borderColor: "white",
    borderWidth: 1,
}

const body = {
    labels: months,
    datasets: [
        datasetItem
    ]
}

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
            align: "start"
        }
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
}

function TotalMarketingInvestimentGraphic(props) {
    const [dataBody, setDataBody] = useState(body);

    useEffect(() => {
        if(props.marketing.length > 0){
            const expenses = props.marketing[0].companyValueChainExpenses;
            const invest = expenses.companyValueChainExpenses.filter(exp=>exp.companyValueChainInvestiment);
            const investBody = invest.map(hit=>{
                return {
                    expense: hit.companyValueChainDescription,
                    values: hit.companyValueChainExpenses,
                    total: hit.companyValueChainTotal
                }
            });

            let monthSum = [0,0,0,0,0,0,0,0,0,0,0,0];
            let total =0;
            let data = datasetItem;

            investBody.forEach(item=>{
                monthSum[0] = monthSum[0] + parseFloat(item.values.jan);
                monthSum[1] = monthSum[1] + parseFloat(item.values.fev);
                monthSum[2] = monthSum[2] + parseFloat(item.values.mar);
                monthSum[3] = monthSum[3] + parseFloat(item.values.abr);
                monthSum[4] = monthSum[4] + parseFloat(item.values.mai);
                monthSum[5] = monthSum[5] + parseFloat(item.values.jun);
                monthSum[6] = monthSum[6] + parseFloat(item.values.jul);
                monthSum[7] = monthSum[7] + parseFloat(item.values.ago);
                monthSum[8] = monthSum[8] + parseFloat(item.values.set);
                monthSum[9] = monthSum[9] + parseFloat(item.values.out);
                monthSum[10] = monthSum[10] + parseFloat(item.values.nov);
                monthSum[11] = monthSum[11] + parseFloat(item.values.dez);
                total = total + item.total;

                data = {
                    label: `Total de investimento: ${total}`,
                    data: monthSum,
                    backgroundColor: someColors,
                    borderColor: "white",
                    borderWidth: 1,
                }
            });

            const body = {
                labels: months,
                datasets: [
                    data
                ]
            }
            setDataBody(body);
        }
    }, [props.marketing]);


    return (
        <div>
            <Bar
                data={dataBody}
                options={options}
                height={300}
            />
        </div>
    );
}

export default TotalMarketingInvestimentGraphic;