import React from 'react';
import {Row, Col, FormGroup, Card} from 'react-bootstrap';
import css from '../../marketdynamicoverview.module.css';
import { FieldArray, Field, ErrorMessage} from 'formik';
import FormInput from '../../../components/FormInput';
import Button from '../../../../Button';
import { MdAdd } from 'react-icons/md';
import FormSelect from '../../../../FormSelect';

function RivalryLevel({values, errors, touched}) {
    return (
        <FieldArray name="marketDynamicRivalryLevel">
            {({remove, unshift, form})=>(
                <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                    <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                        <Button
                            type="button"
                            variant="outline-success"
                            className={`w-100 h-100`}
                            onClick={()=> unshift({marketDynamicSegment: '', marketDynamicLevel: 0})}
                            >
                            <MdAdd fontSize={24} />
                        </Button>
                    </Col>
                    {values.marketDynamicRivalryLevel?.length > 0 &&
                        values.marketDynamicRivalryLevel.map((md, index)=>(
                            <Col xs={3} sm={3} md={3} lg={3} xl={3} key={index}>
                                <Card className="p-3">
                                    <FormGroup>
                                        <FormInput
                                            name={`marketDynamicRivalryLevel[${index}].marketDynamicSegment`}
                                            type="text"
                                            original="marketDynamicSegment"
                                            errors={form.errors?.marketDynamicRivalryLevel && form.errors?.marketDynamicRivalryLevel.length && form.errors?.marketDynamicRivalryLevel[index] ? form.errors.marketDynamicRivalryLevel[index] : ""}
                                            touched={form.touched?.marketDynamicRivalryLevel && form.touched?.marketDynamicRivalryLevel.length && form.touched?.marketDynamicRivalryLevel[index] ? form.touched.marketDynamicRivalryLevel[index] : ""}
                                            holder="Entre com o segmento" >
                                            Segmento
                                        </FormInput>
                                    </FormGroup>

                                    <FormSelect 
                                        label="Level"
                                        name={`marketDynamicRivalryLevel[${index}].marketDynamicLevel`}
                                        id={`marketDynamicRivalryLevel[${index}].marketDynamicLevel`}
                                    >
                                        <option value="H">High</option>    
                                        <option value="M">Medium</option>    
                                        <option value="L">Low</option>
                                    </FormSelect>

                                    <FormGroup>
                                        <Button
                                            variant="outline-danger"
                                            type="button"
                                            onClick={()=>{
                                                if( values.marketDynamicRivalryLevel.length > 1){
                                                    remove(index)
                                                }
                                            }}
                                            className="w-100"
                                        ><i className="fas fa-trash-alt" /></Button>
                                    </FormGroup>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            )}
        </FieldArray>
    )
}


export default RivalryLevel;
