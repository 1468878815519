import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { allColors } from '../css/colors';

const months = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];

const datasetItem = {
  label: 'Total of 0',
  data: [0,0,0,0,0,0,0,0,0,0,0,0],
  backgroundColor: [
		'black',
		'white',
  ],
  borderColor: "white",
  borderWidth: 1,
}

const body = {
  labels: months,
  datasets: [
      datasetItem
  ],
}

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
      legend: {
          position: 'right',
          align: "start"
      }
  },
  scales: {
      yAxes: [
          {
              stacked: true,
              ticks: {
                  beginAtZero: true,
              },
          },
      ],
      xAxes: [
          {
              stacked: true,
          }
      ]
  },
}


function StackedBarGraph(props) {
  const [dataBody, setDataBody] = useState(body);

  useEffect(()=>{
    if (!props.data) {
        return;
    }
    
    if(props?.data[props.property].length > 0){
        const elements = props?.data[props.property].map(hit=>{
            return {
                title: hit.companyValueChainDescription,
                values: hit[props.property]
            }
        });

        //Grafico por produto
        let dataAssembly = []

        elements?.forEach((item, index)=>{
            let data = {
                label: item.title,
                data: [
                    item.values.jan,
                    item.values.fev,
                    item.values.mar,
                    item.values.abr,
                    item.values.mai,
                    item.values.jun,
                    item.values.jul,
                    item.values.ago,
                    item.values.set,
                    item.values.out,
                    item.values.nov,
                    item.values.dez
                ],
                backgroundColor: [
                    allColors[index],
                    allColors[index]
                ],
                borderColor: "white",
                borderWidth: 1,
                stack: "stack"
            }
            dataAssembly.push(data);
        });


        let dataGraph = {
            datasets: dataAssembly
        }

        setDataBody({...dataBody, ...dataGraph});
    }
}, [props.data])

  return props.data ? (
    <Bar
      data={dataBody}
      options={options}
      height={300}
    />
  ) : null;
}

export default StackedBarGraph;