import React, { useState, useEffect } from "react";
import { getBudgetData } from "../../../../services/formData";
import { Container, Table } from "react-bootstrap";
import NoDataPresent from "./NoDataPresent";
import { withRouter } from "react-router-dom";
import BudgetAssumptionTable from "./BudgetAssumptionTable";
import { ToastContainer } from "react-toastify";
import css from "../../budget/budget.module.css";
import { useAuth } from "../../../../providers/AuthProvider";

function BudgetAssumption({ forms, ...props }) {
    const [human, setHuman] = useState([]);
    const [marketing, setMarketing] = useState([]);
    const [operations, setOperations] = useState([]);
    const [service, setServices] = useState([]);
    const [supply, setSupply] = useState([]);
    const [tech, setTech] = useState([]);
    const [finance, setFinance] = useState([]);
    const { getAccessToken } = useAuth();

    const [data, setData] = useState(false);

    useEffect(() => {
        let isSubscribe = true;
        const loadData = async () => {
            const mainMenu = forms?.find((hit) => hit.FormType.menuOrder === 1); //find do menu principal - strategic position
            if (mainMenu && Object.keys(mainMenu)) {
                const secMenu = mainMenu.FormType.MainGroups.find(
                    (hit) => hit.menuOrder === 4
                ); //find do menu secundario - value chain
                if (secMenu && Object.keys(secMenu)) {
                    const rh = secMenu.SubGroups.find(
                        (hit) => hit.menuOrder === 1
                    ); //rh
                    const marketing = secMenu.SubGroups.find(
                        (hit) => hit.menuOrder === 2
                    ); //marketing
                    const operations = secMenu.SubGroups.find(
                        (hit) => hit.menuOrder === 4
                    ); //operations
                    const serviceSales = secMenu.SubGroups.find(
                        (hit) => hit.menuOrder === 3
                    ); // Service and sales
                    const supplyChain = secMenu.SubGroups.find(
                        (hit) => hit.menuOrder === 5
                    ); // Supplychain
                    const technology = secMenu.SubGroups.find(
                        (hit) => hit.menuOrder === 6
                    ); // Tech
                    const finance = secMenu.SubGroups.find(
                        (hit) => hit.menuOrder === 7
                    ); // Financas
                    if (isSubscribe) {
                        const accessToken = await getAccessToken();
                        const budgetData = await getBudgetData(
                            mainMenu.id,
                            secMenu.id,
                            accessToken
                        );
                        if (!Array.isArray(budgetData) || budgetData.error) {
                            props.history.push("/");
                        } else {
                            if (isSubscribe) {
                                if (budgetData?.length) {
                                    let questionsRh = budgetData.find(
                                        (hit) => hit.sGroupId === rh.id
                                    );
                                    if (questionsRh)
                                        setHuman(
                                            questionsRh.questions
                                                .companyValueChainObjectives
                                        );
                                    let questionsMarketing = budgetData.find(
                                        (hit) => hit.sGroupId === marketing.id
                                    );
                                    if (questionsMarketing)
                                        setMarketing(
                                            questionsMarketing.questions
                                                .companyValueChainObjectives
                                        );
                                    let questionsOperations = budgetData.find(
                                        (hit) => hit.sGroupId === operations.id
                                    );
                                    if (questionsOperations)
                                        setOperations(
                                            questionsOperations.questions
                                                .companyValueChainObjectives
                                        );
                                    let questionsServices = budgetData.find(
                                        (hit) =>
                                            hit.sGroupId === serviceSales.id
                                    );
                                    if (questionsServices)
                                        setServices(
                                            questionsServices.questions
                                                .companyValueChainObjectives
                                        );
                                    let questionsSupply = budgetData.find(
                                        (hit) => hit.sGroupId === supplyChain.id
                                    );
                                    if (questionsSupply)
                                        setSupply(
                                            questionsSupply.questions
                                                .companyValueChainObjectives
                                        );
                                    let questionsTech = budgetData.find(
                                        (hit) => hit.sGroupId === technology.id
                                    );
                                    if (questionsTech)
                                        setTech(
                                            questionsTech.questions
                                                .companyValueChainObjectives
                                        );
                                    let questionsFinance = budgetData.find(
                                        (hit) => hit.sGroupId === finance.id
                                    );
                                    if (questionsFinance)
                                        setFinance(
                                            questionsFinance.questions
                                                .companyValueChainObjectives
                                        );
                                    setData(true);
                                } else {
                                    setData(false);
                                }
                            }
                        }
                    }
                }
            }
        };
        if (isSubscribe) loadData();
        return () => {
            isSubscribe = false;
        };
    }, []);

    return (
        <>
            <ToastContainer />
            <Container fluid className="p-4">
                {data && (
                    <Table className={css.budgetTable}>
                        <thead>
                            <tr>
                                <th>Área</th>
                                <th>#</th>
                                <th>Objetivos</th>
                                <th>Metas</th>
                                <th>Plano de Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {human.length > 0 && (
                                <BudgetAssumptionTable
                                    objectBody={human}
                                    title="Recursos Humanos"
                                />
                            )}
                            {marketing.length > 0 && (
                                <BudgetAssumptionTable
                                    objectBody={marketing}
                                    title="Marketing"
                                />
                            )}
                            {operations.length > 0 && (
                                <BudgetAssumptionTable
                                    objectBody={operations}
                                    title="Operações"
                                />
                            )}
                            {service.length > 0 && (
                                <BudgetAssumptionTable
                                    objectBody={service}
                                    title="Vendas"
                                />
                            )}
                            {supply.length > 0 && (
                                <BudgetAssumptionTable
                                    objectBody={supply}
                                    title="Cadeia de Suprimentos"
                                />
                            )}
                            {tech.length > 0 && (
                                <BudgetAssumptionTable
                                    objectBody={tech}
                                    title="Tecnologia"
                                />
                            )}
                            {finance.length > 0 && (
                                <BudgetAssumptionTable
                                    objectBody={finance}
                                    title="Finanças"
                                />
                            )}
                        </tbody>
                    </Table>
                )}
                {!data && <NoDataPresent />}
            </Container>
        </>
    );
}

export default withRouter(BudgetAssumption);
