//Mensagens de success, warning, error
export const messages = {
    "success":{
        "forms":{
            "saved": "Seu formulário foi salvo com sucesso"
        },
        "color": "green"
    },
    "warning":{
        "forms":{
            "error": "Formulário salvo com alertas"
        },
        "color": "yellow"
    },
    "danger":{
        "forms":{
            "error": "Houve um erro ao salvar o formulário"
        },
        "color": "red"
    }
}