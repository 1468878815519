import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../../companyoverview.module.css';
import CurrencyInput from '../../../../../CurrencyInput';

function MonthTotal(props) {
    const {
        values: { companyValueChainBudget },
        setFieldValue,
      } = useFormikContext();
      const [field, meta] = useField(props);
      React.useEffect(() => {
        if(companyValueChainBudget[props.index]["companyValueChainBudget"].length){
            let month = companyValueChainBudget[props.index]["companyValueChainBudget"][props.subindex]["companyValueChainBudget"][props.month];
            let total = month && month.unit && month.price && month.unit !== "" && month.price !== "" ? month.unit * month.price : 0;
            setFieldValue(props.name, total);
        }

      }, [companyValueChainBudget[props.index]["companyValueChainBudget"][props.subindex]["companyValueChainBudget"][props.month]?.unit, companyValueChainBudget[props.index]["companyValueChainBudget"][props.subindex]["companyValueChainBudget"][props.month].price]);
    
      return (
        <>
          <CurrencyInput className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
          {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
      );
}

export default MonthTotal
