import React from 'react';

export default function FormButton({type, disabled=false, text}) {
    return (
        <button 
            type={type}
            disabled={disabled}
            className={`rounded p-1 w-100 border-0`}
            style={{backgroundColor: '#0B5189', color: 'white', fontWeight: '700'}}
        >
            {text}
        </button>
    )
}
