import React, {useEffect, useState} from 'react';
import {Row} from "react-bootstrap";
import {Table} from 'react-bootstrap';

function SupplierPowerNegotiationGraphic(props) {
    const [high, setHigh] = useState([]);
    const [medium, setMedium] = useState([]);
    const [low, setLow] = useState([]);
    const [selected, setSelected] = useState([]);


    useEffect(()=>{
        const powerProps = props.data || [];
        console.log(powerProps);
        const highData = powerProps.filter(item=>item.marketDynamicPower === "H");
        const mediumData = powerProps.filter(item=>item.marketDynamicPower === "M");
        const lowData = powerProps.filter(item=>item.marketDynamicPower === "L");
        const tamHigh = highData.length;
        const tamMedium = mediumData.length;
        const tamLow = lowData.length;
        if(tamHigh >= tamMedium && tamHigh >= tamLow){
            setSelected(highData);
        }else if(tamMedium >= tamHigh && tamMedium >= tamLow){
            setSelected(mediumData);
        }else{
            setSelected(lowData);
        }

        setHigh(highData);
        setMedium(mediumData);
        setLow(lowData);

    }, [props.data]);

    return (
        <>
            <div className="justify-content-between align-items-center card__header--home">
                <h6 className="mb-0">{props.title}</h6>
            </div>

            <hr />
            <Row className="p-4">
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Alto</th>
                            <th>Médio</th>
                            <th>Baixo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selected.length > 0 &&
                            selected.map((item, index)=>{
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            {/*High*/}
                                            <td>{high.length > 0 && high[index] ? high[index].marketDynamicSupplier : ""}</td>
                                            {/*Medium*/}
                                            <td>{medium?.length > 0 && medium[index] ? medium[index].marketDynamicSupplier : ""}</td>
                                            {/*Low*/}
                                            <td>{low?.length > 0 && low[index] ? low[index].marketDynamicSupplier : ""}</td>
                                        </tr>
                                    )
                                })
                            }
                    </tbody>
                </Table>
            </Row>

        </>
    );
}

export default SupplierPowerNegotiationGraphic;