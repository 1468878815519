import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import {
    intialMacroEnvDynamic,
    MacroEnvSchema,
} from "./helpers/formValidation";
import { Formik, Form } from "formik";
import FormFieldArray from "../components/FormFieldArray";
import { withRouter, Prompt } from "react-router-dom";
import { getQuestions, postAnswers } from "../../../services/formData";
import useSpinner from "../../spinner/useSpinner";
import { ToastContainer, toast } from "react-toastify";
import { messages } from "../../../services/system-messages/systemMessages";
import Page from "../../Page";
import Button from "../../Button";
import FormValidationError from "../components/FormValidationError";
import PrivateComponent from "../../PrivateComponent";
import permission from "../../../enums/permission";
import { useAuth } from "../../../providers/AuthProvider";

function MacroEnv(props) {
    const [spinner, showSpinner, hideSpinner] = useSpinner();
    const [formValues, setFormValues] = useState(intialMacroEnvDynamic);
    const [group, setGroup] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [changed, setChanged] = useState(false);
    const { getAccessToken } = useAuth();

    useEffect(() => {
        showSpinner();
        let isSubscribe = true;
        const loadData = async () => {
            if (isSubscribe) {
                const macroForms = props.forms.length
                    ? props.forms.filter(
                          (hit) => hit.id === props.formSelected
                      )[0]
                    : [];
                const groupId = macroForms?.FormType?.MainGroups[0].id;
                setGroup(groupId);

                if (groupId) {
                    const accessToken = await getAccessToken();
                    getQuestions(
                        props.formSelected,
                        groupId,
                        0,
                        (err, questions) => {
                            if ((questions && questions.error) || err) {
                                hideSpinner();
                                console.log(err);
                                props.history.push("/warning"); //Mostrando página de servidor fora do ar
                            } else {
                                if (isSubscribe) {
                                    let initialValues = {
                                        ...intialMacroEnvDynamic,
                                        ...questions,
                                    };
                                    setFormValues(initialValues);
                                }
                                hideSpinner();
                            }
                        }
                    );
                }
            }
        };
        loadData();
        return () => {
            isSubscribe = false;
        };
    }, [props.formSelected]);

    async function componentSubmit(values, groupId) {
        setChanged(false);
        const accessToken = await getAccessToken();
        postAnswers(
            props.formSelected,
            groupId,
            values,
            (err, response) => {
                if ((response && response.error) || err) {
                    console.log(err);
                    toast.error(messages.danger.forms.error);
                } else {
                    console.log(response);
                    toast.success(messages.success.forms.saved);
                }
                setSubmitting(false);
            },
            accessToken
        );
    }

    return (
        <Page>
            <PrivateComponent
                permission={permission.FORM_TYPE.MACRO_ENVIROMENTAL_FORCES}
            >
                {spinner}
                <Prompt
                    when={changed}
                    message="Voce alterou o formulário. Seus dados serão perdidos. :( Gostaria de continuar sem salvar ?"
                />
                <ToastContainer />
                <Formik
                    initialValues={formValues}
                    validationSchema={MacroEnvSchema}
                    validate={(values) => {
                        setChanged(values !== formValues);
                    }}
                    onSubmit={(values) => {
                        setSubmitting(true);
                        componentSubmit(values, group);
                    }}
                    enableReinitialize
                >
                    {({ values, errors, touched, isSubmitting }) => (
                        <Form className="p-4">
                            <Row className="py-4">
                                <Col xs={12} lg={4} className="border-right">
                                    <h4 className="text-muted font-weight-light text-right mr-3">
                                        Forças Macro Ambientais
                                    </h4>
                                </Col>
                                <Col>
                                    <Row>
                                        <Col xs={6}>
                                            <FormFieldArray
                                                name="macroEnvPolitics"
                                                values={values}
                                                title="Políticas"
                                                field="macroEnvPolitics"
                                                holder="Entre com a variavel macro ambiental de origem política"
                                                errors={errors}
                                                touched={touched}
                                                type="text"
                                                lessThenOne={false}
                                                butonAddText="Adicionar"
                                            />
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row>
                                        <Col xs={6}>
                                            <FormFieldArray
                                                name="macroEnvEconomy"
                                                values={values}
                                                title="Economicas"
                                                field="macroEnvEconomy"
                                                holder="Entre com a variavel macro ambiental de origem economica"
                                                errors={errors}
                                                touched={touched}
                                                type="text"
                                                lessThenOne={false}
                                                butonAddText="Adicionar"
                                            />
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row>
                                        <Col xs={6}>
                                            <FormFieldArray
                                                name="macroEnvCulture"
                                                values={values}
                                                title="Culturais"
                                                field="macroEnvCulture"
                                                holder="Entre com a variavel macro ambiental de origem Culturais"
                                                errors={errors}
                                                touched={touched}
                                                type="text"
                                                lessThenOne={false}
                                                butonAddText="Adicionar"
                                            />
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row>
                                        <Col xs={6}>
                                            <FormFieldArray
                                                name="macroEnvTechnology"
                                                values={values}
                                                title="Tecnologicas"
                                                field="macroEnvTechnology"
                                                holder="Entre com a variavel macro ambiental de origem tecnologicas"
                                                errors={errors}
                                                touched={touched}
                                                type="text"
                                                lessThenOne={false}
                                                butonAddText="Adicionar"
                                            />
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row>
                                        <Col xs={6}>
                                            <FormFieldArray
                                                name="macroEnvDemography"
                                                values={values}
                                                title="Demográficas"
                                                field="macroEnvDemography"
                                                holder="Entre com a variavel macro ambiental de origem demográfica"
                                                errors={errors}
                                                touched={touched}
                                                type="text"
                                                lessThenOne={false}
                                                butonAddText="Adicionar"
                                            />
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row>
                                        <Col xs={6}>
                                            <FormFieldArray
                                                name="macroEnvNature"
                                                values={values}
                                                title="Naturais"
                                                field="macroEnvNature"
                                                holder="Entre com a variavel macro ambiental de origem naturais"
                                                errors={errors}
                                                touched={touched}
                                                type="text"
                                                lessThenOne={false}
                                                butonAddText="Adicionar"
                                            />
                                        </Col>
                                    </Row>

                                    <hr />

                                    <Row className="p-0 m-0 mt-3 justify-content-end">
                                        {Object.keys(errors).length > 0 && (
                                            <FormValidationError
                                                errors={errors}
                                            />
                                        )}
                                    </Row>

                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup>
                                                <Button
                                                    variant="success"
                                                    disabled={submitting}
                                                    type="submit"
                                                >
                                                    Salvar informações
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </PrivateComponent>
        </Page>
    );
}

export default withRouter(MacroEnv);
