import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../../companyoverview.module.css';
import CurrencyInput from '../../../../../CurrencyInput';

function AmortizationDepreciationTotalizer({index, children, original, ...props}) {
    const {
        values: { companyValueChainAmortizationDepreciation },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    React.useEffect(() => {
        let entries = Object.values(companyValueChainAmortizationDepreciation[index][original][0][original]);
        const total = entries.reduce((acc, el)=>acc + parseInt(el), 0);
        setFieldValue(props.name, total ? total : 0);
    }, [companyValueChainAmortizationDepreciation[index][original]]);

    return (
        <>
            <div className={`${css.input_label}`}><label htmlFor={props.name}>{children}</label></div>
            <CurrencyInput disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default AmortizationDepreciationTotalizer