import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../../companyoverview.module.css';
import CurrencyInput from '../../../../../CurrencyInput';

function MainMonthTotalizers({month, index, ...props}) {
    const {
        values: { companyValueChainBudget },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    React.useEffect(() => {
        let entries = companyValueChainBudget[0].companyValueChainBudget;
        entries = entries.map(el=>(el.companyValueChainBudget)).map(el=>el[month]);
        const total = entries.reduce((acc, el)=>acc + (el.margin * el.unit), 0);
        setFieldValue(props.name, total ? total : 0);

    }, [companyValueChainBudget[0].companyValueChainBudget]);
    return (
        <>
            <CurrencyInput disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default MainMonthTotalizers