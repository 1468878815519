import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import { formatCurrency } from '../../../../utils/formatCurrency';
import HeaderTable from "./HeadTable";

function BudgetTable({margin, totDesp, amortDep, handleTotal}){
    const [depre, setDepre] = useState(false);
    const [amort, setAmort] = useState(false);
    const [totalDesp, setTotalDesp] = useState(false);

    useEffect(()=>{
        if(Object.keys(amortDep).length){
            const depreObj = amortDep.companyValueChainDepreciation[0].companyValueChainDepreciation
            setDepre(depreObj);
            const amortObj = amortDep.companyValueChainAmortization[0].companyValueChainAmortization
            setAmort(amortObj);
        }

        if(Object.keys(totDesp).length){
            setTotalDesp(totDesp);
        }

    }, [totDesp, amortDep, margin])

    const calculoLucroLiquido = (month)=>{
        let total;
        if(Object.keys(margin).length && depre && amort){
            total = margin[month].totalMargin - totalDesp[month] - parseFloat(depre[month]) - parseFloat(amort[month]);
        }
        return formatCurrency(total ? total : 0);
    }

    const calculoTotal = ()=>{
        const total = (margin?.total ? margin.total : 0) - totalDesp.totalAnual - handleTotal(depre, null) - handleTotal(amort, null)
        return formatCurrency(total ? total : 0);
    }

    return (
        <>
            <tr>
                <td valign="middle">Lucro Líquido</td>
                <td></td>
                <td align="right">{calculoLucroLiquido("jan")}</td>
                <td align="right">{calculoLucroLiquido("fev")}</td>
                <td align="right">{calculoLucroLiquido("mar")}</td>
                <td align="right">{calculoLucroLiquido("abr")}</td>
                <td align="right">{calculoLucroLiquido("mai")}</td>
                <td align="right">{calculoLucroLiquido("jun")}</td>
                <td align="right">{calculoLucroLiquido("jul")}</td>
                <td align="right">{calculoLucroLiquido("ago")}</td>
                <td align="right">{calculoLucroLiquido("set")}</td>
                <td align="right">{calculoLucroLiquido("out")}</td>
                <td align="right">{calculoLucroLiquido("nov")}</td>
                <td align="right">{calculoLucroLiquido("dez")}</td>
                <td align="right">{calculoTotal()}</td>
            </tr>
        </>
    )
}

export default BudgetTable;