import React from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import { Field, ErrorMessage, FieldArray } from "formik";
import css from "../strategic-position/companyoverview.module.css";
import Button from "../../Button";
import { MdAdd } from "react-icons/md";

function FormFieldArray(props) {
    return (
        <FieldArray name={props.name}>
            {({ remove, push }) => (
                <FormGroup className={props.className}>
                    {props.nolabel ? null : (
                        <label className={`${css.input_label}`}>
                            {props.title}
                        </label>
                    )}
                    {props.values[props.name]?.length > 0 &&
                        props.values[props.name].map((csh, index) => (
                            <Row className="mb-2" key={index}>
                                <Col
                                    className=""
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <div className="d-flex">
                                        <Field
                                            className={`w-100 rounded border form-field ${
                                                props.errors[props.name] &&
                                                props.errors[props.name][
                                                    index
                                                ] &&
                                                props.touched[props.name] &&
                                                props.touched[props.name][index]
                                                    ? css.input_error
                                                    : ""
                                            }`}
                                            name={`${props.name}[${index}][${props.field}]`}
                                            placeholder={props.holder}
                                            type={props.type}
                                        />
                                        <Button
                                            variant="outline-danger"
                                            type="button"
                                            className="ml-2"
                                            onClick={() => {
                                                if (props.lessThenOne) {
                                                    remove(index);
                                                } else {
                                                    if (
                                                        props.values[props.name]
                                                            .length > 1
                                                    ) {
                                                        remove(index);
                                                    }
                                                }
                                            }}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </div>
                                    <ErrorMessage
                                        name={`${props.name}[${index}][${props.field}]`}
                                        component="div"
                                        className={`field-error ${css.error_message}`}
                                    />
                                </Col>
                            </Row>
                        ))}
                    <Button
                        type="button"
                        variant="outline-success"
                        onClick={() => {
                            let dataSend = {};
                            dataSend[props.name] = "";
                            push(dataSend);
                        }}
                    >
                        <MdAdd fontSize="20" className="mr-1" />
                        {props.butonAddText}
                    </Button>
                </FormGroup>
            )}
        </FieldArray>
    );
}

export default FormFieldArray;
