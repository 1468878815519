import React, { useCallback, useEffect, useState } from 'react';
import { Badge, ListGroup, Nav, Alert } from 'react-bootstrap';
import classNames from 'classnames';
import { toast } from "react-toastify";

import Page from '../../components/Page';

import { MdAdd, MdDelete, MdEdit, MdPersonAdd, MdWarning } from 'react-icons/md';
import SideModal from '../../components/SideModal';
import GroupForm from '../../components/UserGroupForm';
import CircleButton from '../../components/CircleButton';
import UserList from '../../components/UserList';
import Button from '../../components/Button';

import { getGroups, getUserList, createGroup, editGroup, deleteGroup, addUsersToGroup, deleteUsersInGroup, editGroupPermissions, inviteUser } from '../../services/userGroupService';

import useSpinner from '../../components/spinner/useSpinner';
import PermissionList from '../../components/PermissionList';
import { getPermissionList } from '../../services/permissionService';
import UserAddToGroupForm from '../../components/UserAddToGroupForm';
import UserInviteForm from '../../components/UserInviteForm';
import PrivateComponent from '../../components/PrivateComponent';
import { useAuth } from '../../providers/AuthProvider';
import { useUserContext } from '../../providers/UserProvider';

import styles from './styles.module.css';

const modalEnum = {
  IDLE: 'idle',
  ADD_GROUP: 'addGroup',
  EDIT_GROUP: 'editGroup',
  DELETE_GROUP: 'deleteGroup',
  INVITE_USER: 'inviteUser',
  ADD_USER_TO_GROUP: 'addUserToGroup',
  REMOVE_USER_FROM_GROUP: 'removeUser',
  EDIT_GROUP_PERMISSIONS: 'editGroupPermission'
}

const tabEnum = {
  USER: 'user',
  PERMISSION: 'permission',
}

function UserManagment() {
  const [modalState, setModalState] = useState(modalEnum.IDLE);
  const [groups, setGroups] = useState([]);
  const [userList, setUserList] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [activeGroup, setActiveGroup] = useState(null);
  const [activeTab, setActiveTab] = useState(tabEnum.USER);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [invitedUser, setInvitedUser] = useState({});

  const { getAccessToken } = useAuth();
  const { fetchUserData } = useUserContext();

  const [loader, showLoader, hideLoader] = useSpinner();

  function findSelectedGroup() {
    return groups.find(group => group.id === activeGroup);
  }

  async function onSubmitNewGroup(values) {
    showLoader();
    setModalState(modalEnum.IDLE);

    const accessToken = await getAccessToken()
    createGroup(values, accessToken)
      .then(() => init())
      .then(() => hideLoader())
  }

  async function onSubmitEditGroup(values) {
    showLoader();
    setModalState(modalEnum.IDLE);

    const accessToken = await getAccessToken();
    editGroup(values, activeGroup, accessToken)
      .then(() => init())
      .then(() => hideLoader());
  }

  async function onSubmitDeleteGroup() {
    showLoader();
    setModalState(modalEnum.IDLE);

    const accessToken = await getAccessToken();
    setActiveGroup(null);

    deleteGroup(activeGroup, accessToken)
      .then(() => init())
      .then(() => hideLoader());
  }

  async function onSubmitAddUsers(group) {
    showLoader();
    setModalState(modalEnum.IDLE);

    const accessToken = await getAccessToken();

    const payload = {
      users: selectedUsers.map((user) => user.id)
    }

    addUsersToGroup(payload, group.id, accessToken)
      .then(() => init())
      .then(() => hideLoader());
  }

  async function onSubmitRemoveUsers(group) {
    showLoader();
    setModalState(modalEnum.IDLE);

    const accessToken = await getAccessToken();

    const payload = {
      users: selectedUsers.map((user) => user.id)
    }

    deleteUsersInGroup(payload, group.id, accessToken)
      .then(() => init())
      .then(() => hideLoader());
  }

  async function onSubmitEditPermissions(group) {
    showLoader();
    setModalState(modalEnum.IDLE);

    const accessToken = await getAccessToken();

    const payload = {
      permissions: selectedPermissions.map((permission) => permission.id)
    }

    editGroupPermissions(payload, group.id, accessToken)
      .then(() => init())
      .then(() => fetchUserData())
      .then(() => hideLoader());
  }

  async function onSubmitInviteUser(user) {
    showLoader();
    setModalState(modalEnum.IDLE)
    setInvitedUser(user);
    
    const accessToken = await getAccessToken();

    inviteUser(user, accessToken).then(() => setModalState(modalEnum.IDLE))
      .then(() => setInvitedUser({}))
      .then(() => init())
      .then(() => hideLoader())
      .catch((error) => { 
        setModalState(modalEnum.INVITE_USER)
        if (error.response) {
          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error('Ocorreu um erro ao enviar convite', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        hideLoader()
      })
  }

  function handleAddUsers(selected) {
    setSelectedUsers(selected);
    if (selected.length > 0) {
      setModalState(modalEnum.ADD_USER_TO_GROUP)
    }
  }

  function handleRemoveUsers(selected) {
    setSelectedUsers(selected);
    if (selected.length > 0) {
      setModalState(modalEnum.REMOVE_USER_FROM_GROUP)
    }
  }

  function handleSavePermissions(selected) {
    console.log(selected);
    setSelectedPermissions(selected);
    setModalState(modalEnum.EDIT_GROUP_PERMISSIONS)
  }

  const init = useCallback(async () => {
    const accessToken = await getAccessToken();

    setSelectedUsers([]);
    setSelectedPermissions([]);
    setModalState(modalEnum.IDLE);

    return Promise.all([
      getUserList(accessToken),
      getPermissionList(accessToken),
      getGroups(accessToken),
    ]).then(([userList, permissionsList, groups]) => { 
      setGroups(groups.data);
      setUserList(userList.data);
      setPermissionsList(permissionsList.data);
    });
  }, [getAccessToken]);

  useEffect(() => {
    showLoader();
    init().then(() => hideLoader());
  }, [init, hideLoader, showLoader]);

  return (
    <Page>
      <PrivateComponent isAdmin={true}>
        { loader }
        <div className="d-flex h-100">
          <div className="border-right bg-light py-2">
            <ListGroup variant="flush" className={styles['groups-list']}>
              <ListGroup.Item className="d-flex justify-content-between align-items-center bg-light">
                <h5>Grupos</h5>
                <CircleButton 
                  onClick={() => setModalState(modalEnum.ADD_GROUP)}
                >
                  <MdAdd size="20"/>
                </CircleButton>
              </ListGroup.Item>
              <ListGroup.Item onClick={() => setActiveGroup(null)} action className="d-flex justify-content-between align-items-center bg-light">
                <span>Todos usuários</span>
                <Badge pill variant="info" className="ml-5">{userList.length}</Badge>
              </ListGroup.Item>
              {groups.map((group) => (
                <ListGroup.Item key={group.id} onClick={() => setActiveGroup(group.id)} action className="d-flex justify-content-between align-items-center bg-light" title={group.name}>
                  <span className={styles['group-name']}>{group.name}</span>
                  <Badge pill variant="info" className="ml-1">{group.users.length}</Badge>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>

          { activeGroup ? (
            <div className="flex-fill py-3 px-4 d-flex flex-column">
              <div className="d-flex align-items-center">
                <h3>{findSelectedGroup()?.name}</h3>
                { activeGroup && (
                  <>
                    <button 
                      className="ml-3 text-secondary p-1 border-0 bg-light rounded-circle d-flex justify-content-center align-items-center" 
                      style={{ width: '30px', height: '30px'}}
                      onClick={() => setModalState(modalEnum.EDIT_GROUP)}
                      title="Editar grupo"
                    >
                      <MdEdit size="18"/>
                    </button>
                    <button 
                      className="ml-2 text-danger p-1 border-0 bg-light rounded-circle d-flex justify-content-center align-items-center" 
                      style={{ width: '30px', height: '30px'}}
                      onClick={() => setModalState(modalEnum.DELETE_GROUP)}
                      title="Apagar grupo"
                    >
                      <MdDelete size="18"/>
                    </button>
                  </>
                )}
              </div>
    
              <hr className="mx-0" />
    
              <p className="mb-4">{findSelectedGroup()?.description}</p>    

              <Nav justify variant="tabs" className="mb-4">
                <Nav.Item className={classNames('nav--tab', { 'nav--tab-active': activeTab === tabEnum.USER})}>
                  <Nav.Link onClick={() => setActiveTab(tabEnum.USER)}>Usuários</Nav.Link>
                </Nav.Item>
                <Nav.Item className={classNames('nav--tab', { 'nav--tab-active': activeTab === tabEnum.PERMISSION})}>
                  <Nav.Link onClick={() => setActiveTab(tabEnum.PERMISSION)}>Permissões</Nav.Link>
                </Nav.Item>
              </Nav>

              { activeTab === tabEnum.USER && (
                <UserList users={findSelectedGroup()?.users} onRemoveFromGroup={handleRemoveUsers} />
              )}

              { activeTab === tabEnum.PERMISSION && (
                <PermissionList 
                  groupPermissions={findSelectedGroup()?.permissions} 
                  permissionsTree={permissionsList.permissionTree} 
                  permissionsList={permissionsList.permissionList} 
                  onSubmit={handleSavePermissions} 
                />
              )}
    
            </div>
          ) : (
            <div className="flex-fill py-3 px-4 d-flex flex-column">
              <div className="d-flex align-items-center">
                <h3>Todos usuários</h3>
                { activeGroup && (
                  <>
                    <button 
                      className="ml-3 text-secondary p-1 border-0 bg-light rounded-circle d-flex justify-content-center align-items-center" 
                      style={{ width: '30px', height: '30px'}}
                      onClick={() => setModalState(modalEnum.EDIT_GROUP)}
                      title="Editar grupo"
                    >
                      <MdEdit size="18"/>
                    </button>
                    <button 
                      className="ml-2 text-danger p-1 border-0 bg-light rounded-circle d-flex justify-content-center align-items-center" 
                      style={{ width: '30px', height: '30px'}}
                      onClick={() => setModalState(modalEnum.DELETE_GROUP)}
                      title="Apagar grupo"
                    >
                      <MdDelete size="18"/>
                    </button>
                  </>
                )}
                <Button 
                  className="d-inline-flex justify-content-center align-items-center ml-auto" 
                  variant="outline-success"
                  onClick={() => setModalState(modalEnum.INVITE_USER)}
                >
                  <MdPersonAdd className="mr-2" />
                  Convidar usuário
                </Button>
              </div>

              <hr className="mx-0" />

              <p className="mb-4">Este grupo é padrão criado automaticamente para a listagem de todos os usuários da empresa. É neste grupo que novos usuários se encontrarão ao se cadastrarem na empresa.</p>

              <UserList users={userList} onAddToGroup={handleAddUsers}/>

            </div>
          )}
        </div>

          <SideModal show={modalState === modalEnum.ADD_GROUP} title="Criar novo grupo" onClose={() => setModalState(modalEnum.IDLE)}>
            <p className="text-muted">
              Use o formulário abaixo para criar um novo grupo de usuários para sua empresa. Você pode adicionar permissões a este grupo e seus usuários.
            </p>
            <GroupForm onSubmit={onSubmitNewGroup}/>
          </SideModal>

          <SideModal show={modalState === modalEnum.EDIT_GROUP} title="Alterar grupo" onClose={() => setModalState(modalEnum.IDLE)}>
            <GroupForm edit={true} values={findSelectedGroup()} onSubmit={onSubmitEditGroup}/>
          </SideModal>

          <SideModal show={modalState === modalEnum.DELETE_GROUP} title="Apagar grupo" onClose={() => setModalState(modalEnum.IDLE)}>
            <p className="mb-0">Tem certeza que dejesa apagar o grupo <span className="font-weight-bold">"{findSelectedGroup()?.name}"</span>?</p>
            <Alert variant="danger" className="d-flex align-items-center my-4">
              <MdWarning className="mr-2"/> Atenção. Essa ação é irreversível!
            </Alert>
            <div>
              <Button variant="danger" className="mr-2" onClick={onSubmitDeleteGroup}>
                Apagar grupo
              </Button>
              <Button variant="outline-secondary" onClick={() => setModalState(modalEnum.IDLE)}>
                Cancelar
              </Button>
            </div>
          </SideModal>

          <SideModal show={modalState === modalEnum.ADD_USER_TO_GROUP} title="Adicionar usuários a um grupo" onClose={() => setModalState(modalEnum.IDLE)}>
            <UserAddToGroupForm 
              groups={groups} 
              selectedUsers={selectedUsers} 
              onSubmit={onSubmitAddUsers} 
              onDismiss={() => setModalState(modalEnum.IDLE)}
            />
          </SideModal>

          <SideModal show={modalState === modalEnum.REMOVE_USER_FROM_GROUP} title={`Remover usuário do grupo "${findSelectedGroup()?.name}"`} onClose={() => setModalState(modalEnum.IDLE)}>
            <Alert variant="warning">
              <p>
                Você irá remover do grupo "{findSelectedGroup()?.name}" os seguintes usuários:
              </p>
              <ul>
                {selectedUsers.map((user) => (
                  <li key={user.id}>{`${user.firstName} ${user.lastName} (${user.username})`}</li>
                ))}
              </ul>
            </Alert>
            <div className="mt-4">
              <Button variant="warning" className="mr-2" onClick={() => onSubmitRemoveUsers(findSelectedGroup())}>
                Remover usuários
              </Button>
              <Button variant="outline-secondary" onClick={() => setModalState(modalEnum.IDLE)}>
                Cancelar
              </Button>
            </div>
          </SideModal>

          <SideModal show={modalState === modalEnum.EDIT_GROUP_PERMISSIONS} title={`Alterar permissões do grupo "${findSelectedGroup()?.name}"`} onClose={() => setModalState(modalEnum.IDLE)}>
            <Alert variant="warning">
              <p>
                Você irá alterar as permissões do grupo "{findSelectedGroup()?.name}" para as seguintes:
              </p>
              <ul>
                {selectedPermissions.length > 0 ? selectedPermissions.map((permission) => (
                  <li key={permission.id}>{permission.name}</li>
                )) : (
                  <li>Nenhuma</li>
                )}
              </ul>
            </Alert>
            <div className="mt-4">
              <Button variant="warning" className="mr-2" onClick={() => onSubmitEditPermissions(findSelectedGroup())}>
                Alterar permissões
              </Button>
              <Button variant="outline-secondary" onClick={() => setModalState(modalEnum.IDLE)}>
                Cancelar
              </Button>
            </div>
          </SideModal>

          <SideModal show={modalState === modalEnum.INVITE_USER} title="Convidar novo usuário" onClose={() => setModalState(modalEnum.IDLE)}>
            <UserInviteForm 
              onSubmit={onSubmitInviteUser}
              values={invitedUser}
            />
          </SideModal>
      </PrivateComponent>
    </Page>
  );
}

export default UserManagment;