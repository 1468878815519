import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    marginSection: {
        marginLeft: 28,
    },
    paragraph: {
        fontSize: 11,
        marginLeft: 12,
        marginBottom: 8,
    },
    subTitle: {
        fontSize: 12,
        color: "#1e0f70",
        marginBottom: 4,
    },
    anotherTitle: {
        fontSize: 13,
        textAlign: "center",
        marginBottom: 16,
    },
    line: {
        width: "90%",
        margin: "auto",
        borderWidth: 1,
        borderColor: "rgb(204, 193, 193)",
        borderStyle: "dashed",
        marginTop: 8,
        marginBottom: 16,
    },
    containerCard: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        paddingRight: 128,
        paddingLeft: 128,
    },
    anotherContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 8,
    },
    dFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
    },
    value: {
        marginLeft: 4,
        fontSize: 12,
    },
    footer: {
        marginLeft: 24,
    },
    marketContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
    },
    marginLot: {
        marginBottom: 48,
    },
});

const SPGEsgClient = ({ generalStrategicPositionProp }) => {
    return (
        <>
            <Text style={styles.anotherTitle}>Sobre o cliente</Text>
            <View style={styles.marginSection}>
                <Text style={styles.subTitle}>Qual perfil do cliente ?</Text>
                {generalStrategicPositionProp.companyClientProfile.map(
                    (item, index) => (
                        <Text key={index} style={styles.paragraph}>
                            - {item.companyClientProfile}
                        </Text>
                    )
                )}
                <Text style={styles.subTitle}>
                    Quais os problemas do cliente que vamos resolver?
                </Text>
                <Text style={styles.paragraph}>
                    {generalStrategicPositionProp.companyTargetProblem}
                </Text>
                <Text style={styles.subTitle}>
                    Quais as necessidades que estamos atendendendo?
                </Text>
                <Text style={styles.paragraph}>
                    {generalStrategicPositionProp.companyMainNeeds}
                </Text>
            </View>
            <View style={styles.line}></View>

            <Text break style={styles.anotherTitle}>
                Sobre o negócio
            </Text>
            <View style={styles.marginSection}>
                <Text style={styles.subTitle}>Quais produtos e serviços ?</Text>
                {generalStrategicPositionProp.companyProduct.map(
                    (item, index) => (
                        <Text style={styles.paragraph} key={index}>
                            - {item.companyProduct}
                        </Text>
                    )
                )}
                <Text style={styles.subTitle}>
                    Quais são as vantagens competitivas ?
                </Text>
                {generalStrategicPositionProp.companyCompatetivesAdvantages.map(
                    (item, index) => (
                        <Text style={styles.paragraph} key={index}>
                            - {item.companyCompatetivesAdvantages}
                        </Text>
                    )
                )}
                <Text style={styles.subTitle}>Diferencial Competitivo</Text>
                <Text style={styles.paragraph}>
                    {generalStrategicPositionProp.companyValueProposition}
                </Text>
                <Text style={styles.subTitle}>Modelo de negocio</Text>
                <Text style={styles.paragraph}>
                    {generalStrategicPositionProp.companyBusinessModel[0]}
                </Text>
                <Text style={styles.subTitle}>
                    Como agregar valor aos clientes e acionistas?
                </Text>
                <Text style={styles.paragraph}>
                    {generalStrategicPositionProp.companyWayToAggregate}
                </Text>
                <Text style={styles.subTitle}>Objetivos Estratégicos</Text>
                {generalStrategicPositionProp.strategicGoals.map(
                    (item, index) => (
                        <Text style={styles.paragraph} key={index}>
                            - {item.strategicGoals}
                        </Text>
                    )
                )}
            </View>
        </>
    );
};

export default SPGEsgClient;
