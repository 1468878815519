import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
    intialCompetitionDynamic,
    CompetitionDynamicSchema,
} from "./helpers/formValidation";
import { Formik, Form } from "formik";
import FormButton from "../../components/FormButton";
import FormFieldArray from "../../components/FormFieldArray";
import DirectCompetitors from "./components/DirectCompetitors";
import IndirectCompetitors from "./components/IndirectCompetitors";
import SalesForecast from "./components/SalesForecast";
import GrowthForecast from "./components/GrowthForecast";
import Benckmark from "./components/Benckmark";
import RivalryLevel from "./components/RivalryLevel";
import Button from "../../../Button";
import FormValidationError from "../../components/FormValidationError";
import FormInput from "../../components/FormInput";
import css from "./competitiondynamic.module.css";

function CompetitionDynamic(props) {
    const [formValues, setFormValues] = useState(intialCompetitionDynamic);

    useEffect(() => {
        let initialValues = { ...intialCompetitionDynamic, ...props.questions };
        setFormValues(initialValues);
    }, [props.questions]);

    return (
        <Formik
            initialValues={formValues}
            validationSchema={CompetitionDynamicSchema}
            validate={(values) => {
                props.setChanged(values !== formValues);
            }}
            onSubmit={(values) => {
                props.setSubmitting(true);
                props.competitionSubmit(values, props.group);
            }}
            enableReinitialize
        >
            {({ values, errors, touched }) => (
                <Form className="p-4">
                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Competidores Diretos
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <DirectCompetitors
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Competidores Indiretos
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <IndirectCompetitors
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    {/* <Row className="py-4"> */}
                    <Col xs={12} lg={12} className="border-right">
                        <h4 className="text-muted font-weight-light text-center">
                            Matriz SWOT
                        </h4>
                    </Col>
                    <div className={`${css.gridContainer}`}>
                        <div className={`${css.gridItem}`}></div>
                        <div className={`${css.gridItem}`}></div>
                        <div className={`${css.Fraqueza}`}>
                            <div className={`bg-secondary p-2`}>
                                <h6 className="text-white m-0 p-0 text-center">
                                    Análise Interna
                                </h6>
                            </div>
                            <div
                                className={`bg-info d-flex justify-content-around`}
                            >
                                <h6 className="text-white m-0 p-0 text-center">
                                    Forças (Pontos Fortes)
                                </h6>
                                <h6 className="text-white m-0 p-0 text-center">
                                    Fraquezas( Pontos Fracos)
                                </h6>
                            </div>
                            <Row>
                                <Col className="pr-0">
                                    <FormFieldArray
                                        name="marketDynamicStrengths"
                                        values={values}
                                        // title=""
                                        field="marketDynamicStrengths"
                                        holder="Entre com sua força"
                                        errors={errors}
                                        touched={touched}
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar Forças"
                                        nolabel={true}
                                    />
                                </Col>
                                <Col className="pl-0">
                                    <FormFieldArray
                                        name="marketDynamicWeaknesses"
                                        values={values}
                                        field="marketDynamicWeaknesses"
                                        holder="Entre com suas Fraquezas"
                                        errors={errors}
                                        touched={touched}
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar Fraquezas"
                                        nolabel={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className={`${css.a} `}>
                            <div
                                className={`justify-content-end bg-secondary ${css.identification_column}`}
                            >
                                <div className="text-center text-white ">
                                    <span>Análise Externa</span>
                                </div>
                            </div>
                        </div>
                        <div className={`${css.gridItem}`}>
                            <div
                                className={`bg-info d-flex justify-content-around`}
                            >
                                <h6 className="text-white m-0 p-0 text-center">
                                    Oportunidades
                                </h6>
                            </div>
                            <FormFieldArray
                                name="marketDynamicOpportunities"
                                values={values}
                                field="marketDynamicOpportunities"
                                holder="Entre com sua oportunidade"
                                errors={errors}
                                touched={touched}
                                type="text"
                                lessThenOne={false}
                                butonAddText="Adicionar Oportunidades"
                                nolabel={true}
                            />
                        </div>
                        <div className={`${css.Ataque}`}>
                            <div
                                className={`bg-info d-flex justify-content-around`}
                            >
                                <h6 className="text-white m-0 p-0 text-center">
                                    Estratégia de Ataque
                                </h6>
                                <h6 className="text-white m-0 p-0 text-center">
                                    Estratégia de Crescimento
                                </h6>
                            </div>
                            <Row>
                                <Col className="pr-0">
                                    <FormFieldArray
                                        name="marketDynamicAttack"
                                        values={values}
                                        field="marketDynamicAttack"
                                        holder="Entre com o ataque"
                                        errors={errors}
                                        touched={touched}
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar ataque"
                                        nolabel={true}
                                    />
                                </Col>
                                <Col className="pl-0">
                                    <FormFieldArray
                                        name="marketDynamicGrowth"
                                        values={values}
                                        field="marketDynamicGrowth"
                                        holder="Entre com o crescimento"
                                        errors={errors}
                                        touched={touched}
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar crescimento"
                                        nolabel={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className={`${css.gridItem}`}>
                            <div
                                className={`bg-info d-flex justify-content-around`}
                            >
                                <h6 className="text-white m-0 p-0 text-center">
                                    Ameaças
                                </h6>
                            </div>
                            <FormFieldArray
                                name="marketDynamicThreats"
                                values={values}
                                title="Ameaças"
                                field="marketDynamicThreats"
                                holder="Entre com a ameaça"
                                errors={errors}
                                touched={touched}
                                type="text"
                                lessThenOne={false}
                                butonAddText="Adicionar Ameaças"
                                nolabel={true}
                            />
                        </div>
                        <div className={`${css.manutencao}`}>
                            <div
                                className={`bg-info d-flex justify-content-around`}
                            >
                                <h6 className="text-white m-0 p-0 text-center">
                                    Estratégia de Manutenção
                                </h6>
                                <h6 className="text-white m-0 p-0 text-center">
                                    Estratégia de Sobrevivência
                                </h6>
                            </div>
                            <Row>
                                <Col className="pr-0">
                                    <FormFieldArray
                                        name="marketDynamicMaint"
                                        values={values}
                                        field="marketDynamicMaint"
                                        holder="Entre com a manutenção"
                                        errors={errors}
                                        touched={touched}
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar Manutenção"
                                        nolabel={true}
                                    />
                                </Col>
                                <Col className="pl-0">
                                    <FormFieldArray
                                        name="marketDynamicSurvival"
                                        values={values}
                                        field="marketDynamicSurvival"
                                        holder="Entre com a sobrevivência"
                                        errors={errors}
                                        touched={touched}
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar sobrevivência"
                                        nolabel={true}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Previsão da demanda por segmento
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <SalesForecast
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Previsão de crescimento do segmento (%)
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <GrowthForecast
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Nível de rivalidade por segmento
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <RivalryLevel
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Comparativo de Mercado - Benchmark
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <Benckmark
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>

                            <Row className="p-0 m-0 mt-3 justify-content-end">
                                {Object.keys(errors).length > 0 && (
                                    <FormValidationError errors={errors} />
                                )}
                            </Row>

                            <Row>
                                <Col>
                                    <Button
                                        variant="success"
                                        disabled={props.submitting}
                                        type="submit"
                                    >
                                        Salvar informações
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default CompetitionDynamic;
