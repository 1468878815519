import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import FormInput from "../../components/FormInput";
import {
    intialFundamentalsValues,
    CompanyFundamentalsSchema,
} from "./helpers/formValidation";
import Button from "../../../Button";
import FormValidationError from "../../components/FormValidationError";
import css from "../companyoverview.module.css";

function AdmFundamentals(props) {
    const [formValues, setFormValues] = useState(intialFundamentalsValues);

    useEffect(() => {
        let initialValues = { ...intialFundamentalsValues, ...props.questions };
        setFormValues(initialValues);
    }, [props.questions]);

    return (
        <Formik
            initialValues={formValues}
            validationSchema={CompanyFundamentalsSchema}
            validate={(values) => {
                props.setChanged(values !== formValues);
            }}
            onSubmit={(values) => {
                props.setSubmitting(true);
                props.fundamentalsSubmit(values, props.group);
            }}
            enableReinitialize
        >
            {({ values, errors, touched }) => (
                <Form className="p-4">
                    <Col xs={12} lg={12} className="border-bottom w-70 mb-3">
                        <h4 className="text-muted font-weight-light text-center mr-3">
                            Proposta de Valor Única
                        </h4>
                    </Col>
                    <Col>
                        <Col
                            xs={12}
                            lg={12}
                            className="d-flex align-items-center"
                        >
                            <div
                                className={`bg-secondary rounded-left  d-flex align-items-center  pl-2 mr-1 ${css.left_table}`}
                            >
                                <h6 className="text-white m-0 p-0">PARA</h6>
                            </div>
                            <div className="w-100">
                                <FormInput
                                    name="companyLiders"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                    holder="Para"
                                ></FormInput>
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            lg={12}
                            className="d-flex align-items-center"
                        >
                            <div
                                className={`bg-secondary rounded-left d-flex align-items-center pl-2 mr-1 ${css.left_table}`}
                            >
                                <h6 className="text-white m-0 p-0">
                                    QUE PRECISA
                                </h6>
                            </div>
                            <div className="w-100">
                                <FormInput
                                    name="companyProfileTeam"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                    holder="Que precisa"
                                ></FormInput>
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            lg={12}
                            className="d-flex align-items-center"
                        >
                            <div
                                className={`bg-secondary rounded-left d-flex align-items-center pl-2 mr-1 ${css.left_table}`}
                            >
                                <h6 className="text-white m-0 p-0">E QUEREM</h6>
                            </div>
                            <div className="w-100">
                                <FormInput
                                    name="companyKnowledgeRequired"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                    holder="E Querem"
                                ></FormInput>
                            </div>
                        </Col>

                        <Col
                            xs={12}
                            lg={12}
                            className="d-flex align-items-center"
                        >
                            <div
                                className={`bg-secondary rounded-left d-flex align-items-center pl-2 mr-1 ${css.left_table}`}
                            >
                                <h6 className="text-white m-0 p-0">A</h6>
                            </div>
                            <div className="w-100">
                                <FormInput
                                    name="companyMethod"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                    holder="a"
                                ></FormInput>
                            </div>
                        </Col>

                        <Col
                            xs={12}
                            lg={12}
                            className="d-flex align-items-center"
                        >
                            <div
                                className={`bg-secondary rounded-left d-flex align-items-center pl-2 mr-1 ${css.left_table}`}
                            >
                                <h6 className="text-white m-0 p-0">É</h6>
                            </div>
                            <div className="w-100">
                                <FormInput
                                    name="companyStructure"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                    holder="a"
                                ></FormInput>
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            lg={12}
                            className="d-flex align-items-center"
                        >
                            <div
                                className={`bg-secondary rounded-left d-flex align-items-center pl-2 mr-1 ${css.left_table}`}
                            >
                                <h6 className="text-white m-0 p-0">QUE</h6>
                            </div>
                            <div className="w-100">
                                <FormInput
                                    name="what"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                    holder="a"
                                ></FormInput>
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            lg={12}
                            className="d-flex align-items-center"
                        >
                            <div
                                className={`bg-secondary rounded-left d-flex align-items-center pl-2 mr-1 ${css.left_table}`}
                            >
                                <h6 className="text-white m-0 p-0">AO INVÉS</h6>
                            </div>
                            <div className="w-100">
                                <FormInput
                                    name="instead"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                    holder="a"
                                ></FormInput>
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            lg={12}
                            className="d-flex align-items-center"
                        >
                            <div
                                className={`bg-secondary rounded-left d-flex align-items-center pl-2 mr-1 ${css.left_table}`}
                            >
                                <h6 className="text-white m-0 p-0">
                                    NOSSO SERVIÇO
                                </h6>
                            </div>
                            <div className="w-100">
                                <FormInput
                                    name="ourService"
                                    type="text"
                                    errors={errors}
                                    touched={touched}
                                    holder="a"
                                ></FormInput>
                            </div>
                        </Col>

                        <Row className="p-0 m-0 mt-3 justify-content-end">
                            {Object.keys(errors).length > 0 && (
                                <FormValidationError errors={errors} />
                            )}
                        </Row>

                        <Row>
                            <Col xs={12} lg={6} className="ml-3">
                                <Button
                                    variant="success"
                                    disabled={props.submitting}
                                    type="submit"
                                >
                                    Salvar informações
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Form>
            )}
        </Formik>
    );
}

export default AdmFundamentals;
