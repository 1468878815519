import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    marginSection: {
        marginLeft: 28,
    },
    paragraph: {
        fontSize: 11,
        marginLeft: 12,
        marginBottom: 6,
    },
    subTitle: {
        fontSize: 12,
        color: "#1e0f70",
        marginBottom: 4,
    },
    anotherTitle: {
        fontSize: 13,
        textAlign: "center",
        marginBottom: 12,
    },
    line: {
        width: "90%",
        margin: "auto",
        borderWidth: 1,
        borderColor: "rgb(204, 193, 193)",
        borderStyle: "dashed",
        marginTop: 4,
        marginBottom: 8,
    },
});

const renderInfos = (title, infos) => {
    return (
        <>
            <Text style={styles.anotherTitle}>{title}</Text>
            <View>
                <Text style={styles.subTitle}>Ambiental</Text>
                {infos[0].map((item, index) => (
                    <Text style={styles.paragraph} key={index}>
                        -{" "}
                        {item.companyValueChainEnvironmental
                            ? item.companyValueChainEnvironmental
                            : "Não Informado"}
                    </Text>
                ))}
                <Text style={styles.subTitle}>Social</Text>
                {infos[1].map((item, index) => (
                    <Text style={styles.paragraph} key={index}>
                        -{" "}
                        {item.companyValueChainSocial
                            ? item.companyValueChainSocial
                            : "Não Informado"}
                    </Text>
                ))}
                <Text style={styles.subTitle}>Governança</Text>
                {infos[2].map((item, index) => (
                    <Text style={styles.paragraph} key={index}>
                        -{" "}
                        {item.companyValueChainGovernance
                            ? item.companyValueChainGovernance
                            : "Não Informado"}
                    </Text>
                ))}
            </View>
        </>
    );
};

const ESGSupplyChain = ({ rhESG, marketingESG, vendasESG }) => (
    <View style={styles.marginSection}>
        {renderInfos("Recursos Humanos", rhESG)}
        <View style={styles.line}></View>

        {renderInfos("Marketing", marketingESG)}
        <View style={styles.line}></View>

        {renderInfos("Vendas e Serviços", vendasESG)}
        <View style={styles.line}></View>
    </View>
);

export default ESGSupplyChain;
