import React from "react";
import {Col, Row} from "react-bootstrap";
import FormInput from "../../../../components/FormInput";
import AmortizationDepreciationTotalizer from '../components/AmortizationDepreciationTotalizer'
import FormInputDebounce from "../../../../components/FormInputDebounce";
const indexName = "companyValueChainAmortizationDepreciation";

function AmortizationDepreciationProjection({values, index, name, title}){
    return (
        <>
            <label className="mt-3">{title}</label>
            <hr/>
            <Row className="p-0 m-0">
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].jan`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['jan']}
                    >
                        Jan
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].fev`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['fev']}
                    >
                        Fev
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].mar`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['mar']}
                    >
                        Mar
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].abr`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['abr']}
                    >
                        Abr
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].mai`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['mai']}
                    >
                        Mai
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].jun`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['jun']}
                    >
                        Jun
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].jul`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['jul']}
                    >
                        Jul
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].ago`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['ago']}
                    >
                        Ago
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].set`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['set']}
                    >
                        Set
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].out`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['out']}
                    >
                        Out
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].nov`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['nov']}
                    >
                        Nov
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 m-0">
                    <FormInputDebounce
                        name={`[${indexName}][${index}][${name}][0][${name}].dez`}
                        type="currency"
                        values={values[indexName][index][name][0][name]['dez']}
                    >
                        Dez
                    </FormInputDebounce>
                </Col>
                <Col className="p-0 p-0 m-0">
                    <AmortizationDepreciationTotalizer
                        name={`[${indexName}][${index}][${name}][0].total`}
                        original={name}
                        index={index}
                    >
                        Total
                    </AmortizationDepreciationTotalizer>
                </Col>
            </Row>
        </>
    )
}

export default AmortizationDepreciationProjection;