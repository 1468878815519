import * as Yup from 'yup';

export const intialCompetitorDynamic ={
    marketDynamicBarrierEntranceLevel: "",
    marketDynamicBarrierEntranceLevelComment: "",
    marketDynamicBusinessAtractiveness:"",
    marketDynamicBusinessAtractivenessComment: "",
    marketDynamicBusinessComplementary: "",
    marketDynamicBusinessComplementaryComment: "",
    marketDynamicStrategyMinimizeRisk: "",
    marketDynamicStrategyMinimizeRiskComment: ""
}

export const CompetitorDynamicSchema = Yup.object().shape({
    marketDynamicBarrierEntranceLevel: Yup.string(),        
    marketDynamicBarrierEntranceLevelComment: Yup.string()
        .min(10, 'Muito curto!')
        .max(500, 'Muito Longo'),        
    marketDynamicBusinessAtractiveness: Yup.string(),        
    marketDynamicBusinessAtractivenessComment: Yup.string()
        .min(10, 'Muito curto!')
        .max(500, 'Muito Longo'),        
    marketDynamicBusinessComplementary: Yup.string(),        
    marketDynamicBusinessComplementaryComment: Yup.string()
        .min(10, 'Muito curto!')
        .max(500, 'Muito Longo'),        
    marketDynamicStrategyMinimizeRisk: Yup.string()
        .min(10, 'Muito curto!')
        .max(500, 'Muito Longo'),        
    marketDynamicStrategyMinimizeRiskComment: Yup.string()
        .min(10, 'Muito curto!')
        .max(500, 'Muito Longo'),
});