import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useThemeContext } from "../../providers/ThemeProvider";
import Menu from "../Menu";
import Sidebar from "../Sidebar";
import { useQuestionForms } from "../../providers/QuestionFormsProvider";
import styles from "./styles.module.css";

function Page({ children, withSideBar = true, withMenu = true }) {
    const { sidebarCollapsed, setSidebarCollapsed } = useThemeContext();
    const { types, forms, formSelected, setFormSelected } = useQuestionForms();

    const [strategicFormSelected, setStrategicFormSelected] = useState(0);
    const [dynamicFormSelected, setDynamicFormSelected] = useState(0);
    const [macroFormSelected, setMacroFormSelected] = useState(0);
    const [businessFormSelected, setBusinessFormSelected] = useState(0);
    function togleSideBar() {
        setSidebarCollapsed((prev) => !prev);
        localStorage.setItem("sidebar-collapsed", !sidebarCollapsed);
    }

    // Testar aqui
    useEffect(() => {
        if (!forms || !types) {
            return;
        }

        const sortedTypes = types?.sort((a, b) => a.id - b.id);

        setStrategicFormSelected(
            forms.find((form) => form.formTypeId === sortedTypes[0].id).id
        );

        setDynamicFormSelected(
            forms.find((form) => form.formTypeId === sortedTypes[1].id).id
        );

        setMacroFormSelected(
            forms.find((form) => form.formTypeId === sortedTypes[2].id).id
        );

        setBusinessFormSelected(
            forms.find((form) => form.formTypeId === sortedTypes[3].id).id
        );
    }, [strategicFormSelected, dynamicFormSelected, types, forms]);

    return (
        <div className={styles.container}>
            <ToastContainer />
            {withSideBar && <Sidebar collapsed={sidebarCollapsed} />}
            <div className="flex-fill d-flex flex-column">
                {withMenu && (
                    <Menu
                        toggleSideBar={withSideBar ? togleSideBar : null}
                        strategicFormSelected={strategicFormSelected}
                        dynamicFormSelected={dynamicFormSelected}
                        macroFormSelected={macroFormSelected}
                        businessFormSelected={businessFormSelected}
                        forms={forms}
                    />
                )}
                <main className="flex-fill" style={{ overflowX: "hidden" }}>
                    {children}
                </main>
            </div>
        </div>
    );
}

export default Page;
