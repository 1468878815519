import React, {useEffect, useState} from 'react';
import { formatCurrency } from '../../../../utils/formatCurrency';

function BudgetTable({handleTotal, amortDep, title}){
    const [depre, setDepre] = useState(false);
    const [amort, setAmort] = useState(false);

    useEffect(()=>{
        if(Object.keys(amortDep).length){
            const depreObj = amortDep.companyValueChainDepreciation[0].companyValueChainDepreciation;
            setDepre(depreObj);
            const amortObj = amortDep.companyValueChainAmortization[0].companyValueChainAmortization;
            setAmort(amortObj);
        }
    }, [amortDep])

    return (
        <>
            <tr>
                <td valign="middle" rowSpan="2">{title}</td>
                <td>Depreciação</td>
                <td align="right">{formatCurrency(depre ? depre.jan : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.fev : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.mar : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.abr : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.mai : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.jun : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.jul : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.ago : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.set : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.out : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.nov : 0)}</td>
                <td align="right">{formatCurrency(depre ? depre.dez : 0)}</td>
                <td align="right">{formatCurrency(handleTotal(depre ? depre : {}, null))}</td>
            </tr>
            <tr>
                <td>Amortização</td>
                <td align="right">{formatCurrency(amort ? amort.jan : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.fev : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.mar : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.abr : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.mai : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.jun : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.jul : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.ago : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.set : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.out : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.nov : 0)}</td>
                <td align="right">{formatCurrency(amort ? amort.dez : 0)}</td>
                <td align="right">{formatCurrency(handleTotal(amort ? amort : {}, null))}</td>
            </tr>
        </>
    )
}

export default BudgetTable;