import React from 'react';
import {FieldArray, Field, ErrorMessage} from 'formik';
import {Row, Col, FormGroup, Card} from 'react-bootstrap';
import css from '../../marketdynamicoverview.module.css';
import FormInput from '../../../components/FormInput';
import Button from '../../../../Button';
import { MdAdd } from 'react-icons/md';
import FormSelect from '../../../../FormSelect';

function SupplierNegotiationPower({values, errors, touched}) {
    return (
        <FieldArray name="marketDynamicSupplierNegotiationPower">
            {({remove, unshift, form})=>(
                <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                    <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                        <Button
                            type="button"
                            variant="outline-success"
                            className={`w-100 h-100`}
                            onClick={()=> unshift({marketDynamicSupplier: "", marketDynamicPower: ""})}
                            >
                            <MdAdd fontSize={24} />
                        </Button>
                    </Col>                    
                    {values.marketDynamicSupplierNegotiationPower?.length > 0 &&
                        values.marketDynamicSupplierNegotiationPower.map((md, index)=>(
                            <Col xs={4} sm={4} md={4} lg={4} xl={4} key={index}>
                                <Card className="p-3">
                                    <FormGroup>
                                        <FormInput
                                            name={`marketDynamicSupplierNegotiationPower[${index}].marketDynamicSupplier`}
                                            type="text"
                                            original="marketDynamicSupplier"
                                            errors={form.errors?.marketDynamicSupplierNegotiationPower && form.errors?.marketDynamicSupplierNegotiationPower.length && form.errors?.marketDynamicSupplierNegotiationPower[index] ? form.errors.marketDynamicSupplierNegotiationPower[index] : ""}
                                            touched={form.touched?.marketDynamicSupplierNegotiationPower && form.touched?.marketDynamicSupplierNegotiationPower.length && form.touched?.marketDynamicSupplierNegotiationPower[index] ? form.touched.marketDynamicSupplierNegotiationPower[index] : ""}
                                            holder="Entre com o fornecedor" >
                                            Fornecedor
                                        </FormInput>
                                    </FormGroup>

                                    <FormSelect 
                                        name={`marketDynamicSupplierNegotiationPower[${index}].marketDynamicPower`} 
                                        id={`marketDynamicSupplierNegotiationPower[${index}].marketDynamicPower`} 
                                        label="Poder de Negociação"
                                    >
                                        <option value="H">High</option>    
                                        <option value="M">Medium</option>    
                                        <option value="L">Low</option> 
                                    </FormSelect>

                                    <FormGroup>
                                        <FormInput
                                            name={`marketDynamicSupplierNegotiationPower[${index}].marketDynamicComment`}
                                            as="textarea"
                                            rows={3}
                                            original="marketDynamicComment"
                                            errors={form.errors?.marketDynamicSupplierNegotiationPower && form.errors?.marketDynamicSupplierNegotiationPower.length && form.errors?.marketDynamicSupplierNegotiationPower[index] ? form.errors.marketDynamicSupplierNegotiationPower[index] : ""}
                                            touched={form.touched?.marketDynamicSupplierNegotiationPower && form.touched?.marketDynamicSupplierNegotiationPower.length && form.touched?.marketDynamicSupplierNegotiationPower[index] ? form.touched.marketDynamicSupplierNegotiationPower[index] : ""}
                                            holder="Comentários sobre o fornecedor" >
                                            Comentários
                                        </FormInput>
                                    </FormGroup>

                                    <FormGroup>
                                        <Button 
                                                variant="outline-danger" 
                                                className={`mt-2 w-100`} 
                                                type="button"
                                                onClick={()=>{
                                                    if( values.marketDynamicSupplierNegotiationPower.length > 1){
                                                        remove(index)
                                                    }
                                                }}
                                            >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </FormGroup>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            )}
        </FieldArray>
    )
}

export default SupplierNegotiationPower
