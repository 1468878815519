import React, {useState, useEffect, useCallback} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CompetitionDynamic from './competition-dynamic/CompetitionDynamic';
import CostumerDynamic from './customer-dynamic/CostumerDynamic';
import SupplierDynamic from './supplier-dynamic/SupplierDynamic';
import ProductDynamic from './product-dynamic/ProductDynamic';
import CompetitorDynamic from './competitor-dynamic/CompetitorDynamic';
import {Prompt, withRouter} from 'react-router-dom';
import useSpinner from "../../spinner/useSpinner";
import {getQuestions, postAnswers} from "../../../services/formData";
import {ToastContainer, toast} from "react-toastify";
import {messages} from "../../../services/system-messages/systemMessages";
import Page from '../../Page';
import PrivateComponent from '../../PrivateComponent';
import permissionEnum from '../../../enums/permission';
import PrivateTab from '../../PrivateTab';
import {useAuth} from "../../../providers/AuthProvider";

function MarketDynamicOverview(props) {
    const [tabIndex, setTabIndex] = useState(0);
    const [menu, setMenu] = useState([]);
    const [spinner, showSpinner, hideSpinner] = useSpinner();
    const [questions, setQuestions] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [changed, setChanged] = useState(false);
    const { getAccessToken } = useAuth();

    useEffect(()=>{
        let isSubscribe = true;
        showSpinner();
        const loadData = async ()=>{
            if(isSubscribe && props.formSelected !== null && props.forms !== null){
                console.log(props.formSelected);
                const marketDynamicForms = props.forms.length ? props.forms.filter(hit=>hit.id === props.formSelected)[0] : [];
                let groupId = 0;
                if(marketDynamicForms){
                    marketDynamicForms.FormType?.MainGroups?.sort((a,b)=>a.menuOrder - b.menuOrder);
                    groupId = marketDynamicForms?.FormType?.MainGroups[0].id; //Recuperando o primeiro formulário
                    if(groupId) setMenu(marketDynamicForms.FormType.MainGroups);
                }

                if(groupId !== 0){
                    const accessToken = await getAccessToken();
                    getQuestions(props.formSelected, groupId, 0, (err, questions)=>{
                        if((questions && questions.error) || err){
                            console.log(err);
                            hideSpinner();
                            props.history.push("/warning");
                        }else{
                            if(isSubscribe){
                                setQuestions(questions);
                                setTabIndex(0);
                                hideSpinner();
                            }
                        }
                    });
                }
            }
        }
        loadData();
        return ()=>{isSubscribe = false};
        }, [props.formSelected, props.forms]);

    async function componentSubmit(values, groupId){
        const accessToken = await getAccessToken();
        postAnswers(props.formSelected, groupId, values, (err, response)=>{
            if((response && response.error) || err){
                console.log(err);
                toast.error(messages.danger.forms.error);
            }else{
                console.log(response);
                toast.success(messages.success.forms.saved);
            }
            setSubmitting(false);
            setChanged(false);
        }, accessToken);
    }

    function handleSelectQuestion(group, index){
        if(changed){
            const response = window.confirm("Voce alterou o formulário. Seus dados serão perdidos. :( Gostaria de continuar sem salvar ?");
            if(!response){
                setTabIndex(tabIndex);
            }else{
                setChanged(false);
                setTabIndex(index);
                loadingTabQuestions(group);
            }
        }else{
            setTabIndex(index);
            loadingTabQuestions(group);
        }

    }

    function loadingTabQuestions(group){
        setQuestions({});
        console.log(props.formSelected, group);
        showSpinner();
        getQuestions(props.formSelected, group, 0, (err, questions)=>{
            if((questions && questions.error) || err){
                console.log(err);
                hideSpinner();
                props.history.push("/warning");
            }else{
                setQuestions(questions);
                hideSpinner();
            }
        });
    }

    return (
        <Page>
            {spinner}
            <Prompt
                when={changed}
                message="Voce alterou o formulário. Seus dados serão perdidos. :( Gostaria de continuar sem salvar ?"
            />
            <ToastContainer />
            <Container className="p-0" fluid>
                <Tabs selectedIndex={tabIndex} onSelect={()=>{}}>
                    <TabList>
                        <PrivateTab permission={permissionEnum.MAIN_GROUP.COMPETITION_DYNAMIC}>
                            <Tab
                                onClick={()=> handleSelectQuestion(menu[0].id, 0)}>
                                {menu.length > 0 && menu[0] ? menu[0].title : "Loading..."}
                            </Tab>
                        </PrivateTab>
                        <PrivateTab permission={permissionEnum.MAIN_GROUP.COSTUMER_DYNAMIC}>
                            <Tab
                                onClick={()=>handleSelectQuestion(menu[1].id, 1)}>
                                {menu.length > 0 && menu[1] ? menu[1].title : "Loading..."}
                            </Tab>
                        </PrivateTab>
                        <PrivateTab permission={permissionEnum.MAIN_GROUP.SUPPLIER_DYNAMIC}>
                            <Tab
                                onClick={()=>handleSelectQuestion(menu[2].id, 2)}>
                                {menu.length > 0 && menu[2] ? menu[2].title : "Loading..."}
                            </Tab>
                        </PrivateTab>
                        <PrivateTab permission={permissionEnum.MAIN_GROUP.NEW_PRODUCTS}>
                            <Tab
                                onClick={()=>handleSelectQuestion(menu[3].id, 3)}>
                                {menu.length > 0 && menu[3] ? menu[3].title : "Loading..."}
                            </Tab>
                        </PrivateTab>
                        <PrivateTab permission={permissionEnum.MAIN_GROUP.NEW_COMPETITORS}>
                            <Tab
                                onClick={()=>handleSelectQuestion(menu[4].id, 4)}>
                                {menu.length > 0 && menu[4] ? menu[4].title : "Loading..."}
                            </Tab>
                        </PrivateTab>
                    </TabList>

                    <TabPanel>
                        <PrivateComponent permission={permissionEnum.MAIN_GROUP.COMPETITION_DYNAMIC}>
                            <CompetitionDynamic
                                submitting={submitting}
                                setSubmitting={setSubmitting}
                                group={menu[0]?.id}
                                questions={questions}
                                competitionSubmit={componentSubmit}
                                setChanged={setChanged}
                            />
                        </PrivateComponent>
                    </TabPanel>
                    <TabPanel>
                        <PrivateComponent permission={permissionEnum.MAIN_GROUP.COSTUMER_DYNAMIC}>
                            <CostumerDynamic
                                submitting={submitting}
                                setSubmitting={setSubmitting}
                                group={menu[1]?.id}
                                questions={questions}
                                costumerSubmit={componentSubmit}
                                setChanged={setChanged}
                            />
                        </PrivateComponent>
                    </TabPanel>
                    <TabPanel>
                        <PrivateComponent permission={permissionEnum.MAIN_GROUP.SUPPLIER_DYNAMIC}>
                            <SupplierDynamic
                                submitting={submitting}
                                setSubmitting={setSubmitting}
                                group={menu[2]?.id}
                                questions={questions}
                                supplierSubmit={componentSubmit}
                                setChanged={setChanged}
                            />
                        </PrivateComponent>
                    </TabPanel>
                    <TabPanel>
                        <PrivateComponent permission={permissionEnum.MAIN_GROUP.NEW_PRODUCTS}>
                            <ProductDynamic
                                submitting={submitting}
                                setSubmitting={setSubmitting}
                                group={menu[3]?.id}
                                questions={questions}
                                productSubmit={componentSubmit}
                                setChanged={setChanged}
                            />
                        </PrivateComponent>
                    </TabPanel>
                    <TabPanel>
                        <PrivateComponent permission={permissionEnum.MAIN_GROUP.NEW_COMPETITORS}>
                            <CompetitorDynamic
                                submitting={submitting}
                                setSubmitting={setSubmitting}
                                group={menu[4]?.id}
                                questions={questions}
                                competitorSubmit={componentSubmit}
                                setChanged={setChanged}
                            />
                        </PrivateComponent>
                    </TabPanel>
                </Tabs>
            </Container>
        </Page>
    )
}

export default withRouter(MarketDynamicOverview)
