import React, {useEffect, useState} from 'react';
import SegmentGraphic from "./SegmentGraphic";

function PowerNegotiationGraphic(props) {
    const [segments, setSegments] = useState([]);
    const [segmentSelect, setSegmentSelect] = useState(0);
    const [segmentOne, setSegmentOne] = useState({});

    useEffect(()=>{
        const segmentsProps = props.data || [];
        setSegments(segmentsProps);
        let segment = segmentsProps.filter((hit, index)=>index === segmentSelect);
        if(segment?.length){
            segment = segment[0]
        }
        setSegmentOne(segment);

    }, [props.data]);

    const handleGraphChange = (ev)=>{
        setSegmentSelect(ev.target.value);
        let segment = segments.filter((hit, index)=>index === parseInt(ev.target.value));
        if(segment?.length > 0){
            segment =segment[0];
        }
        console.log(segment);
        setSegmentOne(segment);
    }

    return (
        <>
            <div className="justify-content-between align-items-center card__header--home">
                <h6 className="mb-0">{props.title}</h6>

                <select onChange={handleGraphChange} className="select_input rounded">
                    {segments.length > 0 &&
                        segments.map((item, index)=>{
                            return <option key={index} value={index}>{item.marketDynamicSegment}</option>
                        })
                    }
                </select>
            </div>

            <hr />

            <SegmentGraphic
                index="marketDynamicCustomer"
                indexShare="marketDynamicShare"
                data={segmentOne}
            />
        </>
    );
}

export default PowerNegotiationGraphic;