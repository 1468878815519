import * as Yup from 'yup';

export const initialProductDynamicValues = {
    marketDynamicSubstituteProduct:[
        {
            marketDynamicSegment: "",
            marketDynamicProducts: [
                {
                    marketDynamicProduct: "",
                    marketDynamicShare: 0
                }
            ],
        }
    ],
    marketDynamicAdvantageDisavantages:[
        {
            marketDynamicProduct:"",
            marketDynamicAdvantage: "",
            marketDynamicDisavantages: ""
        }
    ],
    marketDynamicElementValueProduct:[
        {
            marketDynamicProduct:"",
            marketDynamicValue: "",
        }
    ],
    marketDynamicNewProductRisk:[
        {
            marketDynamicSegment: "",
            marketDynamicProduct:"",
            marketDynamicRisk: ""
        }
    ],
    marketDynamicStrategyMinimizeRisk: ""

};

export const ProductDynamicSchema = Yup.object().shape({
    marketDynamicSubstituteProduct: Yup.array()
    .of(
        Yup.object().shape(
            {
                marketDynamicSegment: Yup.string()
                .min(4, "Muito curto!")
                .max(40, "Muito Longo!"),                
                marketDynamicProducts: Yup.array()
                .of(
                    Yup.object().shape(
                        {
                            marketDynamicProduct: Yup.string()
                            .min(4, "Muito curto!")
                            .max(40, "Muito Longo!"),                            
                            marketDynamicShare: Yup.number()
                        }
                    )
                )
            }
        )
    ),
    marketDynamicAdvantageDisavantages: Yup.array()
    .of(
        Yup.object().shape(
            {
                marketDynamicProduct: Yup.string()
                .min(4, "Muito curto!")
                .max(40, "Muito Longo!"),                
                marketDynamicAdvantage: Yup.string()
                .min(10, "Muito curto!")
                .max(200, "Muito Longo!"),                
                marketDynamicDisavantages: Yup.string()
                .min(10, "Muito curto!")
                .max(200, "Muito Longo!"),                
            }
        )
    ),
    marketDynamicElementValueProduct: Yup.array()
    .of(
        Yup.object().shape(
            {
                marketDynamicProduct: Yup.string()
                .min(4, "Muito curto!")
                .max(40, "Muito Longo!"),                
                marketDynamicValue: Yup.string()
                .min(10, "Muito curto!")
                .max(200, "Muito Longo!"),                
            }
        )
    ),
    marketDynamicNewProductRisk: Yup.array()
    .of(
        Yup.object().shape(
            {
                marketDynamicSegment: Yup.string()
                .min(4, "Muito curto!")
                .max(40, "Muito Longo!"),                
                marketDynamicProduct: Yup.string()
                .min(10, "Muito curto!")
                .max(40, "Muito Longo!"),                
                marketDynamicRisk: Yup.string(),                
            }
        )
    ),
    marketDynamicStrategyMinimizeRisk: Yup.string()
    .min(4, "Muito curto!")
    .max(200, "Muito Longo!"),    
});