import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../../companyoverview.module.css';
import CurrencyInput from '../../../../../CurrencyInput';

function GeneralTotalizer({index, ...props}) {
    const {
        values: { companyValueChainBudget },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    React.useEffect(() => {
        let entries = Object.values(companyValueChainBudget[index].total);
        entries = entries.map(el=>(el.totalMargin));
        const total = entries.reduce((acc, el)=>acc + el, 0);
        setFieldValue(props.name, total ? total : 0);

    }, [companyValueChainBudget[index]]);

    return (
        <>
            <CurrencyInput disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default GeneralTotalizer