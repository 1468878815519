import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../companyoverview.module.css';
import CurrencyInput from '../../../../CurrencyInput';

function GeneralTotalizer({index, original, ...props}) {
    const {
        values: { companyValueChainCostExpenses },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    React.useEffect(() => {
        if(companyValueChainCostExpenses[index]){
            const item = companyValueChainCostExpenses[index][original][original];
            if(item?.length){
                const totalValues = item.map(item=>(item['companyValueChainTotal']));
                const total = totalValues.reduce((acc, el)=>acc + parseInt(el), 0);
                setFieldValue(props.name, total ? total : 0);

            }else{
                setFieldValue(props.name, 0)
            }
        }else{
            setFieldValue(props.name, 0);
        }

    }, [companyValueChainCostExpenses[index][original][original]]);
    return (
        <>
            <CurrencyInput disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default GeneralTotalizer