import React, {useState, useEffect} from 'react';
import {Col, ProgressBar} from "react-bootstrap";
import {Row} from 'react-bootstrap';

import Button from '../Button';

const genData = () => ({
    title: "",
    percent: 0
})

function Departments({data, handleLoadData, lastUpdate}) {
    const [rhData, setRhData] = useState(genData());
    const [marketingData, setMarketingData] = useState(genData());
    const [servicesData, setServicesData] = useState(genData());
    const [opData, setOpData] = useState(genData());
    const [supplyData, setSupplyData] = useState(genData());
    const [tecData, setTecData] = useState(genData());
    const [financialData, setFinancialData] = useState(genData());

    useEffect(()=>{
        if(data.length){
            setRhData(data.find(hit=>hit.menuOrder === 1));
            setMarketingData(data.find(hit=>hit.menuOrder === 2));
            setServicesData(data.find(hit=>hit.menuOrder === 3));
            setOpData(data.find(hit=>hit.menuOrder === 4));
            setSupplyData(data.find(hit=>hit.menuOrder === 5));
            setTecData(data.find(hit=>hit.menuOrder === 6));
            setFinancialData(data.find(hit=>hit.menuOrder === 7));
        }
    }, [data]);

    return (
        <div className="border rounded p-4 bg-white">
            <Row className="p-0 m-0 justify-content-between card__header--home">
                <div className="mb-2">
                    <h6 className="mb-0">Preenchimento dos departamentos</h6>
                    <span className="text-muted" style={{fontSize: 'var(--font-size-sm)'}}>última atualização: </span>
                    <span className="text-muted mr-2" style={{fontSize: 'var(--font-size-sm)'}}>
                        {lastUpdate}
                    </span>    
                </div>
                
                <div>
                    <Button variant="muted" onClick={handleLoadData}><i className="fas fa-sync-alt" /></Button>
                </div>
            </Row>
            <hr/>
            <div>
                <div className="mb-3">
                    <h6 className="mb-1">RH</h6>
                    <div className="d-flex text-right align-items-center">
                        <ProgressBar style={{ height: '8px' }} className="flex-grow-1" variant="success" now={rhData.percent}/>
                        <span className="text-muted" style={{ fontSize: 'var(--font-size-sm)', width: '48px' }}>{Math.round(rhData.percent)}%</span>
                    </div>
                </div>
                <div className="mb-3">
                    <h6 className="mb-1">Marketing</h6>
                    <div className="d-flex text-right align-items-center">
                        <ProgressBar style={{ height: '8px' }} className="flex-grow-1" variant="success" now={marketingData.percent}/>
                        <span className="text-muted" style={{ fontSize: 'var(--font-size-sm)', width: '48px' }}>{Math.round(marketingData.percent)}%</span>
                    </div>
                </div>
                <div className="mb-3">
                    <h6 className="mb-1">Serviços e produtos</h6>
                    <div className="d-flex text-right align-items-center">
                        <ProgressBar style={{ height: '8px' }} className="flex-grow-1" variant="success" now={servicesData.percent}/>
                        <span className="text-muted" style={{ fontSize: 'var(--font-size-sm)', width: '48px' }}>{Math.round(servicesData.percent)}%</span>
                    </div>
                </div>
                <div className="mb-3">
                    <h6 className="mb-1">Operações</h6>
                    <div className="d-flex text-right align-items-center">
                        <ProgressBar style={{ height: '8px' }} className="flex-grow-1" variant="success" now={opData.percent}/>
                        <span className="text-muted" style={{ fontSize: 'var(--font-size-sm)', width: '48px' }}>{Math.round(opData.percent)}%</span>
                    </div>
                </div>
                <div className="mb-3">
                    <h6 className="mb-1">Cadeia de abastecimento</h6>
                    <div className="d-flex text-right align-items-center">
                        <ProgressBar style={{ height: '8px' }} className="flex-grow-1" variant="success" now={supplyData.percent}/>
                        <span className="text-muted" style={{ fontSize: 'var(--font-size-sm)', width: '48px' }}>{Math.round(supplyData.percent)}%</span>
                    </div>
                </div>
                <div className="mb-3">
                    <h6 className="mb-1">Tecnologia</h6>
                    <div className="d-flex text-right align-items-center">
                        <ProgressBar style={{ height: '8px' }} className="flex-grow-1" variant="success" now={tecData.percent}/>
                        <span className="text-muted" style={{ fontSize: 'var(--font-size-sm)', width: '48px' }}>{Math.round(tecData.percent)}%</span>
                    </div>
                </div>
                <div className="mb-3">
                    <h6 className="mb-1">Finanças e governanças</h6>
                    <div className="d-flex text-right align-items-center">
                        <ProgressBar style={{ height: '8px' }} className="flex-grow-1" variant="success" now={financialData.percent}/>
                        <span className="text-muted" style={{ fontSize: 'var(--font-size-sm)', width: '48px' }}>{Math.round(financialData.percent)}%</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Departments;