import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    marginSection: {
        marginLeft: 28,
    },
    paragraph: {
        fontSize: 11,
        marginLeft: 12,
        marginBottom: 8,
    },
    subTitle: {
        fontSize: 12,
        color: "#1e0f70",
        marginBottom: 4,
    },
    anotherTitle: {
        fontSize: 13,
        textAlign: "center",
        marginBottom: 16,
    },
    line: {
        width: "90%",
        margin: "auto",
        borderWidth: 1,
        borderColor: "rgb(204, 193, 193)",
        borderStyle: "dashed",
        marginTop: 8,
        marginBottom: 16,
    },
    dFlex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
    },
    value: {
        marginLeft: 4,
        fontSize: 12,
    },
    footer: {
        marginLeft: 2,
    },
    marketContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
    },
});

const StrategicPositionGeneral = ({ generalStrategicPositionProp }) => {
    return (
        <>
            <Text style={styles.anotherTitle}>Sobre a empresa</Text>

            <Text style={styles.subTitle}>Razão Social</Text>
            <Text style={styles.paragraph}>
                {generalStrategicPositionProp.companyName
                    ? generalStrategicPositionProp.companyName
                    : "Não Informado"}
            </Text>
            <Text style={styles.subTitle}>Nome Fantasia</Text>
            <Text style={styles.paragraph}>
                {generalStrategicPositionProp.companyFantasy
                    ? generalStrategicPositionProp.companyFantasy
                    : "Não Informado"}
            </Text>

            <View style={styles.dFlex}>
                <Text style={styles.subTitle}>Número de funcionários: </Text>
                <Text style={styles.value}>
                    {generalStrategicPositionProp.companyNumberEmployees
                        ? generalStrategicPositionProp.companyNumberEmployees
                        : "Não Informado"}
                </Text>
            </View>
            <View style={styles.dFlex}>
                <Text style={styles.subTitle}>Total de vendas: </Text>
                <Text style={[styles.value, { marginTop: 4 }]}>
                    {generalStrategicPositionProp.companyTotalSales
                        ? generalStrategicPositionProp.companyTotalSales
                        : "0"}{" "}
                    R$
                </Text>
            </View>
            <View style={styles.line}></View>

            <Text style={styles.anotherTitle}>Sobre o mercado</Text>

            {generalStrategicPositionProp.companyMarket.companyMarket.map(
                (item, index) => (
                    <View key={index}>
                        <Text style={styles.subTitle}>Mercado</Text>
                        <Text style={styles.paragraph}>
                            {item.companyMarket
                                ? item.companyMarket
                                : "Não Informado"}
                        </Text>
                        <View style={styles.dFlex}>
                            <Text style={styles.subTitle}>
                                Total deste mercado:{" "}
                            </Text>
                            <Text style={[styles.value, { marginTop: 4 }]}>
                                {item.companyMarketTotal}
                            </Text>
                        </View>
                        {item.companySegment.map((item2, index2) => (
                            <View key={index2}>
                                <View style={styles.dFlex}>
                                    <Text style={styles.subTitle}>
                                        Tamanho:
                                    </Text>
                                    <Text
                                        style={[styles.value, { marginTop: 4 }]}
                                    >
                                        {item2.companyMarketSize
                                            ? item2.companyMarketSize
                                            : "Não Informado"}
                                    </Text>
                                </View>

                                <View style={styles.dFlex}>
                                    <Text style={styles.subTitle}>
                                        Unidade:
                                    </Text>
                                    <Text
                                        style={[styles.value, { marginTop: 4 }]}
                                    >
                                        {item2.companyMarketUnity
                                            ? item2.companyMarketUnity
                                            : "Não Informado"}
                                    </Text>
                                </View>

                                <View style={styles.dFlex}>
                                    <Text style={styles.subTitle}>
                                        Segmento:
                                    </Text>
                                    <Text
                                        style={[styles.value, { marginTop: 4 }]}
                                    >
                                        {item2.companySegment
                                            ? item2.companySegment
                                            : "Não Informado"}
                                    </Text>
                                </View>
                            </View>
                        ))}
                        <View style={styles.line}></View>
                    </View>
                )
            )}
            <View style={[styles.dFlex, styles.footer]}>
                <Text style={styles.subTitle}>Total de todos os mercados</Text>
                <Text style={styles.value}>
                    {
                        generalStrategicPositionProp.companyMarket
                            .companyMarketTotal
                    }
                    R$
                </Text>
            </View>
        </>
    );
};

export default StrategicPositionGeneral;
