import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../marketdynamicoverview.module.css';

function MarketDynamicTotal(props) {
    const {values, setFieldValue} = useFormikContext();
      const [field, meta] = useField(props);
    
      React.useEffect(() => {
        if (values[props.values][props.index].marketDynamicCustomer.length) {
            let total = values[props.values][props.index].marketDynamicCustomer.reduce((acc, current)=>acc + current.marketDynamicTotal, 0);
            setFieldValue(props.name, total);
        }
  
      }, [values[props.values][props.index].marketDynamicCustomer]);
    
      return (
        <>
          <input className={`p-1 rounded ${css.input_border}`} {...props} {...field} />
          {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
      );
}

export default MarketDynamicTotal;