import { getAuthUser } from "./auth/auth";

let url = "http://localhost:3000";
if (process.env.NODE_ENV === "development") {
    url = "http://localhost:3000";
} else {
    url = "https://ae360.herokuapp.com";
}

/**
 * @param accessToken
 * @param callback
 */
export const getForm = (accessToken, callback) => {
    const headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
    };

    fetch(`${url}/forms/business`, { method: "GET", headers })
        .then((res) => res.json())
        .then((json) => callback(null, json))
        .catch((error) => callback(error));
};

//`${url}/forms/strategicposition/form/${formId}/group/${groupId}/subgroup/${sgroupId}/questions`
/**
 *
 * @param formId
 * @param groupId
 * @param sgroupId
 * @param callback
 */
export const getQuestions = (formId, groupId, sgroupId, callback) => {
    getAuthUser()?.getSession((err, session) => {
        if (err) {
            callback(err);
        } else {
            const headers = {
                "Content-type": "application/json",
                Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
            };

            fetch(
                `${url}/forms/${formId}/group/${groupId}/subgroup/${sgroupId}/questions`,
                { method: "GET", headers }
            )
                .then((res) => res.json())
                .then((json) => callback(null, json))
                .catch((error) => callback(error));
        }
    });
};

/**
 *
 * @param formId
 * @param groupId
 * @param sgroupId
 * @param accessToken
 * @returns {object | {}}
 */
export const getQuestionsForm = (formId, groupId, sgroupId, accessToken) => {
    const headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
    };

    return fetch(
        `${url}/forms/${formId}/group/${groupId}/subgroup/${sgroupId}/questions`,
        { method: "GET", headers }
    )
        .then((res) => res.json())
        .then((json) => json)
        .catch((error) => error);
};

/**
 * @param accessToken
 * @param callback
 */
export const getMenuTypes = (accessToken, callback) => {
    const headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
    };

    fetch(`${url}/forms/types`, { method: "GET", headers })
        .then((res) => res.json())
        .then((json) => callback(null, json))
        .catch((error) => callback(error));
};
/**
 * FIM
 */

/**
 *
 * @param formId
 * @param groupId
 * @param data
 * @param callback
 * @param accessToken
 */
export const postAnswers = (
    formId,
    groupId,
    data,
    callback,
    accessToken = null
) => {
    const headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
    };
    console.log("data em postAnswers", data);
    fetch(`${url}/forms/${formId}/group/${groupId}/answer`, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((json) => callback(null, json))
        .catch((error) => callback(error));
};

/**
 *
 * @param formId
 * @param groupId
 * @param subGroupId
 * @param data
 * @param callback
 * @param accessToken
 */
export const postSubAnswers = (
    formId,
    groupId,
    subGroupId,
    data,
    callback,
    accessToken
) => {
    const headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
    };
    fetch(
        `${url}/forms/${formId}/group/${groupId}/subgroup/${subGroupId}/answer`,
        { method: "POST", headers, body: JSON.stringify(data) }
    )
        .then((res) => res.json())
        .then((json) => callback(null, json))
        .catch((error) => callback(error));
};

/**
 *
 * @param formId
 * @param groupId
 * @param accessToken
 * @returns {object | {}}
 */
export const getBudgetData = (formId, groupId, accessToken) => {
    const headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
    };
    return fetch(`${url}/forms/${formId}/group/${groupId}/budget`, {
        method: "GET",
        headers,
    })
        .then((res) => res.json())
        .then((json) => json)
        .catch((error) => error);
};

/**
 * @param accessToken
 * @returns {object | {}}
 */
export const getDepartmentPercent = (accessToken = null) => {
    const headers = {
        "Content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
    };
    return fetch(`${url}/departments`, { method: "GET", headers })
        .then((res) => res.json())
        .then((json) => json)
        .catch((error) => error);
};
