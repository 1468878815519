import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import css from "../marketdynamicoverview.module.css";
import {
    initialCostumerDynamicValues,
    CostumerDynamicSchema,
} from "./helpers/formValidation";
import { genElementsValue } from "./helpers/elementsOfValue";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormButton from "../../components/FormButton";
import PowerNegotiation from "./components/PowerNegotiation";
import SalesForecast from "./components/SalesForecast";
import Button from "../../../Button";
import FormSelect from "../../../FormSelect";
import FormValidationError from "../../components/FormValidationError";
// react-select
import CustomSelect from "../../../CustomSelect";

function CostumerDynamic(props) {
    const [formValues, setFormValues] = useState(initialCostumerDynamicValues);
    const [elements, setElements] = useState(genElementsValue());

    useEffect(() => {
        let initialValues = {
            ...initialCostumerDynamicValues,
            ...props.questions,
        };
        setFormValues(initialValues);
        setElements(genElementsValue());
    }, [props.questions]);

    return (
        <Formik
            initialValues={formValues}
            validationSchema={CostumerDynamicSchema}
            validate={(values) => {
                props.setChanged(values !== formValues);
            }}
            onSubmit={(values) => {
                props.setSubmitting(true);
                props.costumerSubmit(values, props.group);
            }}
            enableReinitialize
        >
            {({ values, errors, touched }) => (
                <Form className="p-4">
                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Demanda por segmento/cliente (Poder de
                                negociação)
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <PowerNegotiation
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Previsão de crescimento do segmento
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <SalesForecast
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col xs={6} lg={6}>
                                    <h6
                                        style={{
                                            fontSize: 16,
                                            marginTop: 4,
                                        }}
                                    >
                                        Elementos de Valor
                                    </h6>
                                    <Field
                                        className="basic-multi-select"
                                        name="marketDynamicElementsValue"
                                        options={elements}
                                        component={CustomSelect}
                                        placeholder="Selecione..."
                                        isMulti={true}
                                    />
                                </Col>

                                <Col xs={6} lg={4}>
                                    <FormSelect
                                        label="Risco de verticalização"
                                        name={`marketDynamicRiskVerticalization`}
                                        id={`marketDynamicRiskVerticalization`}
                                    >
                                        <option value="H">High</option>
                                        <option value="M">Medium</option>
                                        <option value="L">Low</option>
                                    </FormSelect>
                                </Col>
                            </Row>

                            <Row className="p-0 m-0 mt-3 justify-content-end">
                                {Object.keys(errors).length > 0 && (
                                    <FormValidationError errors={errors} />
                                )}
                            </Row>

                            <Row>
                                <Col>
                                    <Button
                                        variant="success"
                                        disabled={props.submitting}
                                        type="submit"
                                    >
                                        Salvar informações
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default CostumerDynamic;
