import React, {useState} from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Row } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import FormInput from '../components/FormInput';
import FormButton from '../components/FormButton';
import {} from '../../../services/auth/auth';
import { useAuth } from '../../../providers/AuthProvider';

import logo from './imgs/id_login.png'
import authimg from './imgs/authimg.png'

import css from './auth.module.css'

const intialForgotValues ={
    email: "",
}

const ForgotSchema = Yup.object().shape({
    email: Yup.string()
    .required()
    .email(),
});

const initialResetValues = {
    email: "",
    code: "",
    password: ""
}

const ResetSchema = Yup.object().shape({
    email: Yup.string()
    .required()
    .email(),
    code: Yup.string().required(),
    password: Yup.string().required()
});

function ForgotPassword(props) {
    const [stage, setStage] = useState(false);
    const [errorForm, setErrorForm] = useState({error: false, message: ""});

    const { sendEmailForgot, setPasswordForgot } = useAuth();

    function handleSubmitEmail(values, formikBag) {
        setErrorForm({error: false, message: ""});
        sendEmailForgot(values)
            .then(() => setStage(true))
            .catch((err) => {
                console.log(err);
                setErrorForm({error: true, message: err.message});
                formikBag.setSubmitting(false);
            })
    }

    function handleSubmitNewPassword(values, formikBag) {
        setErrorForm({error: false, message: ""});
        setPasswordForgot(values)
            .then(() => props.history.push("/signin"))
            .catch((err) => {
                console.log(err);
                setErrorForm({error: true, message: err.message});
                formikBag.setSubmitting(false);
            })
    }

    return (
        <div>
            {!stage &&
            <div className={css.authContainer}>
                <section className={css.form}>
                <Link to="/"><img src={logo} alt="CEO360" /></Link>
                    <Formik
                        initialValues={intialForgotValues}
                        validationSchema={ForgotSchema}
                        onSubmit={handleSubmitEmail}>
                        {({errors, touched, isSubmitting})=>(
                            <Form>
                                <FormInput name="email"  type="text" errors={errors} touched={touched} holder="E-mail" />
                                <Row className="p-0 m-0">
                                    {errorForm.error && 
                                        <span style={{color:'red'}}>{errorForm.message}</span>
                                    }
                                </Row>
                                <Row className="p-0 m-0 justify-content-end">
                                    <FormButton disabled={isSubmitting} type="submit" text="Enviar" />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </section>
                <img className={css.authImg} src={authimg} alt="Business" />
            </div>
            }
            {stage &&
            <div className={css.authContainer}>
                <section className={css.form}>
                    <img src={logo} alt="CEO360" />
                    <Formik
                        initialValues={initialResetValues}
                        validationSchema={ResetSchema}
                        onSubmit={handleSubmitNewPassword}>
                        {({errors, touched, isSubmitting})=>(
                            <Form>
                                <h4>Entrar</h4>
                                <FormInput name="email"  type="text" errors={errors} touched={touched} holder="E-mail" />
                                <FormInput name="code"  type="text" errors={errors} touched={touched} holder="Código" />
                                <FormInput name="password"  type="password" errors={errors} touched={touched} holder="Senha" />
                                <Row className="p-0 m-0 mt-3">
                                    {errorForm.error && 
                                        <span style={{color:'red'}}>{errorForm.message}</span>
                                    }
                                </Row>
                                <Row className="p-0 m-0 justify-content-end">
                                    <FormButton disabled={isSubmitting} type="submit" text="Enviar" />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </section>
                <img className={css.authImg} src={authimg} alt="Business" />
            </div>
            }
        </div>
    )
}

export default withRouter(ForgotPassword);