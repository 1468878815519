import React from 'react';
import {Row, Col} from 'react-bootstrap';
import GeneralTotalizer from './GeneralTotalizer';
import MainMonthTotalizers from "./MainMonthTotalizers";
const indexName = "companyValueChainCostExpenses";

function MainTotalizers({index, name}){
    return (
        <Row className="p-0 m-0">
            <Col className="p-0 m-0" lg={1} />
            <Col className="p-0 m-0" lg={9}>
                <Row className="p-0 m-0">
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.jan.total`}
                            original={name}
                            index={index}
                            month="jan"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.fev.total`}
                            original={name}
                            index={index}
                            month="fev"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.mar.total`}
                            original={name}
                            index={index}
                            month="mar"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.abr.total`}
                            original={name}
                            index={index}
                            month="abr"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.mai.total`}
                            original={name}
                            index={index}
                            month="mai"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.jun.total`}
                            original={name}
                            index={index}
                            month="jun"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.jul.total`}
                            original={name}
                            index={index}
                            month="jul"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.ago.total`}
                            original={name}
                            index={index}
                            month="ago"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.set.total`}
                            original={name}
                            index={index}
                            month="set"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.out.total`}
                            original={name}
                            index={index}
                            month="out"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.nov.total`}
                            original={name}
                            index={index}
                            month="nov"
                        />
                    </Col>
                    <Col className="p-0 pr-1 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}][${name}].monthsTotal.dez.total`}
                            original={name}
                            index={index}
                            month="dez"
                        />
                    </Col>
                </Row>
            </Col>
            <Col className="p-0 m-0 h-100" lg={1}>
                Total
                <GeneralTotalizer
                    name={`[${indexName}][${index}][${name}].total`}
                    original={name}
                    index={index}
                />
            </Col>
        </Row>
    )
}

export default MainTotalizers;