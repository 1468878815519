import React, {useState} from 'react';
import {FormGroup, Row} from 'react-bootstrap';
import * as Yup from 'yup';
import {Link, withRouter} from 'react-router-dom';
import FormInput from '../components/FormInput';
import { Formik, Form } from 'formik';
import { useAuth } from '../../../providers/AuthProvider';
import css from "./auth.module.css";
import logo from "./imgs/id_login.png";
import authimg from "./imgs/authimg.png";
import FormButton from "../components/FormButton";

const ConfirmationSchema = Yup.object().shape({
    username: Yup.string().required(),
});

function ResendCode(props) {
    const [errorForm, setErrorForm] = useState({error: false, message: ""});

    const { resendCode } = useAuth();

    const intialConfirmationValues ={
        username: "",
    }

    function handleSubmit(values, formikBag) {
        setErrorForm({ error: false, message: "" });
        resendCode(values)
            .then(() => props.history.push('/confirmation'))
            .catch((err) => {
                console.log(err);
                setErrorForm({ error: true, message: err.message });
                formikBag.setSubmitting(false);
            })
    }

    return (
        <div className={css.authContainer}>
            <section className={css.form}>
                <Link to="/"><img src={logo} alt="CEO360" /></Link>
            <Formik
                initialValues={intialConfirmationValues}
                validationSchema={ConfirmationSchema}
                onSubmit={handleSubmit}
            >
                {({errors, touched, isSubmitting})=>(
                    <Form>
                        <h4>Reenvio</h4>
                        <FormInput name="username"  type="text" errors={errors} touched={touched} holder="Entre com seu usuário"/>
                        <Row className="p-0 m-0">
                            {errorForm.error &&
                            <span style={{color:'red'}}>{errorForm.message}</span>
                            }
                        </Row>
                        <FormGroup className="d-flex justify-content-end">
                            <FormButton disabled={isSubmitting} type="submit" className="mt-2" text="Enviar"/>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
                <Link className={css.authLink} to="/confirmation">
                    voltar
                </Link>
            </section>
            <img className={css.authImg} src={authimg} alt="Business"/>
        </div>
    )
}

export default withRouter(ResendCode)