import React from 'react';
import UnitTotalizers from './UnitTotalizers';
import {Col, Row} from "react-bootstrap";
import MonthTotalizers from "./MonthTotalizers";

function Totalizers({index, subindex, name}){
    return(
        <>
            <label className="w-100">Total</label>
            <label className="w-100">R$</label>
            <Row className="p-0 m-0">
                <Col className="p-0 m-0">
                    <MonthTotalizers
                        name={`${name}['total'].totalMain`}
                        index={index}
                        subindex={subindex}
                        calculate="total"
                    />
                </Col>
            </Row>
            <Row className="p-0 m-0 h-100 align-items-end">
                <Col className="p-0 m-0">
                    <UnitTotalizers
                        name={`${name}['total'].totalUnit`}
                        index={index}
                        subindex={subindex}
                        calculate="unit"
                    />
                </Col>
            </Row>

        </>
    );
}

export default Totalizers;