import React from 'react';
import {Col} from "react-bootstrap";

function FormValidationError(props) {
    return (
        <Col className="offset-6 text-right p-0 m-0">
            <div><span style={{color: 'blue'}}>Existe erro de preenchimento no formulário: </span></div>
            <div><span style={{color: 'red'}}>{`${Object.keys(props.errors)[0]}`}</span></div>
        </Col>
    );
}

export default FormValidationError;