import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import NoDataPresent from "./NoDataPresent";
import { withRouter } from "react-router-dom";
import { getQuestions } from "../../../../services/formData";
import { ToastContainer, toast } from "react-toastify";
import css from "./css/swot.module.css";
import { messages } from "../../../../services/system-messages/systemMessages";

function Swot(props) {
    const [opportunities, setOpportunities] = useState([]);
    const [strengths, setStrengths] = useState([]);
    const [weaknesses, setWeaknesses] = useState([]);
    const [threats, setThreats] = useState([]);
    const [survival, setSurvival] = useState([]);
    const [maint, setMaint] = useState([]);
    const [attack, setAttack] = useState([]);
    const [growth, setGrowth] = useState([]);
    const [data, setData] = useState(false);

    useEffect(() => {
        //Teremos que recuperar o menu order 2, submenu order 1
        let isSubscribe = true;
        const dinamicaRef = props.forms.length
            ? props.forms.find((hit) => hit.FormType.menuOrder === 2)
            : null;
        if (dinamicaRef) {
            const competicaoRef = dinamicaRef.FormType.MainGroups.length
                ? dinamicaRef.FormType.MainGroups.find(
                      (hit) => hit.menuOrder === 1
                  )
                : null;
            if (competicaoRef) {
                getQuestions(
                    dinamicaRef.id,
                    competicaoRef.id,
                    0,
                    (err, questions) => {
                        if ((questions && questions.error) || err) {
                            console.log(err);
                            props.history.push("/");
                        } else {
                            if (isSubscribe) {
                                if (Object.keys(questions).length) {
                                    setData(true);
                                    console.log(questions);
                                    setOpportunities(
                                        questions.marketDynamicOpportunities
                                    );
                                    setStrengths(
                                        questions.marketDynamicStrengths
                                    );
                                    setWeaknesses(
                                        questions.marketDynamicWeaknesses
                                    );
                                    setThreats(questions.marketDynamicThreats);
                                    setSurvival(
                                        questions.marketDynamicSurvival
                                    );
                                    setMaint(questions.marketDynamicMaint);
                                    setAttack(questions.marketDynamicAttack);
                                    setGrowth(questions.marketDynamicGrowth);
                                } else {
                                    setData(false);
                                }
                            }
                        }
                    }
                );
            } else {
                toast.error("Houve um erro no carregamento dos dados do swot");
            }
        } else {
            toast.error("Houve um erro no carregamento dos dados do swot");
        }

        return () => {
            isSubscribe = false;
        };
    }, [props.forms]);

    return (
        <>
            <ToastContainer />
            <Row className="p-0 m-0 mt-5">
                <Col className="p-0 m-0">
                    {data && (
                        <>
                            <Col
                                className={`p-3 ${css.swot_square}`}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <div className={`${css.gridContainer}`}>
                                    <div></div>
                                    <div></div>
                                    <div className={`${css.fraqueza}`}>
                                        <div className={`bg-secondary p-2`}>
                                            <h6 className="text-white m-0 p-0 text-center">
                                                Análise Interna
                                            </h6>
                                        </div>
                                        <Row className="p-0 m-0">
                                            <Col
                                                className={`p-3 ${css.swot_square}`}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                                style={{
                                                    backgroundColor: "#608DC4",
                                                }}
                                            >
                                                <div className="text-center">
                                                    <span
                                                        className={`${css.title}`}
                                                    >
                                                        Forças
                                                    </span>
                                                </div>
                                                <div className={`${css.list}`}>
                                                    <ul>
                                                        {strengths.length > 0 &&
                                                            strengths.map(
                                                                (
                                                                    hit,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            hit.marketDynamicStrengths
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col
                                                className={`p-3 ${css.swot_square}`}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                                style={{
                                                    backgroundColor: "#FAAB64",
                                                }}
                                            >
                                                <div className="text-center">
                                                    <span
                                                        className={`${css.title}`}
                                                    >
                                                        Fraquezas
                                                    </span>
                                                </div>
                                                <div className={`${css.list}`}>
                                                    <ul>
                                                        {weaknesses.length >
                                                            0 &&
                                                            weaknesses.map(
                                                                (
                                                                    hit,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            hit.marketDynamicWeaknesses
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={`${css.a} `}>
                                        <div
                                            className={`justify-content-end bg-secondary ${css.identification_column}`}
                                        >
                                            <div className="text-center text-white ">
                                                <span>Análise Externa</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Col
                                            className={`p-2 ${css.swot_square}`}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            style={{
                                                backgroundColor: "#D55E60",
                                            }}
                                        >
                                            <div className="text-center">
                                                <span
                                                    className={`${css.title}`}
                                                >
                                                    Oportunidades
                                                </span>
                                            </div>
                                            <div className={`${css.list}`}>
                                                <ul>
                                                    {opportunities.length > 0 &&
                                                        opportunities.map(
                                                            (hit, index) => (
                                                                <li key={index}>
                                                                    {
                                                                        hit.marketDynamicOpportunities
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                </ul>
                                            </div>
                                        </Col>
                                    </div>
                                    <div>
                                        <Col
                                            className={`p-3 ${css.swot_square}`}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            style={{
                                                backgroundColor: "#9ECA73",
                                            }}
                                        >
                                            <div className="text-center">
                                                <span
                                                    className={`${css.title}`}
                                                >
                                                    Ameaças
                                                </span>
                                            </div>
                                            <div className={`${css.list}`}>
                                                <ul>
                                                    {threats.length > 0 &&
                                                        threats.map(
                                                            (hit, index) => (
                                                                <li key={index}>
                                                                    {
                                                                        hit.marketDynamicThreats
                                                                    }
                                                                </li>
                                                            )
                                                        )}
                                                </ul>
                                            </div>
                                        </Col>
                                    </div>

                                    <div className={`${css.ataque}`}>
                                        <Row className="p-0 m-0 h-100">
                                            <Col
                                                className={`p-3 ${css.swot_square}`}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                                style={{
                                                    backgroundColor: "#ad60c4",
                                                }}
                                            >
                                                <div className="text-center">
                                                    <span
                                                        className={`${css.title}`}
                                                    >
                                                        Estratégia de Ataque
                                                    </span>
                                                </div>
                                                <div className={`${css.list}`}>
                                                    <ul>
                                                        {attack.length > 0 &&
                                                            attack.map(
                                                                (
                                                                    hit,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            hit.marketDynamicAttack
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col
                                                className={`p-3 ${css.swot_square}`}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                                style={{
                                                    backgroundColor: "#3677ad",
                                                }}
                                            >
                                                <div className="text-center">
                                                    <span
                                                        className={`${css.title}`}
                                                    >
                                                        Estratégia de
                                                        Crescimento
                                                    </span>
                                                </div>
                                                <div className={`${css.list}`}>
                                                    <ul>
                                                        {growth.length > 0 &&
                                                            growth.map(
                                                                (
                                                                    hit,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            hit.marketDynamicGrowth
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className={`${css.manutencao}`}>
                                        <Row className="p-0 m-0 h-100">
                                            <Col
                                                className={`p-3 ${css.swot_square}`}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                                style={{
                                                    backgroundColor: "#60c4b7",
                                                }}
                                            >
                                                <div className="text-center">
                                                    <span
                                                        className={`${css.title}`}
                                                    >
                                                        Estratégia de manutenção
                                                    </span>
                                                </div>
                                                <div className={`${css.list}`}>
                                                    <ul>
                                                        {maint.length > 0 &&
                                                            maint.map(
                                                                (
                                                                    hit,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            hit.marketDynamicMaint
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col
                                                className={`p-3 ${css.swot_square}`}
                                                sm={6}
                                                md={6}
                                                lg={6}
                                                xl={6}
                                                style={{
                                                    backgroundColor: "#4aaf10",
                                                }}
                                            >
                                                <div className="text-center">
                                                    <span
                                                        className={`${css.title}`}
                                                    >
                                                        Estratégia de
                                                        Sobrevivência
                                                    </span>
                                                </div>
                                                <div className={`${css.list}`}>
                                                    <ul>
                                                        {survival.length > 0 &&
                                                            survival.map(
                                                                (
                                                                    hit,
                                                                    index
                                                                ) => (
                                                                    <li
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            hit.marketDynamicSurvival
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                    </ul>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </>
                    )}
                    {!data && <NoDataPresent />}
                </Col>
            </Row>
        </>
    );
}

export default withRouter(Swot);
