import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Card } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import {
    CompanyOverviewSchema,
    initialGeneralValues,
} from "./helpers/formValidation";
import FormInput from "../../components/FormInput";
import FormFieldArray from "../../components/FormFieldArray";
import CompanyMarket from "./components/CompanyMarket";
import CompanyBusinessModel from "./components/CompanyBusinessModel";
import Button from "../../../Button";
import FormValidationError from "../../components/FormValidationError";

function General(props) {
    const [formValues, setFormValues] = useState(initialGeneralValues);

    useEffect(() => {
        let initialValues = { ...initialGeneralValues, ...props.questions };
        if (!initialValues.companyGeneralEnvironmental) {
            initialValues.companyGeneralEnvironmental = [
                {
                    companyGeneralEnvironmental: "",
                },
            ];
            initialValues.companyGeneralGovernance = [
                {
                    companyGeneralGovernance: "",
                },
            ];
            initialValues.companyGeneralSocial = [
                {
                    companyGeneralSocial: "",
                },
            ];
        }
        setFormValues(initialValues);
    }, [props.questions]);

    return (
        <Formik
            initialValues={formValues}
            validationSchema={CompanyOverviewSchema}
            validate={(values) => {
                props.setChanged(values !== formValues);
            }}
            onSubmit={(values) => {
                console.log("values", values);
                props.setSubmitting(true);
                props.overviewSubmit(values, props.group);
            }}
            enableReinitialize
        >
            {({ values, errors, touched }) => (
                <Form className="p-4">
                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Sobre a empresa
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="companyName"
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com a razão social"
                                        >
                                            Razão Social
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="companyFantasy"
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com o nome fantasia"
                                        >
                                            Nome Fantasia
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} lg={4}>
                                    <FormGroup>
                                        <FormInput
                                            name="companyNumberEmployees"
                                            type="number"
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com o numero de funcionários"
                                        >
                                            Entre com número de funcionários
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} lg={4}>
                                    <FormGroup>
                                        <FormInput
                                            name="companyTotalSales"
                                            type="currency"
                                            errors={errors}
                                            touched={touched}
                                            holder="R$ 0.00"
                                        >
                                            Total de vendas
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Sobre o mercado
                            </h4>
                        </Col>
                        <Col xs={8}>
                            <CompanyMarket
                                values={values}
                                errors={errors}
                                touched={touched}
                            />
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-center ">
                                ESG
                            </h4>
                            <h4 className="text-muted font-weight-light text-center mr-3">
                                Environmental-Social-Governance
                            </h4>
                        </Col>
                        <Col>
                            <FormGroup>
                                <FieldArray name="companyGeneralESG">
                                    {({ remove, unshift, form, push }) => (
                                        <Row
                                            style={{
                                                flexWrap: "nowrap",
                                                overflow: "auto",
                                            }}
                                            className="py-1"
                                        >
                                            {values.companyGeneralESG?.length >
                                                0 &&
                                                values.companyGeneralESG.map(
                                                    (cv, index) => (
                                                        <Col
                                                            className=""
                                                            lg={6}
                                                            key={index}
                                                        >
                                                            <Card className="p-3">
                                                                <FormFieldArray
                                                                    name="companyGeneralEnvironmental"
                                                                    values={
                                                                        values
                                                                    }
                                                                    title="Ambiental"
                                                                    field="companyGeneralEnvironmental"
                                                                    errors={
                                                                        errors
                                                                    }
                                                                    touched={
                                                                        touched
                                                                    }
                                                                    holder="Entre com Ambiental"
                                                                    type="text"
                                                                    lessThenOne={
                                                                        false
                                                                    }
                                                                    butonAddText="Adicionar"
                                                                />
                                                                <FormFieldArray
                                                                    name="companyGeneralSocial"
                                                                    values={
                                                                        values
                                                                    }
                                                                    title="Social"
                                                                    field="companyGeneralSocial"
                                                                    errors={
                                                                        errors
                                                                    }
                                                                    touched={
                                                                        touched
                                                                    }
                                                                    holder="Entre com Social"
                                                                    type="text"
                                                                    lessThenOne={
                                                                        false
                                                                    }
                                                                    butonAddText="Adicionar"
                                                                />

                                                                <FormFieldArray
                                                                    name="companyGeneralGovernance"
                                                                    values={
                                                                        values
                                                                    }
                                                                    title="Governança"
                                                                    field="companyGeneralGovernance"
                                                                    errors={
                                                                        errors
                                                                    }
                                                                    touched={
                                                                        touched
                                                                    }
                                                                    holder="Entre com Governança"
                                                                    type="text"
                                                                    lessThenOne={
                                                                        false
                                                                    }
                                                                    butonAddText="Adicionar"
                                                                />
                                                            </Card>
                                                        </Col>
                                                    )
                                                )}
                                        </Row>
                                    )}
                                </FieldArray>
                            </FormGroup>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Sobre o cliente
                            </h4>
                        </Col>
                        <Col xs={8}>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormFieldArray
                                        name="companyClientProfile"
                                        values={values}
                                        title="Qual perfil do cliente ?"
                                        field="companyClientProfile"
                                        errors={errors}
                                        touched={touched}
                                        holder="Entre com o perfil de cada cliente"
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar Perfil"
                                    />
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            className="form-control"
                                            name="companyTargetProblem"
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com o principal problema"
                                        >
                                            Quais os problemas do cliente que
                                            vamos resolver?
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            className="form-control"
                                            name="companyMainNeeds"
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com a principal necessidade"
                                        >
                                            Quais as necessidades que estamos
                                            atendendo?
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Sobre o negócio
                            </h4>
                        </Col>
                        <Col xs={8}>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormFieldArray
                                        name="companyProduct"
                                        values={values}
                                        title="Quais produtos e serviços?"
                                        field="companyProduct"
                                        errors={errors}
                                        touched={touched}
                                        holder="Entre com o nome do serviço ou do produto"
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar Produto"
                                    />
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormFieldArray
                                        name="companyCompatetivesAdvantages"
                                        values={values}
                                        title="Quais são as vantagens competitivas?"
                                        errors={errors}
                                        touched={touched}
                                        field="companyCompatetivesAdvantages"
                                        holder="Descreva sua vantagem competitiva"
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar Vantagens"
                                    />
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="companyValueProposition"
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Descreva sua proposta de valor"
                                        >
                                            Diferencial competitivo
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={8}>
                                    <FormGroup>
                                        <CompanyBusinessModel />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="companyWayToAggregate"
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Como agregar valor aos clientes e capturar valor para os acionistas"
                                        >
                                            Como agregar valor aos clientes e
                                            acionistas?
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormFieldArray
                                        name="strategicGoals"
                                        values={values}
                                        title="Objetivos Estratégicos"
                                        errors={errors}
                                        touched={touched}
                                        field="strategicGoals"
                                        holder="Objetivo"
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar Objetivos"
                                    />
                                </Col>
                            </Row>

                            <Row className="p-0 m-0 mt-3 justify-content-end">
                                {Object.keys(errors).length > 0 && (
                                    <FormValidationError errors={errors} />
                                )}
                            </Row>

                            <Row>
                                <Col>
                                    <Button
                                        variant="success"
                                        disabled={props.submitting}
                                        type="submit"
                                    >
                                        Salvar informações
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default General;
