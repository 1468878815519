import React, { useEffect } from "react";
import { Container, Card } from "react-bootstrap";
import CompanyForm from "../../components/CompanyForm";
import Page from "../../components/Page";
import useSpinner from "../../components/spinner/useSpinner";
import { useAuth } from "../../providers/AuthProvider";
import { useUserContext } from "../../providers/UserProvider";
import { createOnboard } from "../../services/onboardService";

// import { Container } from './styles';

function OnBoard({ history }) {
    const { data, fetchUserData } = useUserContext();

    const [loader, showLoader, hideLoader] = useSpinner();

    const { getAccessToken } = useAuth();

    useEffect(() => {
        console.log(data);
        if (!data) {
            return;
        }

        if (!data?.isAdmin || data.companyId) {
            history.push("/");
        }
    }, [data, history]);

    async function onSubmit(values) {
        const accessToken = await getAccessToken();
        showLoader();
        createOnboard(values, accessToken)
            .then(() => fetchUserData())
            .then(() => hideLoader())
            .then(() => window.location.reload())
            .then(history.push("/"));
    }

    return (
        <Page withSideBar={false} withMenu={false}>
            {loader}
            <Container className="h-100 w-100 d-flex justify-content-center align-items-center">
                <Card className="p-5 flex-fill">
                    <div className="mb-2">
                        <h4>Bem vindo ao CEO360</h4>
                        <p>
                            Para o seu primeiro acesso, por favor insira seus
                            dados.
                        </p>
                        <hr />
                    </div>

                    <CompanyForm onSubmit={onSubmit} />
                </Card>
            </Container>
        </Page>
    );
}

export default OnBoard;
