import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
// Components
import DiretrizesEstrategicas from "./DiretrizesEstrategicas";
import IndexPdf from "./IndexPdf";
import MacroAmbForce from "./MacroAmbForce";
import Orcamento from "./Orcamento";
import Premissas from "./Premissas";
import SPGEsgClient from "./SPGEsgClient";
import StrategicPositionGeneral from "./StrategicPositionGeneral";
import StrategicPositioning from "./StrategicPositioning";
import SwotPdf from "./SwotPdf";
import Capa from "./Capa";
import StrategyPerformance from "./StrategyPerformance";
import ESGSupplyChain from "./ESGSupplyChain";
import ESGSupplyChainTwo from "./ESGSupplyChainTwo";
import ESGGeneral from "./ESGGeneral";

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFF",
        color: "#000",
    },
    section: {
        margin: 10,
        padding: 10,
        height: "93vh",
    },
    title: {
        fontSize: 14,
        textAlign: "center",
        marginBottom: 22,
        marginTop: 12,
    },
});

const PdfDocument = ({
    macroEnv,
    diretEstratProp,
    generalStrategicPositionProp,
    strategicPosition,
    swot,
    human,
    marketing,
    operations,
    service,
    supply,
    tech,
    finance,
    companyName,
    strategyRH,
    performanceRH,
    strategyMarketing,
    performanceMarketing,
    strategyVendas,
    performanceVendas,
    strategyOperations,
    performanceOperations,
    strategySupplyChain,
    performanceSupplyChain,
    strategyTech,
    performanceTech,
    strategyFinances,
    performanceFinances,
    rhESG,
    marketingESG,
    vendasESG,
    operationsESG,
    supplyChainESG,
    techESG,
    financesESG,
    produtos,
    prodTotal,
    costs,
    margin,
    amort,
    depre,
    despesaForm,
    totDesp,
}) => {
    //Funcão que calcula o total normal
    const handleTotal = (months, key) => {
        let total = 0;
        for (let i in months) {
            if (key) {
                if (months[i][key]) {
                    total += parseFloat(months[i][key]);
                }
            } else {
                total += parseFloat(months[i]);
            }
        }
        return total;
    };

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                <View style={styles.section}>
                    <Text style={styles.title}>HOME - PLANO ESTRATÉGICO</Text>
                    <Capa companyName={companyName} />
                </View>
                <View break style={styles.section}>
                    <Text style={styles.title}>ÍNDICE</Text>
                    <IndexPdf />
                </View>
                <View break style={styles.section}>
                    <Text style={styles.title}>
                        POSIÇÃO ESTRATÉGICA - VISÃO GERAL
                    </Text>
                    <StrategicPositionGeneral
                        generalStrategicPositionProp={
                            generalStrategicPositionProp
                        }
                    />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            fixed
                            style={{ fontSize: 10 }}
                        />
                    </View>
                </View>
                <View break style={styles.section}>
                    <Text style={styles.title}>
                        POSIÇÃO ESTRATÉGICA - VISÃO GERAL
                    </Text>
                    <SPGEsgClient
                        generalStrategicPositionProp={
                            generalStrategicPositionProp
                        }
                    />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View>
                <View break style={styles.section}>
                    <ESGGeneral
                        generalStrategicPositionProp={
                            generalStrategicPositionProp
                        }
                    />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View>
                <View break style={styles.section}>
                    <Text style={styles.title}>
                        POSIÇÃO ESTRATÉGICA - DIRETRIZES ESTRATÉGICAS
                    </Text>
                    <DiretrizesEstrategicas diretEstratProp={diretEstratProp} />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View>
                <View break style={styles.section}>
                    <Text style={styles.title}>
                        POSIÇÃO ESTRATÉGICA -POSICIONAMENTO ESTRATÉGICO
                    </Text>
                    <StrategicPositioning
                        strategicPosition={strategicPosition}
                    />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View>
                <View break style={styles.section}>
                    <Text style={styles.title}>PLANO DE NEGÓCIO - SWOT</Text>
                    <SwotPdf swot={swot} />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View>
                <View break style={styles.section}>
                    <Text style={styles.title}>FORÇAS MACRO AMBIENTAIS</Text>
                    <MacroAmbForce macroEnv={macroEnv} />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View>
                <View break style={styles.section}>
                    <Text style={styles.title}>
                        PLANO DE NEGÓCIO – PREMISSAS DO ORÇAMENTO
                    </Text>
                    <Premissas
                        human={human}
                        marketing={marketing}
                        operations={operations}
                        service={service}
                        supply={supply}
                        tech={tech}
                        finance={finance}
                    />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View>
                <View break style={styles.section}>
                    <Text style={styles.title}>ESTRATÉGIA E PERFORMANCE</Text>
                    <StrategyPerformance
                        strategyRH={strategyRH}
                        performanceRH={performanceRH}
                        strategyMarketing={strategyMarketing}
                        performanceMarketing={performanceMarketing}
                        strategyVendas={strategyVendas}
                        performanceVendas={performanceVendas}
                        strategyOperations={strategyOperations}
                        performanceOperations={performanceOperations}
                        strategySupplyChain={strategySupplyChain}
                        performanceSupplyChain={performanceSupplyChain}
                        strategyTech={strategyTech}
                        performanceTech={performanceTech}
                        strategyFinances={strategyFinances}
                        performanceFinances={performanceFinances}
                    />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View>
                <View break style={styles.section}>
                    <Text style={styles.title}>CADEIA DE VALOR - ESG</Text>
                    <ESGSupplyChain
                        rhESG={rhESG}
                        marketingESG={marketingESG}
                        vendasESG={vendasESG}
                    />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View>
                <View break style={styles.section}>
                    <ESGSupplyChainTwo
                        operationsESG={operationsESG}
                        supplyChainESG={supplyChainESG}
                        techESG={techESG}
                        financesESG={financesESG}
                    />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View>

                {/* <View break style={styles.section}>
                    <Text style={styles.title}>
                        PLANO DE NEGÓCIO – ORÇAMENTO
                    </Text>
                    <Orcamento
                        produtos={produtos}
                        prodTotal={prodTotal}
                        costs={costs}
                        margin={margin}
                        amort={amort}
                        depre={depre}
                        handleTotal={handleTotal}
                        totDesp={totDesp}
                        despesaForm={despesaForm}
                    />
                    <View style={{ marginTop: "auto", marginLeft: "auto" }}>
                        <Text
                            render={({ pageNumber, totalPages }) =>
                                `${pageNumber} de ${totalPages}`
                            }
                            style={{ fontSize: 10 }}
                            fixed
                        />
                    </View>
                </View> */}
            </Page>
        </Document>
    );
};
export default PdfDocument;
