import React from "react";
import { formatCurrency } from "../../../../utils/formatCurrency";

function BudgetTable({ produtos, totalMes, totalAno, title }) {
    // console.log(produtos);
    return (
        <>
            {produtos?.length > 0 ? (
                produtos.map((prod, index) => (
                    <tr key={index}>
                        {index === 0 && (
                            <td valign="middle" rowSpan={produtos.length}>
                                {title}
                            </td>
                        )}
                        <td>{prod?.companyValueChainProduct}</td>
                        <td align="right">
                            {isNaN(prod?.companyValueChainBudget.jan[totalMes])
                                ? 0
                                : formatCurrency(
                                      prod?.companyValueChainBudget.jan[
                                          totalMes
                                      ]
                                  )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.fev[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.mar[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.abr[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.mai[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.jun[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.jul[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.ago[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.set[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.out[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.nov[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(
                                prod?.companyValueChainBudget.dez[totalMes]
                            )}
                        </td>
                        <td align="right">
                            {formatCurrency(prod ? prod[totalAno] : 0)}
                        </td>
                    </tr>
                ))
            ) : (
                <tr>
                    <td valign="middle">{title}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            )}
        </>
    );
}

export default BudgetTable;
