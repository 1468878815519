import React from 'react';
import {FieldArray} from 'formik';
import {Row, Col, FormGroup, Card} from 'react-bootstrap';
import css from '../../marketdynamicoverview.module.css';
import FormInput from '../../../components/FormInput';
import CustomerDynamicTotal from './CustomerDynamicTotal';
import CustomerDynamicShare from './CustomerDynamicShare';
import { MdAdd } from 'react-icons/md';
import Button from '../../../../Button';
import SupplierDynamicTotal from "../../supplier-dynamic/components/SupplierDynamicTotal";

function PowerNegotiation({values}) {
    return (
        <FieldArray name="marketDynamicPowerNegociation">
            {({insert, remove, push, form})=>(
                <>
                    {values.marketDynamicPowerNegociation?.length > 0 &&
                        values.marketDynamicPowerNegociation.map((md, index)=>(
                            <React.Fragment key={index}>
                                <Row className="align-items-start">
                                    <Col lg={6}>
                                        <FormGroup>
                                            <FormInput
                                                name={`marketDynamicPowerNegociation[${index}].marketDynamicSegment`}
                                                type="text"
                                                original="marketDynamicSegment"
                                                errors={form.errors?.marketDynamicPowerNegociation && form.errors?.marketDynamicPowerNegociation.length && form.errors?.marketDynamicPowerNegociation[index] ? form.errors.marketDynamicPowerNegociation[index] : ""}
                                                touched={form.touched?.marketDynamicPowerNegociation && form.touched?.marketDynamicPowerNegociation.length && form.touched?.marketDynamicPowerNegociation[index] ? form.touched.marketDynamicPowerNegociation[index] : ""}
                                                holder="Entre com o segmento" >
                                                Segmento
                                            </FormInput>
                                        </FormGroup>
                                    </Col>
                                    <Button
                                        type="button"
                                        onClick={()=>{
                                            if( values.marketDynamicPowerNegociation.length > 1){
                                                remove(index)
                                            }
                                        }}
                                        style={{ marginTop: '34px'}}
                                        variant="outline-danger"
                                    ><i className="fas fa-trash-alt mr-2" />Remover Segmento</Button>
                                </Row>

                                <FieldArray
                                    name={`marketDynamicPowerNegociation[${index}].marketDynamicCustomer`}
                                    render={({insert, remove, unshift})=>(
                                        <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                                            <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                                                <Button
                                                    type="button"
                                                    variant="outline-success"
                                                    className={`w-100 h-100`}
                                                    onClick={() => unshift({marketDynamicCustomer: "", marketDynamicTotal: 0, marketDynamicShare: 0})}
                                                    >
                                                    <MdAdd fontSize={24} />
                                                </Button>
                                            </Col>                                            
                                            {md.marketDynamicCustomer?.map((mdp, indexMdp) => (
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3} key={indexMdp}>
                                                    <Card className="p-3">
                                                        <FormGroup>
                                                            <FormInput
                                                                name={`marketDynamicPowerNegociation[${index}].marketDynamicCustomer[${indexMdp}].marketDynamicCustomer`}
                                                                type="text"
                                                                original="marketDynamicCustomer"
                                                                errors={
                                                                    form.errors?.marketDynamicPowerNegociation &&
                                                                    form.errors?.marketDynamicPowerNegociation?.length &&
                                                                    form.errors?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer &&
                                                                    form.errors?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer.length &&
                                                                    form.errors?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer[indexMdp] ?
                                                                    form.errors?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer[indexMdp]  : ""}
                                                                touched={
                                                                    form.touched?.marketDynamicPowerNegociation &&
                                                                    form.touched?.marketDynamicPowerNegociation?.length &&
                                                                    form.touched?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer &&
                                                                    form.touched?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer.length &&
                                                                    form.touched?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer[indexMdp] ?
                                                                    form.touched?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer[indexMdp] : ""}
                                                                holder="Entre com o cliente" >
                                                                Cliente
                                                            </FormInput>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <FormInput
                                                                name={`marketDynamicPowerNegociation[${index}].marketDynamicCustomer[${indexMdp}].marketDynamicTotal`}
                                                                type="number"
                                                                original="marketDynamicTotal"
                                                                errors={
                                                                    form.errors?.marketDynamicPowerNegociation &&
                                                                    form.errors?.marketDynamicPowerNegociation?.length &&
                                                                    form.errors?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer &&
                                                                    form.errors?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer.length &&
                                                                    form.errors?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer[indexMdp] ?
                                                                    form.errors?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer[indexMdp]  : ""}
                                                                touched={
                                                                    form.touched?.marketDynamicPowerNegociation &&
                                                                    form.touched?.marketDynamicPowerNegociation?.length &&
                                                                    form.touched?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer &&
                                                                    form.touched?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer.length &&
                                                                    form.touched?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer[indexMdp] ?
                                                                    form.touched?.marketDynamicPowerNegociation[index]?.marketDynamicCustomer[indexMdp] : ""}
                                                                holder="Entre com o total" >
                                                                Total
                                                            </FormInput>
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <div className={`${css.input_label}`}>
                                                                <label htmlFor={`marketDynamicPowerNegociation[${index}].marketDynamicCustomer[${indexMdp}].marketDynamicShare`}>
                                                                    Participação(%)
                                                                </label>
                                                            </div>
                                                            <CustomerDynamicShare
                                                                values="marketDynamicPowerNegociation"
                                                                index={index}
                                                                indexmdp={indexMdp}
                                                                disabled={true}
                                                                name={`marketDynamicPowerNegociation[${index}].marketDynamicCustomer[${indexMdp}].marketDynamicShare`}
                                                            />
                                                        </FormGroup>

                                                        <FormGroup>
                                                            <Button variant="outline-danger" className={`mt-2 w-100`} type="button" onClick={() =>{
                                                                if(values.marketDynamicPowerNegociation[index].marketDynamicCustomer.length > 1){
                                                                    remove(indexMdp);
                                                                }
                                                            }}>
                                                                <i className="fas fa-trash-alt" />
                                                            </Button>
                                                        </FormGroup>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    )}
                                />

                                <Row className="mt-4">
                                    <Col className="d-flex align-items-center">
                                        <div className="mr-3 font-weight-bold"><span>Total deste segmento:</span></div>
                                        <CustomerDynamicTotal
                                            values="marketDynamicPowerNegociation"
                                            index={index}
                                            disabled={true} name={`marketDynamicPowerNegociation[${index}].marketDynamicSegmentTotal`} />
                                    </Col>
                                </Row>

                                <hr />
                            </React.Fragment>
                        ))
                    }

                    <FormGroup>
                        <Button
                            type="button"
                            variant="outline-success"
                            onClick={()=> push({marketDynamicSegment: '', marketDynamicCustomer:[{marketDynamicCustomer:"", marketDynamicTotal: 0, marketDynamicShare: 0}], marketDynamicSegmentTotal:0})}
                            className={``}
                        >
                            <MdAdd fontSize={20} className="mr-1" />
                            Adicionar Segmento
                        </Button>
                    </FormGroup>
                </>
            )}
        </FieldArray>
    )
}

export default PowerNegotiation;
