import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    marginSection: {
        marginLeft: 28,
    },
    header: {
        flexDirection: "row",
        borderBottomWidth: 1,
    },
    lines: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#aaaaaa",
    },
    headerText: {
        fontSize: 10,
        fontWeight: "bold",
        paddingVertical: 6,
    },
    lineText: {
        fontSize: 9,
        paddingVertical: 6,
    },
});

const Premissas = ({
    human,
    marketing,
    operations,
    service,
    supply,
    tech,
    finance,
}) => (
    <View style={styles.marginSection}>
        <View style={[styles.header, { borderTopWidth: 1 }]}>
            <Text style={[styles.headerText, { width: "20%" }]}>Área</Text>
            <Text style={[styles.headerText, { width: "5%" }]}>#</Text>
            <Text style={[styles.headerText, { width: "25%" }]}>Objetivos</Text>
            <Text style={[styles.headerText, { width: "25%" }]}>Metas</Text>
            <Text style={[styles.headerText, { width: "25%" }]}>
                Plano de Ação
            </Text>
        </View>
        <View style={styles.lines}>
            <Text style={[styles.lineText, { width: "20%" }]}>
                Recursos Humanos
            </Text>
            <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {human[0].companyValueChainObjectives
                    ? human[0].companyValueChainObjectives
                    : "Não Informado"}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {human[0].companyValueChainGoals
                    ? human[0].companyValueChainGoals
                    : "Não Informado"}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {human[0].companyValueChainActionPlan
                    ? human[0].companyValueChainActionPlan
                    : "Não Informado"}
            </Text>
        </View>
        {human.length > 1 ? (
            <View>
                {human.slice(1).map((item, index) => {
                    return (
                        <View key={index} style={styles.lines}>
                            <Text
                                style={[styles.lineText, { width: "20%" }]}
                            ></Text>

                            <Text style={[styles.lineText, { width: "5%" }]}>
                                {index + 2}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainObjectives}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainGoals}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainActionPlan}
                            </Text>
                        </View>
                    );
                })}
            </View>
        ) : null}
        <View style={styles.lines}>
            <Text style={[styles.lineText, { width: "20%" }]}>Marketing</Text>
            <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {marketing[0].companyValueChainObjectives
                    ? marketing[0].companyValueChainObjectives
                    : "Não Informado "}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {marketing[0].companyValueChainGoals
                    ? marketing[0].companyValueChainGoals
                    : "Não Informado "}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {marketing[0].companyValueChainActionPlan
                    ? marketing[0].companyValueChainActionPlan
                    : "Não Informado "}
            </Text>
        </View>
        {marketing.length > 1 ? (
            <View>
                {marketing.slice(1).map((item, index) => {
                    return (
                        <View key={index} style={styles.lines}>
                            <Text
                                style={[styles.lineText, { width: "20%" }]}
                            ></Text>

                            <Text style={[styles.lineText, { width: "5%" }]}>
                                {index + 2}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainObjectives}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainGoals}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainActionPlan}
                            </Text>
                        </View>
                    );
                })}
            </View>
        ) : null}
        <View style={styles.lines}>
            <Text style={[styles.lineText, { width: "20%" }]}>Operações</Text>
            <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {operations[0].companyValueChainObjectives}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {operations[0].companyValueChainGoals}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {operations[0].companyValueChainActionPlan}
            </Text>
        </View>
        {operations.length > 1 ? (
            <View>
                {operations.slice(1).map((item, index) => {
                    return (
                        <View key={index} style={styles.lines}>
                            <Text
                                style={[styles.lineText, { width: "20%" }]}
                            ></Text>

                            <Text style={[styles.lineText, { width: "5%" }]}>
                                {index + 2}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainObjectives}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainGoals}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainActionPlan}
                            </Text>
                        </View>
                    );
                })}
            </View>
        ) : null}
        <View style={styles.lines}>
            <Text style={[styles.lineText, { width: "20%" }]}>
                Vendas e Serviços
            </Text>
            <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {service[0].companyValueChainObjectives}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {service[0].companyValueChainGoals}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {service[0].companyValueChainActionPlan}
            </Text>
        </View>
        {service.length > 1 ? (
            <View>
                {service.slice(1).map((item, index) => {
                    return (
                        <View key={index} style={styles.lines}>
                            <Text
                                style={[styles.lineText, { width: "20%" }]}
                            ></Text>

                            <Text style={[styles.lineText, { width: "5%" }]}>
                                {index + 2}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainObjectives}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainGoals}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainActionPlan}
                            </Text>
                        </View>
                    );
                })}
            </View>
        ) : null}
        <View style={styles.lines}>
            <Text style={[styles.lineText, { width: "20%" }]}>
                Supply Chain
            </Text>
            <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {supply[0].companyValueChainObjectives}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {supply[0].companyValueChainGoals}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {supply[0].companyValueChainActionPlan}
            </Text>
        </View>
        {supply.length > 1 ? (
            <View>
                {supply.slice(1).map((item, index) => {
                    return (
                        <View key={index} style={styles.lines}>
                            <Text
                                style={[styles.lineText, { width: "20%" }]}
                            ></Text>

                            <Text style={[styles.lineText, { width: "5%" }]}>
                                {index + 2}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainObjectives}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainGoals}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainActionPlan}
                            </Text>
                        </View>
                    );
                })}
            </View>
        ) : null}
        <View style={styles.lines}>
            <Text style={[styles.lineText, { width: "20%" }]}>Tecnologia</Text>
            <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {tech[0].companyValueChainObjectives}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {tech[0].companyValueChainGoals}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {tech[0].companyValueChainActionPlan}
            </Text>
        </View>
        {tech.length > 1 ? (
            <View>
                {tech.slice(1).map((item, index) => {
                    return (
                        <View key={index} style={styles.lines}>
                            <Text
                                style={[styles.lineText, { width: "20%" }]}
                            ></Text>

                            <Text style={[styles.lineText, { width: "5%" }]}>
                                {index + 2}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainObjectives}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainGoals}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainActionPlan}
                            </Text>
                        </View>
                    );
                })}
            </View>
        ) : null}
        <View style={styles.lines}>
            <Text style={[styles.lineText, { width: "20%" }]}>Finanças</Text>
            <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {finance[0].companyValueChainObjectives}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {finance[0].companyValueChainGoals}
            </Text>
            <Text style={[styles.lineText, { width: "25%" }]}>
                {finance[0].companyValueChainActionPlan}
            </Text>
        </View>
        {finance.length > 1 ? (
            <View>
                {finance.slice(1).map((item, index) => {
                    return (
                        <View key={index} style={styles.lines}>
                            <Text
                                style={[styles.lineText, { width: "20%" }]}
                            ></Text>

                            <Text style={[styles.lineText, { width: "5%" }]}>
                                {index + 2}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainObjectives}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainGoals}
                            </Text>
                            <Text style={[styles.lineText, { width: "25%" }]}>
                                {item.companyValueChainActionPlan}
                            </Text>
                        </View>
                    );
                })}
            </View>
        ) : null}
    </View>
);

export default Premissas;
