import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../marketdynamicoverview.module.css';

function MarketDynamicShare(props) {
    const {values,setFieldValue} = useFormikContext();
      const [field, meta] = useField(props);
    
      React.useEffect(() => {
        if (values[props.values][props.index].marketDynamicCustomer.length) {
            let totalMarket = values[props.values][props.index].marketDynamicCustomer[props.indexmdp].marketDynamicTotal;
            let total = values[props.values][props.index].marketDynamicSegmentTotal;
            if(total === 0 || totalMarket === 0){
                setFieldValue(props.name, 0);
            }else{
                let share = parseFloat((totalMarket * 100)/total);
                share = parseFloat(share.toFixed(2));
                if(share){
                    setFieldValue(props.name, share);
                }
            }
        }
  
      }, [props.index, props.indexmdp, values, props.values, setFieldValue, props.name]);
    
      return (
        <>
          <input className={`w-100 p-1 rounded ${css.input_border}`} {...props} {...field} />
          {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
      );
}

export default MarketDynamicShare;
