import React, {useState, useEffect} from 'react';
import {Bar} from "react-chartjs-2";
import {allColors} from '../css/colors';

const months = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];

const datasetItem = {
    label: '',
    data: [0,0,0,0,0,0,0,0,0,0,0,0],
    backgroundColor: [
        'black',
        'white',
    ],
    borderColor: "white",
    borderWidth: 1,
}

const body = {
    labels: months,
    datasets: [
        datasetItem
    ],
}

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
            align: "start"
        }
    },
    scales: {
        yAxes: [
            {
                stacked: true,
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
        xAxes: [
            {
                stacked: true,
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
}

function InvestimentPredictionGraphic(props) {
    const [dataBody, setDataBody] = useState(body);

    useEffect(()=>{
        if(props.marketing.length > 0){
            const expenses = props.marketing[0].companyValueChainExpenses;
            const invest = expenses.companyValueChainExpenses.filter(exp=>exp.companyValueChainInvestiment);
            const investBody = invest.map(hit=>{
                return {
                    expense: hit.companyValueChainDescription,
                    values: hit.companyValueChainExpenses,
                    total: hit.companyValueChainTotal
                }
            });

            let total =0;
            const body = {
                labels: months,
                datasets: []
            }
            let data;
            investBody.forEach((item, index)=>{
                let monthDescr = [
                    parseFloat(item.values.jan),
                    parseFloat(item.values.fev),
                    parseFloat(item.values.mar),
                    parseFloat(item.values.abr),
                    parseFloat(item.values.mai),
                    parseFloat(item.values.jun),
                    parseFloat(item.values.jul),
                    parseFloat(item.values.ago),
                    parseFloat(item.values.set),
                    parseFloat(item.values.out),
                    parseFloat(item.values.nov),
                    parseFloat(item.values.dez)
                ]
                total = total + item.total;

                data = {
                    label: `${item.expense}`,
                    data: monthDescr,
                    backgroundColor: [
                        allColors[index],
                        allColors[index],
                    ],
                    borderColor: 'white',
                    borderWidth: 1,
                    stack: 'stack'
                }
                body.datasets.push(data);
            });

            setDataBody(body);
        }

    }, [props.marketing]);

    return (
        <div>
            <Bar
                data={dataBody}
                options={options}
                height={300}
            />
        </div>
    );
}

export default InvestimentPredictionGraphic;