import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    marginSection: {
        marginLeft: 28,
    },
    paragraph: {
        fontSize: 11,
        marginLeft: 12,
        marginBottom: 8,
    },
    subTitle: {
        fontSize: 12,
        color: "#1e0f70",
        marginBottom: 4,
    },
});

const MacroAmbForce = ({ macroEnv }) => (
    <>
        <View style={styles.marginSection}>
            <Text style={styles.subTitle}> Culturais </Text>
            {macroEnv.macroEnvCulture.map((item, index) => (
                <Text style={styles.paragraph} key={index}>
                    - {item.macroEnvCulture}
                </Text>
            ))}
            <Text style={styles.subTitle}> Demográficas </Text>
            {macroEnv.macroEnvDemography.map((item, index) => (
                <Text style={styles.paragraph} key={index}>
                    - {item.macroEnvDemography}
                </Text>
            ))}
            <Text style={styles.subTitle}> Econômicas </Text>
            {macroEnv.macroEnvEconomy.map((item, index) => (
                <Text style={styles.paragraph} key={index}>
                    - {item.macroEnvEconomy}
                </Text>
            ))}
            <Text style={styles.subTitle}> Naturais </Text>
            {macroEnv.macroEnvNature.map((item, index) => (
                <Text style={styles.paragraph} key={index}>
                    - {item.macroEnvNature}
                </Text>
            ))}
            <Text style={styles.subTitle}> Políticas </Text>
            {macroEnv.macroEnvPolitics.map((item, index) => (
                <Text style={styles.paragraph} key={index}>
                    - {item.macroEnvPolitics}
                </Text>
            ))}
            <Text style={styles.subTitle}> Tecnológicas </Text>
            {macroEnv.macroEnvTechnology.map((item, index) => (
                <Text style={styles.paragraph} key={index}>
                    - {item.macroEnvTechnology}
                </Text>
            ))}
        </View>
    </>
);

export default MacroAmbForce;
