import React from 'react';

function BudgetAssumptionTable({objectBody, title}) {
    return (
        <tr>
            <td>{title}</td>
            <td>1</td>
            <td>{objectBody[0].companyValueChainObjectives}</td>
            <td>{objectBody[0].companyValueChainGoals}</td>
            <td>{objectBody[0].companyValueChainActionPlan}</td>
        </tr>
    );
}

export default BudgetAssumptionTable;