import React from 'react';
import FormInput from '../../../../components/FormInput';
import FormInputDebounce from "../../../../components/FormInputDebounce";
import css from '../../../companyoverview.module.css';
import MonthTotal from './MonthTotal';

function Month({values, month, name, index, subindex}) {
    return (
        <>
            <label className="w-100">{month}</label>
            <div className={`${css.input_label}`}><label htmlFor={name}>Total</label></div>
            <MonthTotal
                name={`${name}[${month.toLowerCase()}].total`}
                month={month.toLowerCase()}
                index={index}
                subindex={subindex}
                disabled={true}
            />
            <FormInputDebounce
                name={`${name}[${month.toLowerCase()}].price`}
                values={values[month.toLowerCase()].price}
                type="currency"
            >
                Preço
            </FormInputDebounce>
            <FormInputDebounce
                name={`${name}[${month.toLowerCase()}].unit`}
                values={values[month.toLowerCase()].unit}
            >
                Unidade
            </FormInputDebounce>
        </>
    )
}

export default Month
