import React from "react";
import { Switch, withRouter } from "react-router-dom";
import Portal from "../../components/Portal";
import BusinessPlan from "./BusinessPlan";
import Warning from "../../components/warnings/Warning";
import UserManagement from "../../pages/UserManagement";
import OnBoard from "../../pages/OnBoard";
import UserProvider from "../../providers/UserProvider";
import PrivateRoute from "../guards/PrivateRoute";
import ThemeProvider from "../../providers/ThemeProvider";
import Tour from "../../pages/Tour";

function AppRouter(props) {
    return (
        <ThemeProvider>
            <UserProvider>
                <Switch>
                    <PrivateRoute isPrivate exact path="/" component={Portal} />
                    <PrivateRoute
                        isPrivate
                        path="/forms"
                        component={(props) => <BusinessPlan {...props} />}
                    />
                    <PrivateRoute
                        isPrivate
                        path="/user-management"
                        render={(props) => <UserManagement {...props} />}
                    />
                    <PrivateRoute
                        isPrivate
                        path="/onboard"
                        render={(props) => <OnBoard {...props} />}
                    />
                    <PrivateRoute
                        isPrivate
                        path="/warning"
                        component={Warning}
                    />
                    <PrivateRoute
                        isPrivate
                        path="/tour"
                        render={(props) => <Tour {...props} />}
                    />
                </Switch>
            </UserProvider>
        </ThemeProvider>
    );
}

export default withRouter(AppRouter);
