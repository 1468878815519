import React, {useEffect, useState} from 'react';
import { PolarArea } from 'react-chartjs-2';
import {Row} from 'react-bootstrap';
import {allColors} from '../css/colors';

const genData = () => ({
    labels: ['Segmento 1', 'Segmento 2', 'Segmento 3'],
    datasets: [
        {
            label: '%',
            data: [0, 0, 0],
            backgroundColor: allColors,
            borderWidth: 1,
        },
    ],
});

function TotalCompetitorsGraphic(props) {
    const [graphData, setGraphData] = useState(genData());

    useEffect(()=>{
        const direct = props.direct || [];
        const indirect = props.indirect || [];
        const totalCompetitors = direct.concat(indirect);
        const data = genData();
        data.labels= [];
        data.datasets[0].data = [];
        const total = totalCompetitors.reduce((acc, value)=>acc + value.marketDynamicSegmentTotal, 0);

        totalCompetitors.forEach(item=>{
            const part = parseInt(((parseFloat(item.marketDynamicSegmentTotal) * 100) / total).toFixed());
            data.labels.push(item.marketDynamicSegment);
            data.datasets[0].data.push(part);
        });

        setGraphData(data);

    }, [props.direct, props.indirect]);

    return (
        <>
            <div className="card__header--home">
                <h6 className="mb-0">Porcentagem de participação do segmento</h6>
            </div>

            <hr />

            <Row className="p-4 h-100">
                <PolarArea data={graphData} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'right',
                            align: "start"
                        }
                    }
                }}/>
            </Row>
        </>
    );
}

export default TotalCompetitorsGraphic;