import React from "react";
import {FieldArray} from "formik";
import {Col, Row} from "react-bootstrap";
import Month from "./Month";
import MonthTotalizer from "./MonthTotalizer";
import FormInputDebounce from "../../../../components/FormInputDebounce";
// import Totalizers from "../../services-sales/components/Totalizers";
const indexName = "companyValueChainBudget";

function BudgetCostProjection({item, values, index, name}){
    return (
        <FieldArray name={`[${indexName}][${index}][${name}]`}>
            {()=>(
                <div className="mt-4 mb-4">
                    {item[name]?.length > 0 &&
                    item[name]?.map((subItem, subIndex)=>(
                        <Row className="p-0 mb-5 m-0" key={subIndex}>
                            <Col className="p-0 m-0" lg={1}>
                                <label className="w-100">Produto</label>
                                <FormInputDebounce
                                    name={`[${indexName}][${index}][${name}][${subIndex}].companyValueChainProduct`}
                                    values={values[indexName][index][name][subIndex].companyValueChainProduct}
                                    type="text"
                                >
                                    Item
                                </FormInputDebounce>
                            </Col>
                            <Col className="p-0 m-0" lg={10}>
                                <Row className="p-0 m-0">
                                    <Col className="p-0 m-0" lg={12}>
                                        <Row className="p-0 m-0">
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Jan"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Fev"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Mar"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Abr"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Mai"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Jun"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Jul"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Ago"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Set"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Out"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Nov"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center">
                                                <Month
                                                    month="Dez"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 p-0 m-0 text-center">
                                                <MonthTotalizer
                                                    name={`[${indexName}][${index}][${name}][${subIndex}]`}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                    subIndex={subIndex}
                                                    index={index}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="p-0 m-0" lg={1}>
                                        <Row className="p-0 m-0">
                                            <Col className="p-0 m-0 text-center" lg={12}>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    ))
                    }
                </div>
            )}
        </FieldArray>
    )
}

export default BudgetCostProjection;