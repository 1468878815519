import * as Yup from "yup";

export const initialGeneralValues = {
    companyName: "",
    companyFantasy: "",
    companyNumberEmployees: "",
    companyTotalSales: "",
    companyMarket: {
        companyMarket: [
            {
                companyMarket: "",
                companySegment: [
                    {
                        companySegment: "seg1",
                        companyMarketSize: 0,
                        companyMarketUnity: "",
                    },
                ],
                companyMarketTotal: 0,
            },
        ],
        companyMarketTotal: 0,
    },
    companyTargetProblem: "",
    companyMainNeeds: "",
    companyProduct: [
        {
            companyProduct: "",
        },
    ],
    companyCompatetivesAdvantages: [
        {
            companyCompatetivesAdvantages: "",
        },
    ],
    companyValueProposition: "",
    companyClientProfile: [
        {
            companyClientProfile: "",
        },
    ],
    companyBusinessModel: [],
    companyWayToAggregate: "",
    strategicGoals: [
        {
            strategicGoals: "",
        },
    ],
    companyGeneralESG: [
        {
            companyGeneralEnvironmental: [
                {
                    companyGeneralEnvironmental: "",
                },
            ],
            companyGeneralSocial: [
                {
                    companyGeneralSocial: "",
                },
            ],
            companyGeneralGovernance: [
                {
                    companyGeneralGovernance: "",
                },
            ],
        },
    ],
};

export const CompanyOverviewSchema = Yup.object().shape({
    companyName: Yup.string().min(10, "Muito curto!").max(200, "Muito Longo"),
    companyFantasy: Yup.string().min(5, "Muito curto!").max(200, "Muito Longo"),
    companyNumberEmployees: Yup.number(),
    companyTotalSales: Yup.number(),
    companyMarket: Yup.object().shape({
        companyMarket: Yup.array().of(
            Yup.object().shape({
                companyMarket: Yup.string()
                    .min(5, "Muito curto!")
                    .max(100, "Muito Longo"),
                companySegment: Yup.array().of(
                    Yup.object().shape({
                        companySegment: Yup.string(),
                        companyMarketSize: Yup.number(),
                        companyMarketUnity: Yup.string(),
                    })
                ),
                companyMarketTotal: Yup.number(),
            })
        ),
    }),
    companyTargetProblem: Yup.string()
        .min(10, "Muito curto!")
        .max(800, "Muito Longo"),
    companyMainNeeds: Yup.string()
        .min(10, "Muito curto!")
        .max(800, "Muito Longo"),
    companyProduct: Yup.array().of(
        Yup.object().shape({
            companyProduct: Yup.string()
                .min(5, "Muito curto!")
                .max(100, "Muito Longo"),
        })
    ),
    companyCompatetivesAdvantages: Yup.array().of(
        Yup.object().shape({
            companyCompatetivesAdvantages: Yup.string()
                .min(5, "Muito curto!")
                .max(800, "Muito Longo"),
        })
    ),
    companyValueProposition: Yup.string()
        .min(10, "Muito curto!")
        .max(800, "Muito Longo"),
    companyClientProfile: Yup.array().of(
        Yup.object().shape({
            companyClientProfile: Yup.string()
                .min(5, "Muito curto!")
                .max(100, "Muito Longo"),
        })
    ),
    companyBusinessModel: Yup.array(),
    companyWayToAggregate: Yup.string()
        .min(10, "Muito curto!")
        .max(800, "Muito Longo"),
    strategicGoals: Yup.array().of(
        Yup.object().shape({
            companyCompatetivesAdvantages: Yup.string()
                .min(5, "Muito curto!")
                .max(800, "Muito Longo"),
        })
    ),
    companyGeneralESG: Yup.array().of(
        Yup.object().shape({
            companyGeneralEnvironmental: Yup.array().of(
                Yup.object().shape({
                    companyGeneralEnvironmental: Yup.string()
                        .min(10, "Muito curto!")
                        .max(300, "Muito Longo"),
                })
            ),
            companyGeneralSocial: Yup.array().of(
                Yup.object().shape({
                    companyGeneralSocial: Yup.string()
                        .min(10, "Muito curto!")
                        .max(300, "Muito Longo"),
                })
            ),
            companyGeneralGovernance: Yup.array().of(
                Yup.object().shape({
                    companyGeneralGovernance: Yup.string()
                        .min(10, "Muito curto!")
                        .max(300, "Muito Longo"),
                })
            ),
        })
    ),
});
