import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    mainView: {},
    containerInterna: {
        width: "62.5%",
        marginLeft: "auto",
    },
    analiseInternaView: {
        padding: 4,
        backgroundColor: "grey",
    },
    analiseExternaView: {
        padding: 0,
        width: 35,
        backgroundColor: "grey",
    },
    externaText: {
        padding: 0,
        marginBottom: "auto",
        textAlign: "center",
        color: "#fff",
        fontSize: 10,
    },
    analiseText: {
        padding: 0,
        marginTop: "auto",
        textAlign: "center",
        color: "#fff",
        fontSize: 10,
    },
    analiseInternaText: {
        padding: 0,
        margin: 0,
        textAlign: "center",
        fontSize: 10,
        color: "#fff",
    },
    row: {
        flexDirection: "row",
    },
    forcaView: {
        backgroundColor: "#608DC4",
        padding: 8,
        flex: 1,
    },
    fraquezaView: {
        backgroundColor: "#DE9E36",
        padding: 8,
        flex: 1,
    },
    oportunidadeView: {
        backgroundColor: "#D55E60",
        padding: 8,
        flex: 1,
    },
    ataqueView: {
        backgroundColor: "#ad60c4",
        padding: 8,
        flex: 1,
    },
    crescimentoView: {
        backgroundColor: "#3677ad",
        padding: 8,
        flex: 1,
    },
    ameacaView: {
        backgroundColor: "#9ECA73",
        padding: 8,
        flex: 1,
    },
    sobrevivenciaView: {
        backgroundColor: "#4aaf10",
        padding: 8,
        flex: 1,
    },
    manutencaoView: {
        backgroundColor: "#60c4b7",
        padding: 8,
        flex: 1,
    },
    forcaList: {},
    textCenter: {
        textAlign: "center",
        color: "#FFF",
        marginBottom: 6,
        fontSize: 8,
    },
    item: {
        color: "#FFF",
        borderWidth: 1,
        borderColor: "#FFF",
        borderRadius: 8,
        textAlign: "center",
        padding: 4,
        fontSize: 8,
    },
});

const SwotPdf = ({ swot }) => {
    return (
        <View style={styles.mainView}>
            <View style={styles.containerInterna}>
                <View style={styles.analiseInternaView}>
                    <Text style={styles.analiseInternaText}>
                        Análise Interna
                    </Text>
                </View>
                <View style={{ flexDirection: "row" }}>
                    <View style={styles.forcaView}>
                        <Text style={styles.textCenter}>Forças</Text>
                        <View style={styles.forcaList}>
                            <View style={styles.forcaList}>
                                {swot.marketDynamicStrengths.map(
                                    (item, index) => (
                                        <Text style={styles.item} key={index}>
                                            {item.marketDynamicStrengths}
                                        </Text>
                                    )
                                )}
                            </View>
                        </View>
                    </View>
                    <View style={styles.fraquezaView}>
                        <Text style={styles.textCenter}>Fraquezas</Text>
                        <View style={styles.forcaList}>
                            {swot.marketDynamicWeaknesses.map((item, index) => (
                                <Text style={styles.item} key={index}>
                                    {item.marketDynamicWeaknesses}
                                </Text>
                            ))}
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.analiseExternaView}>
                    <Text style={styles.analiseText}>Análise</Text>
                </View>
                <View style={styles.oportunidadeView}>
                    <Text style={styles.textCenter}>Oportunidades</Text>
                    <View style={styles.forcaList}>
                        {swot.marketDynamicOpportunities.map((item, index) => (
                            <Text style={styles.item} key={index}>
                                {item.marketDynamicOpportunities}
                            </Text>
                        ))}
                    </View>
                </View>
                <View style={styles.ataqueView}>
                    <Text style={styles.textCenter}>Estratégica de Ataque</Text>
                    <View style={styles.forcaList}>
                        {swot.marketDynamicAttack.map((item, index) => (
                            <Text style={styles.item} key={index}>
                                {item.marketDynamicAttack}
                            </Text>
                        ))}
                    </View>
                </View>
                <View style={styles.crescimentoView}>
                    <Text style={styles.textCenter}>
                        Estratégica de Crescimento
                    </Text>
                    <View style={styles.forcaList}>
                        {swot.marketDynamicGrowth.map((item, index) => (
                            <Text style={styles.item} key={index}>
                                {item.marketDynamicGrowth}
                            </Text>
                        ))}
                    </View>
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.analiseExternaView}>
                    <Text style={styles.externaText}>Externa</Text>
                </View>
                <View style={styles.ameacaView}>
                    <Text style={styles.textCenter}>Ameaças</Text>
                    <View style={styles.forcaList}>
                        {swot.marketDynamicThreats.map((item, index) => (
                            <Text style={styles.item} key={index}>
                                {item.marketDynamicThreats}
                            </Text>
                        ))}
                    </View>
                </View>

                <View style={styles.manutencaoView}>
                    <Text style={styles.textCenter}>Manutenção</Text>
                    <View style={styles.forcaList}>
                        {swot.marketDynamicMaint.map((item, index) => (
                            <Text style={styles.item} key={index}>
                                {item.marketDynamicMaint}
                            </Text>
                        ))}
                    </View>
                </View>
                <View style={styles.sobrevivenciaView}>
                    <Text style={styles.textCenter}>
                        Estratégica de Sobrevivencia
                    </Text>
                    <View style={styles.forcaList}>
                        {swot.marketDynamicSurvival.map((item, index) => (
                            <Text style={styles.item} key={index}>
                                {item.marketDynamicSurvival}
                            </Text>
                        ))}
                    </View>
                </View>
            </View>
        </View>
    );
};

export default SwotPdf;
