import React, {useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';

const months = ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'];

const datasetItem = {
    label: 'Total of 0 ',
    data: [0,0,0,0,0,0,0,0,0,0,0,0],
    backgroundColor: [
        "#26B677",
        "#062F50",
    ],
    borderColor: "white",
    borderWidth: 1,
}

const body = {
    labels: months,
    datasets: [
        datasetItem
    ]
}

const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
    plugins: {
        legend: {
            position: 'right',
            align: "start"
        }
    },
}

function TotalPredictionGraphic(props) {
    const [dataBody, setDataBody] = useState(body);

    useEffect(() => {
        if(props.serviceSales.length > 0){
            //General totalizers
            const totalizer = props.serviceSales[0].generalTotalizer;
            //Carregando o dados de serviços e produtos
            const budgetServiceData = props.serviceSales[0].total;

            let dataGraph = {
                datasets: [
                    {
                        label: `Total of ${totalizer}`,
                        data: [
                            budgetServiceData.jan.totalMain,
                            budgetServiceData.fev.totalMain,
                            budgetServiceData.mar.totalMain,
                            budgetServiceData.abr.totalMain,
                            budgetServiceData.mai.totalMain,
                            budgetServiceData.jun.totalMain,
                            budgetServiceData.jul.totalMain,
                            budgetServiceData.ago.totalMain,
                            budgetServiceData.set.totalMain,
                            budgetServiceData.out.totalMain,
                            budgetServiceData.nov.totalMain,
                            budgetServiceData.dez.totalMain],
                        backgroundColor: [
                            "#26B677",
                            "#062F50",
                        ],
                        borderColor: "white",
                        borderWidth: 1,
                    }
                ]
            }

            setDataBody({...dataBody, ...dataGraph});
        }
    }, [props.serviceSales]);


    return (
        <div>
            <Bar
                data={dataBody}
                options={options}
                height={300}
            />
        </div>
    );
}

export default TotalPredictionGraphic;