import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import astronauta from '../../images/astronauta.jpg';

function Warning(props){
    return (
        <Container fluid>
            <Row className="justify-content-center" style={{marginTop: '100px'}}>
                <Col className="offset-3 text-left">
                    <div><span style={{fontSize: '5rem', color: 'grey'}}>500</span></div>
                    <div><span style={{fontSize: '3rem', color: 'black'}}>Ops!</span></div>
                    <div><span style={{fontSize: '1.5rem', color: 'lightgray'}}>Parece que os servidores estão fora de órbita.</span></div>
                </Col>
                <Col className="p-0 m-0">
                    <img src={astronauta} alt=""/>
                </Col>
            </Row>
        </Container>
    )
}

export default Warning;