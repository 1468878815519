import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import useSpinner from '../../../spinner/useSpinner';
import {getQuestions} from '../../../../services/formData';
import HumanResources from './human-resources/HumanResources';
import Marketing from './marketing/Marketing';
import SupplyChain from './supply-chain/SupplyChain';
import Technology from './technology/Technology';
import FinanceGovernance from './finance-governance/FinanceGovernance';
import ServicesSales from './services-sales/ServicesSales';
import Operations from './operations/Operations';
import {withRouter} from 'react-router-dom';
import permissionEnum from '../../../../enums/permission';
import PrivateTab from '../../../PrivateTab';
import PrivateComponent from '../../../PrivateComponent';

function CadeiaValor(props) {
    const [questions, setQuestions] = useState({});
    const [tabIndex, setTabIndex] = useState(0);
    const [menu, setMenu] = useState([]);
    const [spinner, showSpinner, hideSpinner] = useSpinner();
    const [changed, setChanged] = useState(false);

    useEffect(()=>{
        showSpinner();
        console.log(props.group);
        const formSelected = props.formSelected;
        const group = props.group;
        const valueChainForm = props.forms.length ? props.forms.filter(hit=>hit.id === formSelected)[0] : [];
        const valueChainGroup = valueChainForm?.FormType?.MainGroups.filter(hit=>hit.id === group)[0];
        valueChainGroup.SubGroups.sort((a,b)=>a.menuOrder - b.menuOrder);
        setMenu(valueChainGroup.SubGroups);
        let isSubscribe = true; //Flag de subscrição
        getQuestions(formSelected, group, valueChainGroup.SubGroups[0].id, (err, questions)=>{
            if((questions && questions.error) || err){
                console.log(err);
                hideSpinner();
                props.history.push("/warning");
            }else{
                if(isSubscribe){
                    setQuestions(questions);
                    setTabIndex(0);
                }
                hideSpinner();
            }
        });

        return ()=>(isSubscribe = false);
    }, [props.forms, props.group, props.formSelected]);

    function handleSelectQuestion(subGroup, index){
        console.log(changed)
        if(changed){
            const response = window.confirm("Voce alterou o formulário. Seus dados serão perdidos. :( Gostaria de continuar sem salvar ?");
            if(!response){
                setTabIndex(tabIndex);
            }else{
                setChanged(false);
                setTabIndex(index);
                loadingTabQuestions(subGroup);
            }
        }else{
            setTabIndex(index);
            loadingTabQuestions(subGroup);
        }
    }

    function loadingTabQuestions(subGroup){
        setQuestions({});
        console.log(props.formSelected, props.group, subGroup);
        showSpinner();
        getQuestions(props.formSelected, props.group, subGroup, (err, questions)=>{
            if(err){
                console.log(err);
                hideSpinner();
                props.history.push("/warning");
            }else{
                setQuestions(questions);
                hideSpinner();
            }
        });
    }

    return (
        <>
        {spinner}
            <Tabs selectedIndex={tabIndex} onSelect={()=>{}}>
                <TabList className="react-tabs__tab-list sub-tab">
                    <PrivateTab permission={permissionEnum.SUB_GROUP.RH}>
                        <Tab 
                            onClick={()=> handleSelectQuestion(menu[0].id, 0)}>
                                {menu.length > 0 ? menu[0].title : "Loading..."}
                        </Tab>
                    </PrivateTab>
                    <PrivateTab permission={permissionEnum.SUB_GROUP.MARKETING}>
                        <Tab 
                            onClick={()=>handleSelectQuestion(menu[1].id, 1)}>
                                    {menu.length > 0 ? menu[1].title : "Loading..."}
                        </Tab>
                    </PrivateTab>
                    <PrivateTab permission={permissionEnum.SUB_GROUP.SALES}>
                        <Tab 
                            onClick={()=>handleSelectQuestion(menu[2].id, 2)}>
                                    {menu.length > 0 ? menu[2].title : "Loading..."}
                        </Tab>
                    </PrivateTab>
                    <PrivateTab permission={permissionEnum.SUB_GROUP.OPERATIONS}>
                        <Tab 
                            onClick={()=>handleSelectQuestion(menu[3].id, 3)}>
                                    {menu.length > 0 ? menu[3].title : "Loading..."}
                        </Tab>
                    </PrivateTab>
                    <PrivateTab permission={permissionEnum.SUB_GROUP.SUPPLY_CHAIN}>
                        <Tab 
                            onClick={()=>handleSelectQuestion(menu[4].id, 4)}>
                                    {menu.length > 0 ? menu[4].title : "Loading..."}
                        </Tab>
                    </PrivateTab>
                    <PrivateTab permission={permissionEnum.SUB_GROUP.TECNOLOGY}>
                        <Tab
                            onClick={()=>handleSelectQuestion(menu[5].id, 5)}>
                                    {menu.length > 0 ? menu[5].title : "Loading..."}
                        </Tab>
                    </PrivateTab>
                    <PrivateTab permission={permissionEnum.SUB_GROUP.FINANCES}>
                        <Tab
                            onClick={()=>handleSelectQuestion(menu[6].id, 6)}>
                                    {menu.length > 0 ? menu[6].title : "Loading..."}
                        </Tab>
                    </PrivateTab>
                </TabList>
                <TabPanel>
                    <PrivateComponent permission={permissionEnum.SUB_GROUP.RH}>
                        <HumanResources
                            submitting={props.submitting}
                            setSubmitting={props.setSubmitting}
                            group={props.group}
                            subGroup={menu[0]?.id}
                            questions={questions}
                            handleSubmit={props.componentSubmit}
                            setChanged={setChanged}
                        />
                    </PrivateComponent>
                </TabPanel>
                <TabPanel>
                    <PrivateComponent permission={permissionEnum.SUB_GROUP.MARKETING}>
                        <Marketing
                            submitting={props.submitting}
                            setSubmitting={props.setSubmitting}
                            group={props.group}
                            subGroup={menu[1]?.id}
                            questions={questions}
                            handleSubmit={props.componentSubmit}
                            setChanged={setChanged}
                        />
                    </PrivateComponent>
                </TabPanel>
                <TabPanel>
                    <PrivateComponent permission={permissionEnum.SUB_GROUP.SALES}>
                        <ServicesSales
                            submitting={props.submitting}
                            setSubmitting={props.setSubmitting}
                            group={props.group}
                            subGroup={menu[2]?.id}
                            questions={questions}
                            handleSubmit={props.componentSubmit}
                            setChanged={setChanged}
                        />
                    </PrivateComponent>
                </TabPanel>
                <TabPanel>
                    <PrivateComponent permission={permissionEnum.SUB_GROUP.OPERATIONS}>
                        <Operations
                            submitting={props.submitting}
                            setSubmitting={props.setSubmitting}
                            formSelected={props.formSelected} //Passando o formulário selecionado
                            group={props.group}
                            subGroup={menu[3]?.id}
                            sales={menu[2]?.id} //Usado para recuperar os dados do formulário de sales & services
                            questions={questions}
                            handleSubmit={props.componentSubmit}
                            setChanged={setChanged}
                        />
                    </PrivateComponent>
                </TabPanel>
                <TabPanel>
                    <PrivateComponent permission={permissionEnum.SUB_GROUP.SUPPLY_CHAIN}>
                        <SupplyChain
                            submitting={props.submitting}
                            setSubmitting={props.setSubmitting}
                            group={props.group}
                            subGroup={menu[4]?.id}
                            questions={questions}
                            handleSubmit={props.componentSubmit}
                            setChanged={setChanged}
                        />
                    </PrivateComponent>
                </TabPanel>
                <TabPanel>
                    <PrivateComponent permission={permissionEnum.SUB_GROUP.TECNOLOGY}>
                        <Technology
                            submitting={props.submitting}
                            setSubmitting={props.setSubmitting}
                            group={props.group}
                            subGroup={menu[5]?.id}
                            questions={questions}
                            handleSubmit={props.componentSubmit}
                            setChanged={setChanged}
                        />
                    </PrivateComponent>
                </TabPanel>
                <TabPanel>
                    <PrivateComponent permission={permissionEnum.SUB_GROUP.FINANCES}>
                        <FinanceGovernance
                            submitting={props.submitting}
                            setSubmitting={props.setSubmitting}
                            group={props.group}
                            subGroup={menu[6]?.id}
                            questions={questions}
                            handleSubmit={props.componentSubmit}
                            setChanged={setChanged}
                        />
                    </PrivateComponent>
                </TabPanel>
            </Tabs>
        </>
    )
}

export default withRouter(CadeiaValor)
