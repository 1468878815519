import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { format } from "date-fns";

const styles = StyleSheet.create({
    mainView: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
});

const Capa = ({ companyName }) => (
    <View style={styles.mainView}>
        <Text style={{ fontSize: 14 }}>{companyName}</Text>
        <Text style={{ fontSize: 14 }}>{format(new Date(), "dd/MM/yyyy")}</Text>
    </View>
);

export default Capa;
