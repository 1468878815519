import React from 'react';
import {Row, Col} from "react-bootstrap";
import { useUserContext } from '../../providers/UserProvider';
import PersonalDataUnit from "./PersonalDataUnit";

function PersonalData({data}) {
    const user = useUserContext();
    const parsedData = {...data, companyTotalSales: data.companyTotalSales && parseFloat(data.companyTotalSales).toLocaleString('pt-BR', { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' })}; 
    return (
        <div className="border p-4 rounded mb-4 bg-white" >
            <div className="card__header--home">
                <h4 className="mb-0">Bem vindo de volta {user?.data?.firstName}!</h4>    
            </div>

            <hr />

            <Row>
                <Col>
                    <PersonalDataUnit
                        title="Empresa"
                        data={data}
                        index="companyName"
                    />
                </Col>
                <Col>
                    <PersonalDataUnit
                        title="Fantasia"
                        data={data}
                        index="companyFantasy"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <PersonalDataUnit
                        title="Principal Necessidade"
                        data={data}
                        index="companyMainNeeds"
                    />
                </Col>
                <Col>
                    <PersonalDataUnit
                        title="Problema alvo"
                        data={data}
                        index="companyTargetProblem"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <PersonalDataUnit
                        title="Total de vendas"
                        data={parsedData}
                        index="companyTotalSales"
                    />
                </Col>
                <Col>
                    <PersonalDataUnit
                        title="Número de funcionários "
                        data={data}
                        index="companyNumberEmployees"
                    />
                </Col>
            </Row>
        </div>
    );
}

export default PersonalData;