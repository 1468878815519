import React, { createContext, useCallback, useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useSpinner from '../components/spinner/useSpinner';
import { getAuthUser } from '../services/auth/auth';
import { getUserData } from '../services/users/endpoint';
import { useAuth } from './AuthProvider';

const UserContext = createContext({});

export function useUserContext() {
  return useContext(UserContext);
}

function UserProvider({ children }) { 
  const [data, setData] = useState(null);
  
  const [loader, showLoader, hideLoader] = useSpinner();

  const { getAccessToken } = useAuth();

  const history = useHistory();

  const init = useCallback(async () => {
    const accessToken = await getAccessToken();

    return getUserData(accessToken)
        .then((res) => {
          setData(res.data)
          hideLoader()
          if (!res.data.companyId) history.replace('/onboard')
        }).then(() => hideLoader()); 
  }, [])

  useEffect(() => {
    if (!getAuthUser()) {
      return
    }

    showLoader();
    init()
  }, [init]);

  return (
    <UserContext.Provider value={{
      data,
      setData,
      fetchUserData: init
    }}>
      {loader}
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider;