import React from 'react';
import { Field, ErrorMessage} from 'formik';
import css from '../../companyoverview.module.css';

function CompanyBusinessModel() {
    return (
        <>
            <div id="checkbox-group" className={`${css.input_label}`}><label>Qual o modelo de negocio ?</label></div>
            <div className="d-flex justify-content-around" role="group" aria-labelledby="checkbox-group">
                <label className="d-flex align-items-center mb-0">
                    <Field className="form-check-input" type="checkbox" name="companyBusinessModel" value="b2b" />
                    <span className="ml-2">B2B ( <i className="fas fa-building"></i><i className="fas fa-arrows-alt-h"></i><i className="fas fa-building"></i> )</span>
                </label>
                <label className="d-flex align-items-center mb-0">
                    <Field className="form-check-input" type="checkbox" name="companyBusinessModel" value="b2c" />
                    <span className="ml-2">B2C ( <i className="fas fa-building"></i><i className="fas fa-arrows-alt-h"></i><i className="fas fa-child"></i> )</span>
                </label>
                <label className="d-flex align-items-center mb-0">
                    <Field className="form-check-input" type="checkbox" name="companyBusinessModel" value="c2c" />
                    <span className="ml-2">C2C ( <i className="fas fa-child"></i><i className="fas fa-arrows-alt-h"></i><i className="fas fa-child"></i> )</span>
                </label>
            </div>
            <ErrorMessage
                name={"companyBusinessModel"}
                component="div"
                className={`field-error ${css.error_message}`}
            />
        </>
    )
}

export default CompanyBusinessModel;
