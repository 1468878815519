import React, { useState, useEffect } from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import {
    initialSupplierDynamicValues,
    SupplierDynamicSchema,
} from "./helpers/formValidation";
import { Formik, Form, Field } from "formik";
import FormInput from "../../components/FormInput";
import SupplierSharedYourCost from "./components/SupplierSharedYourCost";
import SupplierNegotiationPower from "./components/SupplierNegotiationPower";
import ImportanceMixCostumerSupplier from "./components/ImportanceMixCostumerSupplier";
import Button from "../../../Button";
import FormSelect from "../../../FormSelect";
import FormValidationError from "../../components/FormValidationError";
import { genElementsValue } from "../customer-dynamic/helpers/elementsOfValue";
// react-select
import CustomSelect from "../../../CustomSelect";

function SupplierDynamic(props) {
    const [formValues, setFormValues] = useState(initialSupplierDynamicValues);
    const [elements, setElements] = useState([]);

    useEffect(() => {
        let initialValues = {
            ...initialSupplierDynamicValues,
            ...props.questions,
        };
        setElements(genElementsValue());
        setFormValues(initialValues);
    }, [props.questions]);

    return (
        <Formik
            initialValues={formValues}
            validationSchema={SupplierDynamicSchema}
            validate={(values) => {
                props.setChanged(values !== formValues);
            }}
            onSubmit={(values) => {
                props.setSubmitting(true);
                props.supplierSubmit(values, props.group);
            }}
            enableReinitialize
        >
            {({ values, errors, touched }) => (
                <Form className="p-4">
                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Fornecedores Estratégicos (Poder de negociação)
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <SupplierSharedYourCost
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Poder de negociação por fornecedor
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <SupplierNegotiationPower
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Importância no MIX de clientes do fornecedor
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <ImportanceMixCostumerSupplier
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Col>
                            </Row>

                            <hr />

                            <Row>
                                <Col xs={6} lg={6}>
                                    <h6
                                        style={{
                                            fontSize: 16,
                                            marginTop: 4,
                                        }}
                                    >
                                        Elementos de Valor para sua empresa
                                    </h6>

                                    <Field
                                        className="basic-multi-select"
                                        name="marketDynamicElementsValue"
                                        options={elements}
                                        component={CustomSelect}
                                        placeholder="Selecione..."
                                        isMulti={true}
                                    />
                                </Col>

                                <Col xs={12} lg={4}>
                                    <FormSelect
                                        label="Risco de verticalização"
                                        name={`marketDynamicRiskVerticalization`}
                                        id={`marketDynamicRiskVerticalization`}
                                    >
                                        <option value="H">High</option>
                                        <option value="M">Medium</option>
                                        <option value="L">Low</option>
                                    </FormSelect>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="marketDynamicRiskVerticalizationComment"
                                            as="textarea"
                                            rows={3}
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com seu comentário"
                                        >
                                            Comentário sobre os riscos de
                                            verticalização
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="p-0 m-0 mt-3 justify-content-end">
                                {Object.keys(errors).length > 0 && (
                                    <FormValidationError errors={errors} />
                                )}
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button
                                            variant="success"
                                            disabled={props.submitting}
                                            type="submit"
                                        >
                                            Salvar informações
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default SupplierDynamic;
