import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { formatCurrency } from "../../utils/formatCurrency";

const styles = StyleSheet.create({
    marginSection: {
        marginLeft: 12,
    },
    header: {
        flexDirection: "row",
        paddingTop: 3,
        paddingBottom: 3,
    },
    headerText: {
        fontSize: 8,
        textAlign: "left",
    },
    headerMonths: {
        fontSize: 8,
        width: "8%",
        textAlign: "center",
    },
    recipeRow: {
        flexDirection: "row",
    },
});

const Orcamento = ({
    produtos,
    prodTotal,
    costs,
    margin,
    amort,
    depre,
    handleTotal,
    totDesp,
    despesaForm,
}) => {
    const calculoLucroLiquido = (month) => {
        let total;
        if (Object.keys(margin).length && depre && amort) {
            total =
                margin[month].totalMargin -
                totDesp[month] -
                parseFloat(depre[month]) -
                parseFloat(amort[month]);
        }
        return formatCurrency(total ? total : 0);
    };

    const calculoTotal = () => {
        const total =
            (margin?.total ? margin.total : 0) -
            totDesp.totalAnual -
            handleTotal(depre, null) -
            handleTotal(amort, null);
        return formatCurrency(total ? total : 0);
    };

    return (
        <View style={styles.marginSection}>
            <View
                style={[
                    styles.header,
                    { borderBottomWidth: 1, borderTopWidth: 1 },
                ]}
            >
                <Text style={[styles.headerText, { width: "11%" }]}>
                    Orçamento
                </Text>
                <Text style={[styles.headerText, { width: "15%" }]}>
                    Produto / Setor
                </Text>
                <Text style={styles.headerMonths}> Jan </Text>
                <Text style={styles.headerMonths}> Fev </Text>
                <Text style={styles.headerMonths}> Mar </Text>
                <Text style={styles.headerMonths}> Abr </Text>
                <Text style={styles.headerMonths}> Mai </Text>
                <Text style={styles.headerMonths}> Jun </Text>
                <Text style={styles.headerMonths}> Jul </Text>
                <Text style={styles.headerMonths}> Ago </Text>
                <Text style={styles.headerMonths}> Set </Text>
                <Text style={styles.headerMonths}> Out </Text>
                <Text style={styles.headerMonths}> Nov </Text>
                <Text style={styles.headerMonths}> Dez </Text>
                <Text style={styles.headerMonths}> Total </Text>
            </View>
            <View style={styles.recipeRow}>
                <Text
                    style={[
                        styles.headerText,
                        { width: "11%", marginTop: 4, borderBottomWidth: 1 },
                    ]}
                >
                    Receitas
                </Text>
                <View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            {produtos[0].companyValueChainProduct}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.jan["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.fev["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.mar["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.abr["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.mai["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.jun["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.jul["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.ago["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.set["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.out["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.nov["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.dez["total"]
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                produtos[0].companyValueChainBudget.total[
                                    "totalMain"
                                ]
                            )}
                        </Text>
                    </View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            Total dos produtos
                        </Text>

                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.jan)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.fev)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.mar)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.abr)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.mai)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.jun)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.jul)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.ago)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.set)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.out)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.nov)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.dez)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(prodTotal?.totalAnual)}
                        </Text>
                    </View>
                </View>
            </View>
            <View style={[styles.header, { borderBottomWidth: 1 }]}>
                <Text style={[styles.headerText, { width: "11%" }]}>
                    Custos Fixos
                </Text>
                <Text style={[styles.headerText, { width: "15%" }]}>
                    {costs[0].companyValueChainProduct}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.jan["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.fev["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.mar["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.abr["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.mai["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.jun["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.jul["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.ago["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.set["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.out["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.nov["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.dez["fixCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(costs[0]["fixCostTotal"])}
                </Text>
            </View>
            <View style={[styles.header, { borderBottomWidth: 1 }]}>
                <Text style={[styles.headerText, { width: "11%" }]}>
                    Custos Var
                </Text>
                <Text style={[styles.headerText, { width: "15%" }]}>
                    {costs[0].companyValueChainProduct}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.jan["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.fev["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.mar["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.abr["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.mai["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.jun["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.jul["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.ago["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.set["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.out["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.nov["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(
                        costs[0].companyValueChainBudget.dez["varCost"]
                    )}
                </Text>
                <Text style={styles.headerMonths}>
                    {formatCurrency(costs[0]["varCostTotal"])}
                </Text>
            </View>
            <View style={styles.recipeRow}>
                <Text
                    style={[
                        styles.headerText,
                        {
                            width: "11%",
                            marginTop: 6,
                            borderBottomWidth: 1,
                            textAlign: "center",
                        },
                    ]}
                >
                    Margem Bruta
                </Text>
                <View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            {costs[0].companyValueChainProduct}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.jan.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.fev.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.mar.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.abr.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.mai.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.jun.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.jul.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.ago.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.set.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.out.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.nov.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                costs[0].companyValueChainBudget.dez.margin
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(costs[0].marginTotal)}
                        </Text>
                    </View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text
                            style={[styles.headerText, { width: "15%" }]}
                        ></Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.jan?.totalMargin
                                    ? margin.jan.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.fev?.totalMargin
                                    ? margin.fev.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.mar?.totalMargin
                                    ? margin.mar.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.abr?.totalMargin
                                    ? margin.abr.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.mai?.totalMargin
                                    ? margin.mai.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.jun?.totalMargin
                                    ? margin.jun.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.jul?.totalMargin
                                    ? margin.jul.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.ago?.totalMargin
                                    ? margin.ago.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.set?.totalMargin
                                    ? margin.set.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.out?.totalMargin
                                    ? margin.out.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.nov?.totalMargin
                                    ? margin.nov.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                margin?.dez?.totalMargin
                                    ? margin.dez.totalMargin
                                    : 0
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(margin?.total ? margin.total : 0)}
                        </Text>
                    </View>
                </View>
            </View>
            <View style={styles.recipeRow}>
                <Text
                    style={[
                        styles.headerText,
                        { width: "11%", marginTop: 36, borderBottomWidth: 1 },
                    ]}
                >
                    Despesas
                </Text>
                <View>
                    <View style={[styles.header, , { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            Finanças
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jan
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.fev
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mar
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.abr
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mai
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jun
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jul
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.ago
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.set
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.out
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.nov
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal.dez
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[0].expenses
                                    ?.companyValueChainExpenses.monthsTotal
                                    .totalAnual
                            )}
                        </Text>
                    </View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            Tecnologia
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jan
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.fev
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mar
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.abr
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mai
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jun
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jul
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.ago
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.set
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.out
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.nov
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal.dez
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[1].expenses
                                    ?.companyValueChainExpenses.monthsTotal
                                    .totalAnual
                            )}
                        </Text>
                    </View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            Cadeia de Abastec
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jan
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.fev
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mar
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.abr
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mai
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jun
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jul
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.ago
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.set
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.out
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.nov
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal.dez
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[2].expenses
                                    ?.companyValueChainExpenses.monthsTotal
                                    .totalAnual
                            )}
                        </Text>
                    </View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            Operações
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jan
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.fev
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mar
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.abr
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mai
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jun
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jul
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.ago
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.set
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.out
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.nov
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal.dez
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[3].expenses
                                    ?.companyValueChainExpenses.monthsTotal
                                    .totalAnual
                            )}
                        </Text>
                    </View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            Vendas e Serviços
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jan
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.fev
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mar
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.abr
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mai
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jun
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jul
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.ago
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.set
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.out
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.nov
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal.dez
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[4].expenses
                                    ?.companyValueChainExpenses.monthsTotal
                                    .totalAnual
                            )}
                        </Text>
                    </View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            Marketing
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jan
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.fev
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mar
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.abr
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mai
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jun
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jul
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.ago
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.set
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.out
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.nov
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal.dez
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[5].expenses
                                    ?.companyValueChainExpenses.monthsTotal
                                    .totalAnual
                            )}
                        </Text>
                    </View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            RH
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jan
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.fev
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mar
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.abr
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.mai
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jun
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.jul
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.ago
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.set
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.out
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.nov
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal.dez
                                    .total
                            )}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                despesaForm[6].expenses
                                    ?.companyValueChainExpenses.monthsTotal
                                    .totalAnual
                            )}
                        </Text>
                    </View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            total de Despesas
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.jan ? totDesp.jan : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.fev ? totDesp.fev : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.mar ? totDesp.mar : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.abr ? totDesp.abr : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.mai ? totDesp.mai : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.jun ? totDesp.jun : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.jul ? totDesp.jul : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.ago ? totDesp.ago : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.set ? totDesp.set : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.out ? totDesp.out : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.nov ? totDesp.nov : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(totDesp.dez ? totDesp.dez : 0)}{" "}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {" "}
                            {formatCurrency(
                                totDesp.totalAnual ? totDesp.totalAnual : 0
                            )}{" "}
                        </Text>
                    </View>
                </View>
            </View>
            <View style={[styles.header, { borderBottomWidth: 1 }]}>
                <Text style={[styles.headerText, { width: "11%" }]}>
                    Resultado
                </Text>
                <Text style={[styles.headerText, { width: "15%" }]}></Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.jan?.totalMargin
                                ? margin?.jan?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.jan ? totDesp.jan : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.fev?.totalMargin
                                ? margin?.fev?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.fev ? totDesp.fev : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.mar?.totalMargin
                                ? margin?.mar?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.mar ? totDesp.mar : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.abr?.totalMargin
                                ? margin?.abr?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.abr ? totDesp.abr : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.mai?.totalMargin
                                ? margin?.mai?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.mai ? totDesp.mai : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.jun?.totalMargin
                                ? margin?.jun?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.jun ? totDesp.jun : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.jul?.totalMargin
                                ? margin?.jul?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.jul ? totDesp.jul : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.ago?.totalMargin
                                ? margin?.ago?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.ago ? totDesp.ago : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.set?.totalMargin
                                ? margin?.set?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.set ? totDesp.set : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.out?.totalMargin
                                ? margin?.out?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.out ? totDesp.out : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.nov?.totalMargin
                                ? margin?.nov?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.nov ? totDesp.nov : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(
                            margin?.dez?.totalMargin
                                ? margin?.dez?.totalMargin
                                : 0
                        ) - parseFloat(totDesp.dez ? totDesp.dez : 0)
                    )}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {formatCurrency(
                        parseFloat(margin?.total ? margin?.total : 0) -
                            parseFloat(
                                totDesp.totalAnual ? totDesp.totalAnual : 0
                            )
                    )}{" "}
                </Text>
            </View>
            <View style={styles.recipeRow}>
                <Text
                    style={[
                        styles.headerText,
                        {
                            width: "11%",
                            marginTop: 6,
                            borderBottomWidth: 1,
                            textAlign: "center",
                        },
                    ]}
                >
                    Deprec & Amort
                </Text>
                <View>
                    <View style={[styles.header, , { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            Depreciação
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.jan : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.fev : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.mar : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.abr : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.mai : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.jun : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.jul : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.ago : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.set : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.out : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.nov : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(depre ? depre.dez : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                handleTotal(depre ? depre : {}, null)
                            )}
                        </Text>
                    </View>
                    <View style={[styles.header, { borderBottomWidth: 1 }]}>
                        <Text style={[styles.headerText, { width: "15%" }]}>
                            Amortização
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.jan : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.fev : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.mar : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.abr : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.mai : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.jun : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.jul : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.ago : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.set : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.out : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.nov : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(amort ? amort.dez : 0)}
                        </Text>
                        <Text style={styles.headerMonths}>
                            {formatCurrency(
                                handleTotal(amort ? amort : {}, null)
                            )}
                        </Text>
                    </View>
                </View>
            </View>
            <View style={[styles.header, { borderBottomWidth: 1 }]}>
                <Text style={[styles.headerText, { width: "11%" }]}>
                    Lucro líquido
                </Text>
                <Text style={[styles.headerText, { width: "15%" }]}></Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("jan")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("fev")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("mar")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("abr")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("mai")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("jun")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("jul")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("ago")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("set")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("out")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("nov")}{" "}
                </Text>
                <Text style={styles.headerMonths}>
                    {" "}
                    {calculoLucroLiquido("dez")}{" "}
                </Text>
                <Text style={styles.headerMonths}> {calculoTotal()} </Text>
            </View>
        </View>
    );
};

export default Orcamento;
