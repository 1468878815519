import React from "react";
import { Row } from "react-bootstrap";
import css from "../../../companyoverview.module.css";
import { FieldArray, FastField } from "formik";
import BudgetCostProjection from "./BudgetCostProjection";
import MainTotalizers from "./MainTotalizers";

const indexName = "companyValueChainBudget";

function BudgetCost({ values }) {
    const toSeeIf2021 = [];

    const handleShowYears = (ano) => {
        toSeeIf2021.push(ano);
        const actualYear = new Date().getFullYear();

        if (toSeeIf2021.includes(2021)) {
            if (ano === 2021) return "Ano 1";
            else return `Ano ${ano - actualYear + 2}`;
        } else {
            if (actualYear === ano) return "Ano 1";
            else return `Ano ${ano - actualYear + 1}`;
        }
    };
    return (
        <FieldArray name={indexName}>
            {() => (
                <div className="mt-4 mb-4 input-font--sm">
                    {values[indexName]?.length > 0 &&
                        values[indexName]
                            .slice(
                                values[indexName].length >= 3
                                    ? values[indexName].length - 3
                                    : 0,
                                values[indexName].length
                            )
                            .map((item, index) => (
                                <div className="mt-3" key={item.year}>
                                    <Row className="p-0 m-0 justify-content-between">
                                        <FastField
                                            className={`rounded ${css.input_border}`}
                                            name={`[${indexName}][${index}].year`}
                                            as="select"
                                            disabled
                                        >
                                            <option value={item.year}>
                                                {handleShowYears(item.year)}
                                            </option>
                                        </FastField>
                                    </Row>
                                    <BudgetCostProjection
                                        index={index}
                                        name={indexName}
                                        item={item}
                                        values={values}
                                    />
                                    <MainTotalizers index={index} />
                                </div>
                            ))}
                </div>
            )}
        </FieldArray>
    );
}

export default BudgetCost;
