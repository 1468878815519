import * as Yup from 'yup';

export const initialSupplierDynamicValues = {
    marketDynamicSupplierSharedYourCost:[
        {
            marketDynamicSupplier: "",
            marketDynamicProduct:[
                {
                    marketDynamicProduct: "",
                    marketDynamicTotal: 0,
                    marketDynamicShare: 0
                }
            ],
            marketDynamicSupplierTotal: 0
        }
    ],
    marketDynamicSupplierNegotiationPower:[
        {
            marketDynamicSupplier: "",
            marketDynamicPower: "",
            marketDynamicComment: ""
        }
    ],
    marketDynamicImportanceMixCostumerSupplier: [
        {
            marketDynamicSupplier: "",
            marketDynamicImportance: "",
            marketDynamicComment: ""
        }
    ],
    marketDynamicElementsValue: "",
    marketDynamicRiskVerticalization: "",
    marketDynamicRiskVerticalizationComment: ""

};

export const SupplierDynamicSchema = Yup.object().shape({
    marketDynamicSupplierSharedYourCost: Yup.array()
    .of(
        Yup.object().shape({
            marketDynamicSupplier: Yup.string()
            .min(3, 'Muito curto!')
            .max(40, 'Muito Longo!'),
            marketDynamicProduct: Yup.array()
            .of(
                Yup.object().shape({
                    marketDynamicProduct: Yup.string()
                    .min(3, 'Muito curto!')
                    .max(40, 'Muito Longo!'),
                    marketDynamicTotal: Yup.number(),
                    marketDynamicShare: Yup.number()
                })
            ),
            marketDynamicSupplierTotal: Yup.number()
        })
    ),
    marketDynamicSupplierNegotiationPower: Yup.array()
    .of(
        Yup.object().shape({
            marketDynamicSupplier: Yup.string()
                .min(3, 'Muito curto!')
                .max(40, 'Muito Longo!'),                
            marketDynamicPower: Yup.string(),                
            marketDynamicComment: Yup.string()
                .min(10, 'Muito curto!')
                .max(500, 'Muito Longo!'),                
        })
    ),
    marketDynamicImportanceMixCostumerSupplier: Yup.array()
    .of(
        Yup.object().shape({
            marketDynamicSupplier: Yup.string()
                .min(3, 'Muito curto!')
                .max(40, 'Muito Longo!'),                
            marketDynamicImportance: Yup.string(),                
            marketDynamicComment: Yup.string()
                .min(10, 'Muito curto!')
                .max(500, 'Muito Longo!'),                
        })
    ),
    marketDynamicElementsValue: Yup.string(),        
    marketDynamicRiskVerticalization: Yup.string(),        
    marketDynamicRiskVerticalizationComment: Yup.string()
        .min(10, 'Muito curto!')
        .max(500, 'Muito Longo!'),        
});