import React from "react";
import { Switch, Route } from "react-router-dom";

import Signin from "../components/forms/auth/Signin";
import Signup from "../components/forms/auth/Signup";
import Confirmation from "../components/forms/auth/Confirmation";
import ForgotPassword from "../components/forms/auth/ForgotPassword";
import ResendCode from "../components/forms/auth/ResendCode";
import App from "./App";
import WithoutSessionRoute from "./guards/WithoutSessionRoute";
import UpdatePassword from "../components/forms/auth/UpdatePassword";
import UserDisabled from "../components/forms/auth/UserDisabled";

function Router() {
    return (
        <Switch>
            <WithoutSessionRoute exact path="/signin">
                <Signin />
            </WithoutSessionRoute>
            <WithoutSessionRoute exact path="/signup">
                <Signup />
            </WithoutSessionRoute>
            <WithoutSessionRoute exact path="/confirmation">
                <Confirmation />
            </WithoutSessionRoute>
            <WithoutSessionRoute exact path="/forgot">
                <ForgotPassword />
            </WithoutSessionRoute>
            <WithoutSessionRoute exact path="/update-password">
                <UpdatePassword />
            </WithoutSessionRoute>
            <WithoutSessionRoute exact path="/resend">
                <ResendCode />
            </WithoutSessionRoute>
            <WithoutSessionRoute exact path="/user-disabled">
                <UserDisabled />
            </WithoutSessionRoute>
            <Route path="/">
                <App />
            </Route>
        </Switch>
    );
}

export default Router;
