import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";

const ThemeContext = createContext({});

export function useThemeContext() {
    return useContext(ThemeContext);
}

function ThemeProvider({ children }) {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(
        JSON.parse(localStorage.getItem("sidebar-collapsed"))
    );
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const getContentWidth = () => {
        return sidebarCollapsed ? windowWidth - 250 : windowWidth - 70;
    };

    const getContentHeight = () => {
        return sidebarCollapsed ? windowHeight - 137 : windowHeight - 70;
    };

    useEffect(() => {
        const resizeListener = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener("resize", resizeListener);

        return () => window.removeEventListener("resize", resizeListener);
    });

    return (
        <ThemeContext.Provider
            value={{
                sidebarCollapsed,
                setSidebarCollapsed,
                getContentWidth,
                getContentHeight,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
}

export default ThemeProvider;
