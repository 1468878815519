import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap";
import { formatCurrency } from '../../../../utils/formatCurrency';
import HeaderTable from "./HeadTable";

function BudgetTable({departamentos, totDesp, title}){
    const [total, setTotal] = useState(false);

    useEffect(()=>{
        if(Object.keys(totDesp).length){
            setTotal(totDesp);
        }
    }, [totDesp])

    return (
        <>
            {
                departamentos?.length > 0 && departamentos.map((depart, index)=>{
                    return (
                        <tr key={index}>
                            {index === 0 && <td valign="middle" rowSpan={departamentos.length + 1}>{title}</td>}
                            <td>{depart.title}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.jan.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.fev.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.mar.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.abr.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.mai.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.jun.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.jul.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.ago.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.set.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.out.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.nov.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.dez.total)}</td>
                            <td align="right">{formatCurrency(depart.expenses?.companyValueChainExpenses.monthsTotal.totalAnual)}</td>
                        </tr>
                    )
                })
            }
            <tr >
                <td>Total de despesas</td>
                <td align="right">{formatCurrency(total ? totDesp.jan : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.fev : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.mar : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.abr : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.mai : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.jun : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.jul : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.ago : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.set : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.out : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.nov : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.dez : 0)}</td>
                <td align="right">{formatCurrency(total ? totDesp.totalAnual : 0)}</td>
            </tr>
        </>
    )
}

export default BudgetTable;