import * as Yup from 'yup';

const UserGroupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Mínimo 2 caractéres')
    .required('Campo obrigatório'),
  description: Yup.string()
    .min(3, 'Mínimo 3 caractéres')
    .required('Campo obrigatório'),
});

export default UserGroupSchema;