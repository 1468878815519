import { Redirect, Route } from "react-router-dom";
import SpinnerGlobal from "../../components/spinner/SpinnerGlobal";
import { useAuth } from "../../providers/AuthProvider";
import QuestionFormsProvider from "../../providers/QuestionFormsProvider";

function PrivateRoute({isPrivate, setPrivateMenu, ...rest}){
  const { session, isLoading } = useAuth();

  if (isLoading) {
      return <SpinnerGlobal />
  }

  if (isPrivate && !session && !isLoading) {
      return <Redirect to="/signin" />
  } else {
      return <QuestionFormsProvider><Route {...rest} /></QuestionFormsProvider>
  }
}

export default PrivateRoute;