import React, {useState, useEffect} from 'react';
import {useFormikContext} from 'formik';
import css from "../strategic-position/companyoverview.module.css";
import CurrencyInput from '../../CurrencyInput';

//Input field com debounce
function FormInputDebounce({values, children, disabled=false, type="number",...props}){

    const {
        setFieldValue,
    } = useFormikContext();

    const [inputValue, setInputValue] = useState(values);

    useEffect(()=>{
        setInputValue(values);
    }, [values]);

    const handleChange = (ev)=>{
        setInputValue(ev.target.value);
    }

    const handleBlur = (ev)=>{
        if (type === 'currency') {
            setFieldValue(props.name, ev);
        } else {
            setFieldValue(props.name, ev.target.value ? ev.target.value : 0);
        }
    }

    return (
        <>
            <div className={`${css.input_label}`}><label htmlFor={props.name}>{children}</label></div>
            { type === 'currency' ? (
                <CurrencyInput 
                    {...props}
                    type={type} 
                    disabled={disabled} 
                    className={`w-100 p-1 rounded ${css.input_border}`}
                    value={inputValue}  
                    onBlur={handleBlur} 
                />
            ) : (
                <input type={type} disabled={disabled} className={`w-100 p-1 rounded ${css.input_border}`} value={inputValue} onChange={handleChange} onBlur={handleBlur} {...props} />
            )}
        </>
    );
}

export default FormInputDebounce