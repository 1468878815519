import React, { useEffect, useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { MdArrowDropDown, MdMenu } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import UserAvatar from "../UserAvatar";
import styles from "./styles.module.css";
import classNames from "classnames";
// import useSpinner from "../spinner/useSpinner";
// Pdfs
import { getBudgetData } from "../../services/formData";
import { saveAs } from "file-saver";
import { ImPrinter } from "react-icons/im";
import { pdf } from "@react-pdf/renderer";
import { getQuestions, getQuestionsForm } from "../../services/formData";
import PdfDocument from "./PdfDocument";
// Contexts
import { useAuth } from "../../providers/AuthProvider";
import { useUserContext } from "../../providers/UserProvider";

function Menu(props) {
    const { signOut, getAccessToken } = useAuth();
    const { data } = useUserContext();
    // const [spinner, showSpinner, hideSpinner] = useSpinner();

    const [despesaForm, setDespesaForm] = useState();
    const [totDesp, setTotDesp] = useState();

    const [macroEnv, setMacroEnv] = useState();
    const [diretEstrat, setDiretEstrat] = useState();
    const [strategicPosition, setStrategicPosition] = useState();
    const [generalStrategicPosition, setGeneralStrategicPosition] = useState(
        []
    );
    const [swot, setSwot] = useState();
    // Variaveis de Premissas de orçamento
    const [human, setHuman] = useState([]);
    const [marketing, setMarketing] = useState([]);
    const [operations, setOperations] = useState([]);
    const [service, setServices] = useState([]);
    const [supply, setSupply] = useState([]);
    const [tech, setTech] = useState([]);
    const [finance, setFinance] = useState([]);
    const [strategyRH, setStrategyRH] = useState([]);
    const [performanceRH, setPerformanceRH] = useState([]);
    const [strategyMarketing, setStrategyMarketing] = useState([]);
    const [performanceMarketing, setPerformanceMarketing] = useState([]);
    const [strategyVendas, setStrategyVendas] = useState([]);
    const [performanceVendas, setPerformanceVendas] = useState([]);
    const [strategyOperations, setStrategyOperations] = useState([]);
    const [performanceOperations, setPerformanceOperations] = useState([]);
    const [strategySupplyChain, setStrategySupplyChain] = useState([]);
    const [performanceSupplyChain, setPerformanceSupplyChain] = useState([]);
    const [strategyTech, setStrategyTech] = useState([]);
    const [performanceTech, setPerformanceTech] = useState([]);
    const [strategyFinances, setStrategyFinances] = useState([]);
    const [performanceFinances, setPerformanceFinances] = useState([]);
    const [rhESG, setRhESG] = useState([]);
    const [marketingESG, setMarketingESG] = useState([]);
    const [vendasESG, setVendasESG] = useState([]);
    const [operationsESG, setOperationsESG] = useState([]);
    const [supplyChainESG, setSupplyChainESG] = useState([]);
    const [techESG, setTechESG] = useState([]);
    const [financesESG, setFinancesESG] = useState([]);

    // Variaveis de orçamento
    const [produtos, setProdutos] = useState([]);
    const [prodTotal, setProdTotal] = useState([]);
    const [costs, setCosts] = useState([]);
    const [margin, setMargin] = useState([]);
    const [amort, setAmort] = useState([]);
    const [depre, setDepre] = useState([]);

    const [companyName, setCompanyName] = useState();

    function onSignout() {
        signOut();
    }

    const calcTotalDespesas = (departamentos) => {
        let total = {
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            totalAnual: 0,
        };
        departamentos.forEach((hit) => {
            if (hit?.expenses?.companyValueChainExpenses.monthsTotal) {
                let obj = hit?.expenses?.companyValueChainExpenses.monthsTotal;
                total.jan += parseFloat(obj.jan.total);
                total.fev += parseFloat(obj.fev.total);
                total.mar += parseFloat(obj.mar.total);
                total.abr += parseFloat(obj.abr.total);
                total.mai += parseFloat(obj.mai.total);
                total.jun += parseFloat(obj.jun.total);
                total.jul += parseFloat(obj.jul.total);
                total.ago += parseFloat(obj.ago.total);
                total.set += parseFloat(obj.set.total);
                total.out += parseFloat(obj.out.total);
                total.nov += parseFloat(obj.nov.total);
                total.dez += parseFloat(obj.dez.total);
                total.totalAnual += parseFloat(obj.totalAnual);
            }
        });
        return total;
    };

    useEffect(() => {
        if (!props.forms) {
            return;
        } else {
            if (props.forms) {
                // Grupo das Forças Macro Ambientais
                const macroForms = props.forms.length
                    ? props.forms.filter(
                          (hit) => hit.id === props.macroFormSelected
                      )[0]
                    : [];
                if (macroForms) {
                    const grupoIdMacro = macroForms?.FormType?.MainGroups[0].id;
                    getQuestions(
                        props.macroFormSelected,
                        grupoIdMacro,
                        0,
                        (err, questions) => {
                            setMacroEnv(questions);
                        }
                    );
                }
                // Fim das forças macro ambientais
                // Grupo da Posicao Estrategica
                const strategicForms = props.forms.length
                    ? props.forms.filter(
                          (hit) => hit.id === props.strategicFormSelected
                      )[0]
                    : null;
                if (strategicForms) {
                    strategicForms.FormType.MainGroups.sort(
                        (a, b) => a.menuOrder - b.menuOrder
                    );
                    // Id da Diretriz Estrategica
                    const grupoIdStr =
                        strategicForms?.FormType?.MainGroups[1].id;
                    getQuestions(
                        props.strategicFormSelected,
                        grupoIdStr,
                        0,
                        (err, questions) => {
                            setDiretEstrat(questions);
                        }
                    );
                    // Id do Geral
                    const grupoIdGeneral =
                        strategicForms?.FormType?.MainGroups[0].id;
                    getQuestions(
                        props.strategicFormSelected,
                        grupoIdGeneral,
                        0,
                        (err, questions) => {
                            setGeneralStrategicPosition(questions);
                        }
                    );
                    // Id do posicionamento Estrategico
                    const grupoIdStrategic =
                        strategicForms?.FormType?.MainGroups[2].id;
                    getQuestions(
                        props.strategicFormSelected,
                        grupoIdStrategic,
                        0,
                        (err, questions) => {
                            setStrategicPosition(questions);
                        }
                    );
                }
                // Fim do grupo da diretriz estrategica
                // Grupo do Dinamica de Mercado
                const dynamicPlanForms = props.forms.length
                    ? props.forms.filter(
                          (hit) => hit.id === props.dynamicFormSelected
                      )[0]
                    : [];
                if (dynamicPlanForms) {
                    dynamicPlanForms.FormType?.MainGroups?.sort(
                        (a, b) => a.menuOrder - b.menuOrder
                    );
                    // Id do Swot
                    const grupoIdSwot =
                        dynamicPlanForms?.FormType?.MainGroups[0].id;
                    getQuestions(
                        props.dynamicFormSelected,
                        grupoIdSwot,
                        0,
                        (err, questions) => {
                            setSwot(questions);
                        }
                    );
                }

                // Premissas do orçamento
                const loadData = async () => {
                    const mainMenu = props.forms?.find(
                        (hit) => hit.FormType.menuOrder === 1
                    ); //find do menu principal - strategic position
                    if (mainMenu && Object.keys(mainMenu)) {
                        const secMenu = mainMenu.FormType.MainGroups.find(
                            (hit) => hit.menuOrder === 4
                        ); //find do menu secundario - value chain
                        if (secMenu && Object.keys(secMenu)) {
                            const rh = secMenu.SubGroups.find(
                                (hit) => hit.menuOrder === 1
                            ); //rh
                            const marketing = secMenu.SubGroups.find(
                                (hit) => hit.menuOrder === 2
                            ); //marketing
                            const operations = secMenu.SubGroups.find(
                                (hit) => hit.menuOrder === 4
                            ); //operations
                            const serviceSales = secMenu.SubGroups.find(
                                (hit) => hit.menuOrder === 3
                            ); // Service and sales
                            const supplyChain = secMenu.SubGroups.find(
                                (hit) => hit.menuOrder === 5
                            ); // Supplychain
                            const technology = secMenu.SubGroups.find(
                                (hit) => hit.menuOrder === 6
                            ); // Tech
                            const finance = secMenu.SubGroups.find(
                                (hit) => hit.menuOrder === 7
                            ); // Financas
                            const accessToken = await getAccessToken();
                            const budgetData = await getBudgetData(
                                mainMenu.id,
                                secMenu.id,
                                accessToken
                            );
                            if (
                                !Array.isArray(budgetData) ||
                                budgetData.error
                            ) {
                                props.history.push("/");
                            } else {
                                if (budgetData?.length) {
                                    // Começo do Recursos Humanos
                                    let questionsRh = budgetData.find(
                                        (hit) => hit.sGroupId === rh.id
                                    );
                                    if (questionsRh)
                                        setHuman(
                                            questionsRh.questions
                                                .companyValueChainObjectives
                                        );
                                    setStrategyRH(
                                        questionsRh.questions
                                            .companyValueChainStrategy
                                    );
                                    setPerformanceRH(
                                        questionsRh.questions
                                            .companyValueChainPerformanceIndicators
                                    );
                                    setRhESG((arr) => [
                                        questionsRh.questions
                                            .companyValueChainEnvironmental,
                                        questionsRh.questions
                                            .companyValueChainSocial,
                                        questionsRh.questions
                                            .companyValueChainGovernance,
                                    ]);
                                    // Começo do Marketing
                                    let questionsMarketing = budgetData.find(
                                        (hit) => hit.sGroupId === marketing.id
                                    );
                                    if (questionsMarketing)
                                        setMarketing(
                                            questionsMarketing.questions
                                                .companyValueChainObjectives
                                        );
                                    setStrategyMarketing(
                                        questionsMarketing.questions
                                            .companyValueChainStrategy
                                    );
                                    setPerformanceMarketing(
                                        questionsMarketing.questions
                                            .companyValueChainPerformanceIndicators
                                    );
                                    setMarketingESG((arr) => [
                                        questionsMarketing.questions
                                            .companyValueChainEnvironmental,
                                        questionsMarketing.questions
                                            .companyValueChainSocial,
                                        questionsMarketing.questions
                                            .companyValueChainGovernance,
                                    ]);
                                    // Começo de Operações
                                    let questionsOperations = budgetData.find(
                                        (hit) => hit.sGroupId === operations.id
                                    );
                                    if (questionsOperations)
                                        setOperations(
                                            questionsOperations.questions
                                                .companyValueChainObjectives
                                        );
                                    setStrategyOperations(
                                        questionsOperations.questions
                                            .companyValueChainStrategy
                                    );
                                    setPerformanceOperations(
                                        questionsOperations.questions
                                            .companyValueChainPerformanceIndicators
                                    );
                                    setOperationsESG((arr) => [
                                        questionsOperations.questions
                                            .companyValueChainEnvironmental,
                                        questionsOperations.questions
                                            .companyValueChainSocial,
                                        questionsOperations.questions
                                            .companyValueChainGovernance,
                                    ]);
                                    // Começo de Vendas
                                    let questionsServices = budgetData.find(
                                        (hit) =>
                                            hit.sGroupId === serviceSales.id
                                    );
                                    if (questionsServices)
                                        setServices(
                                            questionsServices.questions
                                                .companyValueChainObjectives
                                        );
                                    setStrategyVendas(
                                        questionsServices.questions
                                            .companyValueChainStrategy
                                    );
                                    setPerformanceVendas(
                                        questionsServices.questions
                                            .companyValueChainPerformanceIndicators
                                    );
                                    setVendasESG((arr) => [
                                        questionsServices.questions
                                            .companyValueChainEnvironmental,
                                        questionsServices.questions
                                            .companyValueChainSocial,
                                        questionsServices.questions
                                            .companyValueChainGovernance,
                                    ]);
                                    // Começo de Supply Chain
                                    let questionsSupply = budgetData.find(
                                        (hit) => hit.sGroupId === supplyChain.id
                                    );
                                    if (questionsSupply)
                                        setSupply(
                                            questionsSupply.questions
                                                .companyValueChainObjectives
                                        );
                                    setStrategySupplyChain(
                                        questionsSupply.questions
                                            .companyValueChainStrategy
                                    );
                                    setPerformanceSupplyChain(
                                        questionsSupply.questions
                                            .companyValueChainPerformanceIndicators
                                    );
                                    setSupplyChainESG((arr) => [
                                        questionsSupply.questions
                                            .companyValueChainEnvironmental,
                                        questionsSupply.questions
                                            .companyValueChainSocial,
                                        questionsSupply.questions
                                            .companyValueChainGovernance,
                                    ]);
                                    // Começo de Tecnologia
                                    let questionsTech = budgetData.find(
                                        (hit) => hit.sGroupId === technology.id
                                    );
                                    if (questionsTech)
                                        setTech(
                                            questionsTech.questions
                                                .companyValueChainObjectives
                                        );
                                    setStrategyTech(
                                        questionsTech.questions
                                            .companyValueChainStrategy
                                    );
                                    setPerformanceTech(
                                        questionsTech.questions
                                            .companyValueChainPerformanceIndicators
                                    );
                                    setTechESG((arr) => [
                                        questionsTech.questions
                                            .companyValueChainEnvironmental,
                                        questionsTech.questions
                                            .companyValueChainSocial,
                                        questionsTech.questions
                                            .companyValueChainGovernance,
                                    ]);
                                    // Começo de Finanças
                                    let questionsFinance = budgetData.find(
                                        (hit) => hit.sGroupId === finance.id
                                    );
                                    if (questionsFinance)
                                        setFinance(
                                            questionsFinance.questions
                                                .companyValueChainObjectives
                                        );
                                    setStrategyFinances(
                                        questionsFinance.questions
                                            .companyValueChainStrategy
                                    );
                                    setPerformanceFinances(
                                        questionsFinance.questions
                                            .companyValueChainPerformanceIndicators
                                    );
                                    setFinancesESG((arr) => [
                                        questionsFinance.questions
                                            .companyValueChainEnvironmental,
                                        questionsFinance.questions
                                            .companyValueChainSocial,
                                        questionsFinance.questions
                                            .companyValueChainGovernance,
                                    ]);
                                }
                            }
                        }
                    }
                };
                // Fim do Premissas de Orçamento
                // Orçamento
                const loadBudgetData = async () => {
                    if (props.forms) {
                        const mainMenu = props.forms.find(
                            (hit) => hit.FormType.menuOrder === 1
                        ); //find do menu principal - strategic position
                        if (mainMenu && Object.keys(mainMenu).length) {
                            //Verificando a existencia de chaves
                            const secMenu = mainMenu.FormType.MainGroups.find(
                                (hit) => hit.menuOrder === 4
                            ); //find do menu secundario - value chain
                            if (secMenu && Object.keys(secMenu).length) {
                                //Verificando a existencia de chaves
                                let operations = secMenu.SubGroups.find(
                                    (hit) => hit.menuOrder === 4
                                ); //operations
                                let serviceSales = secMenu.SubGroups.find(
                                    (hit) => hit.menuOrder === 3
                                ); // Service and sales
                                const accessToken = await getAccessToken();
                                const budgetData = await getBudgetData(
                                    mainMenu.id,
                                    secMenu.id,
                                    accessToken
                                );
                                if (
                                    !Array.isArray(budgetData) ||
                                    budgetData.error
                                ) {
                                    props.history.push("/");
                                } else {
                                    //Services and sales
                                    let budgetServiceData = budgetData?.filter(
                                        (hit) =>
                                            hit.sGroupId === serviceSales.id
                                    )[0];
                                    if (budgetServiceData) {
                                        budgetServiceData =
                                            budgetServiceData.questions?.companyValueChainBudget?.filter(
                                                (item) =>
                                                    parseInt(item.year) ===
                                                    new Date().getFullYear()
                                            );
                                        if (budgetServiceData.length) {
                                            //Carregando o dados de serviços e produtos
                                            budgetServiceData =
                                                budgetServiceData[0]
                                                    .companyValueChainBudget;
                                            setProdutos(budgetServiceData);

                                            let totalProdutos =
                                                calcTotalProduto(
                                                    budgetServiceData
                                                );
                                            setProdTotal(totalProdutos);
                                        }
                                    }

                                    //Carregando os dados de custo fixo e custo variável de produtos
                                    let budgetCostData = budgetData.filter(
                                        (hit) => hit.sGroupId === operations.id
                                    );
                                    if (budgetCostData.length) {
                                        budgetCostData = budgetCostData[0];

                                        //Recuperando o ano corrente
                                        let budgetCostDataCurrentYear =
                                            budgetCostData.questions.companyValueChainBudget?.filter(
                                                (item) =>
                                                    parseInt(item.year) ===
                                                    new Date().getFullYear()
                                            )[0];
                                        if (budgetCostDataCurrentYear) {
                                            let costs =
                                                budgetCostDataCurrentYear.companyValueChainBudget;
                                            setCosts(costs);

                                            //Margem Bruta Total
                                            let totalMargin =
                                                budgetCostDataCurrentYear?.total;
                                            totalMargin.total = 0;
                                            Object.values(totalMargin).forEach(
                                                (hit) => {
                                                    if (hit.totalMargin)
                                                        totalMargin.total +=
                                                            parseFloat(
                                                                hit.totalMargin
                                                            );
                                                }
                                            );
                                            setMargin(totalMargin);

                                            //Amortização e depreciação
                                            // let amortDepData =
                                            //     budgetCostData.questions.companyValueChainAmortizationDepreciation.find(
                                            //         (item) =>
                                            //             item.year ===
                                            //             new Date().getFullYear()
                                            //     );
                                            // setAmortDep(amortDepData);
                                            let amortDepData =
                                                budgetCostData.questions.companyValueChainAmortizationDepreciation.find(
                                                    (item) =>
                                                        item.year ===
                                                        new Date().getFullYear()
                                                );
                                            if (
                                                Object.keys(amortDepData).length
                                            ) {
                                                const depreObj =
                                                    amortDepData
                                                        .companyValueChainDepreciation[0]
                                                        .companyValueChainDepreciation;
                                                setDepre(depreObj);
                                                const amortObj =
                                                    amortDepData
                                                        .companyValueChainAmortization[0]
                                                        .companyValueChainAmortization;
                                                setAmort(amortObj);
                                            }
                                        }
                                    }

                                    secMenu.SubGroups.forEach((item) => {
                                        let dataDepart = budgetData.find(
                                            (hit) => hit.sGroupId === item.id
                                        );
                                        if (dataDepart) {
                                            //Departamento encontrado
                                            //Inserindo despesas dos departamentos separadamente
                                            item.expenses =
                                                dataDepart.questions.companyValueChainCostExpenses.find(
                                                    (data) =>
                                                        data.year ===
                                                        new Date().getFullYear()
                                                );
                                        }
                                    });
                                    let totalDasDespesas = calcTotalDespesas(
                                        secMenu.SubGroups
                                    );
                                    setTotDesp(totalDasDespesas);
                                    setDespesaForm(secMenu.SubGroups);
                                }
                            }
                        }
                    }
                };
                // Fim do Orçamento
                const loadCompanyData = async () => {
                    const mainMenu = props.forms?.find(
                        (hit) => hit.FormType.menuOrder === 1
                    );
                    //find do menu secundário - value chain
                    const generalMenu = mainMenu.FormType.MainGroups.find(
                        (hit) => hit.menuOrder === 1
                    );

                    const accessToken = await getAccessToken();
                    const genData = await getQuestionsForm(
                        mainMenu.id,
                        generalMenu.id,
                        0,
                        accessToken
                    );
                    setCompanyName(genData.companyName);
                };

                loadData();
                loadBudgetData();
                loadCompanyData();
            }
        }
    }, [props.forms, props.strategicFormSelected, props.dynamicFormSelected]);

    const calcTotalProduto = (produtos) => {
        let total = {
            jan: 0,
            fev: 0,
            mar: 0,
            abr: 0,
            mai: 0,
            jun: 0,
            jul: 0,
            ago: 0,
            set: 0,
            out: 0,
            nov: 0,
            dez: 0,
            totalAnual: 0,
        };
        produtos.forEach((hit) => {
            if (hit?.companyValueChainBudget) {
                let obj = hit?.companyValueChainBudget;
                total.jan += parseFloat(obj.jan.total);
                total.fev += parseFloat(obj.fev.total);
                total.mar += parseFloat(obj.mar.total);
                total.abr += parseFloat(obj.abr.total);
                total.mai += parseFloat(obj.mai.total);
                total.jun += parseFloat(obj.jun.total);
                total.jul += parseFloat(obj.jul.total);
                total.ago += parseFloat(obj.ago.total);
                total.set += parseFloat(obj.set.total);
                total.out += parseFloat(obj.out.total);
                total.nov += parseFloat(obj.nov.total);
                total.dez += parseFloat(obj.dez.total);
                total.totalAnual += parseFloat(obj.total.totalMain);
            }
        });
        return total;
    };

    const generatePdf = async () => {
        const blob = await pdf(
            <PdfDocument
                macroEnv={macroEnv}
                despesaForm={despesaForm}
                totDesp={totDesp}
                diretEstratProp={diretEstrat}
                generalStrategicPositionProp={generalStrategicPosition}
                strategicPosition={strategicPosition}
                swot={swot}
                human={human.reverse()}
                marketing={marketing.reverse()}
                operations={operations.reverse()}
                service={service.reverse()}
                supply={supply.reverse()}
                tech={tech.reverse()}
                finance={finance.reverse()}
                produtos={produtos}
                prodTotal={prodTotal}
                costs={costs}
                margin={margin}
                amort={amort}
                depre={depre}
                companyName={companyName}
                strategyRH={strategyRH}
                performanceRH={performanceRH}
                strategyMarketing={strategyMarketing}
                performanceMarketing={performanceMarketing}
                strategyVendas={strategyVendas}
                performanceVendas={performanceVendas}
                strategyOperations={strategyOperations}
                performanceOperations={performanceOperations}
                strategySupplyChain={strategySupplyChain}
                performanceSupplyChain={performanceSupplyChain}
                strategyTech={strategyTech}
                performanceTech={performanceTech}
                strategyFinances={strategyFinances}
                performanceFinances={performanceFinances}
                rhESG={rhESG}
                marketingESG={marketingESG}
                vendasESG={vendasESG}
                operationsESG={operationsESG}
                supplyChainESG={supplyChainESG}
                techESG={techESG}
                financesESG={financesESG}
            />
        ).toBlob();
        saveAs(blob, "CEO360_infos.pdf");
    };

    const handleDownloadPdf = async () => {
        generatePdf();
    };

    return (
        <Container fluid className={styles.container}>
            <Row className="justify-content-center align-items-center p-2 py-3">
                <Col className="d-flex align-items-center">
                    {props.toggleSideBar && (
                        <MdMenu
                            className={styles.menuToggler}
                            onClick={props.toggleSideBar}
                        />
                    )}
                </Col>

                <Col
                    className="text-right d-flex align-items-center justify-content-end"
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <div class="d-flex align-items-center justify-content-end mr-2">
                        <button
                            onClick={handleDownloadPdf}
                            style={{
                                outline: "none",
                                marginRight: 8,
                                borderWidth: 1,
                                borderRadius: 5,
                                display: "flex",
                                alignItems: "center",
                                padding: 6,
                            }}
                        >
                            <ImPrinter style={{ fontSize: 20 }} />
                        </button>
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle
                            as={CustomToggle}
                            id="dropdown-custom-components"
                        >
                            <UserAvatar username={data?.username} />
                            <span className="ml-2">{data?.username}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            className={classNames(
                                "shadow mt-1",
                                styles.dropdown
                            )}
                        >
                            <Dropdown.Item onClick={onSignout}>
                                <FiLogOut className="mr-2 text-danger" /> Sair
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
        </Container>
    );
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
        className="d-flex align-items-center"
        style={{ cursor: "pointer" }}
        ref={ref}
        onClick={(e) => {
            onClick(e);
        }}
    >
        {children}
        <MdArrowDropDown />
    </div>
));

export default withRouter(Menu);
