import React, {useState, useEffect} from 'react';
import {allColors} from "../css/colors";
import {Row} from "react-bootstrap";
import {PolarArea} from "react-chartjs-2";

const genData = () => ({
    labels: ['Segmento 1', 'Segmento 2', 'Segmento 3'],
    datasets: [
        {
            label: '%',
            data: [0, 0, 0],
            backgroundColor: allColors,
            borderWidth: 1,
        },
    ],
});

function TotalPowerSegmentGraphic(props) {
    const [graphData, setGraphData] = useState(genData());

    useEffect(()=>{
        let bodyTemp = genData();
        bodyTemp.labels = [];
        bodyTemp.datasets[0].data = [];
        if(props.data && props.data.length > 0){
            props.data.forEach((item)=>{
                bodyTemp.labels.push(item.marketDynamicSegment);
                bodyTemp.datasets[0].data.push(item.marketDynamicSegmentTotal);
            });
        }
        setGraphData(bodyTemp);
    }, [props.data]);

    return (
        <>
            <div className="card__header--home">
                <h6 className="mb-0">Poder de negociação por segmento</h6>
            </div>

            <hr />

            <Row className="p-4 h-100">
                <PolarArea data={graphData} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'right',
                            align: "start"
                        }
                    }
                }}/>
            </Row>
        </>
    );
}

export default TotalPowerSegmentGraphic;