import React from "react";
import { Row } from "react-bootstrap";
import css from "../../companyoverview.module.css";
import { FieldArray, Field } from "formik";
import CostsExpensesProjection from "./CostsExpensesProjection";
import Button from "../../../../Button";
import { MdAdd } from "react-icons/md";
const indexName = "companyValueChainCostExpenses";

const months = {
    jan: 0,
    fev: 0,
    mar: 0,
    abr: 0,
    mai: 0,
    jun: 0,
    jul: 0,
    ago: 0,
    set: 0,
    out: 0,
    nov: 0,
    dez: 0,
};

export const totalizers = {
    jan: { total: 0 },
    fev: { total: 0 },
    mar: { total: 0 },
    abr: { total: 0 },
    mai: { total: 0 },
    jun: { total: 0 },
    jul: { total: 0 },
    ago: { total: 0 },
    set: { total: 0 },
    out: { total: 0 },
    nov: { total: 0 },
    dez: { total: 0 },
};

const newYear = {
    year: new Date().getFullYear(),
    companyValueChainFixedCost: {
        companyValueChainFixedCost: [
            {
                companyValueChainDescription: "",
                companyValueChainFixedCost: months,
                companyValueChainTotal: 0,
                companyValueChainInvestiment: false,
            },
        ],
        monthsTotal: totalizers,
        total: 0,
    },
    companyValueChainVariableCost: {
        companyValueChainVariableCost: [
            {
                companyValueChainDescription: "",
                companyValueChainVariableCost: months,
                companyValueChainTotal: 0,
                companyValueChainInvestiment: false,
            },
        ],
        monthsTotal: totalizers,
        total: 0,
    },
    companyValueChainExpenses: {
        companyValueChainExpenses: [
            {
                companyValueChainDescription: "",
                companyValueChainExpenses: months,
                companyValueChainTotal: 0,
            },
        ],
        monthsTotal: totalizers,
        total: 0,
    },
};

function CostsExpenses({ values, showCosts = false }) {
    const toSeeIf2021 = [];

    const handleShowYears = (ano) => {
        toSeeIf2021.push(ano);
        const actualYear = new Date().getFullYear();

        if (toSeeIf2021.includes(2021)) {
            if (ano === 2021) return "Ano 1";
            else return `Ano ${ano - actualYear + 2}`;
        } else {
            if (actualYear === ano) return "Ano 1";
            else return `Ano ${ano - actualYear + 1}`;
        }
    };

    return (
        <FieldArray name={indexName}>
            {({ remove, push }) => (
                <div className="mt-4 mb-4 input-font--sm">
                    {values[indexName]?.length > 0 &&
                        values[indexName]
                            .slice(
                                values[indexName].length >= 3
                                    ? values[indexName].length - 3
                                    : 0,
                                values[indexName].length
                            )
                            .map((item, index) => (
                                <div className="mt-3" key={item.year}>
                                    <Row className="p-0 m-0 justify-content-between">
                                        <Field
                                            className={`rounded ${css.input_border}`}
                                            name={`[${indexName}][${index}].year`}
                                            as="select"
                                            disabled
                                        >
                                            <option value={item.year}>
                                                {handleShowYears(item.year)}
                                            </option>
                                        </Field>
                                        <Button
                                            type="button"
                                            onClick={() => {
                                                if (
                                                    values[indexName].length > 1
                                                ) {
                                                    //Finding the correct index of the year
                                                    let foundedIndex = values[
                                                        indexName
                                                    ].findIndex(
                                                        (el) =>
                                                            el.year ===
                                                            item.year
                                                    );
                                                    remove(foundedIndex);
                                                }
                                            }}
                                            className="btn"
                                            variant="outline-danger"
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </Row>
                                    {showCosts && (
                                        <>
                                            <CostsExpensesProjection
                                                index={index}
                                                name="companyValueChainVariableCost"
                                                item={item}
                                                title="Custo variável"
                                                button="Adicionar custo variável"
                                                months={months}
                                                label="Custo"
                                                values={values}
                                            />
                                            <CostsExpensesProjection
                                                index={index}
                                                name="companyValueChainFixedCost"
                                                item={item}
                                                title="Custo Fixo"
                                                button="Adicionar custo Fixo"
                                                months={months}
                                                label="Custo"
                                                values={values}
                                            />
                                        </>
                                    )}
                                    <CostsExpensesProjection
                                        index={index}
                                        name="companyValueChainExpenses"
                                        item={item}
                                        title="Despesas"
                                        button="Adicionar Despesas"
                                        months={months}
                                        label="Despesa"
                                        values={values}
                                    />
                                </div>
                            ))}
                    <Button
                        type="button"
                        variant="outline-success"
                        onClick={() => {
                            newYear.year =
                                values[indexName][values[indexName].length - 1]
                                    .year + 1;
                            push(newYear);
                        }}
                        className={`mt-2`}
                    >
                        <MdAdd fontSize="20" className="mr-1" />
                        Adicionar Ano
                    </Button>
                </div>
            )}
        </FieldArray>
    );
}

export default CostsExpenses;
