import React, {useState} from 'react';
import { useCallback } from 'react';
import SpinnerGlobal from './SpinnerGlobal';

function useSpinner(props) {
    const [visible, setVisible] = useState(false);
    const showSpinner = useCallback(() => setVisible(true), []);
    const hideSpinner = useCallback(() => setVisible(false), []);
    const spinner = visible ? <SpinnerGlobal /> : null;
    return [spinner, showSpinner, hideSpinner];
}

export default useSpinner;
