import {CognitoUserPool } from 'amazon-cognito-identity-js';

const POOL_DATA = {
    UserPoolId: process.env.REACT_APP_USER_POOL_ID,
    ClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID
}

const userPool = new CognitoUserPool(POOL_DATA);

//Checking
export const isAuthenticated = (callback)=>{
    const user = userPool.getCurrentUser();
    if(user){
        user?.getSession((err, session)=>{
            if(err){
                callback(err)
            }else{
                if(session.isValid()){
                    user.getUserAttributes((err, attributes)=>{
                        if(err){
                            callback(err);
                        }else{
                            callback(null, {
                                user: user, 
                                username: session.idToken.payload['cognito:username'], 
                                email: session.idToken.payload.email, 
                                token: session.getIdToken().getJwtToken(), 
                                session: session, attributes: 
                                attributes
                            });
                        }
                    })
                }else{
                    callback(true);
                }
            }
        });
    }else{
        callback("Problemas para encontrar a session")
    }
}

export const getAuthUser = () =>{
    return userPool.getCurrentUser();
}