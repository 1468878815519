import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

// import { Container } from './styles';

function UserAddToGroupForm({ groups, selectedUsers, onSubmit, onDismiss }) {
  const [selectedGroup, setSelectedGroup] = useState(''); 
  
  function handleSubmit(e) {
    e.preventDefault();
    onSubmit(selectedGroup)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <p className="text-muted">
        Selecione um grupo adicionar os usuários selecionados.
      </p>
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label>Grupo destino</Form.Label>
        <Form.Control 
          as="select" 
          value={selectedGroup.id || ''} 
          onChange={(e) => setSelectedGroup(groups.find((group) => group.id === parseInt(e.target.value, 10)))}
        >
          <option value="" disabled>Selecione...</option>
          { groups.length > 0 && groups.map((group) => (
            <option key={group.id} value={group.id}>{group.name}</option>
          ))}
        </Form.Control>
      </Form.Group>
      <Alert variant="warning">
        <p>
         Você irá adicionar ao grupo selecionado os seguintes usuários:
        </p>
        <ul>
          {selectedUsers.map((user) => (
            <li key={user.id}>{`${user.firstName} ${user.lastName} (${user.username})`}</li>
          ))}
        </ul>
      </Alert>
      <div className="mt-4">
        <Button type="submit" variant="success" className="mr-2" disabled={selectedGroup === ''}>
          Adicionar usuários
        </Button>
        <Button variant="outline-secondary" onClick={onDismiss}>
          Cancelar
        </Button>
      </div>
    </Form>
  );
}

export default UserAddToGroupForm;