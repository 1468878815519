import React from "react";
import AmortizationDepreciationProjection from "./AmortizationDepreciationProjection";
import { Row, Button } from "react-bootstrap";
import css from "../../../companyoverview.module.css";
import cssButton from "../../../../../Button/styles.module.css";
import { FieldArray, Field } from "formik";
import { months } from "../helpers/formValidation";
import { MdAdd } from "react-icons/md";
const indexName = "companyValueChainAmortizationDepreciation";

const newYear = {
    year: new Date().getFullYear(),
    companyValueChainDepreciation: [
        {
            companyValueChainDepreciation: months,
            total: 0,
        },
    ],
    companyValueChainAmortization: [
        {
            companyValueChainAmortization: months,
            total: 0,
        },
    ],
};

function AmortizationDepreciation({ values }) {
    const toSeeIf2021 = [];

    const handleShowYears = (ano) => {
        toSeeIf2021.push(ano);
        const actualYear = new Date().getFullYear();

        if (toSeeIf2021.includes(2021)) {
            if (ano === 2021) return "Ano 1";
            else return `Ano ${ano - actualYear + 2}`;
        } else {
            if (actualYear === ano) return "Ano 1";
            else return `Ano ${ano - actualYear + 1}`;
        }
    };
    return (
        <FieldArray name={indexName}>
            {({ remove, push }) => (
                <div className="mt-4 mb-4 input-font--sm">
                    {values[indexName]?.length > 0 &&
                        values[indexName]
                            .slice(
                                values[indexName].length >= 3
                                    ? values[indexName].length - 3
                                    : 0,
                                values[indexName].length
                            )
                            .map((item, index) => (
                                <div className="mt-3" key={item.year}>
                                    <Row className="p-0 m-0 justify-content-between">
                                        <Field
                                            className={`rounded ${css.input_border}`}
                                            name={`[${indexName}][${index}].year`}
                                            as="select"
                                            disabled
                                        >
                                            <option value={item.year}>
                                                {handleShowYears(item.year)}
                                            </option>
                                        </Field>
                                        <Button
                                            type="button"
                                            variant="outline-danger"
                                            onClick={() => {
                                                if (
                                                    values[indexName].length > 1
                                                ) {
                                                    //Finding the correct index of the year
                                                    let foundedIndex = values[
                                                        indexName
                                                    ].findIndex(
                                                        (el) =>
                                                            el.year ===
                                                            item.year
                                                    );
                                                    remove(foundedIndex);
                                                }
                                            }}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </Row>
                                    <AmortizationDepreciationProjection
                                        index={index}
                                        values={values}
                                        name="companyValueChainDepreciation"
                                        title="Depreciação"
                                    />
                                    <AmortizationDepreciationProjection
                                        index={index}
                                        values={values}
                                        name="companyValueChainAmortization"
                                        title="Amortização"
                                    />
                                </div>
                            ))}
                    <Button
                        type="button"
                        variant="outline-success"
                        onClick={() => {
                            newYear.year =
                                values[indexName][values[indexName].length - 1]
                                    .year + 1;
                            push(newYear);
                        }}
                        className={`mt-2 ${cssButton.btn}`}
                    >
                        <MdAdd fontSize="20" className="mr-1" />
                        Adicionar Ano
                    </Button>
                </div>
            )}
        </FieldArray>
    );
}

export default AmortizationDepreciation;
