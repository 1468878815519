import React from "react";
import { Spinner } from "react-bootstrap";

function SpinnerGlobal() {
    return (
        <div
            style={{
                position: "fixed",
                left: "0",
                top: "0",
                zIndex: "9999",
                width: "100vw",
                height: "100vh",
                background: "lightgray",
                opacity: ".5",
            }}
        >
            <Spinner
                animation="border"
                role="status"
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    height: "60px",
                    width: "60px",
                    margin: "0px auto",
                    zIndex: "9999",
                    overflow: "show",
                }}
            >
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
    );
}

export default SpinnerGlobal;
