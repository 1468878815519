import React from "react";
// Components
import Page from "../../components/Page";

const Tour = (props) => {
    return (
        <Page>
            <iframe
                src="https://tour360.meupasseiovirtual.com/036821/135706/tourvirtual/"
                name="ceoTour"
                width="100%"
                height="100%"
            ></iframe>
        </Page>
    );
};

export default Tour;
