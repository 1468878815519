import * as Yup from "yup";

export const initialStrategicValues = {
    companyPropose: "",
    companyMission: "",
    companyVision: "",
    organizacionalCultureValues: "",
    organizacionalCulturePrinciples: "",
};

export const CompanyStrategicSchema = Yup.object().shape({
    companyPropose: Yup.string()
        .min(10, "Muito curto!")
        .max(800, "Muito Longo"),
    companyMission: Yup.string()
        .min(10, "Muito curto!")
        .max(800, "Muito Longo"),
    companyVision: Yup.string().min(10, "Muito curto!").max(800, "Muito Longo"),

    organizacionalCultureValues: Yup.string()
        .min(10, "Muito curto!")
        .max(800, "Muito Longo"),
    organizacionalCulturePrinciples: Yup.string()
        .min(10, "Muito curto!")
        .max(800, "Muito Longo"),
});
