import { ErrorMessage, Field } from 'formik';
import React from 'react';
import { FormGroup } from 'react-bootstrap';
import css from '../forms/strategic-position/companyoverview.module.css';

function FormSelect({ children, name, id, label }) {
  return (
    <FormGroup>
      <label className={`${css.input_label}`} htmlFor={id}>{label}</label>
      <Field
        name={name}
        id={id}
      >
        {({ field, form, meta }) => (
          <select
            className={`w-100 form-field rounded ${css.input_border} ${css.select_input} ${meta.touched && meta.error ? css.input_error : ""}`}
            {...field}
          >
            <option value="" disabled={true}>Selecione...</option>
            {children}
          </select>
        )}
      </Field>
      <ErrorMessage
          name={name}
          component="div"
          className={`field-error ${css.error_message}`}
      />
    </FormGroup>
  );
}

export default FormSelect;