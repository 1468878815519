import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../companyoverview.module.css';

function CompanyMarketTotal(props) {
    const {
        values: { companyMarket },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    React.useEffect(() => {
        if (companyMarket.companyMarket.length) {
            const total = companyMarket.companyMarket.reduce((acc, current)=>acc + parseFloat(current.companyMarketTotal), 0);
            setFieldValue(props.name, total ? total : 0);
        }

    }, [companyMarket.companyMarket]);

    return (
        <>
            <input className={`p-1 rounded border`} {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default CompanyMarketTotal;
