import React from 'react';
import {Row, Col} from 'react-bootstrap';
import GeneralTotalizer from './GeneralTotalizer';
import MainMonthTotalizers from "./MainMonthTotalizers";
const indexName = "companyValueChainBudget";

function MainTotalizers({index}){
    return (
        <Row className="p-0 m-0 mt-5">
            <Col lg={1} />
            <Col className="p-0 m-0" lg={10}>
                <Row className="p-0 m-0">
                    <Col className="p-0 m-0">
                        Margem Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.jan.totalMargin`}
                            index={index}
                            month="jan"
                        />
                    </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.fev.totalMargin`}
                                index={index}
                                month="fev"
                            />
                        </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.mar.totalMargin`}
                                index={index}
                                month="mar"
                            />
                        </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.abr.totalMargin`}
                                index={index}
                                month="abr"
                            />
                        </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.mai.totalMargin`}
                                index={index}
                                month="mai"
                            />
                        </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.jun.totalMargin`}
                                index={index}
                                month="jun"
                            />
                        </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.jul.totalMargin`}
                                index={index}
                                month="jul"
                            />
                        </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.ago.totalMargin`}
                                index={index}
                                month="ago"
                            />
                        </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.set.totalMargin`}
                                index={index}
                                month="set"
                            />
                        </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.out.totalMargin`}
                                index={index}
                                month="out"
                            />
                        </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.nov.totalMargin`}
                                index={index}
                                month="nov"
                            />
                        </Col>
                        <Col className="p-0 m-0" >
                            Margem Total
                            <MainMonthTotalizers
                                name={`[${indexName}][${index}].total.dez.totalMargin`}
                                index={index}
                                month="dez"
                            />
                        </Col>
                        <Col className="p-0 p-0 m-0">
                            Margem Total
                            <GeneralTotalizer
                                name={`[${indexName}][${index}].generalTotalizer`}
                                index={index}
                            />
                        </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default MainTotalizers;