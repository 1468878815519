import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../../companyoverview.module.css';
import CurrencyInput from '../../../../../CurrencyInput';

function MainMonthTotalizers({type, refkey, month, index, valueToWatch, ...props}) {
    const {
        values: { companyValueChainBudget },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    React.useEffect(() => {
        let entries = Object.entries(companyValueChainBudget);
        entries = entries.map(el=>(el[1].companyValueChainBudget))[index].map(el=>(el.companyValueChainBudget)).map(el=>el[month]);
        const total = entries.reduce((acc, el)=>acc + Number(el[refkey]), 0);
        setFieldValue(props.name, total);

    }, [companyValueChainBudget[index].companyValueChainBudget]);

    return (
        <> 
            {
                type === 'currency' ? (
                    <CurrencyInput disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
                ) : (
                    <input disabled className={`w-100 p-1 rounded ${css.input_border}`} type='number' {...props} {...field} />
                )
            }
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default MainMonthTotalizers