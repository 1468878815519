import React, {useEffect, useState} from 'react';
import { Pie } from 'react-chartjs-2';
import {Row} from 'react-bootstrap';
import {allColors} from '../css/colors';

const genData = () => ({
    labels: ['Red', 'Blue', 'Yellow'],
    datasets: [
        {
            label: 'Competidor',
            data: [0,0,0],
            backgroundColor: allColors,
            borderColor: "white",
            borderWidth: 1,
        },
    ],
});

function SegmentGraphic(props) {
    const [pieData, setPieData] = useState(genData());

    useEffect(()=>{
        const competitors = props.data[props.index];
        if(competitors && competitors.length > 0){
            const dataTemp = genData();
            dataTemp.labels = [];
            dataTemp.datasets[0].data = [];

            competitors.forEach(item=>{
                dataTemp.labels.push(item[props.index]);
                dataTemp.datasets[0].data.push(item[props.indexShare]);
            });
            setPieData(dataTemp);
        }
    }, [props.data])

    return (
        <>
            {pieData &&
                <Row className="p-4">
                    <Pie data={pieData} options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                position: 'right',
                                align: "start"
                            }
                        }
                    }}/>
                </Row>
            }
        </>
    );
}

export default SegmentGraphic;