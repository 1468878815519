import React, {useEffect, useState} from 'react';
import useSpinner from "../../../spinner/useSpinner";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {ToastContainer} from "react-toastify";
import {withRouter} from 'react-router-dom';
import {getBudgetData} from "../../../../services/formData";
import TotalPredictionGraphic from "./graphics/TotalPredictionGraphic";
import ProductPredictionGraphic from "./graphics/ProductPredictionGraphic";
import ProductPredictionTotal from "./graphics/ProductPredictionTotal";
import TotalMarketingInvestimentGraphic from "./graphics/TotalMarketingInvestimentGraphic";
import InvestimentPredictionGraphic from "./graphics/InvestimentPredictionGraphic";
import DynamicCompetition from "./graphics/DynamicCompetition";
import CustomerDynamic from "./graphics/CustomerDynamic";
import SupplierDynamic from "./graphics/SupplierDynamic";
import DepartmentData from './graphics/DepartmentData';
import {useAuth} from "../../../../providers/AuthProvider";

function MarketOverview({forms, ...props}){
    const [tabIndex, setTabIndex] = useState(0);
    const [spinner, showSpinner, hideSpinner] = useSpinner();
    const [graph, setGraph] = useState(true);
    const [serviceSales, setServiceSales] = useState([]);
    const [marketing, setMarketing] = useState([]);
    const { getAccessToken } = useAuth();

    const handleGraphChange = (ev)=>{
        if(ev.target.value === 'total'){
            setGraph(true);
        }else{
            setGraph(false);
        }
    }

    useEffect(()=>{
        let isSubscribe = true;
        const loadData = async ()=>{
            if(isSubscribe){
                showSpinner();
                const mainMenu = forms.find(hit=>hit.FormType.menuOrder === 1); //find do menu principal - strategic position

                if(mainMenu && Object.keys(mainMenu).length){ //Verificando a existencia de chaves
                    const secMenu = mainMenu.FormType.MainGroups.find(hit=>hit.menuOrder === 4); //find do menu secundario - value chain
                    if(secMenu && Object.keys(secMenu).length){ //Verificando a existencia de chaves
                        let marketing = secMenu.SubGroups.find(hit=>hit.menuOrder === 2); // Service and sales
                        let serviceSales = secMenu.SubGroups.find(hit=>hit.menuOrder === 3); // Service and sales
                        if(isSubscribe){
                            const accessToken = await getAccessToken();
                            const budgetData = await getBudgetData(mainMenu.id, secMenu.id, accessToken);
                            if(!Array.isArray(budgetData) || budgetData.error){
                                console.log(budgetData);
                                props.history.push("/");
                                hideSpinner();
                                return;
                            }else{
                                if(isSubscribe){
                                    //Services and sales
                                    let budgetServiceData = budgetData?.filter(hit=>hit.sGroupId === serviceSales.id)[0];
                                    let marketingData = budgetData?.filter(hit=>hit.sGroupId === marketing.id)[0];

                                    if(budgetServiceData){
                                        //Separando o ano de 2021
                                        budgetServiceData = budgetServiceData.questions?.companyValueChainBudget?.filter(item=> parseInt(item.year) === new Date().getFullYear());
                                        setServiceSales(budgetServiceData);
                                    }

                                    if(marketingData){
                                        marketingData = marketingData.questions?.companyValueChainCostExpenses?.filter(item=> parseInt(item.year) === new Date().getFullYear())
                                        setMarketing(marketingData);
                                    }
                                }
                            }
                            hideSpinner();
                        }
                    }
                }
            }
        }

        if(isSubscribe)loadData();

        return ()=>(isSubscribe=false)
    }, []);

    const resetingGraphs = ()=>{
        setGraph(true);
    }

    return (
        <div>
            {spinner}
            <ToastContainer />
            <Container fluid className="p-0">
                <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                    <TabList className="react-tabs__tab-list sub-tab">
                        <Tab onClick={resetingGraphs}>Previsão de vendas</Tab>
                        <Tab onClick={resetingGraphs}>Investimento em marketing</Tab>
                        <Tab onClick={resetingGraphs}>Dinâmica de competição</Tab>
                        <Tab onClick={resetingGraphs}>Dinâmica de cliente</Tab>
                        <Tab onClick={resetingGraphs}>Dinâmica de fornecedor</Tab>
                        <Tab onClick={resetingGraphs}>Departamentos</Tab>
                    </TabList>
                    <TabPanel>
                        <div className="p-4">
                            <div className="d-flex justify-content-between card__header--home">
                                {graph ? <h6 className="mb-0">Previsão Mensal - R$</h6> : <h6 className="mb-0">Previsão Mensal por produto - R$</h6>}
        
                                <select name="" id="" onChange={handleGraphChange} className="select_input rounded">
                                    <option value="total">Previsão total</option>
                                    <option value="produto">Previsão produto</option>
                                </select>
                            </div>
    
                            <hr />

                                    {graph ? (
                                        <Card>
                                            <div className="p-4">
                                                <TotalPredictionGraphic
                                                    serviceSales={serviceSales}
                                                />
                                            </div>
                                        </Card>
                                    ) : (
                                        <Row>
                                            <Col xs={8}>
                                                <Card className="p-4">
                                                    <ProductPredictionGraphic
                                                        serviceSales={serviceSales}
                                                    />
                                                </Card>
                                            </Col>
                                            <Col>
                                                <Card className="p-4">
                                                    <ProductPredictionTotal serviceSales={serviceSales} />
                                                </Card>
                                            </Col>
                                        </Row>
                                    )
                                    
                                }
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="p-4">
                            <div className="d-flex justify-content-between card__header--home">
                                {graph ? <h6 className="mb-0">Investimento em Marketing</h6> : <h6 className="mb-0">Previsão de investimento por despesa</h6>}
        
                                <select name="" id="" onChange={handleGraphChange} className="select_input rounded">
                                    <option value="total">Previsão Total</option>
                                    <option value="produto">Previsão por despesa</option>
                                </select>
                            </div>
    
                            <hr />
    
                            {graph ?
                                <TotalMarketingInvestimentGraphic
                                    marketing={marketing}
                                />
                            :
                                <InvestimentPredictionGraphic
                                    marketing={marketing}
                                />
                            }
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="p-4">
                            <DynamicCompetition
                                forms={forms}
                            />
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <CustomerDynamic forms={forms} />
                    </TabPanel>
                    <TabPanel>
                        <SupplierDynamic forms={forms} />
                    </TabPanel>
                    <TabPanel>
                        <DepartmentData forms={forms} />
                    </TabPanel>
                </Tabs>
            </Container>
        </div>
    );
}

export default withRouter(MarketOverview);
