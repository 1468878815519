import React from 'react';
import {Row, Col, FormGroup, Card} from 'react-bootstrap';
import css from '../../marketdynamicoverview.module.css';
import { FieldArray} from 'formik';
import FormInput from '../../../components/FormInput';
import Button from '../../../../Button';
import { MdAdd } from 'react-icons/md';

function SalesForecast({values}) {
    return (
        <FieldArray name="marketDynamicSalesForecast">
            {({remove, unshift, form})=>(
                <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                    <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                        <Button
                            type="button"
                            variant="outline-success"
                            className={`w-100 h-100`}
                            onClick={()=> unshift({marketDynamicSegment: '', marketDynamicYear1: 0, marketDynamicYear2: 0, marketDynamicYear3: 0})}
                        >
                            <MdAdd fontSize={24} />
                        </Button>
                    </Col>
                    {values.marketDynamicSalesForecast?.length > 0 &&
                        values.marketDynamicSalesForecast.map((md, index)=>(
                            <Col xs={3} sm={3} md={3} lg={3} xl={3} key={index}>
                                <Card className="p-3">
                                    <FormGroup>                            
                                        <FormInput
                                            name={`marketDynamicSalesForecast[${index}].marketDynamicSegment`}
                                            type="text"
                                            original="marketDynamicSegment"
                                            errors={form.errors?.marketDynamicSalesForecast && form.errors?.marketDynamicSalesForecast.length && form.errors?.marketDynamicSalesForecast[index] ? form.errors.marketDynamicSalesForecast[index] : ""}
                                            touched={form.touched?.marketDynamicSalesForecast && form.touched?.marketDynamicSalesForecast.length && form.touched?.marketDynamicSalesForecast[index] ? form.touched.marketDynamicSalesForecast[index] : ""}
                                            holder="Entre com o segmento" >
                                            Segmento
                                        </FormInput>
                                    </FormGroup>

                                    <FormGroup>                            
                                        <FormInput
                                            name={`marketDynamicSalesForecast[${index}].marketDynamicYear1`}
                                            type="number"
                                            original="marketDynamicYear1"
                                            errors={form.errors?.marketDynamicSalesForecast && form.errors?.marketDynamicSalesForecast.length && form.errors?.marketDynamicSalesForecast[index] ? form.errors.marketDynamicSalesForecast[index] : ""}
                                            touched={form.touched?.marketDynamicSalesForecast && form.touched?.marketDynamicSalesForecast.length && form.touched?.marketDynamicSalesForecast[index] ? form.touched.marketDynamicSalesForecast[index] : ""}
                                            holder="Entre com o total do ano 1" >
                                            Primeiro Ano
                                        </FormInput>
                                    </FormGroup>

                                    <FormGroup>                            
                                        <FormInput
                                            name={`marketDynamicSalesForecast[${index}].marketDynamicYear2`}
                                            type="number"
                                            original="marketDynamicYear2"
                                            errors={form.errors?.marketDynamicSalesForecast && form.errors?.marketDynamicSalesForecast.length && form.errors?.marketDynamicSalesForecast[index] ? form.errors.marketDynamicSalesForecast[index] : ""}
                                            touched={form.touched?.marketDynamicSalesForecast && form.touched?.marketDynamicSalesForecast.length && form.touched?.marketDynamicSalesForecast[index] ? form.touched.marketDynamicSalesForecast[index] : ""}
                                            holder="Entre com o total do ano 2" >
                                            Segundo Ano
                                        </FormInput>
                                    </FormGroup>

                                    <FormGroup>                            
                                        <FormInput
                                            name={`marketDynamicSalesForecast[${index}].marketDynamicYear3`}
                                            type="number"
                                            original="marketDynamicYear3"
                                            errors={form.errors?.marketDynamicSalesForecast && form.errors?.marketDynamicSalesForecast.length && form.errors?.marketDynamicSalesForecast[index] ? form.errors.marketDynamicSalesForecast[index] : ""}
                                            touched={form.touched?.marketDynamicSalesForecast && form.touched?.marketDynamicSalesForecast.length && form.touched?.marketDynamicSalesForecast[index] ? form.touched.marketDynamicSalesForecast[index] : ""}
                                            holder="Entre com o total do ano 3" >
                                            Terceiro Ano
                                        </FormInput>
                                    </FormGroup>

                                    <FormGroup>
                                        <Button 
                                            variant="outline-danger" 
                                            className={`mt-2 w-100`} 
                                            type="button"
                                            onClick={()=>{
                                                if( values.marketDynamicSalesForecast.length > 1){
                                                    remove(index)
                                                }
                                            }}
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </FormGroup>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            )}
        </FieldArray>
    )
}

export default SalesForecast
