export const allColors = [
    "#26B677",
    "#062F50",
    "#FC828F",
    "#4F3F49",
    "#DE9E36"
]

export const someColors = [
    "#26B677",
    "#062F50",
]