import React from 'react';

// import { Container } from './styles';

function CircleButton({ children, ...rest }) {
  return (
    <button 
      {...rest}
      className="text-success p-1 border-0 bg-light rounded-circle d-flex justify-content-center align-items-center" 
      style={{ width: '30px', height: '30px'}}
    >
      {children}
    </button>
  );
}

export default CircleButton;