import React from 'react';
import Totalizers from './Totalizers';
import labelcss from '../../../companyoverview.module.css';

function MonthTotalizer({subIndex, name, values, index}) {
    return (
        <>
            <div className={`${labelcss.input_label}`}><label htmlFor="">Total</label></div>
            <Totalizers
                name={`${name}.priceTotal`}
                values={values}
                original="total"
                label="Preço"
                subIndex={subIndex}
                index={index}
            />
            <Totalizers
                name={`${name}.varCostTotal`}
                values={values}
                original="varCost"
                label="Custo Variável"
                subIndex={subIndex}
                index={index}
            />
            <Totalizers
                name={`${name}.fixCostTotal`}
                values={values}
                original="fixCost"
                label="Custo Fixo"
                subIndex={subIndex}
                index={index}
            />
            <Totalizers
                name={`${name}.marginTotal`}
                values={values}
                original="margin"
                label="Margin"
                subIndex={subIndex}
                index={index}
            />
        </>
    )
}

export default MonthTotalizer;
