import React, {useEffect} from 'react';
import {Table} from "react-bootstrap";
import { formatCurrency } from '../../../../utils/formatCurrency';
import HeaderTable from "./HeadTable";

function BudgetTable({produtos, totalMes, totalAno, title, prodTotal}){
    
    return (
        <>
            {produtos?.length > 0 && produtos.map((p, index)=>{
                return (
                    <tr key={index}>
                        {index === 0 && <td valign="middle" rowSpan={produtos.length + 1}>{title}</td>}
                        <td>{p.companyValueChainProduct}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.jan[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.fev[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.mar[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.abr[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.mai[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.jun[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.jul[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.ago[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.set[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.out[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.nov[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.dez[totalMes])}</td>
                        <td align="right">{formatCurrency(p.companyValueChainBudget.total[totalAno])}</td>
                    </tr>
                )
            })}
            {
                prodTotal && Object.keys(prodTotal).length > 0 && (
                    <tr>
                        <td>Total dos produtos</td>
                        <td align="right">{formatCurrency(prodTotal?.jan)}</td>
                        <td align="right">{formatCurrency(prodTotal?.fev)}</td>
                        <td align="right">{formatCurrency(prodTotal?.mar)}</td>
                        <td align="right">{formatCurrency(prodTotal?.abr)}</td>
                        <td align="right">{formatCurrency(prodTotal?.mai)}</td>
                        <td align="right">{formatCurrency(prodTotal?.jun)}</td>
                        <td align="right">{formatCurrency(prodTotal?.jul)}</td>
                        <td align="right">{formatCurrency(prodTotal?.ago)}</td>
                        <td align="right">{formatCurrency(prodTotal?.set)}</td>
                        <td align="right">{formatCurrency(prodTotal?.out)}</td>
                        <td align="right">{formatCurrency(prodTotal?.nov)}</td>
                        <td align="right">{formatCurrency(prodTotal?.dez)}</td>
                        <td align="right">{formatCurrency(prodTotal?.totalAnual)}</td>
                    </tr>
                )
            }
        </>
    )
}

export default BudgetTable;