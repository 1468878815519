import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import { Formik, Form } from "formik";
import FormInput from "../../components/FormInput";
import {
    CompanyStrategicSchema,
    initialStrategicValues,
} from "./helpers/formValidation";
import Button from "../../../Button";
import FormValidationError from "../../components/FormValidationError";

function StrategicGuidelines(props) {
    const [formValues, setFormValues] = useState(initialStrategicValues);

    useEffect(() => {
        let initialValues = { ...initialStrategicValues, ...props.questions };
        setFormValues(initialValues);
    }, [props.questions]);

    return (
        <Formik
            initialValues={formValues}
            validationSchema={CompanyStrategicSchema}
            validate={(values) => {
                props.setChanged(values !== formValues);
            }}
            onSubmit={(values) => {
                props.setSubmitting(true);
                props.strategicSubmit(values, props.group);
            }}
            enableReinitialize
        >
            {({ values, errors, touched }) => (
                <Form className="p-4">
                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Diretrizes Estratégicas
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="companyPropose"
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com o propósito da empresa"
                                        >
                                            Propósito da empresa
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="companyMission"
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com a missão da empresa"
                                        >
                                            Missão
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="companyVision"
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com a visão da empresa"
                                        >
                                            Visão
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="organizacionalCultureValues"
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com os valores"
                                        >
                                            Cultura - Valores
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="organizacionalCulturePrinciples"
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Entre com os princípios"
                                        >
                                            Cultura - Princípios de Gestão
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <Row className="p-0 m-0 mt-3 justify-content-end">
                                        {Object.keys(errors).length > 0 && (
                                            <FormValidationError
                                                errors={errors}
                                            />
                                        )}
                                    </Row>

                                    <Button
                                        variant="success"
                                        disabled={props.submitting}
                                        type="submit"
                                    >
                                        Salvar informações
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default StrategicGuidelines;
