import * as Yup from 'yup';

const CompanyForm = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Mínimo 3 caractéres')
    .required('Campo obrigatório'),
  lastName: Yup.string()
    .min(3, 'Mínimo 3 caractéres')
    .required('Campo obrigatório'),
  name: Yup.string()
    .min(3, 'Mínimo 3 caractéres')
    .required('Campo obrigatório'),
  fantasy: Yup.string()
    .min(3, 'Mínimo 3 caractéres')
    .required('Campo obrigatório'),
});

export default CompanyForm;