import React from 'react';
import { Field, ErrorMessage, FieldArray} from 'formik';
import FormInput from '../../../components/FormInput';
import {Row, Col, FormGroup, Card} from 'react-bootstrap';
import CompanyMarketTotal from './CompanyMarketTotal';
import css from '../../companyoverview.module.css';
import CompanyMarketTotalizer from "./CompanyMarketTotalizer";
import { MdAdd } from 'react-icons/md';
import Button from '../../../../Button';
import FormSelect from '../../../../FormSelect';

function CompanyMarket({values, errors, touched}) {

    const validationError = (index, subIndex, form)=>{
        return (form.errors?.companyMarket?.companyMarket &&
            form.errors?.companyMarket?.companyMarket?.length &&
            form.errors?.companyMarket?.companyMarket[index]?.companySegment &&
            form.errors?.companyMarket?.companyMarket[index]?.companySegment.length &&
            form.errors?.companyMarket?.companyMarket[index]?.companySegment[subIndex])
    }

    const validationTouched = (index, subIndex, form)=>{
        return(form.touched?.companyMarket?.companyMarket &&
            form.touched?.companyMarket?.companyMarket?.length &&
            form.touched?.companyMarket?.companyMarket[index]?.companySegment &&
            form.touched?.companyMarket?.companyMarket[index]?.companySegment.length &&
            form.touched?.companyMarket?.companyMarket[index]?.companySegment[subIndex])
    }

    return (
        <FieldArray name="companyMarket.companyMarket">
            {({remove, push, form})=>(
                <>
                    {values.companyMarket.companyMarket?.length > 0 &&
                    values.companyMarket.companyMarket.map((cm, index)=>(
                        <div key={index}>
                            <Row className="align-items-start">
                                <Col lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name={`companyMarket.companyMarket[${index}].companyMarket`}
                                            original="companyMarket"
                                            type="text"
                                            errors={form.errors?.companyMarket && form.errors?.companyMarket.companyMarket.length && form.errors?.companyMarket.companyMarket[index] ? form.errors.companyMarket.companyMarket[index] : ""}
                                            touched={form.touched?.companyMarket && form.touched?.companyMarket.companyMarket.length && form.touched?.companyMarket.companyMarket[index] ? form.touched.companyMarket.companyMarket[index] : ""}
                                            holder="Entre com o mercado" >
                                            Mercado
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                                <Button
                                    type="button"
                                    onClick={()=>{
                                        if(values.companyMarket.companyMarket.length > 1){
                                            remove(index);
                                        }
                                    }}
                                    style={{ marginTop: '34px'}}
                                    variant="outline-danger"
                                ><i className="fas fa-trash-alt mr-2" />Remover mercado</Button>
                            </Row>

                                <FieldArray
                                    name={`companyMarket.companyMarket[${index}].companySegment`}
                                    render={({remove, push, unshift})=>(
                                        <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                                            <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                                                <Button
                                                    type="button"
                                                    variant="outline-success"
                                                    className={`w-100 h-100`}
                                                    onClick={() => unshift({companySegment: "", companyMarketSize: 0, companyMarketUnity: 0})}
                                                >
                                                    <MdAdd fontSize="24"/>
                                                </Button>
                                            </Col>
                                            {cm.companySegment?.map((cps, indexCps) => (
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3} key={indexCps}>
                                                    <Card className="p-3">
                                                        <FormGroup>
                                                            <FormInput
                                                                name={`companyMarket.companyMarket[${index}].companySegment[${indexCps}].companySegment`}
                                                                type="text"
                                                                original="companySegment"
                                                                errors={validationError(index, indexCps, form) ? form.errors.companyMarket.companyMarket[index].companySegment[indexCps]  : ""}
                                                                touched={validationTouched(index, indexCps, form) ? form.touched.companyMarket.companyMarket[index].companySegment[indexCps] : ""}
                                                                holder="Entre com o segmento"
                                                            >
                                                                Segmento
                                                            </FormInput>
                                                        </FormGroup>
    
                                                        <FormGroup>
                                                            <FormInput
                                                                name={`companyMarket.companyMarket[${index}].companySegment[${indexCps}].companyMarketSize`}
                                                                type="number"
                                                                original="companyMarketSize"
                                                                errors={validationError(index, indexCps, form) ? form.errors.companyMarket.companyMarket[index].companySegment[indexCps]  : ""}
                                                                touched={validationTouched(index, indexCps, form) ? form.touched.companyMarket.companyMarket[index].companySegment[indexCps] : ""}
                                                                holder="Entre com o tamanho do mercado" >
                                                                Tamanho
                                                            </FormInput>
                                                        </FormGroup>

                                                        <FormSelect
                                                            label={`Unidade`}
                                                            name={`companyMarket.companyMarket[${index}].companySegment[${indexCps}].companyMarketUnity`}
                                                            id={`companyMarket.companyMarket[${index}].companySegment[${indexCps}].companyMarketUnity`}
                                                        >
                                                            <option value="milheiro">Milheiro</option>
                                                            <option value="litro">Litros</option>
                                                            <option value="dinheiro">Dinheiro</option>
                                                            <option value="tonelada">Toneladas</option>
                                                            <option value="real">Reais</option>
                                                            <option value="dolar">Dólar</option>
                                                        </FormSelect>
    
                                                        <FormGroup>
                                                            <Button variant="outline-danger" className={`w-100 mt-2`} type="button" onClick={() => {
                                                                if(values.companyMarket.companyMarket[index].companySegment.length > 1){
                                                                    remove(indexCps);
                                                                }
                                                                }}>
                                                                <i className="fas fa-trash-alt" />
                                                            </Button>
                                                        </FormGroup>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    )}>
                                </FieldArray>

                                <div className="d-flex align-items-center mt-3">
                                    <label className={`${css.input_label} font-weight-bold mb-0 mr-2`} htmlFor={`companyMarket.companyMarket[${index}].companyMarketTotal`}>Total deste mercado:</label>
                                    <CompanyMarketTotal
                                        index={index}
                                        disabled={true}
                                        name={`companyMarket.companyMarket[${index}].companyMarketTotal`}
                                        // style={{ width: '100px !important' }}
                                    />
                                </div>
                        
                                <hr />
                            </div>
                    ))
                    }
                    <Row className="mt-4">
                        <Col className="d-flex align-items-center">
                            <div className="mr-3 font-weight-bold"><span>Total de todos mercados:</span></div>
                            <CompanyMarketTotalizer
                                disabled={true}
                                name={`companyMarket.companyMarketTotal`}
                            />
                            <Button
                                variant="outline-success"
                                type="button"
                                onClick={()=> push({companyMarket: '', companySegment: [{companySegment: "", companyMarketSize: "", companyMarketUnity: ""}], companyMarketTotal: ''})}
                                className="ml-3"
                            ><MdAdd fontSize="20" className="mr-1"/>Adicionar mercado</Button>
                        </Col>
                    </Row>
                </>
            )}
        </FieldArray>
    )
}

export default CompanyMarket;
