import React from 'react';
import {Row, Col, FormGroup} from 'react-bootstrap';
import css from '../../marketdynamicoverview.module.css';
import { FieldArray, Field } from 'formik';
import FormInput from '../../../components/FormInput';
import Button from '../../../../Button';
import { MdAdd } from 'react-icons/md';


function Benckmark({values, errors, touched}) {
    return (
        <FieldArray name="marketDynamicBenckmark">
            {({insert, remove, push, form})=>(
                <>
                    <Row style={{ fontSize: '12px'}}>
                        <Col sm={5} md={5} lg={5} xl={5}>       
                            <div className={`${css.input_label}`}><label>Player</label></div>
                        </Col>
                        <Col sm={1} md={1} lg={1} xl={1} className="text-center">
                            <label htmlFor="">Custo</label>
                        </Col>
                        <Col sm={1} md={1} lg={1} xl={1} className="text-center">
                            <label htmlFor="">Variedade</label>
                        </Col>
                        <Col sm={1} md={1} lg={1} xl={1} className="text-center">
                            <label htmlFor="">Qualidade</label>
                        </Col>
                        <Col sm={1} md={1} lg={1} xl={1} className="text-center">
                            <label htmlFor="">Tempo de entrega</label>
                        </Col>
                        <Col sm={1} md={1} lg={1} xl={1} className="text-center">
                            <label htmlFor="">Outro</label>
                        </Col>
                        <Col sm={1} md={1} lg={1} xl={1}>
                        </Col>
                    </Row>
                    {values.marketDynamicBenckmark?.length > 0 &&
                        values.marketDynamicBenckmark.map((md, index)=>(
                            <Row key={index}>
                                <Col sm={5} md={5} lg={5} xl={5}> 
                                    <FormGroup className="mb-2">
                                        <FormInput
                                            name={`marketDynamicBenckmark[${index}].marketDynamicPlayer`}
                                            type="text"
                                            original="marketDynamicPlayer"
                                            errors={form.errors?.marketDynamicBenckmark && form.errors?.marketDynamicBenckmark.length && form.errors?.marketDynamicBenckmark[index] ? form.errors.marketDynamicBenckmark[index] : ""}
                                            touched={form.touched?.marketDynamicBenckmark && form.touched?.marketDynamicBenckmark.length && form.touched?.marketDynamicBenckmark[index] ? form.touched.marketDynamicBenckmark[index] : ""}
                                            holder="Entre com o 'Player'" />
                                    </FormGroup>
                                </Col>
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <div className="d-flex justify-content-center"><Field className="form-check-input p-0 m-0" type="checkbox" name={`marketDynamicBenckmark[${index}].marketDynamicBenckmark`} value="cost"/></div>
                                </Col>
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <div className="d-flex justify-content-center"><Field className="form-check-input p-0 m-0" type="checkbox" name={`marketDynamicBenckmark[${index}].marketDynamicBenckmark`} value="selection"/></div>
                                </Col>
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <div className="d-flex justify-content-center"><Field className="form-check-input p-0 m-0" type="checkbox" name={`marketDynamicBenckmark[${index}].marketDynamicBenckmark`} value="quality"/></div>
                                </Col>
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <div className="d-flex justify-content-center"><Field className="form-check-input p-0 m-0" type="checkbox" name={`marketDynamicBenckmark[${index}].marketDynamicBenckmark`} value="Delivery"/></div>
                                </Col>
                                <Col sm={1} md={1} lg={1} xl={1}>
                                    <div className="d-flex justify-content-center"><Field className="form-check-input p-0 m-0" type="checkbox" name={`marketDynamicBenckmark[${index}].marketDynamicBenckmark`} value="other"/></div>
                                </Col>
                                <Col>
                                    <Button
                                        variant="outline-danger"
                                        type="button"
                                        onClick={()=>{
                                            if( values.marketDynamicBenckmark.length > 1){
                                                remove(index)
                                            }
                                        }}
                                    ><i className="fas fa-trash-alt" /></Button>
                                </Col>
                            </Row>
                        ))
                    }
                    <Row>
                        <Col>
                            <FormGroup>
                                <Button
                                    variant="outline-success"
                                    type="button"
                                    onClick={()=> push({marketDynamicPlayer: '', marketDynamicBenckmark: []})}
                                    className={`mt-2`}
                                ><MdAdd fontSize={20} className="mr-1" />Adicionar Player</Button>
                            </FormGroup>
                        </Col>
                    </Row>

                    <hr />
                </>
            )}
        </FieldArray>
    )
}

export default Benckmark;
