import React, {useEffect, useState} from 'react';
import {Bar} from "react-chartjs-2";
import {allColors} from "../css/colors";
import {Row} from "react-bootstrap";

const datasetItem = {
    label: `Total of 0`,
    data: [0,0,0],
    backgroundColor: allColors,
    borderColor: allColors,
    borderWidth: 1,
};

const body = ()=>({
    labels: ["primeiro ano", "segundo ano", "terceiro ano"],
    datasets: [
        datasetItem
    ],
})

const options = ()=>({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
});

function SalesForecastGraphic(props) {
    const [segments, setSegments] = useState([]);
    const [dataBody, setDataBody] = useState(body());

    const setGraph = (segment)=>{
        let tempBody = body();
        tempBody.datasets[0].data[0] = segment.marketDynamicYear1;
        tempBody.datasets[0].data[1] = segment.marketDynamicYear2;
        tempBody.datasets[0].data[2] = segment.marketDynamicYear3;
        tempBody.datasets[0].label = `Total of ${parseFloat(segment.marketDynamicYear1) + parseFloat(segment.marketDynamicYear2) + parseFloat(segment.marketDynamicYear3)}`;

        setDataBody(tempBody);
    }

    const handleGraphChange = (ev)=>{
        const segment = segments.find(item=>item.marketDynamicSegment === ev.target.value);
        setGraph(segment);
    }

    useEffect(()=>{
        setSegments(props.data);
        if(props.data && props.data.length > 0){
            setGraph(props.data[0])
        }
    }, []);

    return (
        <>
            <div className="justify-content-between align-items-center card__header--home">
                <h6 className="mb-0">{props.title}</h6>

                <select onChange={handleGraphChange} className="select_input rounded">
                    {segments.length > 0 &&
                        segments.map((item, index)=>{
                            return <option key={index} value={item.marketDynamicSegment}>{item.marketDynamicSegment}</option>
                        })
                    }
                </select>
            </div>
            <Row className="p-4">
                <Bar
                    data={dataBody}
                    options={options()}
                    height={300}
                />
            </Row>
            <hr />
        </>
    );
}

export default SalesForecastGraphic;