import React from 'react';
import {Table} from "react-bootstrap";
import { formatCurrency } from '../../../../utils/formatCurrency';
import HeaderTable from "./HeadTable";

function BudgetTable(props){
    const margin = props.margin
    const totDesp = props.totDesp
    return (
            <tr>
                <td valign="middle" >Resultado Operacional</td>
                <td></td>
                <td align="right">{ formatCurrency(parseFloat(margin?.jan?.totalMargin ? margin?.jan?.totalMargin : 0) - parseFloat(totDesp.jan ? totDesp.jan : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.fev?.totalMargin ? margin?.fev?.totalMargin : 0) - parseFloat(totDesp.fev ? totDesp.fev : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.mar?.totalMargin ? margin?.mar?.totalMargin : 0) - parseFloat(totDesp.mar ? totDesp.mar : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.abr?.totalMargin ? margin?.abr?.totalMargin : 0) - parseFloat(totDesp.abr ? totDesp.abr : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.mai?.totalMargin ? margin?.mai?.totalMargin : 0) - parseFloat(totDesp.mai ? totDesp.mai : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.jun?.totalMargin ? margin?.jun?.totalMargin : 0) - parseFloat(totDesp.jun ? totDesp.jun : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.jul?.totalMargin ? margin?.jul?.totalMargin : 0) - parseFloat(totDesp.jul ? totDesp.jul : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.ago?.totalMargin ? margin?.ago?.totalMargin : 0) - parseFloat(totDesp.ago ? totDesp.ago : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.set?.totalMargin ? margin?.set?.totalMargin : 0) - parseFloat(totDesp.set ? totDesp.set : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.out?.totalMargin ? margin?.out?.totalMargin : 0) - parseFloat(totDesp.out ? totDesp.out : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.nov?.totalMargin ? margin?.nov?.totalMargin : 0) - parseFloat(totDesp.nov ? totDesp.nov : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.dez?.totalMargin ? margin?.dez?.totalMargin : 0) - parseFloat(totDesp.dez ? totDesp.dez : 0))}</td>
                <td align="right">{ formatCurrency(parseFloat(margin?.total ? margin?.total : 0) - parseFloat(totDesp.totalAnual ? totDesp.totalAnual : 0))}</td>
            </tr>
    )
}

export default BudgetTable;