import React from 'react';

import authimg from './imgs/authimg.png'
import logo from './imgs/id_login.png'
import css from './auth.module.css'
import { Link } from 'react-router-dom';

function UserDisabled() {
  return (
    <div className={css.authContainer}>
        <section className={css.form}>
            <Link to="/"><img src={logo} alt="CEO360" /></Link>
            <form>
              {/*<h4>Usuário desabilitado</h4>*/}
              <h4 className="text-center">
                  Bem Vindo - Estamos Quase lá!
              </h4>
                <p className="text-center">
                    Caso esteja tendo problemas de acessar, ou esse é eu 1º login no CEO360-PLAN, entre em contato com nosso suporte técnico pelo email <b style={{color: 'blue'}}>contato@academiadeexecutivos.com</b> ou pelo WhatsApp <b>+55 11 989638919</b>.
                </p>
              <Link className={css.authLink} to="/signin">
                Voltar ao login   
              </Link>
            </form>
        </section>
      <img className={css.authImg} src={authimg} alt="Business"/>
    </div>
  );
}

export default UserDisabled;