import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    marginSection: {
        marginLeft: 28,
    },
    header: {
        flexDirection: "row",
        borderBottomWidth: 1,
    },
    lines: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#aaaaaa",
    },
    headerText: {
        fontSize: 10,
        fontWeight: "bold",
        paddingVertical: 6,
    },
    lineText: {
        fontSize: 9,
        paddingVertical: 6,
    },
});

const StrategyPerformance = ({
    strategyRH,
    performanceRH,
    strategyMarketing,
    performanceMarketing,
    strategyVendas,
    performanceVendas,
    strategyOperations,
    performanceOperations,
    strategySupplyChain,
    performanceSupplyChain,
    strategyTech,
    performanceTech,
    strategyFinances,
    performanceFinances,
}) => (
    <>
        <View style={styles.marginSection}>
            <View style={[styles.header, { borderTopWidth: 1 }]}>
                <Text style={[styles.headerText, { width: "20%" }]}>Área</Text>
                <Text style={[styles.headerText, { width: "5%" }]}>#</Text>
                <Text style={[styles.headerText, { width: "32.5%" }]}>
                    Performance
                </Text>
                <Text style={[styles.headerText, { width: "32.5%" }]}>
                    Estratégia
                </Text>
            </View>
            <View style={styles.lines}>
                <Text style={[styles.lineText, { width: "20%" }]}>
                    Recursos Humanos
                </Text>
                <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {performanceRH[0].companyValueChainPerformanceIndicators
                        ? performanceRH[0]
                              .companyValueChainPerformanceIndicators
                        : "Não Informado"}
                </Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {strategyRH ? strategyRH : "Não Informado"}
                </Text>
            </View>
            {performanceRH.length > 1 ? (
                <View>
                    {performanceRH.slice(1).map((item, index) => {
                        return (
                            <View key={index} style={styles.lines}>
                                <Text
                                    style={[styles.lineText, { width: "20%" }]}
                                ></Text>

                                <Text
                                    style={[styles.lineText, { width: "5%" }]}
                                >
                                    {index + 2}
                                </Text>
                                <Text
                                    style={[
                                        styles.lineText,
                                        { width: "32.5%" },
                                    ]}
                                >
                                    {
                                        item.companyValueChainPerformanceIndicators
                                    }
                                </Text>
                            </View>
                        );
                    })}
                </View>
            ) : null}
            <View style={styles.lines}>
                <Text style={[styles.lineText, { width: "20%" }]}>
                    Marketing
                </Text>
                <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {performanceMarketing[0]
                        .companyValueChainPerformanceIndicators
                        ? performanceMarketing[0]
                              .companyValueChainPerformanceIndicators
                        : "Não Informado"}
                </Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {strategyMarketing ? strategyMarketing : "Não Informado"}
                </Text>
            </View>
            {performanceMarketing.length > 1 ? (
                <View>
                    {performanceMarketing.slice(1).map((item, index) => {
                        return (
                            <View key={index} style={styles.lines}>
                                <Text
                                    style={[styles.lineText, { width: "20%" }]}
                                ></Text>

                                <Text
                                    style={[styles.lineText, { width: "5%" }]}
                                >
                                    {index + 2}
                                </Text>
                                <Text
                                    style={[
                                        styles.lineText,
                                        { width: "32.5%" },
                                    ]}
                                >
                                    {
                                        item.companyValueChainPerformanceIndicators
                                    }
                                </Text>
                            </View>
                        );
                    })}
                </View>
            ) : null}
            <View style={styles.lines}>
                <Text style={[styles.lineText, { width: "20%" }]}>
                    Operaçoes
                </Text>
                <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {performanceOperations[0]
                        .companyValueChainPerformanceIndicators
                        ? performanceOperations[0]
                              .companyValueChainPerformanceIndicators
                        : "Não Informado"}
                </Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {strategyOperations ? strategyOperations : "Não Informado"}
                </Text>
            </View>
            {performanceOperations.length > 1 ? (
                <View>
                    {performanceOperations.slice(1).map((item, index) => {
                        return (
                            <View key={index} style={styles.lines}>
                                <Text
                                    style={[styles.lineText, { width: "20%" }]}
                                ></Text>

                                <Text
                                    style={[styles.lineText, { width: "5%" }]}
                                >
                                    {index + 2}
                                </Text>
                                <Text
                                    style={[
                                        styles.lineText,
                                        { width: "32.5%" },
                                    ]}
                                >
                                    {
                                        item.companyValueChainPerformanceIndicators
                                    }
                                </Text>
                            </View>
                        );
                    })}
                </View>
            ) : null}
            <View style={styles.lines}>
                <Text style={[styles.lineText, { width: "20%" }]}>
                    Vendas & Serviços
                </Text>
                <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {performanceVendas[0].companyValueChainPerformanceIndicators
                        ? performanceVendas[0]
                              .companyValueChainPerformanceIndicators
                        : "Não Informado"}
                </Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {strategyVendas ? strategyVendas : "Não Informado"}
                </Text>
            </View>
            {performanceVendas.length > 1 ? (
                <View>
                    {performanceVendas.slice(1).map((item, index) => {
                        return (
                            <View key={index} style={styles.lines}>
                                <Text
                                    style={[styles.lineText, { width: "20%" }]}
                                ></Text>

                                <Text
                                    style={[styles.lineText, { width: "5%" }]}
                                >
                                    {index + 2}
                                </Text>
                                <Text
                                    style={[
                                        styles.lineText,
                                        { width: "32.5%" },
                                    ]}
                                >
                                    {
                                        item.companyValueChainPerformanceIndicators
                                    }
                                </Text>
                            </View>
                        );
                    })}
                </View>
            ) : null}
            <View style={styles.lines}>
                <Text style={[styles.lineText, { width: "20%" }]}>
                    Supply Chain
                </Text>
                <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {performanceSupplyChain[0]
                        .companyValueChainPerformanceIndicators
                        ? performanceSupplyChain[0]
                              .companyValueChainPerformanceIndicators
                        : "Não Informado"}
                </Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {strategySupplyChain
                        ? strategySupplyChain
                        : "Não Informado"}
                </Text>
            </View>
            {performanceSupplyChain.length > 1 ? (
                <View>
                    {performanceSupplyChain.slice(1).map((item, index) => {
                        return (
                            <View key={index} style={styles.lines}>
                                <Text
                                    style={[styles.lineText, { width: "20%" }]}
                                ></Text>

                                <Text
                                    style={[styles.lineText, { width: "5%" }]}
                                >
                                    {index + 2}
                                </Text>
                                <Text
                                    style={[
                                        styles.lineText,
                                        { width: "32.5%" },
                                    ]}
                                >
                                    {
                                        item.companyValueChainPerformanceIndicators
                                    }
                                </Text>
                            </View>
                        );
                    })}
                </View>
            ) : null}
            <View style={styles.lines}>
                <Text style={[styles.lineText, { width: "20%" }]}>
                    Tecnologia
                </Text>
                <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {performanceTech[0].companyValueChainPerformanceIndicators
                        ? performanceTech[0]
                              .companyValueChainPerformanceIndicators
                        : "Não Informado"}
                </Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {strategyTech ? strategyTech : "Não Informado"}
                </Text>
            </View>
            {performanceTech.length > 1 ? (
                <View>
                    {performanceTech.slice(1).map((item, index) => {
                        return (
                            <View key={index} style={styles.lines}>
                                <Text
                                    style={[styles.lineText, { width: "20%" }]}
                                ></Text>

                                <Text
                                    style={[styles.lineText, { width: "5%" }]}
                                >
                                    {index + 2}
                                </Text>
                                <Text
                                    style={[
                                        styles.lineText,
                                        { width: "32.5%" },
                                    ]}
                                >
                                    {
                                        item.companyValueChainPerformanceIndicators
                                    }
                                </Text>
                            </View>
                        );
                    })}
                </View>
            ) : null}
            <View style={styles.lines}>
                <Text style={[styles.lineText, { width: "20%" }]}>
                    Finanças
                </Text>
                <Text style={[styles.lineText, { width: "5%" }]}>1</Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {performanceFinances[0]
                        .companyValueChainPerformanceIndicators
                        ? performanceFinances[0]
                              .companyValueChainPerformanceIndicators
                        : "Não Informado"}
                </Text>
                <Text style={[styles.lineText, { width: "32.5%" }]}>
                    {strategyFinances ? strategyFinances : "Não Informado"}
                </Text>
            </View>
            {performanceFinances.length > 1 ? (
                <View>
                    {performanceFinances.slice(1).map((item, index) => {
                        return (
                            <View key={index} style={styles.lines}>
                                <Text
                                    style={[styles.lineText, { width: "20%" }]}
                                ></Text>

                                <Text
                                    style={[styles.lineText, { width: "5%" }]}
                                >
                                    {index + 2}
                                </Text>
                                <Text
                                    style={[
                                        styles.lineText,
                                        { width: "32.5%" },
                                    ]}
                                >
                                    {
                                        item.companyValueChainPerformanceIndicators
                                    }
                                </Text>
                            </View>
                        );
                    })}
                </View>
            ) : null}
        </View>
    </>
);

export default StrategyPerformance;
