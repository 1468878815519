import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';

// import { Container } from './styles';

function WithoutSessionRoute({children, ...rest}) {
  const { session } = useAuth();
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !session ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default WithoutSessionRoute;