import { ErrorMessage, Field, Formik } from 'formik';
import React from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';

import CompanySchema from '../../validation/CompanyForm';

function CompanyForm({ onSubmit, values }) {
  
  const initialValues = {
    name: values?.name || '',
    fantasy: values?.fantasy || '',
    firstName: values?.fantasy || '',
    lastName: values?.fantasy || '',
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CompanySchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '1000px' }}>

          <h6 className="text-muted mb-4 font-weight-normal">Sobre você</h6>
          
          <Row>
            <Col>
              <Form.Group controlId="firstName">
                <Form.Label>Nome</Form.Label>
                <Field name="firstName">
                  {({ field }) => (
                    <Form.Control {...field} type="text" />
                  )}
                </Field>
                <ErrorMessage name="firstName" component="div" className="error-message"/>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="lastName">
                <Form.Label>Sobrenome</Form.Label>
                <Field name="lastName">
                  {({ field }) => (
                    <Form.Control {...field} type="text" />
                  )}
                </Field>
                <ErrorMessage name="lastName" component="div" className="error-message"/>
              </Form.Group>
            </Col>
          </Row>

          <hr />

          <h6 className="text-muted mb-4 font-weight-normal">Sobre sua empresa</h6>

          <Row>
            <Col>
              <Form.Group controlId="name">
                <Form.Label>Razão social</Form.Label>
                <Field name="name">
                  {({ field }) => (
                    <Form.Control {...field} type="text" />
                  )}
                </Field>
                <ErrorMessage name="name" component="div" className="error-message"/>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="fantasy">
                <Form.Label>Nome fantasia</Form.Label>
                <Field name="fantasy">
                  {({ field }) => (
                    <Form.Control {...field} type="text" />
                  )}
                </Field>
                <ErrorMessage name="fantasy" component="div" className="error-message"/>
              </Form.Group>
            </Col>
          </Row>

          <Button variant="success" type="submit" className="mt-4" disabled={isSubmitting}>
            Enviar dados
          </Button>
        </Form>
      )}
    </Formik>
  );
}

export default CompanyForm;