import React, {useEffect, useState} from 'react';
import {getQuestions} from "../../../../../services/formData";
import useSpinner from "../../../../spinner/useSpinner";
import {Container, Row, Col, Card} from "react-bootstrap";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Competitors from "./Competitors";
import TotalCompetitorsGraphic from "./TotalCompetitorsGraphic";
import {toast, ToastContainer} from "react-toastify";

function DynamicCompetition(props) {
    const [spinner, showSpinner, hideSpinner] = useSpinner();
    const [tabIndex, setTabIndex] = useState(0);
    const [directCompetitors, setDirectCompetitors] = useState([]);
    const [indirectCompetitors, setIndirectCompetitors] = useState([]);

    useEffect(()=>{
        let isSubscribe = true;
        showSpinner();
        const formularios = props.forms;
        const dynamicMenu = formularios?.find(hit=>hit.FormType.menuOrder === 2); //find do menu de dinâmica de mercado

        if(dynamicMenu && Object.keys(dynamicMenu).length) {
            const dynamicSecMenu = dynamicMenu.FormType.MainGroups.find(hit=>hit.menuOrder === 1);
            if(dynamicSecMenu && Object.keys(dynamicSecMenu).length && isSubscribe){
                getQuestions(dynamicMenu.id, dynamicSecMenu.id, 0, (err, data)=>{
                    if(err){
                        console.log(err);
                        toast.error("Algo deu errado ao recuperar os dados");
                        // props.history.push("/");
                        hideSpinner();
                    }else{
                        if(isSubscribe){
                            const direct = data.marketDynamicDirectCompetitors;
                            const indirect = data.marketDynamicIndirectCompetitors;
                            setDirectCompetitors(direct);
                            setIndirectCompetitors(indirect);
                            hideSpinner();
                        }
                    }
                });
            }
        }

        return ()=>{
            isSubscribe = false;
        }
    }, [props.forms])

    return (
        <>
            {spinner}
            <ToastContainer />
            <Container fluid>
                <Row className="p-2">
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Card className="p-4 h-100">
                            <TotalCompetitorsGraphic
                                direct={directCompetitors}
                                indirect={indirectCompetitors}
                            />
                        </Card>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Card>
                            <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                                <TabList>
                                    <Tab>Competidores Diretos</Tab>
                                    <Tab>Competidores Indiretos</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="p-4">
                                        <Competitors
                                            title="Market Share por Segmento"
                                            data={directCompetitors}
                                        />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="p-4">
                                        <Competitors
                                            title="Mix da Embalagem por Segmento"
                                            data={indirectCompetitors}
                                        />
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DynamicCompetition;