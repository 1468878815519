const permissionEnum = {
  FORM_TYPE: {
    STRATEGIC_POSITION: 1,
    MARKET_DYNAMIC: 2,
    MACRO_ENVIROMENTAL_FORCES: 3,
    BUSINESS_PLAN: 4
  },
  MAIN_GROUP: {
    GENERAL: 5,
    STRATEGIC_GUIDELINES: 6, 
    ADMINISTRATION_FUNDAMENTALS: 7,
    VALUE_CHAIN: 8,
    COMPETITION_DYNAMIC: 9, 
    COSTUMER_DYNAMIC: 10, 
    SUPPLIER_DYNAMIC: 11,
    NEW_PRODUCTS: 12,
    NEW_COMPETITORS: 13,
    MACRO_ENVIROMENTAL_FORCES: 14,
    BUDGET: 15,
    BUDGET_ASSUMPTIONS: 16,
    MARKET_OVERVIEW: 17,
    SWOT: 18,
  },
  SUB_GROUP: {
    RH: 19,
    MARKETING: 20,
    SALES: 21,
    OPERATIONS: 22,
    SUPPLY_CHAIN: 23,
    TECNOLOGY: 24,
    FINANCES: 25,
  }
}
export default permissionEnum;