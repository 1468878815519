import React from 'react';
import {Row, Col} from 'react-bootstrap';
import GeneralTotalizers from './GeneralTotalizers';
import GeneralUnitTotalizers from './GeneralUnitTotalizers';
import MainMonthTotalizers from "./MainMonthTotalizers";
const indexName = "companyValueChainBudget";

function MainTotalizers({index}){
    return (
        <Row className="p-0 m-0 mt-5">
            <Col lg={1} />
            <Col className="p-0 m-0" lg={9}>
                <Row className="p-0 m-0">
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.jan.totalMain`}
                            index={index}
                            month="jan"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.jan.totalUnit`}
                            index={index}
                            month="jan"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.fev.totalMain`}
                            index={index}
                            month="fev"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.fev.totalUnit`}
                            index={index}
                            month="fev"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.mar.totalMain`}
                            index={index}
                            month="mar"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.mar.totalUnit`}
                            index={index}
                            month="mar"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.abr.totalMain`}
                            index={index}
                            month="abr"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.abr.totalUnit`}
                            index={index}
                            month="abr"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.mai.totalMain`}
                            index={index}
                            month="mai"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.mai.totalUnit`}
                            index={index}
                            month="mai"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.jun.totalMain`}
                            index={index}
                            month="jun"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.jun.totalUnit`}
                            index={index}
                            month="jun"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.jul.totalMain`}
                            index={index}
                            month="jul"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.jul.totalUnit`}
                            index={index}
                            month="jul"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.ago.totalMain`}
                            index={index}
                            month="ago"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.ago.totalUnit`}
                            index={index}
                            month="ago"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.set.totalMain`}
                            index={index}
                            month="set"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.set.totalUnit`}
                            index={index}
                            month="set"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.out.totalMain`}
                            index={index}
                            month="out"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.out.totalUnit`}
                            index={index}
                            month="out"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.nov.totalMain`}
                            index={index}
                            month="nov"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.nov.totalUnit`}
                            index={index}
                            month="nov"
                            refkey="unit"
                        />
                    </Col>
                    <Col className="p-0 m-0" lg={1}>
                        Total
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.dez.totalMain`}
                            index={index}
                            month="dez"
                            refkey="total"
                            type="currency"
                        />
                        Unidade
                        <MainMonthTotalizers
                            name={`[${indexName}][${index}].total.dez.totalUnit`}
                            index={index}
                            month="dez"
                            refkey="unit"
                        />
                    </Col>
                </Row>
            </Col>
            <Col>
                Total
                <GeneralTotalizers
                    name={`[${indexName}][${index}].generalTotalizer`}
                    index={index}
                />
                Unidade
                <GeneralUnitTotalizers
                    name={`[${indexName}][${index}].generalUnitTotalizer`}
                    index={index}
                />
            </Col>
        </Row>
    )
}

export default MainTotalizers;