import React, {useState, useEffect} from 'react';
import { Pie } from 'react-chartjs-2';
import { allColors } from '../css/colors';

const datasetItem = {
    label: 'Total of 0 ',
    data: [0, 0],
    backgroundColor: [
        'black',
        'white',
    ],
    borderColor: "white",
    borderWidth: 1,
}

const body = {
    labels: ['one', 'two'],
    datasets: [
        datasetItem
    ],
}

const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            position: 'right',
            align: "start"
        }
    },
}

function ProductPredictionTotal(props) {
    const [dataBody, setDataBody] = useState(body);
    console.log(props.serviceSales)

    useEffect(() => {
        if (props.serviceSales.length > 0) {
          const produtos = props.serviceSales[0].companyValueChainBudget.map(hit=>{
              return {
                  prod: hit.companyValueChainProduct,
                  values: hit.companyValueChainBudget
              }
          });

          //Grafico por produto
          let dataAssembly = {
            label: "Produto",
            data: [],
            backgroundColor: allColors,
            borderColor: "white",
            borderWidth: 1,
          }
          let labels = [];

          produtos.forEach((item, index)=>{
            let data =  item.values.jan.total +
                        item.values.fev.total +
                        item.values.mar.total +
                        item.values.abr.total +
                        item.values.mai.total +
                        item.values.jun.total +
                        item.values.jul.total +
                        item.values.ago.total +
                        item.values.set.total +
                        item.values.out.total +
                        item.values.nov.total +
                        item.values.dez.total;
            dataAssembly.data.push(data);
            labels.push(item.prod);
          })

          console.log({...dataBody, datasets: [dataAssembly], labels });
          setDataBody({...dataBody, datasets: [dataAssembly], labels });
        };
    }, [props.serviceSales])

    return (
        <div>
            <h6>Total do ano</h6>
            <div>
              <Pie
                  data={dataBody}
                  options={options}
                  height={300 - 16 - 10}
              />
            </div>
        </div>
    );
}

export default ProductPredictionTotal;