import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    marginSection: {
        marginLeft: 28,
    },
    paragraph: {
        fontSize: 11,
        marginLeft: 12,
        marginBottom: 8,
    },
    subTitle: {
        fontSize: 12,
        color: "#1e0f70",
        marginBottom: 4,
    },
    anotherTitle: {
        fontSize: 13,
        textAlign: "center",
        marginBottom: 16,
    },
});

const ESGGeneral = ({ generalStrategicPositionProp }) => {
    return (
        <View style={styles.marginSection}>
            <Text style={styles.anotherTitle}>
                ESG Environmental-Social-Governance
            </Text>
            <View>
                <Text style={styles.subTitle}>Ambiental</Text>
                {generalStrategicPositionProp.companyGeneralEnvironmental.map(
                    (item, index) => (
                        <Text style={styles.paragraph} key={index}>
                            - {item.companyGeneralEnvironmental}
                        </Text>
                    )
                )}
                <Text style={styles.subTitle}>Social</Text>
                {generalStrategicPositionProp.companyGeneralSocial.map(
                    (item, index) => (
                        <Text style={styles.paragraph} key={index}>
                            - {item.companyGeneralSocial}
                        </Text>
                    )
                )}
                <Text style={styles.subTitle}>Governança</Text>
                {generalStrategicPositionProp.companyGeneralGovernance.map(
                    (item, index) => (
                        <Text style={styles.paragraph} key={index}>
                            - {item.companyGeneralGovernance}
                        </Text>
                    )
                )}
            </View>
        </View>
    );
};

export default ESGGeneral;
