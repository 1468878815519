import React from 'react';
import { Button, Col, Form, Row, Alert } from 'react-bootstrap';

import { Formik, Field, ErrorMessage } from 'formik';

import UserInviteSchema from '../../validation/UserInviteForm';

function GroupForm({ onSubmit, values }) {

  const initialValues = {
    firstName: values?.firstName || '',
    lastName: values?.lastName || '',
    email: values?.email || '',
    username: values?.username || '',
  }
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserInviteSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <p className="text-muted">
            Use o formulário abaixo para convidar um novo usuário a se juntar a sua empresa.
          </p>

          <Row className="form-row">
            <Col>
              <Form.Group controlId="firstName">
                <Form.Label>Nome</Form.Label>
                <Field name="firstName">
                  {({ field }) => (
                    <Form.Control {...field} type="text" placeholder="Ex: RH" />
                  )}
                </Field>
                <ErrorMessage name="firstName" component="div" className="error-message"/>
              </Form.Group>
            </Col>
  
            <Col>
              <Form.Group controlId="lastName">
                <Form.Label>Sobrenome</Form.Label>
                <Field name="lastName">
                  {({ field }) => (
                    <Form.Control {...field} type="text" placeholder="Ex: RH" />
                  )}
                </Field>
                <ErrorMessage name="lastName" component="div" className="error-message"/>
              </Form.Group>
            </Col>
          </Row>

          <Row className="form-row">
            <Col xs={4}>
              <Form.Group controlId="username">
                <Form.Label>Usuário</Form.Label>
                <Field name="username">
                  {({ field }) => (
                    <Form.Control {...field} type="text" placeholder="Ex: RH" />
                  )}
                </Field>
                <ErrorMessage name="username" component="div" className="error-message"/>
              </Form.Group>
            </Col>
  
            <Col>
              <Form.Group controlId="email">
                <Form.Label>E-mail</Form.Label>
                <Field name="email">
                  {({ field }) => (
                    <Form.Control {...field} type="text" placeholder="Ex: RH" />
                  )}
                </Field>
                <ErrorMessage name="email" component="div" className="error-message"/>
              </Form.Group>
            </Col>
          </Row>

          <Alert variant="warning">
            <p className="mb-0">O usuário irá receber um e-mail de primeiro acesso e redefinição de senha.</p>
          </Alert>

          <Button variant="success" type="submit" disabled={isSubmitting}>
            Convidar
          </Button>

        </Form>
      )}
    </Formik>
  );
}

export default GroupForm;