import React, {useState, useEffect} from 'react';
import SegmentGraphic from "./SegmentGraphic";

function SupplierSharedGraphic(props) {
    const [suppliers, setSuppliers] = useState([]);
    const [supplierSelect, setSupplierSelect] = useState(0);
    const [supplierOne, setSupplierOne] = useState({});

    useEffect(()=>{
        const supplierProps = props.data || [];
        setSuppliers(supplierProps);
        let supplier = supplierProps.filter((hit, index)=>index === supplierSelect);
        if(supplier?.length){
            supplier = supplier[0];
        }
        setSupplierOne(supplier);

    }, [props.data]);

    const handleGraphChange = (ev)=>{
        setSupplierSelect(ev.target.value);
        let supplier = suppliers.filter((hit, index)=>index === parseInt(ev.target.value));
        if(supplier?.length > 0){
            supplier = supplier[0];
        }
        setSupplierOne(supplier);
    }

    return (
        <>
            <div className="justify-content-between align-items-center card__header--home">
                <h6 className="mb-0">{props.title}</h6>

                <select onChange={handleGraphChange} className="select_input rounded">
                    {suppliers.length > 0 &&
                    suppliers.map((item, index)=>{
                        return <option key={index} value={index}>{item.marketDynamicSupplier}</option>
                    })
                    }
                </select>
            </div>

            <hr />

            <SegmentGraphic
                index="marketDynamicProduct"
                indexShare="marketDynamicShare"
                data={supplierOne}
            />
        </>
    );
}

export default SupplierSharedGraphic;