import React, {useState, useEffect} from 'react';
import {Row, Col, FormGroup, Card} from 'react-bootstrap';
import css from '../marketdynamicoverview.module.css';
import {initialProductDynamicValues, ProductDynamicSchema} from './helpers/formValidation';
import { Formik, Field, Form, ErrorMessage, FieldArray} from 'formik';
import FormButton from '../../components/FormButton';
import FormInput from '../../components/FormInput';
import Button from '../../../Button';
import { MdAdd } from 'react-icons/md';
import FormSelect from '../../../FormSelect';
import FormValidationError from "../../components/FormValidationError";

function ProductDynamic(props) {
    const [formValues, setFormValues] = useState(initialProductDynamicValues);

    useEffect(()=>{
        let initialValues = {...initialProductDynamicValues, ...props.questions};
        setFormValues(initialValues);
    }, [props.questions])

    return (
        <Formik
        initialValues={formValues}
        validationSchema={ProductDynamicSchema}
        validate={values=>{
            props.setChanged(values !== formValues);
        }
        }
        onSubmit={(values)=>{
            props.setSubmitting(true);
            props.productSubmit(values, props.group);
        }}
        enableReinitialize
        >
            {({values, errors, touched})=>(
                <Form className="p-4">
                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">Produto Concorrente por segmento</h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <FieldArray name="marketDynamicSubstituteProduct">
                                        {({insert, remove, push, form})=>(
                                            <>
                                                {values.marketDynamicSubstituteProduct?.length > 0 &&
                                                    values.marketDynamicSubstituteProduct.map((md, index)=>(
                                                        <React.Fragment key={index}>
                                                            <Row className="align-items-start">
                                                                <Col lg={6}>
                                                                    <FormGroup>
                                                                        <FormInput
                                                                            name={`marketDynamicSubstituteProduct[${index}].marketDynamicSegment`}
                                                                            type="text"
                                                                            original="marketDynamicSegment"
                                                                            errors={form.errors?.marketDynamicSubstituteProduct && form.errors?.marketDynamicSubstituteProduct.length && form.errors?.marketDynamicSubstituteProduct[index] ? form.errors.marketDynamicSubstituteProduct[index] : ""}
                                                                            touched={form.touched?.marketDynamicSubstituteProduct && form.touched?.marketDynamicSubstituteProduct.length && form.touched?.marketDynamicSubstituteProduct[index] ? form.touched.marketDynamicSubstituteProduct[index] : ""}
                                                                            holder="Entre com o segmento" >
                                                                            Segmento
                                                                        </FormInput>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Button
                                                                    type="button"
                                                                    onClick={()=>{
                                                                        if(values.marketDynamicSubstituteProduct.length > 1){
                                                                            remove(index)
                                                                        }
                                                                    }}
                                                                    style={{ marginTop: '34px'}}
                                                                    variant="outline-danger"
                                                                ><i className="fas fa-trash-alt mr-2" />Remover Segmento</Button>
                                                            </Row>

                                                            <FieldArray
                                                                name={`marketDynamicSubstituteProduct[${index}].marketDynamicProducts`}
                                                                render={({remove, unshift})=>(
                                                                    <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                                                                        <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                                                                            <Button
                                                                                type="button"
                                                                                variant="outline-success"
                                                                                className={`w-100 h-100`}
                                                                                onClick={() => unshift({marketDynamicProduct: "", marketDynamicShare: 0})}
                                                                                >
                                                                                <MdAdd fontSize={24} />
                                                                            </Button>
                                                                        </Col>
                                                                        {md.marketDynamicProducts?.map((mdc, indexMdc) => (
                                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} key={indexMdc}>
                                                                            <Card className="p-3">
                                                                                <FormGroup>
                                                                                    <FormInput
                                                                                        name={`marketDynamicSubstituteProduct[${index}].marketDynamicProducts[${indexMdc}].marketDynamicProduct`}
                                                                                        type="text"
                                                                                        original="marketDynamicProduct"
                                                                                        errors={
                                                                                            form.errors?.marketDynamicSubstituteProduct &&
                                                                                            form.errors?.marketDynamicSubstituteProduct?.length &&
                                                                                            form.errors?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts &&
                                                                                            form.errors?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts.length &&
                                                                                            form.errors?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts[indexMdc] ?
                                                                                            form.errors?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts[indexMdc]  : ""}
                                                                                        touched={
                                                                                            form.touched?.marketDynamicSubstituteProduct &&
                                                                                            form.touched?.marketDynamicSubstituteProduct?.length &&
                                                                                            form.touched?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts &&
                                                                                            form.touched?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts.length &&
                                                                                            form.touched?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts[indexMdc] ?
                                                                                            form.touched?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts[indexMdc] : ""}
                                                                                        holder="Entre com o produto" >
                                                                                        Produto
                                                                                    </FormInput>
                                                                                </FormGroup>

                                                                                <FormGroup>
                                                                                    <FormInput
                                                                                        name={`marketDynamicSubstituteProduct[${index}].marketDynamicProducts[${indexMdc}].marketDynamicShare`}
                                                                                        type="number"
                                                                                        original="marketDynamicShare"
                                                                                        errors={
                                                                                            form.errors?.marketDynamicSubstituteProduct &&
                                                                                            form.errors?.marketDynamicSubstituteProduct?.length &&
                                                                                            form.errors?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts &&
                                                                                            form.errors?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts.length &&
                                                                                            form.errors?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts[indexMdc] ?
                                                                                            form.errors?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts[indexMdc]  : ""}
                                                                                        touched={
                                                                                            form.touched?.marketDynamicSubstituteProduct &&
                                                                                            form.touched?.marketDynamicSubstituteProduct?.length &&
                                                                                            form.touched?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts &&
                                                                                            form.touched?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts.length &&
                                                                                            form.touched?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts[indexMdc] ?
                                                                                            form.touched?.marketDynamicSubstituteProduct[index]?.marketDynamicProducts[indexMdc] : ""}
                                                                                        holder="Entre com a participação" >
                                                                                        Participação (%)
                                                                                    </FormInput>
                                                                                </FormGroup>

                                                                                <FormGroup>
                                                                                    <Button variant="outline-danger" className={`mt-2 w-100`} type="button" onClick={() =>{
                                                                                        if(values.marketDynamicSubstituteProduct[index].marketDynamicProducts.length > 1){
                                                                                            remove(indexMdc)
                                                                                        }
                                                                                    }}>
                                                                                        <i className="fas fa-trash-alt" />
                                                                                    </Button>
                                                                                </FormGroup>
                                                                            </Card>
                                                                        </Col>
                                                                    ))}
                                                                </Row>
                                                            )}
                                                        />

                                                        <hr />

                                                    </React.Fragment>
                                                ))
                                            }

                                            <FormGroup>
                                                <Button
                                                    type="button"
                                                    variant="outline-success"
                                                    onClick={()=> push({marketDynamicSegment: "", marketDynamicProducts: [{marketDynamicProduct: "", marketDynamicShare: 0}]})}
                                                    className={``}
                                                >
                                                    <MdAdd fontSize={20} className="mr-1" />
                                                    Adicionar Segmento
                                                </Button>
                                            </FormGroup>

                                        </>
                                        )}
                                    </FieldArray>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">Vantagens e desvantagens do produto concorrente</h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <FieldArray name="marketDynamicAdvantageDisavantages">
                                        {({insert, remove, unshift, form})=>(
                                            <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                                                <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                                                    <Button
                                                        type="button"
                                                        variant="outline-success"
                                                        className={`w-100 h-100`}
                                                        onClick={()=> unshift({marketDynamicProduct: '', marketDynamicAdvantage: "", marketDynamicDisavantages: ""})}
                                                        >
                                                        <MdAdd fontSize={24} />
                                                    </Button>
                                                </Col>
                                                {values.marketDynamicAdvantageDisavantages?.length > 0 &&
                                                    values.marketDynamicAdvantageDisavantages.map((md, index)=>(
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} key={index}>
                                                            <Card className="p-3">
                                                                <FormGroup>
                                                                    <FormInput
                                                                        name={`marketDynamicAdvantageDisavantages[${index}].marketDynamicProduct`}
                                                                        type="text"
                                                                        original="marketDynamicProduct"
                                                                        errors={form.errors?.marketDynamicAdvantageDisavantages && form.errors?.marketDynamicAdvantageDisavantages.length && form.errors?.marketDynamicAdvantageDisavantages[index] ? form.errors.marketDynamicAdvantageDisavantages[index] : ""}
                                                                        touched={form.touched?.marketDynamicAdvantageDisavantages && form.touched?.marketDynamicAdvantageDisavantages.length && form.touched?.marketDynamicAdvantageDisavantages[index] ? form.touched.marketDynamicAdvantageDisavantages[index] : ""}
                                                                        holder="Entre com o produto" >
                                                                        Produto
                                                                    </FormInput>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormInput
                                                                        name={`marketDynamicAdvantageDisavantages[${index}].marketDynamicAdvantage`}
                                                                        type="text"
                                                                        original="marketDynamicAdvantage"
                                                                        errors={form.errors?.marketDynamicAdvantageDisavantages && form.errors?.marketDynamicAdvantageDisavantages.length && form.errors?.marketDynamicAdvantageDisavantages[index] ? form.errors.marketDynamicAdvantageDisavantages[index] : ""}
                                                                        touched={form.touched?.marketDynamicAdvantageDisavantages && form.touched?.marketDynamicAdvantageDisavantages.length && form.touched?.marketDynamicAdvantageDisavantages[index] ? form.touched.marketDynamicAdvantageDisavantages[index] : ""}
                                                                        holder="Entre com a vantagem" >
                                                                        Vantagem
                                                                    </FormInput>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormInput
                                                                        name={`marketDynamicAdvantageDisavantages[${index}].marketDynamicDisavantages`}
                                                                        type="text"
                                                                        original="marketDynamicDisavantages"
                                                                        errors={form.errors?.marketDynamicAdvantageDisavantages && form.errors?.marketDynamicAdvantageDisavantages.length && form.errors?.marketDynamicAdvantageDisavantages[index] ? form.errors.marketDynamicAdvantageDisavantages[index] : ""}
                                                                        touched={form.touched?.marketDynamicAdvantageDisavantages && form.touched?.marketDynamicAdvantageDisavantages.length && form.touched?.marketDynamicAdvantageDisavantages[index] ? form.touched.marketDynamicAdvantageDisavantages[index] : ""}
                                                                        holder="Entre com a desvantagem" >
                                                                        Desvantagem
                                                                    </FormInput>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Button 
                                                                        variant="outline-danger" 
                                                                        className={`mt-2 w-100`} 
                                                                        type="button"
                                                                        onClick={()=>{
                                                                            if( values.marketDynamicAdvantageDisavantages.length > 1){
                                                                                remove(index)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-trash-alt" />
                                                                    </Button>
                                                                </FormGroup>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        )}
                                    </FieldArray>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr/>

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">Valor agregado pelo produto</h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <FieldArray name="marketDynamicElementValueProduct">
                                        {({remove, unshift, form})=>(
                                            <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                                                <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                                                    <Button
                                                        type="button"
                                                        variant="outline-success"
                                                        className={`w-100 h-100`}
                                                        onClick={()=> unshift({marketDynamicSegment: '', marketDynamicYear1: 0, marketDynamicYear2: 0, marketDynamicYear3: 0})}
                                                        >
                                                        <MdAdd fontSize={24} />
                                                    </Button>
                                                </Col>
                                                {values.marketDynamicElementValueProduct?.length > 0 &&
                                                    values.marketDynamicElementValueProduct.map((md, index)=>(
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} key={index}>
                                                            <Card className="p-3">
                                                                <FormGroup>
                                                                    <FormInput
                                                                        name={`marketDynamicElementValueProduct[${index}].marketDynamicProduct`}
                                                                        type="text"
                                                                        original="marketDynamicProduct"
                                                                        errors={form.errors?.marketDynamicElementValueProduct && form.errors?.marketDynamicElementValueProduct.length && form.errors?.marketDynamicElementValueProduct[index] ? form.errors.marketDynamicElementValueProduct[index] : ""}
                                                                        touched={form.touched?.marketDynamicElementValueProduct && form.touched?.marketDynamicElementValueProduct.length && form.touched?.marketDynamicElementValueProduct[index] ? form.touched.marketDynamicElementValueProduct[index] : ""}
                                                                        holder="Entre com o produto" >
                                                                        Produto
                                                                    </FormInput>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <FormInput
                                                                        name={`marketDynamicElementValueProduct[${index}].marketDynamicValue`}
                                                                        type="text"
                                                                        original="marketDynamicValue"
                                                                        errors={form.errors?.marketDynamicElementValueProduct && form.errors?.marketDynamicElementValueProduct.length && form.errors?.marketDynamicElementValueProduct[index] ? form.errors.marketDynamicElementValueProduct[index] : ""}
                                                                        touched={form.touched?.marketDynamicElementValueProduct && form.touched?.marketDynamicElementValueProduct.length && form.touched?.marketDynamicElementValueProduct[index] ? form.touched.marketDynamicElementValueProduct[index] : ""}
                                                                        holder="Entre com o valor agregado" >
                                                                        Valor agregado
                                                                    </FormInput>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Button 
                                                                        variant="outline-danger" 
                                                                        className={`mt-2 w-100`} 
                                                                        type="button"
                                                                        onClick={()=>{
                                                                            if( values.marketDynamicElementValueProduct.length > 1){
                                                                                remove(index)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-trash-alt" />
                                                                    </Button>
                                                                </FormGroup>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        )}
                                    </FieldArray>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr/>

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">Risco do novo produto por Segmento</h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={12}>
                                    <FieldArray name="marketDynamicNewProductRisk">
                                        {({remove, unshift, form})=>(
                                            <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                                                <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                                                    <Button
                                                        type="button"
                                                        variant="outline-success"
                                                        className={`w-100 h-100`}
                                                        onClick={()=> unshift({marketDynamicSegment: '', marketDynamicYear1: 0, marketDynamicYear2: 0, marketDynamicYear3: 0})}
                                                        >
                                                        <MdAdd fontSize={24} />
                                                    </Button>
                                                </Col>
                                                {values.marketDynamicNewProductRisk?.length > 0 &&
                                                    values.marketDynamicNewProductRisk.map((md, index)=>(
                                                        <Col xs={3} sm={3} md={3} lg={3} xl={3} key={index}>
                                                            <Card className="p-3">
                                                                <FormGroup>
                                                                    <FormInput
                                                                        name={`marketDynamicNewProductRisk[${index}].marketDynamicSegment`}
                                                                        type="text"
                                                                        original="marketDynamicSegment"
                                                                        errors={form.errors?.marketDynamicNewProductRisk && form.errors?.marketDynamicNewProductRisk.length && form.errors?.marketDynamicNewProductRisk[index] ? form.errors.marketDynamicNewProductRisk[index] : ""}
                                                                        touched={form.touched?.marketDynamicNewProductRisk && form.touched?.marketDynamicNewProductRisk.length && form.touched?.marketDynamicNewProductRisk[index] ? form.touched.marketDynamicNewProductRisk[index] : ""}
                                                                        holder="Entre com o segmento" >
                                                                        Segmento
                                                                    </FormInput>
                                                                </FormGroup>
                                                                
                                                                <FormGroup>
                                                                    <FormInput
                                                                        name={`marketDynamicNewProductRisk[${index}].marketDynamicProduct`}
                                                                        type="text"
                                                                        original="marketDynamicProduct"
                                                                        errors={form.errors?.marketDynamicNewProductRisk && form.errors?.marketDynamicNewProductRisk.length && form.errors?.marketDynamicNewProductRisk[index] ? form.errors.marketDynamicNewProductRisk[index] : ""}
                                                                        touched={form.touched?.marketDynamicNewProductRisk && form.touched?.marketDynamicNewProductRisk.length && form.touched?.marketDynamicNewProductRisk[index] ? form.touched.marketDynamicNewProductRisk[index] : ""}
                                                                        holder="Entre com o produto" >
                                                                        Produto
                                                                    </FormInput>
                                                                </FormGroup>

                                                                <FormSelect 
                                                                    name={`marketDynamicNewProductRisk[${index}].marketDynamicRisk`} 
                                                                    id={`marketDynamicNewProductRisk[${index}].marketDynamicRisk`} 
                                                                    label="Risco"
                                                                >
                                                                    <option value="H">High</option>    
                                                                    <option value="M">Medium</option>    
                                                                    <option value="L">Low</option> 
                                                                </FormSelect>

                                                                <FormGroup>
                                                                    <Button 
                                                                        variant="outline-danger" 
                                                                        className={`mt-2 w-100`} 
                                                                        type="button"
                                                                        onClick={()=>{
                                                                            if( values.marketDynamicNewProductRisk.length > 1){
                                                                                remove(index)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-trash-alt" />
                                                                    </Button>
                                                                </FormGroup>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        )}
                                    </FieldArray>
                                </Col>
                            </Row>
                            
                            <hr />

                            <Row>
                                <Col xs={12} lg={12}>
                                    <FormGroup>
                                        <FormInput name="marketDynamicStrategyMinimizeRisk" type="" as="textarea" rows="3" errors={errors} touched={touched} holder="Entre com a estratégia para minimizar os riscos" >
                                            Estratégia para minimizar os riscos
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="p-0 m-0 mt-3 justify-content-end">
                                {Object.keys(errors).length > 0 &&
                                <FormValidationError
                                    errors={errors}
                                />
                                }
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button variant="success" disabled={props.submitting} type="submit">
                                            Salvar informações
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>         
                </Form>
            )}
        </Formik>
    )
}

export default ProductDynamic
