import { set } from 'date-fns/esm';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import { getQuestions } from '../../../../../services/formData';
import useSpinner from '../../../../spinner/useSpinner';
import StackedBarGraph from './StackedBarGraph';

// import { Container } from './styles';

function DepartmentData(props) {
  const [departmentsData, setDepartmentsData] = useState([]);
  const [spinner, showSpinner, hideSpinner] = useSpinner();
  const [department, setDepartment] = useState('');
  const [fixedCosts, setFixedCosts] = useState(null);
  const [variableCosts, setVariableCosts] = useState(null);
  const [expenses, setExpenses] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [shouldDisplayCosts, setShouldDisplayCosts] = useState(false);

  function handleDepartmentChange(el) {
    setDepartment(parseInt(el.target.value, 10));
  }

  function isFormEmpty() {
    return !fixedCosts && !variableCosts && !expenses
  }

  useEffect(()=>{
    let isSubscribe = true;
    showSpinner();
    const formularios = props.forms;
    const positionMenu = formularios?.find(hit=>hit.FormType.menuOrder === 1); 

    if(positionMenu && Object.keys(positionMenu).length) {
        const valueChain = positionMenu.FormType.MainGroups.find(hit=>hit.menuOrder === 4);
        const subGroups = [...valueChain.SubGroups].sort((a, b) => a.id - b.id);
        console.log(subGroups);
        if (!department) setDepartment(subGroups[0].id)
        if (!departmentsData.length) setDepartmentsData(subGroups);
        (department && subGroups.find(({id}) => id === department).menuOrder === 4) ? setShouldDisplayCosts(true) : setShouldDisplayCosts(false); 

        if(valueChain && Object.keys(valueChain).length && isSubscribe){
            getQuestions(positionMenu.id, valueChain.id, department || subGroups[0].id, (err, data) => {
                if(err){
                    props.history.push("/");
                    hideSpinner();
                }else{
                    if(isSubscribe){
                        const costAndExpenses = data?.companyValueChainCostExpenses;
                        if (costAndExpenses) {
                          setFixedCosts(costAndExpenses[0]?.companyValueChainFixedCost)
                          setVariableCosts(costAndExpenses[0]?.companyValueChainVariableCost)
                          setExpenses(costAndExpenses[0]?.companyValueChainExpenses)
                        } else {
                          setFixedCosts(null)
                          setVariableCosts(null)
                          setExpenses(null)
                        }

                        hideSpinner();
                    }
                }
            });
        } else {
          hideSpinner();
        }
    } else {
      hideSpinner();
    }
}, [props.forms, hideSpinner, props.history, showSpinner, department])

  return (
    <>
      {spinner}
      <div className="p-4">
        <div className="d-flex justify-content-between card__header--home">
          <h6 className="mb-0">Visão do departamento {departmentsData?.find((el) => el.id === department)?.title}</h6>

          <select name="" id="" onChange={handleDepartmentChange} className="select_input rounded" value={department}>
            {departmentsData?.map((el) => <option key={el.id} value={el.id}>{el.title}</option>)}
          </select>
        </div>

            <hr />

        { isFormEmpty() ? <p>Departamento não preenchido completamente</p> : (
            <Card>
              <Tabs selectedIndex={tabIndex} onSelect={index => setTabIndex(index)}>
                  <TabList>
                    {
                      shouldDisplayCosts && (
                        <>
                          <Tab>Custo Fixo</Tab>
                          <Tab>Custo Variável</Tab>
                        </>
                      )
                    }
                      <Tab>Despesas</Tab>
                  </TabList>
                  { shouldDisplayCosts && (
                    <>
                      <TabPanel>
                          <div className="p-4">
                            <StackedBarGraph property="companyValueChainFixedCost" data={fixedCosts} />
                          </div>
                      </TabPanel>
                      <TabPanel>
                          <div className="p-4">
                            <StackedBarGraph property="companyValueChainVariableCost" data={variableCosts} />
                          </div>
                      </TabPanel>
                    </>
                  )}
                  <TabPanel>
                      <div className="p-4">
                        <StackedBarGraph property="companyValueChainExpenses" data={expenses} />
                      </div>
                  </TabPanel>
              </Tabs>
            </Card>
        )}
      </div>
    </>
  )
}

export default DepartmentData;