import React from 'react';
import {useField, useFormikContext} from 'formik';
import css from '../../companyoverview.module.css';

function CompanyMarketTotal(props) {
    const {
        values: { companyMarket },
        setFieldValue,
    } = useFormikContext();
    const [field, meta] = useField(props);

    React.useEffect(() => {
        if (companyMarket.companyMarket[props.index].companySegment.length) {
            let total = companyMarket.companyMarket[props.index].companySegment.reduce((acc, current)=>acc + current.companyMarketSize, 0);
            setFieldValue(props.name, total);
        }

    }, [companyMarket.companyMarket[props.index].companySegment]);

    return (
        <>
            <input className={`p-1 rounded border`} {...props} {...field} />
            {!!meta.touched && !!meta.error && <div>{meta.error}</div>}
        </>
    );
}

export default CompanyMarketTotal;
