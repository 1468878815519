import React from 'react';
import {Row, Col, FormGroup, Card} from 'react-bootstrap';
import css from '../../marketdynamicoverview.module.css';
import { FieldArray} from 'formik';
import FormInput from '../../../components/FormInput';
import CompetitionDynamicTotal from './CompetitionDynamicTotal';
import CompetitionDynamicShare from './CompetitionDynamicShare';
import Button from '../../../../Button';
import { MdAdd } from 'react-icons/md';

function IndirectCompetitors({values}) {
    return (
        <FieldArray name="marketDynamicIndirectCompetitors">
            {({remove, push, form})=>(
                <>
                    {values.marketDynamicIndirectCompetitors?.length > 0 &&
                        values.marketDynamicIndirectCompetitors.map((md, index)=>(
                        <React.Fragment key={index}>
                            <Row className="align-items-start">
                                <Col lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name={`marketDynamicIndirectCompetitors[${index}].marketDynamicSegment`}
                                            type="text"
                                            original="marketDynamicSegment"
                                            errors={form.errors?.marketDynamicIndirectCompetitors && form.errors?.marketDynamicIndirectCompetitors.length && form.errors?.marketDynamicIndirectCompetitors[index] ? form.errors.marketDynamicIndirectCompetitors[index] : ""}
                                            touched={form.touched?.marketDynamicIndirectCompetitors && form.touched?.marketDynamicIndirectCompetitors.length && form.touched?.marketDynamicIndirectCompetitors[index] ? form.touched.marketDynamicIndirectCompetitors[index] : ""}
                                            holder="Entre com o segmento" >
                                            Segmento
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                                <Button
                                    type="button"
                                    onClick={()=>{
                                        if(values.marketDynamicIndirectCompetitors.length > 1){
                                            remove(index)
                                        }
                                    }}
                                    style={{ marginTop: '34px'}}
                                    variant="outline-danger"
                                ><i className="fas fa-trash-alt mr-2" />Remover Segmento</Button>
                            </Row>
                            <FieldArray
                                name={`marketDynamicIndirectCompetitors[${index}].marketDynamicCompetitors`}
                                render={({remove, unshift})=>(
                                    <Row style={{ flexWrap: 'nowrap', overflow: 'auto' }} className="py-1">
                                        <Col xs={1} sm={1} md={1} lg={1} xl={1} >
                                            <Button
                                                type="button"
                                                variant="outline-success"
                                                className={`w-100 h-100`}
                                                onClick={() => unshift({marketDynamicCompetitors: "", marketDynamicTotalMarket: 0, marketDynamicShare: 0})}                                            >
                                                <MdAdd fontSize={24} />
                                            </Button>
                                        </Col>
                                        {md.marketDynamicCompetitors?.map((mdc, indexMdc) => (
                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} key={indexMdc}>
                                                <Card className="p-3">
                                                    <FormGroup>
                                                        <FormInput
                                                            name={`marketDynamicIndirectCompetitors[${index}].marketDynamicCompetitors[${indexMdc}].marketDynamicCompetitors`}
                                                            type="text"
                                                            original="marketDynamicCompetitors"
                                                            errors={
                                                                form.errors?.marketDynamicIndirectCompetitors &&
                                                                form.errors?.marketDynamicIndirectCompetitors?.length &&
                                                                form.errors?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors &&
                                                                form.errors?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors.length &&
                                                                form.errors?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] ?
                                                                form.errors?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors[indexMdc]  : ""}
                                                            touched={
                                                                form.touched?.marketDynamicIndirectCompetitors &&
                                                                form.touched?.marketDynamicIndirectCompetitors?.length &&
                                                                form.touched?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors &&
                                                                form.touched?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors.length &&
                                                                form.touched?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] ?
                                                                form.touched?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] : ""}
                                                            holder="Entre com o competidor" >
                                                            Competidor
                                                        </FormInput>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <FormInput
                                                            name={`marketDynamicIndirectCompetitors[${index}].marketDynamicCompetitors[${indexMdc}].marketDynamicTotalMarket`}
                                                            type="number"
                                                            original="marketDynamicTotalMarket"
                                                            errors={
                                                                form.errors?.marketDynamicIndirectCompetitors &&
                                                                form.errors?.marketDynamicIndirectCompetitors?.length &&
                                                                form.errors?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors &&
                                                                form.errors?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors.length &&
                                                                form.errors?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] ?
                                                                form.errors?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors[indexMdc]  : ""}
                                                            touched={
                                                                form.touched?.marketDynamicIndirectCompetitors &&
                                                                form.touched?.marketDynamicIndirectCompetitors?.length &&
                                                                form.touched?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors &&
                                                                form.touched?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors.length &&
                                                                form.touched?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] ?
                                                                form.touched?.marketDynamicIndirectCompetitors[index]?.marketDynamicCompetitors[indexMdc] : ""}
                                                            holder="Entre com o total" >
                                                            Total (U$)
                                                        </FormInput>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <div className={`${css.input_label}`}><label htmlFor={`marketDynamicDirectCompetitors[${index}].marketDynamicCompetitors[${indexMdc}].marketDynamicShare`}>Participação(%)</label></div>
                                                        <CompetitionDynamicShare
                                                            values="marketDynamicIndirectCompetitors"
                                                            index={index}
                                                            indexmdc={indexMdc}
                                                            disabled={true}
                                                            name={`marketDynamicIndirectCompetitors[${index}].marketDynamicCompetitors[${indexMdc}].marketDynamicShare`} 
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Button 
                                                            variant="outline-danger" 
                                                            className={`mt-2 w-100`} 
                                                            type="button"
                                                            onClick={()=>{
                                                                if(values.marketDynamicIndirectCompetitors[index].marketDynamicCompetitors.length > 1){
                                                                    remove(indexMdc);
                                                                }
                                                            }}>
                                                            <i className="fas fa-trash-alt" />
                                                        </Button>
                                                    </FormGroup>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                )}
                            />

                            <Row className="mt-4">
                                <Col className="d-flex align-items-center">
                                    <div className="mr-3 font-weight-bold"><span>Total deste segmento:</span></div>
                                    <CompetitionDynamicTotal
                                        values="marketDynamicIndirectCompetitors"
                                        index={index}
                                        disabled={true}
                                        name={`marketDynamicIndirectCompetitors[${index}].marketDynamicSegmentTotal`}
                                    />
                                </Col>
                            </Row>

                            <hr />
                        </React.Fragment>
                    ))
                }
            <FormGroup>
                <Button
                    type="button"
                    variant="outline-success"
                    onClick={()=> push({marketDynamicSegment: "", marketDynamicCompetitors: [{marketDynamicCompetitors: "", marketDynamicTotalMarket: 0, marketDynamicShare: 0}], marketDynamicSegmentTotal: 0})}
                    className={``}
                >
                    <MdAdd fontSize={20} className="mr-1" />
                    Adicionar Segmento
                </Button>
            </FormGroup>
        </>
    )}
        </FieldArray>
    )
}

export default IndirectCompetitors;
