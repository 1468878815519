import React, { useEffect, useState } from "react";
import { Row, Col, FormGroup, Card } from "react-bootstrap";
import { Formik, Form, FieldArray } from "formik";
import {
    initialValueChainValues,
    CompanyValueChainSchema,
} from "./helpers/formValidation";
import FormInput from "../../../components/FormInput";
import CostsExpenses from "../components/CostsExpenses";
import Button from "../../../../Button";
import { MdAdd } from "react-icons/md";
import FormFieldArray from "../../../components/FormFieldArray";
import FormValidationError from "../../../components/FormValidationError";

function HumanResources(props) {
    const [formValues, setFormValues] = useState(initialValueChainValues);

    useEffect(() => {
        let initialValues = { ...initialValueChainValues, ...props.questions };

        if (!initialValues.companyValueChainEnvironmental) {
            initialValues.companyValueChainEnvironmental = [
                {
                    companyValueChainEnvironmental: "",
                },
            ];
            initialValues.companyValueChainGovernance = [
                {
                    companyValueChainGovernance: "",
                },
            ];
            initialValues.companyValueChainSocial = [
                {
                    companyValueChainSocial: "",
                },
            ];
        }
        initialValues.companyValueChainESG = [
            {
                companyValueChainEnvironmental: [
                    {
                        companyValueChainEnvironmental: "",
                    },
                ],
                companyValueChainSocial: [
                    {
                        companyValueChainSocial: "",
                    },
                ],
                companyValueChainGovernance: [
                    {
                        companyValueChainGovernance: "",
                    },
                ],
            },
        ];
        setFormValues(initialValues);
    }, [props.questions]);

    return (
        <Formik
            initialValues={formValues}
            validationSchema={CompanyValueChainSchema}
            validate={(values) => {
                props.setChanged(values !== formValues);
            }}
            onSubmit={(values) => {
                props.setSubmitting(true);
                props.setChanged(false);
                props.handleSubmit(values, props.group, props.subGroup);
            }}
            enableReinitialize
        >
            {({ values, errors, touched }) => (
                <Form className="p-4">
                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Sobre os objetivos da área
                            </h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12}>
                                    <FormGroup>
                                        <FieldArray name="companyValueChainObjectives">
                                            {({
                                                insert,
                                                remove,
                                                push,
                                                unshift,
                                                form,
                                            }) => (
                                                <Row
                                                    style={{
                                                        flexWrap: "nowrap",
                                                        overflow: "auto",
                                                    }}
                                                    className="py-1"
                                                >
                                                    <Col xs={1}>
                                                        <Button
                                                            type="button"
                                                            variant="outline-success"
                                                            onClick={() =>
                                                                unshift({
                                                                    companyValueChainObjectives:
                                                                        "",
                                                                    companyValueChainGoals:
                                                                        "",
                                                                    companyValueChainActionPlan:
                                                                        "",
                                                                })
                                                            }
                                                            className={`w-100 h-100`}
                                                        >
                                                            <MdAdd fontSize="24" />
                                                        </Button>
                                                    </Col>
                                                    {values
                                                        .companyValueChainObjectives
                                                        ?.length > 0 &&
                                                        values.companyValueChainObjectives.map(
                                                            (cv, index) => (
                                                                <Col
                                                                    className=""
                                                                    xs={4}
                                                                    key={index}
                                                                >
                                                                    <Card className="p-3">
                                                                        <FormGroup>
                                                                            <FormInput
                                                                                name={`companyValueChainObjectives[${index}].companyValueChainObjectives`}
                                                                                type="text"
                                                                                original="companyValueChainObjectives"
                                                                                errors={
                                                                                    form
                                                                                        .errors
                                                                                        ?.companyValueChainObjectives &&
                                                                                    form
                                                                                        .errors
                                                                                        ?.companyValueChainObjectives
                                                                                        .length &&
                                                                                    form
                                                                                        .errors
                                                                                        ?.companyValueChainObjectives[
                                                                                        index
                                                                                    ]
                                                                                        ? form
                                                                                              .errors
                                                                                              .companyValueChainObjectives[
                                                                                              index
                                                                                          ]
                                                                                        : ""
                                                                                }
                                                                                touched={
                                                                                    form
                                                                                        .touched
                                                                                        ?.companyValueChainObjectives &&
                                                                                    form
                                                                                        .touched
                                                                                        ?.companyValueChainObjectives
                                                                                        .length &&
                                                                                    form
                                                                                        .touched
                                                                                        ?.companyValueChainObjectives[
                                                                                        index
                                                                                    ]
                                                                                        ? form
                                                                                              .touched
                                                                                              .companyValueChainObjectives[
                                                                                              index
                                                                                          ]
                                                                                        : ""
                                                                                }
                                                                                holder="Entre com o objetivo"
                                                                            >
                                                                                Objetivo
                                                                            </FormInput>
                                                                        </FormGroup>

                                                                        <FormGroup>
                                                                            <FormInput
                                                                                name={`companyValueChainObjectives[${index}].companyValueChainGoals`}
                                                                                type="text"
                                                                                original="companyValueChainGoals"
                                                                                errors={
                                                                                    form
                                                                                        .errors
                                                                                        ?.companyValueChainObjectives &&
                                                                                    form
                                                                                        .errors
                                                                                        ?.companyValueChainObjectives
                                                                                        .length &&
                                                                                    form
                                                                                        .errors
                                                                                        ?.companyValueChainObjectives[
                                                                                        index
                                                                                    ]
                                                                                        ? form
                                                                                              .errors
                                                                                              .companyValueChainObjectives[
                                                                                              index
                                                                                          ]
                                                                                        : ""
                                                                                }
                                                                                touched={
                                                                                    form
                                                                                        .touched
                                                                                        ?.companyValueChainObjectives &&
                                                                                    form
                                                                                        .touched
                                                                                        ?.companyValueChainObjectives
                                                                                        .length &&
                                                                                    form
                                                                                        .touched
                                                                                        ?.companyValueChainObjectives[
                                                                                        index
                                                                                    ]
                                                                                        ? form
                                                                                              .touched
                                                                                              .companyValueChainObjectives[
                                                                                              index
                                                                                          ]
                                                                                        : ""
                                                                                }
                                                                                holder="Entre com a meta"
                                                                            >
                                                                                Meta
                                                                            </FormInput>
                                                                        </FormGroup>

                                                                        <FormGroup>
                                                                            <FormInput
                                                                                name={`companyValueChainObjectives[${index}].companyValueChainActionPlan`}
                                                                                as="textarea"
                                                                                rows={
                                                                                    3
                                                                                }
                                                                                original="companyValueChainActionPlan"
                                                                                errors={
                                                                                    form
                                                                                        .errors
                                                                                        ?.companyValueChainObjectives &&
                                                                                    form
                                                                                        .errors
                                                                                        ?.companyValueChainObjectives
                                                                                        .length &&
                                                                                    form
                                                                                        .errors
                                                                                        ?.companyValueChainObjectives[
                                                                                        index
                                                                                    ]
                                                                                        ? form
                                                                                              .errors
                                                                                              .companyValueChainObjectives[
                                                                                              index
                                                                                          ]
                                                                                        : ""
                                                                                }
                                                                                touched={
                                                                                    form
                                                                                        .touched
                                                                                        ?.companyValueChainObjectives &&
                                                                                    form
                                                                                        .touched
                                                                                        ?.companyValueChainObjectives
                                                                                        .length &&
                                                                                    form
                                                                                        .touched
                                                                                        ?.companyValueChainObjectives[
                                                                                        index
                                                                                    ]
                                                                                        ? form
                                                                                              .touched
                                                                                              .companyValueChainObjectives[
                                                                                              index
                                                                                          ]
                                                                                        : ""
                                                                                }
                                                                                holder="Entre com p plano de ação"
                                                                            >
                                                                                Plano
                                                                                de
                                                                                ação
                                                                                (5W2H)
                                                                            </FormInput>
                                                                        </FormGroup>

                                                                        <FormGroup>
                                                                            <Button
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    if (
                                                                                        values
                                                                                            .companyValueChainObjectives
                                                                                            .length >
                                                                                        1
                                                                                    ) {
                                                                                        remove(
                                                                                            index
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                className="mt-2 w-100"
                                                                                variant="outline-danger"
                                                                            >
                                                                                <i className="fas fa-trash-alt" />
                                                                            </Button>
                                                                        </FormGroup>
                                                                    </Card>
                                                                </Col>
                                                            )
                                                        )}
                                                </Row>
                                            )}
                                        </FieldArray>
                                    </FormGroup>

                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput
                                            name="companyValueChainStrategy"
                                            type="text"
                                            errors={errors}
                                            touched={touched}
                                            holder="Quais é a sua estratégia?"
                                        >
                                            Qual a sua estratégia?
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormFieldArray
                                        name="companyValueChainPerformanceIndicators"
                                        values={values}
                                        title="Indicadores de performance"
                                        field="companyValueChainPerformanceIndicators"
                                        errors={errors}
                                        touched={touched}
                                        holder="Quais são seus indicadores de performance?"
                                        type="text"
                                        lessThenOne={false}
                                        butonAddText="Adicionar Indicador de Performance"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-center ">
                                ESG
                            </h4>
                            <h4 className="text-muted font-weight-light text-center mr-3">
                                Environmental-Social-Governance
                            </h4>
                        </Col>
                        <Col>
                            <FormGroup>
                                <FieldArray name="companyValueChainESG">
                                    {({ remove, unshift, form, push }) => (
                                        <Row
                                            style={{
                                                flexWrap: "nowrap",
                                                overflow: "auto",
                                            }}
                                            className="py-1"
                                        >
                                            {values.companyValueChainESG
                                                ?.length > 0 &&
                                                values.companyValueChainESG.map(
                                                    (cv, index) => (
                                                        <Col
                                                            className=""
                                                            lg={6}
                                                            key={index}
                                                        >
                                                            <Card className="p-3">
                                                                <FormFieldArray
                                                                    name="companyValueChainEnvironmental"
                                                                    values={
                                                                        values
                                                                    }
                                                                    title="Ambiental"
                                                                    field="companyValueChainEnvironmental"
                                                                    errors={
                                                                        errors
                                                                    }
                                                                    touched={
                                                                        touched
                                                                    }
                                                                    holder="Entre com Ambiental"
                                                                    type="text"
                                                                    lessThenOne={
                                                                        false
                                                                    }
                                                                    butonAddText="Adicionar"
                                                                />
                                                                <FormFieldArray
                                                                    name="companyValueChainSocial"
                                                                    values={
                                                                        values
                                                                    }
                                                                    title="Social"
                                                                    field="companyValueChainSocial"
                                                                    errors={
                                                                        errors
                                                                    }
                                                                    touched={
                                                                        touched
                                                                    }
                                                                    holder="Entre com Social"
                                                                    type="text"
                                                                    lessThenOne={
                                                                        false
                                                                    }
                                                                    butonAddText="Adicionar"
                                                                />

                                                                <FormFieldArray
                                                                    name="companyValueChainGovernance"
                                                                    values={
                                                                        values
                                                                    }
                                                                    title="Governança"
                                                                    field="companyValueChainGovernance"
                                                                    errors={
                                                                        errors
                                                                    }
                                                                    touched={
                                                                        touched
                                                                    }
                                                                    holder="Entre com Governança"
                                                                    type="text"
                                                                    lessThenOne={
                                                                        false
                                                                    }
                                                                    butonAddText="Adicionar"
                                                                />
                                                            </Card>
                                                        </Col>
                                                    )
                                                )}
                                        </Row>
                                    )}
                                </FieldArray>
                            </FormGroup>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="pt-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">
                                Orçamento - Despesas
                            </h4>
                        </Col>
                        <Col></Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <Row>
                                <Col>
                                    <CostsExpenses values={values} />

                                    <Row className="p-0 m-0 mt-3 justify-content-end">
                                        {Object.keys(errors).length > 0 && (
                                            <FormValidationError
                                                errors={errors}
                                            />
                                        )}
                                    </Row>

                                    <Button
                                        variant="success"
                                        disabled={props.submitting}
                                        type="submit"
                                    >
                                        Salvar informações
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    );
}

export default HumanResources;
