import * as Yup from "yup";

export const intialFundamentalsValues = {
    companyLiders: "",
    companyProfileTeam: "",
    companyKnowledgeRequired: "",
    companyMethod: "",
    companyStructure: "",
    what: "",
    instead: "",
    ourService: "",
};

export const CompanyFundamentalsSchema = Yup.object().shape({
    companyLiders: Yup.string().min(10, "Muito curto!").max(300, "Muito Longo"),
    companyProfileTeam: Yup.string()
        .min(10, "Muito curto!")
        .max(300, "Muito Longo"),
    companyKnowledgeRequired: Yup.string()
        .min(10, "Muito curto!")
        .max(300, "Muito Longo"),
    companyMethod: Yup.string().min(10, "Muito curto!").max(300, "Muito Longo"),
    companyStructure: Yup.string()
        .min(10, "Muito curto!")
        .max(300, "Muito Longo"),
    what: Yup.string().min(10, "Muito curto!").max(300, "Muito Longo"),
    instead: Yup.string().min(10, "Muito curto!").max(300, "Muito Longo"),
    ourService: Yup.string().min(10, "Muito curto!").max(300, "Muito Longo"),
});
