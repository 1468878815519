import React from 'react';

import styles from './styles.module.css';

function UserAvatar({ imageSrc, username = '' }) {
  return (
    <div className={styles.container}>
      { imageSrc ? (
        <img src={imageSrc} alt={`Avatar ${username}`} />
      ) : (
        <span>{username.substring(0, 2).toUpperCase()}</span>
      )}
    </div>
  );
}

export default UserAvatar;