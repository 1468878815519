import * as Yup from "yup";

const months = {
    jan: 0,
    fev: 0,
    mar: 0,
    abr: 0,
    mai: 0,
    jun: 0,
    jul: 0,
    ago: 0,
    set: 0,
    out: 0,
    nov: 0,
    dez: 0,
};

export const budget = {
    jan: {
        unit: 0,
        price: 0,
        total: 0,
    },
    fev: {
        unit: 0,
        price: 0,
        total: 0,
    },
    mar: {
        unit: 0,
        price: 0,
        total: 0,
    },
    abr: {
        unit: 0,
        price: 0,
        total: 0,
    },
    mai: {
        unit: 0,
        price: 0,
        total: 0,
    },
    jun: {
        unit: 0,
        price: 0,
        total: 0,
    },
    jul: {
        unit: 0,
        price: 0,
        total: 0,
    },
    ago: {
        unit: 0,
        price: 0,
        total: 0,
    },
    set: {
        unit: 0,
        price: 0,
        total: 0,
    },
    out: {
        unit: 0,
        price: 0,
        total: 0,
    },
    nov: {
        unit: 0,
        price: 0,
        total: 0,
    },
    dez: {
        unit: 0,
        price: 0,
        total: 0,
    },
    total: {
        totalMain: 0,
        totalPrice: 0,
        totalUnit: 0,
    },
};

export const monthTotalizers = {
    jan: { total: 0 },
    fev: { total: 0 },
    mar: { total: 0 },
    abr: { total: 0 },
    mai: { total: 0 },
    jun: { total: 0 },
    jul: { total: 0 },
    ago: { total: 0 },
    set: { total: 0 },
    out: { total: 0 },
    nov: { total: 0 },
    dez: { total: 0 },
};

export const totalizers = {
    jan: {
        totalMain: 0,
        totalUnit: 0,
    },
    fev: {
        totalMain: 0,
        totalUnit: 0,
    },
    mar: {
        totalMain: 0,
        totalUnit: 0,
    },
    abr: {
        totalMain: 0,
        totalUnit: 0,
    },
    mai: {
        totalMain: 0,
        totalUnit: 0,
    },
    jun: {
        totalMain: 0,
        totalUnit: 0,
    },
    jul: {
        totalMain: 0,
        totalUnit: 0,
    },
    ago: {
        totalMain: 0,
        totalUnit: 0,
    },
    set: {
        totalMain: 0,
        totalUnit: 0,
    },
    out: {
        totalMain: 0,
        totalUnit: 0,
    },
    nov: {
        totalMain: 0,
        totalUnit: 0,
    },
    dez: {
        totalMain: 0,
        totalUnit: 0,
    },
};

export const initialValueChainValues = {
    companyValueChainObjectives: [
        {
            companyValueChainObjectives: "",
            companyValueChainGoals: "",
            companyValueChainActionPlan: "",
        },
    ],
    companyValueChainStrategy: "",
    companyValueChainPerformanceIndicators: [
        {
            companyValueChainPerformanceIndicators: "",
        },
    ],
    companyValueChainESG: [
        {
            companyValueChainEnvironmental: [
                {
                    companyValueChainEnvironmental: "",
                },
            ],
            companyValueChainSocial: [
                {
                    companyValueChainSocial: "",
                },
            ],
            companyValueChainGovernance: [
                {
                    companyValueChainGovernance: "",
                },
            ],
        },
    ],
    companyValueChainCostExpenses: [
        {
            year: new Date().getFullYear(),
            companyValueChainFixedCost: {
                companyValueChainFixedCost: [
                    {
                        companyValueChainDescription: "",
                        companyValueChainFixedCost: months,
                        companyValueChainTotal: 0,
                        companyValueChainInvestiment: false,
                    },
                ],
                monthsTotal: monthTotalizers,
                total: 0,
            },
            companyValueChainVariableCost: {
                companyValueChainVariableCost: [
                    {
                        companyValueChainDescription: "",
                        companyValueChainVariableCost: months,
                        companyValueChainTotal: 0,
                        companyValueChainInvestiment: false,
                    },
                ],
                monthsTotal: monthTotalizers,
                total: 0,
            },
            companyValueChainExpenses: {
                companyValueChainExpenses: [
                    {
                        companyValueChainDescription: "",
                        companyValueChainExpenses: months,
                        companyValueChainTotal: 0,
                        companyValueChainInvestiment: false,
                    },
                ],
                monthsTotal: monthTotalizers,
                total: 0,
            },
        },
    ],
    companyValueChainBudget: [
        {
            year: new Date().getFullYear(),
            companyValueChainBudget: [
                {
                    companyValueChainProduct: "",
                    companyValueChainBudget: budget,
                },
            ],
            total: totalizers,
            generalTotalizer: 0,
            generalUnitTotalizer: 0,
        },
    ],
};

export const CompanyValueChainSchema = Yup.object().shape({
    companyValueChainObjectives: Yup.array().of(
        Yup.object().shape({
            companyValueChainObjectives: Yup.string()
                .min(10, "Muito curto!")
                .max(300, "Muito Longo"),
            companyValueChainGoals: Yup.string()
                .min(10, "Muito curto!")
                .max(300, "Muito Longo"),
            companyValueChainActionPlan: Yup.string()
                .min(10, "Muito curto!")
                .max(800, "Muito Longo"),
        })
    ),
    companyValueChainStrategy: Yup.string()
        .min(10, "Muito curto!")
        .max(300, "Muito Longo"),
    companyValueChainPerformanceIndicators: Yup.array().of(
        Yup.object().shape({
            companyValueChainPerformanceIndicators: Yup.string()
                .min(10, "Muito curto!")
                .max(300, "Muito Longo"),
        })
    ),
    companyValueChainESG: Yup.array().of(
        Yup.object().shape({
            companyValueChainEnvironmental: Yup.array().of(
                Yup.object().shape({
                    companyValueChainEnvironmental: Yup.string()
                        .min(10, "Muito curto!")
                        .max(300, "Muito Longo"),
                })
            ),
            companyValueChainSocial: Yup.array().of(
                Yup.object().shape({
                    companyValueChainSocial: Yup.string()
                        .min(10, "Muito curto!")
                        .max(300, "Muito Longo"),
                })
            ),
            companyValueChainGovernance: Yup.array().of(
                Yup.object().shape({
                    companyValueChainGovernance: Yup.string()
                        .min(10, "Muito curto!")
                        .max(300, "Muito Longo"),
                })
            ),
        })
    ),
});
