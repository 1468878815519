import React from 'react';
import css from '../strategic-position/companyoverview.module.css';
import {Field, ErrorMessage} from 'formik';
import CurrencyInput from '../../CurrencyInput';

function FormInput({disabled = false, ...props}) {
    return (
        <>
            { props.children && <div className={`${css.input_label}`}><label htmlFor={props.name}>{props.children}</label></div> }
            <div className="mb-2">
                { props.type === 'currency' ? (
                    <Field
                        id={props.name}
                        name={props.name}
                        disabled={disabled}
                        as={props.as}
                        rows={props.rows}
                        placeholder={props.holder}
                        type={'tel'}
                    >
                        {({ field, form }) => (
                            <CurrencyInput
                                {...field}
                                disabled={disabled}
                                onChange={(value) => form.setFieldValue(field.name, value)}
                                className={`w-100 form-field rounded ${css.input_border} ${(props.errors[props.name] && props.touched[props.name]) || (props.errors[props.original] && props.touched[props.original]) ? css.input_error : ""}`}
                            />
                        )}
                    </Field>
                ) : (
                    <Field
                        className={`w-100 form-field rounded ${css.input_border} ${(props.errors[props.name] && props.touched[props.name]) || (props.errors[props.original] && props.touched[props.original]) ? css.input_error : ""}`}
                        id={props.name}
                        name={props.name}
                        type={props.type}
                        disabled={disabled}
                        as={props.as}
                        rows={props.rows}
                        placeholder={props.holder}
                    />
                )}
            </div>
            <ErrorMessage
                name={props.name}
                component="div"
                className={`error-message ${css.error_message}`}
            />
        </>
    )
}

export default FormInput
