import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { formatCurrency } from '../../utils/formatCurrency';

function cleanUpCurrency(val) {
  return val.replace(/[^\d,]/g, '').replace(/[,]/g, '.').trim();
}

function CurrencyInput({ value, onChange, onBlur, ...rest }) {
  const [innerValue, setInnerValue] = useState(formatCurrency(value));
  const innerRef = useRef(null);

  useEffect(() => {
    setInnerValue(formatCurrency(value))
  }, [value])

  function handleChange(e) {
    const raw = cleanUpCurrency(e.target.value);
    const commaIndex = raw.indexOf('.');
    let updated = raw;

    if (raw === "") {
      updated = 0;
    }

    if (commaIndex === -1 && updated) {
      updated = `0.${updated.padStart('2', '0')}`;
    }

    if (commaIndex === raw.length - 4 && commaIndex !== -1) {
      let [beforeComma, afterComma] = raw.split('.');
      beforeComma = `${beforeComma}${afterComma[0]}`;
      afterComma = afterComma.substring(1);

      updated = `${beforeComma}.${afterComma}`;
    }

    if (commaIndex === raw.length - 2 && commaIndex !== -1) {
      let [beforeComma, afterComma] = raw.split('.');
      afterComma = `${beforeComma[beforeComma.length - 1]}${afterComma}`;
      beforeComma = beforeComma.substring(0, beforeComma.length - 1);

      updated = `${beforeComma}.${afterComma}`;
    }

    setInnerValue(formatCurrency(updated))
    if (onChange) onChange(parseFloat(updated));
  }

  function handleBlur(e) {
    const raw = cleanUpCurrency(e.target.value);
    const commaIndex = raw.indexOf('.');
    let updated = raw;

    if (raw === "") {
      updated = 0;
    }

    if (commaIndex === -1 && updated) {
      updated = `0.${updated.padStart('2', '0')}`;
    }

    if (commaIndex === raw.length - 4 && commaIndex !== -1) {
      let [beforeComma, afterComma] = raw.split('.');
      beforeComma = `${beforeComma}${afterComma[0]}`;
      afterComma = afterComma.substring(1);

      updated = `${beforeComma}.${afterComma}`;
    }

    if (commaIndex === raw.length - 2 && commaIndex !== -1) {
      let [beforeComma, afterComma] = raw.split('.');
      afterComma = `${beforeComma[beforeComma.length - 1]}${afterComma}`;
      beforeComma = beforeComma.substring(0, beforeComma.length - 1);

      updated = `${beforeComma}.${afterComma}`;
    }

    setInnerValue(formatCurrency(updated))
    if (onBlur) onBlur(parseFloat(updated))
  }

  return (
    <input
      {...rest}
      value={innerValue}
      ref={innerRef}
      onChange={handleChange}
      onBlur={handleBlur}
      type="tel"
    />
  );
}

export default CurrencyInput;