import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
    MdHome,
    MdGroup,
    MdTrackChanges,
    MdArrowDropDown,
    MdArrowForward,
} from "react-icons/md";

import styles from "./styles.module.css";
import PrivateComponent from "../PrivateComponent";
import permissionEnum from "../../enums/permission";
import classNames from "classnames";

import logo from "../../images/logo.png";

const menu = [
    {
        path: "/tour",
        title: "CEO360 RV - GESTÃO VIRTUAL",
        icon: <MdArrowForward />,
    },
    {
        path: "/",
        title: "Home",
        icon: <MdHome />,
        exact: true,
    },
    {
        path: "/forms",
        title: "Gestão 360",
        icon: <MdTrackChanges />,
        subMenu: [
            {
                path: "/forms/strategic",
                title: "Posição estratégica",
                permissionId: permissionEnum.FORM_TYPE.STRATEGIC_POSITION,
            },
            {
                path: "/forms/dynamic",
                title: "Dinâmica de mercado",
                permissionId: permissionEnum.FORM_TYPE.MARKET_DYNAMIC,
            },
            {
                path: "/forms/macro",
                title: "Forças Macro Ambientais",
                permissionId:
                    permissionEnum.FORM_TYPE.MACRO_ENVIROMENTAL_FORCES,
            },
            {
                path: "/forms/businessplan",
                title: "Plano de Negócio",
                permissionId: permissionEnum.FORM_TYPE.BUSINESS_PLAN,
            },
        ],
    },
    {
        path: "/user-management",
        title: "Gestão de usuários",
        icon: <MdGroup />,
        permissionIsAdmin: true,
    },
];

function withPermission(Component, permissionId, permissionIsAdmin) {
    if (permissionId || permissionIsAdmin) {
        return (
            <PrivateComponent
                permission={permissionId}
                isAdmin={permissionIsAdmin}
                key={Component.key}
            >
                {Component}
            </PrivateComponent>
        );
    } else {
        return Component;
    }
}

function Sidebar({ collapsed }) {
    const [activeMenu, setActiveMenu] = useState("");

    return (
        <nav
            className={classNames("flex-column py-2", styles.container, {
                [styles.collapsed]: collapsed,
            })}
        >
            <Link to="/">
                <div className="text-center">
                    <img src={logo} alt="CEO360" className={styles.logo} />
                </div>
            </Link>

            <ul className={styles["primary-nav"]}>
                {menu.map((menuItem) =>
                    withPermission(
                        <NavItem
                            collapsed={collapsed}
                            title={menuItem.title}
                            icon={menuItem.icon}
                            subMenu={menuItem.subMenu}
                            path={menuItem.path}
                            key={menuItem.path}
                            useActiveMenu={[activeMenu, setActiveMenu]}
                            exact={menuItem.exact}
                        />,
                        menuItem.permissionId,
                        menuItem.permissionIsAdmin
                    )
                )}
            </ul>
        </nav>
    );
}

function NavItem({
    title,
    icon,
    subMenu,
    path,
    collapsed,
    className,
    useActiveMenu,
    exact,
}) {
    return !collapsed ? (
        <li>
            <CollapsedNavItem
                path={path}
                className={classNames(className)}
                title={title}
                icon={icon}
                exact={exact}
            >
                {subMenu?.map((menuItem) => (
                    <li key={menuItem.path}>
                        <NavLink path={menuItem.path} exact={menuItem.exact}>
                            {menuItem.title}{" "}
                        </NavLink>
                    </li>
                ))}
            </CollapsedNavItem>
        </li>
    ) : (
        <li>
            {subMenu ? (
                <SubMenu
                    path={path}
                    title={title}
                    icon={icon}
                    subMenu={subMenu}
                    useActiveMenu={useActiveMenu}
                    exact={exact}
                />
            ) : (
                <NavLink
                    exact={exact}
                    path={path}
                    className={classNames(className)}
                >
                    {icon}
                    {title}
                </NavLink>
            )}
        </li>
    );
}

function SubMenu({ title, icon, path, subMenu, useActiveMenu }) {
    const [activeMenu, setActiveMenu] = useActiveMenu;
    const location = useLocation();

    function handleClick() {
        if (activeMenu === path) setActiveMenu("");
        else setActiveMenu(path);
    }

    useEffect(() => {
        if (location.pathname.includes(path)) {
            setActiveMenu(path);
        }
    }, [location, setActiveMenu, path]);

    return (
        <ul
            className={classNames(styles["secondary-nav"], {
                [styles.active]: activeMenu === path,
            })}
        >
            <li onClick={handleClick}>
                <NavLink
                    path={path}
                    isLink={false}
                    className={classNames({
                        [styles.active]: activeMenu === path,
                    })}
                >
                    {icon}
                    {title}
                    <MdArrowDropDown
                        className={classNames(styles.arrow, {
                            [styles.active]: activeMenu === path,
                        })}
                    />
                </NavLink>
                <div
                    className={classNames(styles["submenu-links"], {
                        [styles.active]: activeMenu === path,
                    })}
                >
                    {subMenu.map((menuItem) => (
                        <NavLink key={menuItem.path} path={menuItem.path}>
                            {menuItem.title}
                        </NavLink>
                    ))}
                </div>
            </li>
        </ul>
    );
}

function CollapsedNavItem({ children, icon, title, path, exact }) {
    const [hover, setHover] = useState(false);
    const location = useLocation();

    function isActive() {
        return exact
            ? location.pathname === path
            : location.pathname.includes(path);
    }

    return (
        <>
            <button
                className={classNames(styles["primary-nav-toggler"], {
                    [styles.hovered]: hover,
                    [styles["active"]]: isActive(),
                })}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {icon}
            </button>
            <ul
                className={classNames(styles["primary-nav-collapsed"], {
                    [styles.hovered]: hover,
                })}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <li>
                    <NavLink path={path}>{title}</NavLink>
                </li>
                {children}
            </ul>
        </>
    );
}

function NavLink({ exact, children, path, isLink = true, className }) {
    const location = useLocation();

    function isActive() {
        return exact
            ? location.pathname === path
            : location.pathname.includes(path);
    }

    return isLink ? (
        <Link
            to={path}
            className={classNames(
                styles["nav-link"],
                { [styles["active"]]: isActive() },
                className
            )}
        >
            {children}
        </Link>
    ) : (
        <button
            to={path}
            className={classNames(
                styles["nav-link"],
                { [styles["active"]]: isActive() },
                className
            )}
        >
            {children}
        </button>
    );
}

export default Sidebar;
