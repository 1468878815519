import React, {useEffect, useState, useCallback} from 'react';
import {Row} from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';

const genData = () => ({
    labels: ['Produto 1', 'Produto 2', 'Produto 3'],
    datasets: [
        {
            label: 'Produtos now',
            data: [0, 0, 0],
            backgroundColor: [
                '#FFE83B',
                '#5D6AFA',
                '#52BC8C',
                '#341A38',
                '#D2691E',
                '#8B008B',
                '#B0E0E6',
            ],
            borderColor: [
                '#FFE83B',
                '#8791FB',
                '#52BC8C',
                '#341A38',
                '#D2691E',
                '#8B008B',
                '#B0E0E6',
            ],
            borderWidth: 1,
            lineTension: 0.1,
            cutout: '80%'
        },
    ],
});

function SalesAnlytics(props) {
    const [graphData, setGraphData] = useState(genData());
    const [monthOne, setMonthOne] = useState("jan");
    const [arrayKey, setArrayKey] = useState(props.arrayKey);

    const handleMonthChange = useCallback((ev)=>{
        setMonthOne(ev.target.value);
        const graph = genData();
        graph.labels = [];
        graph.datasets[0].data = [];
        const data = [...props.data];
        if(data && data.length > 0){
            const monthOne = data[0].companyValueChainBudget.map(hit=>(
                {
                    "prod": hit.companyValueChainProduct,
                    "value": hit.companyValueChainBudget[ev.target.value][arrayKey]
                }
            ));

            monthOne.forEach(hit=>{
                graph.labels.push(hit.prod);
                graph.datasets[0].data.push(hit.value);
                setGraphData(graph);
            });
        }
    }, [props.data, arrayKey]);

    useEffect(() => {
        setArrayKey(props.arrayKey);
        handleMonthChange({target:{value: 'jan'}});
    }, [props.data, props.arrayKey, handleMonthChange]);

    return (
        <div className="border rounded p-4 mb-4 bg-white">
            <Row className="p-0 m-0 justify-content-between align-items-center card__header--home">
                <h6 className="mb-0">{props.title}</h6>
                <select value={monthOne} onChange={handleMonthChange} className="select_input rounded">
                    <option value="jan">Jan</option>
                    <option value="fev">Fev</option>
                    <option value="mar">Mar</option>
                    <option value="abr">Abr</option>
                    <option value="mai">Mai</option>
                    <option value="jun">Jun</option>
                    <option value="jul">Jul</option>
                    <option value="ago">Ago</option>
                    <option value="set">Set</option>
                    <option value="out">Out</option>
                    <option value="nov">Nov</option>
                    <option value="dez">Dez</option>
                </select>
            </Row>
            <hr />
            {props.data.length > 0 ?
            <Row className="p-4">
                <Doughnut data={graphData} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'right',
                            align: "start"
                        }
                    }
                }}/>
            </Row>
            :
            <Row className="justify-content-center p-4">
                <div>Não há dados para mostrar</div>
            </Row>
            }
        </div>
    );
}

export default SalesAnlytics;