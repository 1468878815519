import axios from 'axios';

import { trimKeys } from '../utils/formatObject';

const server = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT || "http://localhost:3000",
  headers: {
    "Content-type": "application/json",
  }
})

export function createOnboard(data, accessToken) {
  return server.post('/onboard', trimKeys(data), {
    headers: {
      'Authorization': `Bearer ${accessToken}`
    }
  });
}