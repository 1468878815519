import React, {useState, useEffect} from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Row } from 'react-bootstrap';
import {Link, withRouter} from 'react-router-dom';
import FormInput from '../components/FormInput';
import FormButton from '../components/FormButton';
import {postUser} from '../../../services/users/endpoint';
import { useAuth } from '../../../providers/AuthProvider';

import authimg from './imgs/authimg.png'
import logo from './imgs/id_login.png'
import css from './auth.module.css'

export const intialConfirmationValues ={
    username: "",
    code: ""
}

export const ConfirmationSchema = Yup.object().shape({
    username: Yup.string().required(),
    code: Yup.string().required()
});

function Confirmation(props) {
    const [errorResend, setErrorResend] = useState({error: true, message: ""});
    const [errorForm, setErrorForm] = useState({error: false, message: ""});

    const { confirmUser } = useAuth();

    function handleSubmit(values, formikBag) {
        setErrorForm({error: false, message: ""});
        confirmUser(values)
            .then(() => {
                const dataToSend = { username: values.username, role: 'visitor', isAdmin: true }
                    postUser(dataToSend, (err, user)=>{
                        if(err){
                            console.log("Erro ao criar o usuário: ", err);
                        }
                        props.history.push("/user-disabled");
                    });
            })
            .catch((err) => {
                console.log(err);
                setErrorForm({error: true, message: err.message});
                formikBag.setSubmitting(false);
            })
    }

    useEffect(()=>{
        setErrorResend({error: false, message: ""});
    }, [])

    return (
        <div className={css.authContainer}>
            <section className={css.form}>
            <Link to="/"><img src={logo} alt="CEO360" /></Link>
            <Formik
                initialValues={intialConfirmationValues}
                validationSchema={ConfirmationSchema}
                onSubmit={handleSubmit}>
                {({errors, touched, isSubmitting})=>(
                    <Form>
                        <h4>Confirmação</h4>
                        <FormInput name="username"  type="text" errors={errors} touched={touched} holder="Usuário"/>
                        <FormInput name="code"  type="code" errors={errors} touched={touched} holder="Código"/>
                        <Row className="p-0 m-0">
                            {errorForm.error &&
                            <span style={{color:'red'}}>{errorForm.message}</span>
                            }
                        </Row>
                        <Row className="p-0 m-0 justify-content-end">
                            <FormButton disabled={isSubmitting} type="submit" text="Enviar" />
                        </Row>
                        <Link className={css.authLink} to="/resend">
                            Reenviar código
                        </Link>
                        <Link className={css.authLink} to="/signin">
                            Já tenho usuário
                        </Link>
                    </Form>
                )}
            </Formik>
            <Row className="justify-content-center">
                {errorResend.error &&
                    <span style={{color: 'red'}}>Para enviar o cógio é necessário o username</span>
                }
            </Row>
            </section>
            <img className={css.authImg} src={authimg} alt="Business"/>
        </div>
    )
}

export default withRouter(Confirmation);