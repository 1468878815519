import * as Yup from 'yup';

export const intialMacroEnvDynamic ={
    macroEnvPolitics: [
        {macroEnvPolitics: ""}
    ],
    macroEnvEconomy: [
        {macroEnvEconomy: ""}
    ],
    macroEnvCulture:[
        {macroEnvCulture: ""}
    ],
    macroEnvTechnology:[
        {macroEnvTechnology: ""}
    ],
    macroEnvDemography:[
        {macroEnvDemography: ""}
    ],
    macroEnvNature:[
        {macroEnvNature: ""}
    ],
}

export const MacroEnvSchema = Yup.object().shape({
    macroEnvPolitics: Yup.array()
    .of(
        Yup.object().shape({
            macroEnvPolitics: Yup.string()
            .min(2, 'Muito curto!')
            .max(500, 'Muito Longo')            
        })
    ),
    macroEnvEconomy: Yup.array()
    .of(
        Yup.object().shape({
            macroEnvEconomy: Yup.string()
            .min(2, 'Muito curto!')
            .max(500, 'Muito Longo')            
        })
    ),
    macroEnvCulture: Yup.array()
    .of(
        Yup.object().shape({
            macroEnvCulture: Yup.string()
            .min(2, 'Muito curto!')
            .max(500, 'Muito Longo')            
        })
    ),
    macroEnvTechnology: Yup.array()
    .of(
        Yup.object().shape({
            macroEnvTechnology: Yup.string()
            .min(2, 'Muito curto!')
            .max(500, 'Muito Longo')            
        })
    ),
    macroEnvDemography: Yup.array()
    .of(
        Yup.object().shape({
            macroEnvDemography: Yup.string()
            .min(2, 'Muito curto!')
            .max(500, 'Muito Longo')            
        })
    ),
    macroEnvNature: Yup.array()
    .of(
        Yup.object().shape({
            macroEnvNature: Yup.string()
            .min(2, 'Muito curto!')
            .max(500, 'Muito Longo')            
        })
    ),
});