import React from "react";
import {budget} from "../helpers/formValidation";
import {FieldArray} from "formik";
import {Col, Row, Button} from "react-bootstrap";
import css from '../../../../../Button/styles.module.css';
import Month from "./Month";
import Totalizers from "./Totalizers";
import FormInputDebounce from "../../../../components/FormInputDebounce";
import {MdAdd} from "react-icons/md";
const indexName = "companyValueChainBudget";

function BudgetProjection({values, item, index, name, button}){
    return (
        <FieldArray name={`[${indexName}][${index}][${name}]`}>
            {({remove, push})=>(
                <div className="mt-4 mb-4">
                    {item[name]?.length > 0 &&
                    item[name]?.map((subItem, subIndex)=>(
                        <Row className="p-0 m-0 mb-5" key={subIndex}>
                            <Col className="p-0 m-0" lg={1}>
                                <label className="w-100">Produto</label>
                                <FormInputDebounce
                                    name={`[${indexName}][${index}][${name}][${subIndex}].companyValueChainProduct`}
                                    values={values[indexName][index][name][subIndex].companyValueChainProduct}
                                    type="text"
                                >
                                    Item
                                </FormInputDebounce>
                            </Col>
                            <Col className="p-0 m-0" lg={10}>
                                <Row className="p-0 m-0">
                                    <Col className="p-0 m-0" lg={11}>
                                        <Row className="p-0 m-0">
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Jan"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Fev"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Mar"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Abr"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Mai"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Jun"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Jul"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Ago"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Set"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Out"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Nov"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                            <Col className="p-0 m-0 text-center" lg={1}>
                                                <Month
                                                    month="Dez"
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                    values={values[indexName][index][name][subIndex][name]}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="p-0 m-0" lg={1}>
                                        <Row className="p-0 m-0">
                                            <Col className="p-0 m-0 text-center">
                                                <Totalizers
                                                    name={`[${indexName}][${index}][${name}][${subIndex}][${name}]`}
                                                    index={index}
                                                    subindex={subIndex}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="p-2 m-0" lg={1}>
                                <Row className="p-0 m-0 h-100 justify-content-center align-items-center">
                                        <Button
                                            type="button"
                                            variant="outline-danger"
                                            onClick={()=>{
                                                if(item[name].length > 1){
                                                    remove(subIndex);
                                                }
                                            }}
                                            className="mt-2"
                                        ><i className="fas fa-trash-alt"/></Button>
                                        <Button
                                            type="button"
                                            variant="outline-info"
                                            onClick={()=>{
                                                let objectToPush = {companyValueChainProduct: ""};
                                                objectToPush[name] = values[indexName][index][name][subIndex][name];
                                                push(objectToPush);
                                            }}
                                            className="mt-2 ml-1"
                                        ><i className="far fa-copy"/></Button>
                                </Row>
                            </Col>
                        </Row>
                    ))
                    }
                    <Button
                        type="button"
                        variant="outline-success"
                        onClick={()=>{
                            console.log(name);
                            let objectToPush = {companyValueChainProduct: ""};
                            objectToPush[name] = budget;
                            push(objectToPush);
                        }}
                        className={`mt-2 w-100 ${css.btn}`}
                    ><MdAdd fontSize="20" className="mr-1" />{button}</Button>
                </div>
            )}
        </FieldArray>
    )
}

export default BudgetProjection;