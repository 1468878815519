import React from "react";
import { StyleSheet, View, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    marginSection: {
        marginLeft: 28,
    },
    paragraph: {
        fontSize: 11,
        marginLeft: 12,
        marginBottom: 8,
    },
    subtitle: {
        fontSize: 12,
        color: "#1e0f70",
        marginBottom: 4,
    },
});

const DiretrizesEstrategicas = ({ diretEstratProp }) => {
    return (
        <>
            <View style={styles.marginSection}>
                <Text style={styles.subtitle}>Propósito da empresa</Text>
                <Text style={styles.paragraph}>
                    {diretEstratProp.companyPropose}
                </Text>
                <Text style={styles.subtitle}>Missão</Text>
                <Text style={styles.paragraph}>
                    {diretEstratProp.companyMission}
                </Text>
                <Text style={styles.subtitle}>Visão</Text>
                <Text style={styles.paragraph}>
                    {diretEstratProp.companyVision}
                </Text>
                <Text style={styles.subtitle}>Valores e princípios</Text>
                <Text style={styles.paragraph}>
                    {diretEstratProp.Principals}
                </Text>
                <Text style={styles.subtitle}>Cultura - Nossos Valores</Text>
                <Text style={styles.paragraph}>
                    {diretEstratProp.organizacionalCultureValues}
                </Text>
                <Text style={styles.subtitle}>Cultura - Nossos Princípios</Text>
                <Text style={styles.paragraph}>
                    {diretEstratProp.organizacionalCulturePrinciples}
                </Text>
            </View>
        </>
    );
};

export default DiretrizesEstrategicas;
