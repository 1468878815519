import React, {useState, useEffect} from 'react';
import {Row, Col, FormGroup} from 'react-bootstrap';
import css from '../marketdynamicoverview.module.css';
import {intialCompetitorDynamic, CompetitorDynamicSchema} from './helpers/formValidation';
import { Formik, Field, Form, ErrorMessage} from 'formik';
import FormButton from '../../components/FormButton';
import FormInput from '../../components/FormInput';
import Button from '../../../Button';
import FormSelect from '../../../FormSelect';
import FormValidationError from "../../components/FormValidationError";

function CompetitorDynamic(props) {
    const [formValues, setFormValues] = useState(intialCompetitorDynamic);

    useEffect(()=>{
        let initialValues = {...intialCompetitorDynamic, ...props.questions};
        setFormValues(initialValues);
    }, [props.questions])

    return (
        <Formik
        initialValues={formValues}
        validationSchema={CompetitorDynamicSchema}
        validate={values=>{
            props.setChanged(values !== formValues);
        }
        }
        onSubmit={(values)=>{
            props.setSubmitting(true);
            props.competitorSubmit(values, props.group);
        }}
        enableReinitialize
        >
            {({errors, touched})=>(
                <Form className="p-4">
                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">Barreira de entrada</h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={4}>
                                    <FormSelect 
                                        name="marketDynamicBarrierEntranceLevel" 
                                        id="marketDynamicBarrierEntranceLevel" 
                                        label="Nível de barreira de entrada"
                                    >
                                        <option value="H">High</option>    
                                        <option value="M">Medium</option>    
                                        <option value="L">Low</option> 
                                    </FormSelect>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput name="marketDynamicBarrierEntranceLevelComment"  as="textarea" rows={3} errors={errors} touched={touched} holder="Entre com seu comentário" >
                                            Comentário sobre as barreiras acima
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">Atratividade do Setor</h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={4}>
                                    <FormSelect 
                                        name="marketDynamicBusinessAtractiveness" 
                                        id="marketDynamicBusinessAtractiveness" 
                                        label="Atrativos para o negócio"
                                    >
                                        <option value="H">High</option>    
                                        <option value="M">Medium</option>    
                                        <option value="L">Low</option> 
                                    </FormSelect>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput name="marketDynamicBusinessAtractivenessComment"  as="textarea" rows={3} errors={errors} touched={touched} holder="Entre com seu comentário" >
                                            Comentário sobre os atrativos
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">Perfil do Negócio</h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={4}>
                                    <FormSelect 
                                        name="marketDynamicBusinessComplementary" 
                                        id="marketDynamicBusinessComplementary" 
                                        label="Seu negócio é complementar a outro negócio?"
                                    >
                                        <option value="sim">Sim</option>    
                                        <option value="nao">Não</option>    
                                        <option value="nao sei">Não sei</option> 
                                    </FormSelect>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput name="marketDynamicBusinessComplementaryComment"  as="textarea" rows={3} errors={errors} touched={touched} holder="Entre com seu comentário" >
                                            Comentário sobre o negocio
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="py-4">
                        <Col xs={12} lg={4} className="border-right">
                            <h4 className="text-muted font-weight-light text-right mr-3">Possíveis Riscos</h4>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput name="marketDynamicStrategyMinimizeRisk" type="" as="textarea" rows="3" errors={errors} touched={touched} holder="Entre com a estratégia para minimizar os riscos" >
                                            Estratégia para minimizar os riscos
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} lg={6}>
                                    <FormGroup>
                                        <FormInput name="marketDynamicStrategyMinimizeRiskComment"  as="textarea" rows={3} errors={errors} touched={touched} holder="Entre com seu comentário" >
                                            Comentário sobre os riscos
                                        </FormInput>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className="p-0 m-0 mt-3 justify-content-end">
                                {Object.keys(errors).length > 0 &&
                                <FormValidationError
                                    errors={errors}
                                />
                                }
                            </Row>

                            <Row>
                                <Col><Button variant="success" disabled={props.submitting} type="submit">Salvar informações</Button></Col>
                            </Row>
                        </Col>
                    </Row>

                    
                </Form>
            )}
        </Formik>
    )
}

export default CompetitorDynamic
