import React from 'react';
import {Col} from "react-bootstrap";

function PersonalDataUnit({data, title, index}) {
    return (
        <div className="p-0 mb-3">
            <label style={{fontWeight: '800', fontSize: 'var(--font-size-sm)'}}>{title}:</label>
            <div style={{overflowWrap: 'anywhere'}}>{data[index] ? <span>{data[index]}</span> : <span className="text-muted" style={{ fontSize: 'var(--font-size-sm)' }}>Não preenchido</span>}</div>
        </div>
    );
}

export default PersonalDataUnit;